import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Labels } from "../../utils/constants";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import { SigningRequestStatus } from "../../utils/enums";
import { Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setRequestApprovalFormState } from "./signingRequestsSlice";
import { FormType } from "../../utils/constants";
import { initializeForm } from "../forms/formSlice";

export default function SigningRequestDetailTable({ dispatch, requestData }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function renderStatusCell(requestData) {
    let { status, approvalStatus } = requestData;

    let cellText = "";

    switch (status) {
      case SigningRequestStatus.ApprovePending:
        if (approvalStatus) {
          cellText = "In Progress";
        } else {
          cellText = "Pending";
        }
        break;
      case SigningRequestStatus.Approved:
        cellText = "Approved";
        break;
      case SigningRequestStatus.Signed:
        cellText = "Signed";
        break;
      case SigningRequestStatus.Rejected:
        cellText = "Rejected";
        break;
      default:
        cellText = "Pending";
        break;
    }

    function getBGColorForCell(cellText) {
      switch (cellText) {
        case "Pending": {
          return "#fffbb0";
        }
        case "Approved": {
          return "success.main";
        }
        case "Signed": {
          return "";
        }
        case "In Progress": {
          return "#fffbb0";
        }
        case "Rejected": {
          return "error.main";
        }
        default: {
          return "";
        }
      }
    }

    return (
      <Box
        sx={{
          width: "90px",
          textAlign: "center",
          paddingBlock: 0.75,
          color: "primary.main",
          borderRadius: "5px",
          backgroundColor: getBGColorForCell(cellText),
          fontWeight: cellText === "Signed" ? 700 : 500,
        }}
      >
        {cellText}
      </Box>
    );
  }

  function handleRequestApprove() {
    dispatch(setRequestApprovalFormState(true));

    dispatch(
      initializeForm({
        formType: FormType.SIGNING_REQUEST,
        serviceData: {
          ...requestData,
          type: "approve",
          isUserSigningOfficer: true,
        },
      })
    );

    setAnchorEl(null);
  }

  function handleRequestReject() {
    dispatch(setRequestApprovalFormState(false));
    dispatch(
      initializeForm({
        formType: FormType.SIGNING_REQUEST,
        serviceData: {
          ...requestData,
          type: "reject",
          isUserSigningOfficer: true,
        },
      })
    );

    setAnchorEl(null);
  }

  function renderActionCell(requestData) {
    if (
      requestData.status === SigningRequestStatus.ApprovePending &&
      !requestData.approvalStatus
    ) {
      return (
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
            pl: 2,
          }}
        >
          <IconButton
            onClick={(event) => setAnchorEl(event.target)}
            size="small"
            color="primary"
            aria-label="more actions"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            sx={{
              "& .MuiPaper-root .MuiList-root": {
                minWidth: "150px",
                py: 0,
                "& .MuiButtonBase-root": {
                  py: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  bgcolor: "tertiary.light",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "tertiary.main",
                  },
                  "& .MuiListItemText-root .MuiTypography-root": {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                },
              },
            }}
            id="action-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => handleRequestApprove()}>
              {Labels.APPROVE}
            </MenuItem>
            <MenuItem onClick={() => handleRequestReject()}>
              {Labels.REJECT}
            </MenuItem>
          </Menu>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontSize: "1rem", pl: 3.25, fontWeight: 600 }}
          >
            -
          </Typography>
        </Box>
      );
    }
  }

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow
            className="custom-scroll-bar"
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{Labels.REQUEST_ID}</TableCell>
            <TableCell>{Labels.ENVIRONMENT_NAME}</TableCell>
            <TableCell>{Labels.APPLICATION_NAME}</TableCell>
            <TableCell>{Labels.SUBMITTED_ON}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{requestData?.requestID ?? "-"}</TableCell>
            <TableCell>{requestData?.environmentName ?? "-"}</TableCell>
            <TableCell>{requestData?.applicationName ?? "-"}</TableCell>
            <TableCell>
              {requestData?.submittedOn
                ? formatDateToUserTimeZone(requestData.submittedOn)
                : "-"}
            </TableCell>
            <TableCell>{renderStatusCell(requestData)}</TableCell>
            <TableCell>{renderActionCell(requestData)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
