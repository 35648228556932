import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getAllTeamService = createAsyncThunk(
  "applicationManagement/getAllTeamService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.ApplicationManagement.GetAllTeams,
        abortController && {
          signal: abortController.signal,
        }
      );

      let response =
        request.data?.map((data) => ({
          id: data.id,
          name: data?.team_name ?? null,
          label: data?.team_name ?? null,
          members:
            data?.members?.map((member) => ({
              id: member.id,
              name: member.username,
              label: member.username,
            })) ?? [],
          certificateIDs: data?.certificates ?? [],
        })) ?? [];

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getAllTeamService;
