// React
import { useRef } from "react";

// Redux
import { useDispatch } from "react-redux";
import { store } from "../../lib/redux/store";

// MUI
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { setKeyNameInput } from "./keysAndCertsSlice";
import debounce from "lodash/debounce";

export default function SearchKeyName() {
  const dispatch = useDispatch();

  const defaultSearchedKeyName = useRef(
    (() => store.getState().keysAndCerts.keyNameInput)()
  );

  const debouncedOnInputChange = debounce((keyName) => {
    dispatch(setKeyNameInput(keyName));
  }, 200);

  function onInputChange(event) {
    let keyName = event.target.value;
    debouncedOnInputChange(keyName);
  }

  return (
    <TextField
      sx={{
        width: "300px",

        "& .MuiInputBase-root": {
          backgroundColor: "background.paper",
          pr: 0,
          borderRadius: "5px",
          "& fieldset": {
            border: "2px solid",
            borderColor: "background.paper",
          },
          "&:hover fieldset": {
            borderColor: "tertiary.main",
          },
          "&.Mui-focused fieldset": {
            borderColor: "divider",
          },
        },
      }}
      defaultValue={defaultSearchedKeyName.current}
      size="small"
      id="key-name"
      variant="outlined"
      placeholder="Type key name"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={onInputChange}
    />
  );
}
