import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getAdditionalDetailFromKeyID = createAsyncThunk(
  "keysAndCertificates/getAdditionalDetailFromKeyID",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let keyID = serviceConfig.keyID;
      let certificateName = serviceConfig.certificateName;
      let keyAlias = serviceConfig.keyAlias;

      let applicationNameReq = Api.get(
        URL.KeysAndCertificates.ApplicationDetailFromKeyID + keyID + "/",
        abortController && {
          signal: abortController.signal,
        }
      );
      let certificateDetailReq = Api.get(
        URL.KeysAndCertificates.CertificateDetailFromCertificateName +
          keyAlias +
          "/" +
          "?certificate_name=" +
          certificateName,
        abortController && {
          signal: abortController.signal,
        }
      );

      const [applicationNameRes, certificateDetailRes] = await Promise.all([
        applicationNameReq,
        certificateDetailReq,
      ]);

      let response = {};

      if (applicationNameRes.data?.length > 0) {
        response.applicationName =
          applicationNameRes.data[0]?.project_name ?? null;
      }

      if (certificateDetailRes.data?.length > 0) {
        response.certificateData =
          certificateDetailRes.data[0]?.certificateData ?? null;
      }

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getAdditionalDetailFromKeyID;
