export default function WindowsLogo({ fill = "none", width }) {
  return (
    <svg width={width} viewBox="0 0 25 25" fill="none">
      <path
        d="M0.126375 3.53363L10.2698 2.13662V11.9363H0.125V3.535L0.126375 3.53363ZM0.126375 21.4664L10.2698 22.8647V13.1848H0.125L0.126375 21.4664ZM11.3849 23.0133L24.8764 24.875V13.1848H11.3849V23.0133ZM11.3849 1.98675V11.9363H24.8764V0.125L11.3849 1.98675Z"
        fill={fill}
      />
    </svg>
  );
}
