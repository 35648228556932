import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { FormType, Labels } from "../../../utils/constants";
import { initializeForm } from "../../../features/forms/formSlice";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { resetSigningProjectsTableState } from "../../../features/signingProjects/SigningProjectsSlice";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import SigningProjectsTable from "../../../features/signingProjects/SigningProjectsTable";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";

function Header({ dispatch }) {
  return (
    <Box>
      <Button
        sx={{
          ml: "auto",
          display: "block",
          borderRadius: "5px",
        }}
        variant="contained"
        size="large"
        onClick={() =>
          dispatch(
            initializeForm({
              formType: FormType.ADD_SIGNING_PROJECT,
            })
          )
        }
      >
        {Labels.ADD_SIGNING_PROJECT}
      </Button>
    </Box>
  );
}

export default function SigningProjects() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSigningProjectsTableState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetSigningProjectsTableState())}
    >
      <Stack sx={{ width: 1, height: 1 }}>
        {checkUserPermission(Permissions.ManageSigningProjects) && (
          <Header dispatch={dispatch} />
        )}

        <SigningProjectsTable />
      </Stack>
    </ErrorBoundary>
  );
}
