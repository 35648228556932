import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { initializeModal } from "../../features/modals/modalSlice";
import { ModalType } from "../../utils/constants";
import { fetchBackup } from "../../features/backup/backupSlice";

export default async function takeBackup() {
  try {
    store.dispatch(showLoader());

    let response = await Api.get(URL.Backup.TakeBackup);

    let extractedName =
      response.headers["content-disposition"].match(/filename=([^;]+)/);
    store.dispatch(fetchBackup());

    store.dispatch(
      initializeModal({
        modalType: ModalType.DOWNLOAD_BACKUP,
        modalData: {
          backupName: extractedName[1],
        },
      })
    );

    Notification.success(NotificationLabels.BACKUP_SUCCESSFUL);
  } catch (error) {
    Notification.error(NotificationLabels.FAILED_TO_BACKUP);
  } finally {
    store.dispatch(closeLoader());
  }
}
