import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { store } from "../../lib/redux/store";
import * as Yup from "yup";
import { Typography, Paper, IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { Status } from "../../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchLogsTableData, setLogsDateRange } from "./LogsSlice";

export default function LogSearch() {
  const dispatch = useDispatch();

  let logsFetchingState = useSelector(
    (state) => state.logs.logsTableDataLoadingState
  );

  const formik = useFormik({
    initialValues: store.getState().logs.logsDateRange,
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("From date is required"),
      toDate: Yup.date()
        .required("To date is required")
        .min(Yup.ref("fromDate"), "To date must be greater than From date"),
    }),
    onSubmit: (values) => {
      if (
        logsFetchingState === Status.Idle ||
        logsFetchingState === Status.Loading
      ) {
        return;
      }
      dispatch(setLogsDateRange(values));
      dispatch(fetchLogsTableData());
    },
  });

  return (
    <Stack direction="row" sx={{ gap: 2, alignItems: "end" }}>
      <CustomDatePicker label="From Date" id="fromDate" formik={formik} />
      <CustomDatePicker
        label="To Date"
        id="toDate"
        formik={formik}
        minDate={formik.values["fromDate"]}
      />
      <IconButton
        aria-label="search"
        onClick={() => formik.submitForm()}
        sx={{
          width: "fit-content",
          mb: "1px",
          p: 1,
          height: "fit-content",
          backgroundColor: "primary.main",
          borderRadius: "5px",

          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
      >
        {logsFetchingState === Status.Idle ||
        logsFetchingState === Status.Loading ? (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: "white",
            }}
            size={20}
            thickness={3}
          />
        ) : (
          <SearchIcon sx={{ fill: "white" }} />
        )}
      </IconButton>
    </Stack>
  );
}

function CustomDatePicker({ label, id, minDate, formik }) {
  let error = formik.errors[id];
  let value = formik.values[id];

  function handleDateChange(date) {
    if (date) {
      formik.setFieldValue(id, date.format("YYYY-MM-DD"));
    }
  }

  function InputLabel() {
    return (
      <Stack
        sx={{
          gap: "10px",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "0.85rem", fontWeight: 600, mb: "3px" }}
        >
          {label}
        </Typography>
        {error ? (
          <Typography
            variant="subtitle1"
            sx={{
              width: "200%",
              position: "absolute",
              top: "105%",
              fontSize: "0.85rem",
              color: "error.dark",
            }}
          >
            {`(${error})`}
          </Typography>
        ) : null}
      </Stack>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <InputLabel />
      <Paper
        elevation={0}
        sx={{ px: 1, pt: 1.5, pb: 0.5, borderRadius: "5px" }}
      >
        <DatePicker
          timezone="utc"
          format="MMM D YYYY"
          sx={{
            width: "130px",

            ".MuiInputBase-root": {
              pr: "5px",
            },
            "& input": {
              p: 0,
              fontSize: "0.85rem",
            },

            fieldset: {
              border: "0 !important",
            },
          }}
          id={id}
          onChange={(event) => {
            handleDateChange(event);
          }}
          defaultValue={value ? moment.utc(value, "YYYY-MM-DD") : null}
          minDate={minDate ? moment.utc(minDate, "YYYY-MM-DD") : null}
        />
      </Paper>
    </Box>
  );
}
