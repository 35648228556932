// React
import { useEffect } from "react";

// MUI
import Box from "@mui/material/Box";

// Redux
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Utils
import { useNavigate } from "react-router-dom";
import { resetSelectedReportData } from "../../features/reports/ReportsSlice";
import ReportDetailTable from "../../features/reports/ReportDetailTable";
import ReportDetailModal from "../../features/reports/ReportDetailModal";
import { Skeleton } from "@mui/material";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 280px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

const ReportsDetail = function () {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reportData = useSelector((state) => state.reports.selectedReportData);

  useEffect(() => {
    if (!reportData) {
      navigate("/reports");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedReportData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!reportData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <ReportDetailTable reportDetail={reportData} />
      <ReportDetailModal reportDetail={reportData} />
    </Box>
  );
};

export default ReportsDetail;
