import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";

export default async function downloadSelfSignedCertService(
  certificateName,
  format
) {
  try {
    const request = await Api.get(
      URL.KeysAndCertificates.DownloadSelfSignedCertificate +
        "?certificateName=" +
        certificateName
    );
    const response = request.data;
    let fileName = certificateName + "." + format;
    var file = new Blob([response], {
      type: "text/crt;charset=utf-8",
    });
    var csrFile = window.URL.createObjectURL(file);
    let tempLink = document.createElement("a");
    tempLink.href = csrFile;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
    Notification.success(NotificationLabels.FILE_DOWNLOADED);
  } catch (error) {
    Notification.error(NotificationLabels.FILE_DOWNLOAD_UNSUCCESSFUL);
  }
}
