import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import InputLabel from "./InputLabel";
import { store } from "../../../lib/redux/store";
import { FormType } from "../../../utils/constants";
import Api from "../../../lib/axios/api";
import URL from "../../../utils/url";

export default function FileInputComponent({
  id,
  handleBlur,
  label,
  touched,
  error,
  accept,
  value,
  placeholder,
  setFieldValue,
  setFieldTouched,
  setFieldError,
}) {
  async function checkFileInHSM(fileName) {
    try {
      const request = await Api.get(
        URL.KeysAndCertificates.GetCertificateDetailByName + fileName + "/"
      );

      const certs = request.data;

      if (certs.length > 0) {
        setFieldError(
          id,
          "Certificate already exists in HSM. Please choose a different certificate"
        );
      }
    } catch (error) {
      setFieldError(
        id,
        "Certificate already exists in HSM. Please choose a different certificate"
      );
    }
  }

  function handleOnChange(event) {
    let { formType } = store.getState().form;
    setFieldTouched(id, true);

    const file = event.target.files[0];
    if (file) {
      setFieldValue(id, file);

      if (formType === FormType.IMPORT_CERTIFICATE) {
        let fileNameWithoutExtention = file.name.replace(/\.[^/.]+$/, "");

        setFieldValue("certificateName", fileNameWithoutExtention);

        const fileNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/;
        const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");

        if (!fileNameRegex.test(fileNameWithoutExtension)) {
          setFieldError(id, "Invalid file name. Please use a different name.");
        }

        // checkFileInHSM(fileNameWithoutExtension);
      }
    }
  }

  return (
    <Box sx={{ position: "reltiave" }}>
      <InputLabel label={label} error={error} touched={touched} />
      <Paper
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "row",
          gap: 3,
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
          alignItems: "center",
        }}
      >
        <Button
          component="label"
          variant="contained"
          startIcon={<UploadFileIcon />}
          size="small"
          sx={{ borderRadius: "5px" }}
        >
          Upload file
          <input
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
            id={id}
            name={id}
            type="file"
            accept={accept}
            onChange={(event) => handleOnChange(event)}
            onBlur={() => {
              setFieldTouched(id, true);
            }}
          />
        </Button>
        <Typography
          variant="subtitle1"
          color={value ? "primary" : "text.placeholder"}
        >
          {value ? value.name : placeholder}
        </Typography>
      </Paper>
    </Box>
  );
}
