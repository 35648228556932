import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { ModalType, NotificationLabels } from "../../utils/constants";
import { initializeModal } from "../../features/modals/modalSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import getKeysAndCertsTableData from "./getKeysAndCertsTableData";
import getAllCertificateService from "../applicationManagement/getAllCertificateService";
import { closeForm } from "../../features/forms/formSlice";

export default async function createSelfSignedCertificateService(
  formData,
  _,
  __
) {
  try {
    store.dispatch(showLoader());

    let requestBody = {
      id: 0,
      certificate_name: formData.certificateName,
      certificate_description: formData.certificateDescription,
      common_name: formData.commonName,
      organization_name: formData.organization,
      organization_unit: formData.organizationUnit,
      country_code: formData.countryCode.toUpperCase(),
      state_province: formData.state,
      locality: formData.locality,
      email: formData.email,
      key_algorithm: formData.keyAlgorithm,
      valid_from: formData.validFrom,
      valid_to: formData.validTo,
      isActivate: true,
      isArchive: false,
      scope: formData.scope,
      Is_deleted: false,
      created_user: store.getState().auth.userID,
      key_usage: formData.keyUsage === "None" ? null : formData.keyUsage,
      key_renewal: formData.keyRenewal,
    };

    await Api.post(
      URL.KeysAndCertificates.GenerateSelfSignedCertificate,
      requestBody
    );

    store.dispatch(
      initializeModal({
        modalType: ModalType.DOWNLOAD_SELF_SIGNED_CERT,
        modalData: {
          certificateName: formData.certificateName,
        },
      })
    );

    store.dispatch(getKeysAndCertsTableData({ abortController: null }));

    store.dispatch(closeForm());

    if (store.getState().applicationManagement.formActive) {
      store.dispatch(getAllCertificateService());
    }

    store.dispatch(closeLoader());
    Notification.success(NotificationLabels.CERTIFICATE_CREATED);
  } catch (error) {
    store.dispatch(closeLoader());
    Notification.error(NotificationLabels.CERTIFICATE_ADDITION_FAILED);
  }
}
