import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import TimezoneSelect from "react-timezone-select";
import { setSelectedTimezone } from "./LogsSlice";

export default function TimezoneSelector() {
  const dispatch = useDispatch();

  const selectedTimezone = useSelector((state) => state.logs.timezone);

  return (
    <Box sx={{ width: "300px" }}>
      <TimezoneSelect
        styles={{ width: "100%" }}
        value={selectedTimezone}
        onChange={(event) => {
          dispatch(setSelectedTimezone(event.value));
        }}
      />
    </Box>
  );
}
