import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { store } from "../../lib/redux/store";
import { closeForm, initialValueFetched } from "../../features/forms/formSlice";

// This function is responsible for fetching the existing state for trigger setup based on the keyID for the certificate and return it to the form so that a initial value can be setuped
export default async function getTriggerDataByKeyIDService({ keyID }) {
  try {
    const response = await Api.get(
      URL.KeysAndCertificates.KeyAndCertificateTriggerDataByKeyID + keyID + "/"
    );

    const triggerData = response.data;

    let initialFormData = {};

    let triggerDays = triggerData?.triggerDays ?? [];
    let triggerEmails = triggerData?.triggerEmails ?? [];

    let optionsForTriggerDays = [
      { id: 1, label: "7 Days", value: 7 },
      { id: 2, label: "30 Days", value: 30 },
      { id: 3, label: "60 Days", value: 60 },
      { id: 4, label: "90 Days", value: 90 },
    ];

    if (triggerDays.length > 0) {
      initialFormData.days = triggerDays.map((item) => {
        return optionsForTriggerDays.find(
          (option) => option.value === item.trigger_days
        );
      });
    } else {
      initialFormData.days = [];
    }

    if (triggerEmails.length > 0) {
      initialFormData.emails = triggerEmails.map((item) => item.email);
    } else {
      initialFormData.emails = [];
    }

    store.dispatch(initialValueFetched(initialFormData));
  } catch (error) {
    Notification.error(NotificationLabels.FORM_SETUP_UNSUCCESSFUL);
    store.dispatch(closeForm());
  }
}
