import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { Labels } from "../../utils/constants";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";

const getEmailConfigTableDataService = createAsyncThunk(
  "email/getEmailConfigTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let response = await Api.get(
        URL.Email.GetConfigTableData,
        abortController && {
          signal: abortController.signal,
        }
      );

      let result =
        response.data?.map((config, index) => ({
          id: config?.id ?? index,
          configID: config?.id ?? null,
          status: config.Is_deleted ? Labels.IN_ACTIVE : Labels.ACTIVE,
          createdDate: config?.created_date
            ? formatDateToUserTimeZone(config.created_date)
            : null,
          createdUser: config?.created_user ?? null,
          configType: config?.email_type ?? null,
          host: config?.host ?? null,
          modifiedBy: config?.modified_by ?? null,
          modifiedDate: config?.modified_date
            ? formatDateToUserTimeZone(config.modified_date)
            : null,
          password: config?.password ?? null,
          port: config?.port ?? null,
          userName: config?.username ?? null,
        })) ?? [];
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getEmailConfigTableDataService;
