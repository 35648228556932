import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Labels } from "../../utils/constants";
import Box from "@mui/material/Box";
import { EmailConfigType } from "../../utils/enums";
import { Stack, Button } from "@mui/material";
import { initializeModal } from "../modals/modalSlice";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import { ModalType } from "../../utils/constants";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";

export default function ConfigDetailTable({ configDetail, dispatch }) {
  let isUserAllowedToManageEmailConfig = checkUserPermission(
    Permissions.ManageEmailConfig
  );

  function handleEditClicked(data) {
    dispatch(
      initializeForm({
        formType: FormType.EMAIL_CONFIG,
        initialValue: {
          configType: data.configType,
          host: data.host,
          userName: data.userName,
          port: data.port,
          password: data.password,
        },
        serviceData: { ...data, newConfig: false },
      })
    );
  }

  function renderActionCell() {
    return (
      <>
        {isUserAllowedToManageEmailConfig ? (
          <Stack
            direction="row"
            sx={{
              width: "fit-content",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
                paddingInline: "30px",
              }}
              onClick={() => handleEditClicked(configDetail)}
            >
              {Labels.EDIT}
            </Button>
            <Button
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
              }}
              variant="outlined"
              onClick={() => {
                if (configDetail.status === "Active") {
                  dispatch(
                    initializeModal({
                      modalType: ModalType.ARCHIVE_EMAIL_CONFIG,
                      modalData: {
                        configID: configDetail.configID,
                      },
                    })
                  );
                } else {
                  dispatch(
                    initializeModal({
                      modalType: ModalType.RESTORE_EMAIL_CONFIG,
                      modalData: {
                        configID: configDetail.configID,
                      },
                    })
                  );
                }
              }}
            >
              {configDetail.status === "Active"
                ? Labels.ARCHIVE
                : Labels.RESTORE}
            </Button>
          </Stack>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function getConfigTypeName(typeID) {
    let configName = "";

    switch (typeID) {
      case EmailConfigType.SMTP:
        configName = "SMTP";
        break;

      default:
        configName = "-";
        break;
    }

    return configName;
  }

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow className="custom-scroll-bar">
            <TableCell>{Labels.TYPE}</TableCell>
            <TableCell>{Labels.HOST}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell sx={{ width: "15%" }}>
              {getConfigTypeName(configDetail.configType)}
            </TableCell>
            <TableCell sx={{ width: "20%" }}>
              {configDetail?.host ?? "-"}
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              {renderStatusCell(configDetail?.status)}
            </TableCell>
            <TableCell sx={{ width: "20%" }}>{renderActionCell()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
