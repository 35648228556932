import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { selectIdentityDataByIdentityID } from "../../../features/identityStore/identityStoreSlice";
import getIdentityStoreTableDataService from "../../../services/identityStore/getIdentityStoreTableData";
import IdentityDetailTable from "../../../features/identityStore/IdentityDetailTable";
import IdentityDetailModal from "../../../features/identityStore/IdentityDetailModal";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "react-error-boundary";
import { useErrorBoundary } from "react-error-boundary";
import { resetState } from "../../../features/identityStore/identityStoreSlice";
import ErrorComponent from "../../../components/ui/ErrorComponent";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        pt: 3,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 380px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function IdentityStoreDetail() {
  const { identityID } = useParams();
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isTableDataFetched, error, selectedIdentityData } = useSelector(
    (state) => selectIdentityDataByIdentityID(state, identityID)
  );

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(getIdentityStoreTableDataService());
    }

    if (!selectedIdentityData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityID, isTableDataFetched, error, selectedIdentityData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedIdentityData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1, pt: 3 }}>
      <IdentityDetailTable identityDetail={selectedIdentityData} />
      <IdentityDetailModal identityDetail={selectedIdentityData} />
    </Box>
  );
}

export default function ErrorComponentWrappedIdentityStoreDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <IdentityStoreDetail />
    </ErrorBoundary>
  );
}
