import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Labels } from "../../utils/constants";
import Box from "@mui/material/Box";
import { Stack, Button } from "@mui/material";
import { initializeModal } from "../modals/modalSlice";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import { ModalType } from "../../utils/constants";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";

export default function UserDetailTable({ userDetail, dispatch }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function renderActionCell() {
    let isAllowedToModifyUser = checkUserPermission(Permissions.ManageUser);
    let isAllowedToDeleteUser = checkUserPermission(Permissions.RegisterUser);

    return (
      <Box
        sx={{
          width: 1,
          height: 1,
        }}
      >
        {isAllowedToDeleteUser || isAllowedToModifyUser ? (
          <>
            <IconButton
              onClick={(event) => setAnchorEl(event.target)}
              size="small"
              color="primary"
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              sx={{
                "& .MuiPaper-root .MuiList-root": {
                  minWidth: "150px",
                  py: 0,
                  "& .MuiButtonBase-root": {
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    bgcolor: "tertiary.light",
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    "&:hover": {
                      bgcolor: "tertiary.main",
                    },
                    "& .MuiListItemText-root .MuiTypography-root": {
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    },
                  },
                },
              }}
              id="action-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {isAllowedToDeleteUser && (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    handleDeleteUser();
                  }}
                >
                  {Labels.DELETE}
                </MenuItem>
              )}

              {isAllowedToModifyUser && (
                <>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleLockStatus();
                    }}
                  >
                    {userDetail?.isLocked === Labels.LOCKED
                      ? Labels.UNLOCK
                      : Labels.LOCK}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleModifyRoles();
                    }}
                  >
                    Modify Roles
                  </MenuItem>
                </>
              )}
            </Menu>
          </>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </Box>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "100px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.UnLocked ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function handleDeleteUser() {
    dispatch(
      initializeModal({
        modalType: ModalType.DELETE_USER,
        modalData: {
          userID: userDetail.userID,
        },
      })
    );
  }

  function handleModifyRoles() {
    dispatch(
      initializeForm({
        formType: FormType.MODIFY_USER_ROLES,
        initialValue: {
          userRoles:
            userDetail.roles?.map((role) => ({
              id: role.id,
              label: role.label,
              value: role,
            })) ?? [],
        },
        serviceData: {
          userID: userDetail.userID,
        },
      })
    );
  }

  function handleLockStatus() {
    if (userDetail.isLocked === "Unlocked") {
      dispatch(
        initializeModal({
          modalType: ModalType.LOCK_USER,
          modalData: {
            userID: userDetail.userID,
            lockStatus: true,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.UNLOCK_USER,
          modalData: {
            userID: userDetail.userID,
            lockStatus: false,
          },
        })
      );
    }
  }

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow className="custom-scroll-bar">
            <TableCell>{Labels.EMAIL}</TableCell>
            <TableCell>{Labels.USERNAME}</TableCell>
            <TableCell>{Labels.CREATED_DATE}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{userDetail?.email ?? "-"}</TableCell>
            <TableCell>{userDetail?.userName ?? "-"}</TableCell>
            <TableCell>
              {formatDateToUserTimeZone(userDetail?.createdDate)}
            </TableCell>
            <TableCell>{renderStatusCell(userDetail?.isLocked)}</TableCell>
            <TableCell>{renderActionCell()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
