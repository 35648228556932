import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { closeForm } from "../../features/forms/formSlice";
import getUserManagementTableDataService from "./getUserManagementTableDataService";

export default async function registerUserService(formData) {
  try {
    store.dispatch(showLoader());

    let reqBody = {
      username: formData.email.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email.email,
      password: formData.password,
      group_ids: formData.userRoles?.map((role) => role.id) ?? [],
    };

    const res = await Api.post(URL.User.RegisterUser, reqBody);

    if (res.data?.detail === "user exists") {
      throw new Error("User already exists!");
    }

    if (res.status !== 201) {
      throw Error("Falied to register user");
    }

    store.dispatch(getUserManagementTableDataService());

    store.dispatch(closeForm());

    Notification.success(NotificationLabels.USER_REGISTERED_SUCCESSFULLY);
  } catch (error) {
    if (error?.response.data?.username) {
      Notification.error("User already exists!");
    } else {
      Notification.error(NotificationLabels.FAIL_TO_REGISTER_USER);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
