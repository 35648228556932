import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getSigningKSPService = createAsyncThunk(
  "dashboard/getSigningKSPService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      const response = await Api.get(
        URL.Dashboard.SigningKSP,
        abortController && {
          signal: abortController.signal,
        }
      );

      let data = {
        limit: response.data?.limit ?? 0,
        consumed: response.data?.status ?? 0,
      };

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getSigningKSPService;
