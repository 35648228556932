import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { createSelector } from "reselect";
import getSSLTableDataService from "../../services/sslManagement/getSSLTableData";

const initialState = {
  status: Status.Idle,
  error: null,
  tableData: [],
  navigateBack: false,
};

export const sslManagementSlice = createSlice({
  name: "sslManagement",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = Status.Idle;
      state.error = null;
      state.navigateBack = false;
    },
    fetchSSLTableData: (state) => {
      state.status = Status.Idle;
      state.error = null;
    },
    navigateBack: (state) => {
      state.navigateBack = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSSLTableDataService.pending, (state) => {
        state.status = Status.Loading;
        state.error = null;
      })
      .addCase(getSSLTableDataService.fulfilled, (state, action) => {
        state.tableData = action.payload;
        state.status = Status.Succeeded;
        state.error = null;
      })
      .addCase(getSSLTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.tableData = [];
          state.status = Status.Failed;
          state.error = action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectSSLTableData = createSelector(
  [
    (state) => state.sslManagement.status,
    (state) => state.sslManagement.error,
    (state) => state.sslManagement.tableData,
  ],

  (status, error, tableData) => ({
    tableData,
    status,
    error,
  })
);

export const selectCertificateDataByID = createSelector(
  [
    (state) => state.sslManagement.tableData,
    (state) => state.sslManagement.status,
    (state) => state.sslManagement.error,
    (_, certificateID) => certificateID,
  ],
  (tableData, status, error, certificateID) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedCertificateData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedCertificateData = tableData.filter(
        (data) => data.id === certificateID
      );

      if (selectedCertificateData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedCertificateData: selectedCertificateData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedCertificateData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedCertificateData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedCertificateData: null,
      };
    }
  }
);

export const { resetState, fetchBackup, navigateBack } =
  sslManagementSlice.actions;

export default sslManagementSlice.reducer;
