// export default function SystemSetupSideBar({ fill, width }) {
//   return (
//     <svg viewBox="0 50 512 450" height={width} fill={fill}>
//       <path d="m273.5 224.645-21.544 2.855a97.279 97.279 0 0 0 -15.456-15.457l2.852-21.544-39.189-16.241-13.215 17.242a96.353 96.353 0 0 0 -21.9 0l-13.218-17.24-39.185 16.24 2.852 21.544a97.327 97.327 0 0 0 -15.454 15.456l-21.543-2.854-16.242 39.188 17.242 13.218a96.2 96.2 0 0 0 0 21.9l-17.242 13.214 16.242 39.189 21.546-2.853a97.321 97.321 0 0 0 15.454 15.455l-2.855 21.543 39.189 16.241 13.218-17.24a96.353 96.353 0 0 0 21.9 0l13.218 17.24 39.185-16.241-2.855-21.543a97.327 97.327 0 0 0 15.457-15.457l21.544 2.852 16.241-39.188-17.242-13.216a96.2 96.2 0 0 0 0-21.9l17.239-13.218zm-17.455 50.178a80.852 80.852 0 0 1 0 26.354l-.772 4.725 14.983 11.487-6.835 16.492-18.729-2.48-2.8 3.89a81.412 81.412 0 0 1 -18.601 18.609l-3.89 2.8 2.48 18.729-16.492 6.835-11.489-14.989-4.724.771a81.092 81.092 0 0 1 -26.354 0l-4.724-.771-11.488 14.983-16.492-6.835 2.48-18.729-3.89-2.8a81.408 81.408 0 0 1 -18.608-18.603l-2.8-3.89-18.731 2.48-6.834-16.492 14.989-11.489-.771-4.725a80.852 80.852 0 0 1 0-26.354l.772-4.725-14.983-11.485 6.835-16.492 18.729 2.48 2.8-3.89a81.412 81.412 0 0 1 18.603-18.609l3.89-2.8-2.48-18.729 16.492-6.835 11.489 14.989 4.724-.771a81.092 81.092 0 0 1 26.354 0l4.724.771 11.488-14.983 16.492 6.835-2.48 18.729 3.89 2.8a81.428 81.428 0 0 1 18.608 18.603l2.8 3.89 18.731-2.48 6.834 16.492-14.989 11.489z" />
//       <path d="m176 240a48 48 0 1 0 48 48 48.054 48.054 0 0 0-48-48zm0 80a32 32 0 1 1 32-32 32.036 32.036 0 0 1-32 32z" />
//       <path d="m456 80h-400a40.045 40.045 0 0 0-40 40v272a40.045 40.045 0 0 0 40 40h400a40.045 40.045 0 0 0 40-40v-272a40.045 40.045 0 0 0-40-40zm-400 16h400a24.028 24.028 0 0 1 24 24v24h-448v-24a24.028 24.028 0 0 1 24-24zm400 320h-400a24.028 24.028 0 0 1-24-24v-232h448v232a24.028 24.028 0 0 1-24 24z" />
//       <path d="m64 112h16v16h-16z" />
//       <path d="m96 112h16v16h-16z" />
//       <path d="m128 112h16v16h-16z" />
//       <path d="m256 112h192v16h-192z" />
//       <path d="m416 184a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v16h-64v16h64v16a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-16h32v-16h-32zm-16 40h-16v-32h16z" />
//       <path d="m368 264a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v16h-16v16h16v16a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-16h80v-16h-80zm-16 40h-16v-32h16z" />
//       <path d="m400 344a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v16h-48v16h48v16a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-16h48v-16h-48zm-16 40h-16v-32h16z" />
//     </svg>
//   );
// }
export default function SystemSetupSideBar({ fill, width }) {
  return (
    <svg viewBox="0 30 512 450" height={width} fill={fill}>
      <path d="m480.47 381.32h-448.95a26.55 26.55 0 0 1-26.52-26.52v-126.42a6 6 0 0 1 12 0v126.42a14.54 14.54 0 0 0 14.52 14.52h449a14.54 14.54 0 0 0 14.48-14.52v-282.71a14.54 14.54 0 0 0-14.53-14.53h-448.95a14.54 14.54 0 0 0-14.52 14.53v109.49a6 6 0 0 1-12 0v-109.49a26.56 26.56 0 0 1 26.52-26.53h449a26.56 26.56 0 0 1 26.48 26.53v282.71a26.56 26.56 0 0 1-26.53 26.52z" />
      <path d="m11 215.48a6 6 0 0 1-6-6v-9a6 6 0 0 1 12 0v9a6 6 0 0 1-6 6z" />
      <path d="m345.73 426.12h-179.46a6 6 0 0 1-6-6v-45.35a6 6 0 0 1 6-6h179.46a6 6 0 0 1 6 6v45.35a6 6 0 0 1-6 6zm-173.46-12h167.46v-33.35h-167.46z" />
      <path d="m370.78 466.44h-229.56a17.27 17.27 0 0 1-17.22-17.25v-17.82a17.27 17.27 0 0 1 17.25-17.25h229.53a17.27 17.27 0 0 1 17.22 17.25v17.82a17.27 17.27 0 0 1-17.22 17.25zm-229.56-40.32a5.26 5.26 0 0 0-5.25 5.25v17.82a5.25 5.25 0 0 0 5.25 5.25h229.56a5.25 5.25 0 0 0 5.25-5.25v-17.82a5.26 5.26 0 0 0-5.25-5.25z" />
      <path d="m209.72 251.33a37.88 37.88 0 1 1 37.88-37.87 37.91 37.91 0 0 1-37.88 37.87zm0-63.75a25.88 25.88 0 1 0 25.88 25.88 25.9 25.9 0 0 0-25.88-25.88z" />
      <path d="m266.86 167.62a37.88 37.88 0 1 1 37.88-37.88 37.92 37.92 0 0 1-37.88 37.88zm0-63.75a25.88 25.88 0 1 0 25.88 25.87 25.89 25.89 0 0 0-25.88-25.87z" />
      <path d="m314.52 335a37.88 37.88 0 1 1 37.87-37.87 37.92 37.92 0 0 1-37.87 37.87zm0-63.75a25.88 25.88 0 1 0 25.87 25.88 25.91 25.91 0 0 0-25.87-25.87z" />
      <path d="m177.84 219.46h-30a6 6 0 0 1 0-12h30a6 6 0 0 1 0 12z" />
      <path d="m364.18 219.46h-122.59a6 6 0 0 1 0-12h122.59a6 6 0 0 1 0 12z" />
      <path d="m364.18 135.74h-65.45a6 6 0 1 1 0-12h65.45a6 6 0 0 1 0 12z" />
      <path d="m235 135.74h-87.19a6 6 0 0 1 0-12h87.19a6 6 0 0 1 0 12z" />
      <path d="m282.64 303.14h-134.83a6 6 0 0 1 0-12h134.83a6 6 0 0 1 0 12z" />
      <path d="m364.18 303.14h-17.79a6 6 0 0 1 0-12h17.79a6 6 0 0 1 0 12z" />
    </svg>
  );
}
