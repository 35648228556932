import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { store } from "../../lib/redux/store";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
import { setSearchedUserName } from "./userSlice";

export default function SearchUser() {
  const dispatch = useDispatch();

  const onInputChangeDebounce = debounce((event) => {
    let userName = event.target.value;
    dispatch(setSearchedUserName(userName));
  }, 200);

  useEffect(() => {
    return () => {
      onInputChangeDebounce.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Mixins
  const inputContainerMixin = {
    width: "300px",
    "& .MuiInputBase-root": {
      backgroundColor: "background.paper",
      pr: 0,
      borderRadius: "5px",
      "& fieldset": {
        border: "2px solid",
        borderColor: "background.paper",
      },
      "&:hover fieldset": {
        borderColor: "tertiary.main",
      },
      "&.Mui-focused fieldset": {
        borderColor: "divider",
      },
    },
  };

  function getDefaultValueForTextField() {
    return store.getState().user.searchedUserName;
  }

  return (
    <Box>
      <TextField
        sx={{ ...inputContainerMixin }}
        defaultValue={getDefaultValueForTextField()}
        size="small"
        id="user-name"
        variant="outlined"
        placeholder="Search User Name"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onInputChangeDebounce}
      />
    </Box>
  );
}
