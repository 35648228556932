// React
import { useRef, useEffect, useState } from "react";

// MUI
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// React Router
import { Link } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectKeysAndCertsTableData } from "./keysAndCertsSlice";
import getKeysAndCertsTableData from "../../services/keysAndCertificates/getKeysAndCertsTableData";
import { initializeForm } from "../forms/formSlice";
import Typography from "@mui/material/Typography";

// Constants
import { Status, Labels } from "../../utils/constants";

// Utils
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import { isCurrentTimeGreaterThenProvidedTime } from "../../utils/dateFormatters";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Custom Components
import CustomTable from "../../components/ui/table/CustomTable";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";

import { FormType } from "../../utils/constants";

import { useErrorBoundary } from "react-error-boundary";
import { Skeleton } from "@mui/material";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { number } from "yup";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        mt: 3,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function KeysAndCertsTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(selectKeysAndCertsTableData);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getKeysAndCertsTableData({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} dispatch={dispatch} />;
}

function TableComponent({ tableData, dispatch }) {
  const [actionMenuState, setActionMenuState] = useState({
    anchorEl: null,
    selectedRowData: null,
  });

  const rowData = tableData;

  const columnData = [
    {
      field: "certificateName",
      headerName: Labels.KEY_NAME,
      width: 200,
      filterable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "keyUID",
      headerName: Labels.UID,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => renderUID(params),
    },
    {
      field: "algorithm",
      headerName: Labels.ALGORITHM,
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "size",
      headerName: Labels.SIZE,
      width: 70,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      width: 140,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "validTill",
      headerName: Labels.VALID_TILL,
      width: 220,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return formatDateToUserTimeZone(params.value);
      },
    },
    {
      field: "keyUsage",
      headerName: Labels.KEY_USAGE,
      minWidth: 230,
      renderCell: (params) => {
        if (params.value == null) {
          return (
            <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
              -
            </Typography>
          );
        } else {
          return params.value;
        }
      },
      // valueFormatter: (params) => {
      //   if (params.value == null) {
      //     return "-";
      //   }
      //   return params.value;
      // },
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 140,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderUID(params) {
    if (params.value) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${params.row.keyID}`}
          >
            {params.value}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function handleEditStatusClicked() {
    let { selectedRowData } = actionMenuState;

    if (selectedRowData.status === Labels.ACTIVE) {
      dispatch(
        initializeModal({
          modalType: ModalType.DEACTIVATE_KEY,
          modalData: {
            keyID: selectedRowData.keyID,
            currentStatus: selectedRowData.status,
            certificateName: selectedRowData.certificateName,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.ACTIVATE_KEY,
          modalData: {
            keyID: selectedRowData.keyID,
            certificateName: selectedRowData.certificateName,
          },
        })
      );
    }

    setActionMenuState({ anchorEl: null, selectedRowData: null });
  }

  function handleTriggerSetupClicked(daysDiff) {
    let { selectedRowData } = actionMenuState;

    function constructDaysOptions() {
      let days = [];

      if (daysDiff >= 7) {
        days.push({ id: 1, label: "7 Days", value: 7 });
      }

      if (daysDiff >= 30) {
        days.push({ id: 2, label: "30 Days", value: 30 });
      }

      if (daysDiff >= 60) {
        days.push({ id: 3, label: "60 Days", value: 60 });
      }

      if (daysDiff >= 90) {
        days.push({ id: 4, label: "90 Days", value: 90 });
      }

      return days;
    }

    dispatch(
      initializeForm({
        formType: FormType.TRIGGER_SETUP,

        serviceData: {
          keyID: selectedRowData.keyID,
        },

        dynamicOption: {
          days: constructDaysOptions(),
        },

        customSetupData: {
          email: selectedRowData?.email ?? null,
        },
      })
    );

    setActionMenuState({ anchorEl: null, selectedRowData: null });
  }

  function handleEditKeyUsageClicked() {
    let { selectedRowData } = actionMenuState;

    dispatch(
      initializeForm({
        formType: FormType.UPDATE_KEY_USAGE,
        initialValue: {
          keyUsage: selectedRowData?.keyUsage ?? "None",
        },
        serviceData: {
          keyID: selectedRowData?.keyID,
        },
      })
    );

    setActionMenuState({ anchorEl: null, selectedRowData: null });
  }

  function isCertificateValidForTriggerSetup(dateString) {
    const date = new Date(dateString);

    const now = new Date();

    const diffMilliseconds = date.getTime() - now.getTime();

    const numberOfDays = Math.round(diffMilliseconds / (1000 * 60 * 60 * 24));

    return numberOfDays;
  }

  function ActionMenu() {
    let { anchorEl, selectedRowData } = actionMenuState;

    if (anchorEl === null) {
      return null;
    }

    let daysDiff = isCertificateValidForTriggerSetup(selectedRowData.validTill);

    return (
      <Menu
        sx={{
          "& .MuiPaper-root .MuiList-root": {
            minWidth: "150px",
            py: 0,
            "& .MuiButtonBase-root": {
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "tertiary.light",
              fontSize: "0.9rem",
              fontWeight: 500,
              "&:hover": {
                bgcolor: "tertiary.main",
              },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontSize: "0.9rem",
                fontWeight: 500,
              },
            },
          },
        }}
        id="action-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setActionMenuState({ anchorEl: null, selectedRowData: null });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {daysDiff >= 7 && (
          <MenuItem onClick={() => handleTriggerSetupClicked(daysDiff)}>
            {Labels.SETUP_TRIGGER}
          </MenuItem>
        )}

        <MenuItem onClick={() => handleEditStatusClicked()}>
          {selectedRowData?.status === Labels.ACTIVE
            ? "Deactivate"
            : "Activate"}
        </MenuItem>
        <MenuItem onClick={() => handleEditKeyUsageClicked()}>
          {Labels.UPDATE_KEY_USAGE}
        </MenuItem>
      </Menu>
    );
  }

  function renderActionCell(rowData) {
    let isAllowedToCreateSelfSigned = checkUserPermission(
      Permissions.CreateSelfSignedCertificate
    );

    let isAllowedToGenerateCSR = checkUserPermission(Permissions.GenerateCSR);

    let isAllowedToImportCertificate = checkUserPermission(
      Permissions.ImportCertificate
    );

    if (
      !isCurrentTimeGreaterThenProvidedTime(rowData?.validTill) &&
      (isAllowedToCreateSelfSigned ||
        isAllowedToGenerateCSR ||
        isAllowedToImportCertificate)
    ) {
      return (
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={(event) =>
              setActionMenuState({
                anchorEl: event.target,
                selectedRowData: rowData,
              })
            }
            size="small"
            color="primary"
            aria-label="more actions"
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
          -
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <CustomTable rowData={rowData} columnData={columnData} />
      <ActionMenu />
    </>
  );
}
