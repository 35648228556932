// React
import { useEffect, useState } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { initializeForm } from "../../../features/forms/formSlice";

// Router
import { useNavigate } from "react-router-dom";

// MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { FormType, Labels } from "../../../utils/constants";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Custom Components
import SigningProjectDetailsModal from "../../../features/signingProjects/SigningProjectDetailsModal";

import { resetSigningProjectsTableState } from "../../../features/signingProjects/SigningProjectsSlice";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { selectProjectDataByProjectID } from "../../../features/signingProjects/SigningProjectsSlice";
import { getSigningProjectsTableDataService } from "../../../services/signingProjects/signingProjectsService";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";
import Typography from "@mui/material/Typography";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 350px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

const SelectedKeyAndCertTableInfo = function ({ tableData, dispatch }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleEditProjectClicked() {
    dispatch(
      initializeForm({
        formType: FormType.EDIT_SIGNING_PROJECT,
        initialValue: {
          ...tableData,
        },
        serviceData: { projectID: tableData.id },
      })
    );
    setAnchorEl(null);
  }

  function renderActionCell() {
    return (
      <Box
        sx={{
          width: 1,
          height: 1,
        }}
      >
        {checkUserPermission(Permissions.ManageSigningProjects) ? (
          <>
            <IconButton
              onClick={(event) => setAnchorEl(event.target)}
              size="small"
              color="primary"
              aria-label="more actions"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              sx={{
                "& .MuiPaper-root .MuiList-root": {
                  minWidth: "150px",
                  py: 0,
                  "& .MuiButtonBase-root": {
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    bgcolor: "tertiary.light",
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    "&:hover": {
                      bgcolor: "tertiary.main",
                    },
                    "& .MuiListItemText-root .MuiTypography-root": {
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    },
                  },
                },
              }}
              id="action-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={() => handleEditProjectClicked()}>
                {Labels.EDIT_SIGNING_PROJECT}
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{Labels.PROJECT_NAME}</TableCell>
            <TableCell>{Labels.SOURCE_CODE_MANAGEMENT_TYPE}</TableCell>
            <TableCell>{Labels.GIT_REPOSITORY_URL}</TableCell>
            <TableCell>{Labels.BUILD_SERVER_TYPE}</TableCell>
            <TableCell>{Labels.JENKINS_PROJECT_URL}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{tableData?.projectName ?? "-"}</TableCell>
            <TableCell>{tableData?.sourceCodeManagementType ?? "-"}</TableCell>
            <TableCell>{tableData?.gitRepositoryURL ?? "-"}</TableCell>
            <TableCell>{tableData?.buildServerType ?? "-"}</TableCell>
            <TableCell>{tableData?.jenkinsProjectURL ?? "-"}</TableCell>
            <TableCell>{renderActionCell()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function SigningProjectDetails({ dispatch }) {
  const { projectID } = useParams();
  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const { isTableDataFetched, error, selectedProjectData } = useSelector(
    (state) => selectProjectDataByProjectID(state, projectID)
  );

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(getSigningProjectsTableDataService());
    }

    if (!selectedProjectData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID, isTableDataFetched, error, selectedProjectData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedProjectData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <SelectedKeyAndCertTableInfo
        tableData={selectedProjectData}
        dispatch={dispatch}
      />
      <SigningProjectDetailsModal tableData={selectedProjectData} />
    </Box>
  );
}

export default function ErrorComponentWrappedSigningProjectDetails() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSigningProjectsTableState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetSigningProjectsTableState())}
    >
      <SigningProjectDetails dispatch={dispatch} />
    </ErrorBoundary>
  );
}
