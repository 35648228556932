// React
import { useEffect, useRef } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectHSMHealthInfo, resetHSMHealthInfo } from "./dashboardSlice";
import getHSMHealthService from "../../services/dashboard/getHSMHealthService";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTheme } from "@emotion/react";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { useErrorBoundary } from "react-error-boundary";

// Constants
import { Status } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

function LoadingSkeleton() {
  return (
    <Box sx={{ width: 1, height: 1 }}>
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          minHeight: "195px",
          height: "100%",
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function HSMHealthCheckup() {
  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const theme = useTheme();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { data, status, error } = useSelector(selectHSMHealthInfo);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getHSMHealthService({
          abortController: abortControllerRef.current,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (data == null && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        height: 1,
        borderRadius: "5px",
      }}
    >
      <Typography
        variant="h6"
        sx={{ p: 2, py: 1.5, fontSize: "1.2rem", lineHeight: 1 }}
      >
        HSM Health Checkup
      </Typography>

      <Divider />

      <Stack
        direction={"column"}
        justifyContent={"center"}
        sx={{
          py: 1.5,
          px: 2,
          justifyContent: "center",
          gap: 1.75,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            gap: 6,
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: 1,
            // maxWidth: "400px",
            rowGap: "normal",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.1rem",
            }}
            variant="h6"
          >
            Date & Time
          </Typography>

          <Typography variant="subtitle1">{data.createdTime}</Typography>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            width: 1,
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 4,
            // maxWidth: "400px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.1rem",
            }}
            variant="h6"
          >
            Status
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {data.status ? (
              <CheckBoxIcon
                fontSize="large"
                sx={{ fill: theme.palette.success.dark }}
              />
            ) : (
              <DisabledByDefaultIcon
                fontSize="large"
                sx={{ fill: theme.palette.error.dark }}
              />
            )}
          </Box>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            width: 1,
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 4,
            // maxWidth: "400px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.1rem",
            }}
            variant="h6"
          >
            Vendor
          </Typography>

          <Typography variant="subtitle1">{data?.vendor ?? "-"}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
