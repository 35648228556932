import { closeLoader, showLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import { Labels, NotificationLabels } from "../../utils/constants";
import { getCurrentUTCTime } from "../../utils/dateFormatters";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";
import getApplicationEnvironmentsDetailService from "./getApplicationEnvironmentsDetailService";
import { switchForm } from "../../features/applicationManagement/applicationManagementSlice";
import { ApplicationManagementFormTypes } from "../../utils/enums";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { navigateBack } from "../../features/applicationManagement/applicationManagementSlice";

export default async function manageApplicationEnvironmentService(
  deletedEnvironments,
  environments
) {
  try {
    store.dispatch(showLoader());

    let isPolicyManagementAllowed = checkUserPermission(
      Permissions.ManageApplicationPolicy
    );

    let isCreateTeamAllowed = checkUserPermission(Permissions.CreateTeam);

    let applicationID =
      store.getState().applicationManagement.formState.applicationDetail
        .applicationID;

    let userID = store.getState().auth.userID;

    let newEnvironments = [];

    let updatedEnvironments = [];

    deletedEnvironments.forEach((envData) => {
      if (envData.isNewlyAdded === false) {
        updatedEnvironments.push({
          id: envData.id,
          project_id: applicationID,
          environment_id: envData.certificateEnvironmentDetail.id,
          certificate_id: envData.certificateDetail.id,
          status: envData.status === Labels.ACTIVE ? 0 : 1,
          modified_by: userID,
          modified_date: getCurrentUTCTime(),
          Is_deleted: true,
        });
      }
    });

    environments.forEach((envData) => {
      if (envData.isNewlyAdded === true) {
        newEnvironments.push({
          project_id: applicationID,
          environment_id: envData.certificateEnvironmentDetail.id,
          certificate_id: envData.certificateDetail.id,
          status: envData.status === Labels.ACTIVE ? 0 : 1,
          created_user: userID,
          created_date: getCurrentUTCTime(),
          Is_deleted: false,
        });
      } else {
        updatedEnvironments.push({
          id: envData.id,
          project_id: applicationID,
          environment_id: envData.certificateEnvironmentDetail.id,
          certificate_id: envData.certificateDetail.id,
          status: envData.status === Labels.ACTIVE ? 0 : 1,
          modified_by: userID,
          modified_date: getCurrentUTCTime(),
          Is_deleted: false,
        });
      }
    });

    let addNewEnvironmentsReq =
      newEnvironments.length > 0
        ? Api.post(
            URL.ApplicationManagement.ManageApplicationEnvironment,
            newEnvironments
          )
        : null;

    let updatedEnvironmentReqArr =
      updatedEnvironments?.map((envData) =>
        Api.put(
          URL.ApplicationManagement.ManageApplicationEnvironment +
            envData.id +
            "/",
          envData
        )
      ) ?? [];

    await Promise.all(
      [addNewEnvironmentsReq, ...updatedEnvironmentReqArr].filter(Boolean)
    );

    Notification.success(NotificationLabels.APPLICATION_ENVIRONMENT_UPDATED);

    if (!isPolicyManagementAllowed && !isCreateTeamAllowed) {
      store.dispatch(navigateBack());
    } else {
      if (isCreateTeamAllowed) {
        store.dispatch(
          switchForm({
            activeForm: ApplicationManagementFormTypes.Team,
            completedForm: ApplicationManagementFormTypes.Environment,
          })
        );
      } else {
        store.dispatch(
          switchForm({
            activeForm: ApplicationManagementFormTypes.Policy,
            completedForm: ApplicationManagementFormTypes.Environment,
          })
        );
      }
      store.dispatch(
        getApplicationEnvironmentsDetailService({ applicationID })
      );
    }
  } catch (error) {
    Notification.error(
      NotificationLabels.FAIL_TO_UPDATED_APPLICATION_ENVIRONMENT
    );
  } finally {
    store.dispatch(closeLoader());
  }
}
