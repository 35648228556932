import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Labels } from "../../utils/constants";
import Box from "@mui/material/Box";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import checkUserPermission from "../../utils/checkUserPermission";
import { Typography, Menu, MenuItem, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import { Permissions } from "../../utils/enums";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function CertificateDetailTable({ certificateDetail }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const isUserAllowedToManageSSL = checkUserPermission(
    Permissions.ManageSSLCertificate
  );

  function downloadCertificate() {
    const blob = new Blob([certificateDetail.certificateData], {
      type: "application/x-pem-file",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${certificateDetail.domain}.pem`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  function renderActionCell() {
    if (isUserAllowedToManageSSL) {
      return (
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={(event) => setAnchorEl(event.target)}
            size="small"
            color="primary"
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            sx={{
              "& .MuiPaper-root .MuiList-root": {
                minWidth: "150px",
                py: 0,
                "& .MuiButtonBase-root": {
                  py: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  bgcolor: "tertiary.light",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "tertiary.main",
                  },
                  "& .MuiListItemText-root .MuiTypography-root": {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                },
              },
            }}
            id="action-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {certificateDetail?.status !== "Active" && (
              <MenuItem
                onClick={() => {
                  dispatch(
                    initializeModal({
                      modalType: ModalType.DELETE_SSL_CERTIFICATE,
                      modalData: {
                        certificateID: certificateDetail.id,
                      },
                    })
                  );
                  setAnchorEl(null);
                }}
              >
                {Labels.DELETE}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                downloadCertificate();
                setAnchorEl(null);
              }}
            >
              {Labels.DOWNLOAD}
            </MenuItem>
          </Menu>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          width: 1,
          height: 1,
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
          -
        </Typography>
      </Box>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow className="custom-scroll-bar">
            <TableCell>{Labels.UID}</TableCell>
            <TableCell>{Labels.DOMAIN}</TableCell>
            <TableCell>{Labels.EXPIRES_AT}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell sx={{ width: "15%" }}>{certificateDetail.id}</TableCell>
            <TableCell sx={{ width: "15%" }}>
              {certificateDetail.domain}
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              {formatDateToUserTimeZone(certificateDetail.expiresAt)}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              {renderStatusCell(certificateDetail.status)}
            </TableCell>
            <TableCell sx={{ width: "10%" }}>{renderActionCell()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
