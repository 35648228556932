import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Labels } from "../../utils/constants";
import { IdentityStoreADType, IdentityStoreType } from "../../utils/enums";

export default function IdentityDetailModal({ identityDetail }) {
  function getIdentityTypeName(data) {
    let identityName = "";

    let identityType = data.identityType;

    switch (identityType) {
      case IdentityStoreType.AD:
        if (data.ADType === IdentityStoreADType.AzureAD) {
          identityName = "Azure AD";
        } else if (data.ADType === IdentityStoreADType.LocalAD) {
          identityName = "Local AD";
        }

        break;
      case IdentityStoreType.SSO:
        identityName = "SSO";
        break;

      default:
        identityName = "-";
        break;
    }

    return identityName;
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.CLIENT_ID,
        value: identityDetail?.clientID ?? "-",
      },
      {
        label: Labels.CALLBACK_URL,
        value: identityDetail?.callbackURL ?? "-",
      },
      {
        label: Labels.CONNECTION_URL,
        value: identityDetail?.connectionURL ?? "-",
      },
      {
        label: Labels.IDENTITY_STORE_TYPE,
        value: getIdentityTypeName(identityDetail),
      },
      {
        label: Labels.STATUS,
        component: renderStatusCell(identityDetail.status),
      },
    ];

    return arr;
  }

  return (
    <Box
      className="custom-scroll-bar"
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        // height: "calc(100% - 280px)",
        height: "calc(100% - 380px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            More Information
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  mb: 1,
                }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              {item.component ? (
                item.component
              ) : (
                <Typography
                  sx={{
                    wordBreak: "break-all",
                  }}
                  variant="subtitle1"
                >
                  {item.value}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
