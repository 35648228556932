import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { getLicenseInfoService } from "../../services/license/getLicenseInfoService";

const initialState = {
  error: null,
  status: Status.Idle,
  licenseInfo: null,
  navigateToRoot: false,
};

export const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    navigateBack: (state) => {
      state.navigateToRoot = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLicenseInfoService.pending, (state) => {
        state.status = Status.Loading;
        state.error = null;
      })
      .addCase(getLicenseInfoService.fulfilled, (state, action) => {
        state.licenseInfo = action.payload;
        state.status = Status.Succeeded;
        state.error = null;
      })
      .addCase(getLicenseInfoService.rejected, (state, action) => {
        state.licenseInfo = null;
        state.status = Status.Failed;
        state.error = action.payload ?? "something went wrong";
      });
  },
});

export const { navigateBack } = licenseSlice.actions;

export default licenseSlice.reducer;
