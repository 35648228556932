import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { closeForm } from "../../features/forms/formSlice";
import { initializeModal } from "../../features/modals/modalSlice";
import { ModalType } from "../../utils/constants";

export default async function generateAPIKeyService(formData) {
  try {
    store.dispatch(showLoader());

    let reqBody = {
      tokenName: formData.keyName,
      expiryDays: formData.expiryDate.value,
    };

    const res = await Api.post(URL.User.GenerateToken, reqBody);

    store.dispatch(
      initializeModal({
        modalType: ModalType.TOKEN_GENERATION,
        modalData: {
          token: res.data.token,
        },
      })
    );

    store.dispatch(closeForm());

    Notification.success(NotificationLabels.TOKEN_GENERATED);
  } catch (error) {
    Notification.error(NotificationLabels.FAIL_TO_GENERATE_TOKEN);
  } finally {
    store.dispatch(closeLoader());
  }
}
