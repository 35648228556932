import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { Labels } from "../../utils/constants";
import { UserRoles } from "../../utils/enums";

const getUserManagementTableDataService = createAsyncThunk(
  "user/getUserManagementTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let response = await Api.get(
        URL.User.GetAllUsers,
        abortController && {
          signal: abortController.signal,
        }
      );

      let result =
        response.data
          ?.filter((user) => user?.is_active === true)
          ?.map((user, index) => ({
            id: user?.id ?? index,
            userID: user?.id ?? null,
            userName: user?.username ?? null,
            email: user?.email ?? null,
            createdDate: user?.date_joined ?? null,
            isLocked:
              user?.is_staff === false ? Labels.UnLocked : Labels.LOCKED,
            isDeleted: user?.is_active ?? true,
            roles:
              user?.groups?.map((role) => {
                if (role === "SystemAdmin") {
                  return UserRoles.SystemAdmin;
                } else if (role === "ProjectManager") {
                  return UserRoles.ProjectManager;
                } else if (role === "SecurityOfficer") {
                  return UserRoles.SecurityOfficer;
                } else if (role === "Auditor") {
                  return UserRoles.Auditor;
                } else {
                  return UserRoles.Developer;
                }
              }) ?? null,
          })) ?? [];

      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getUserManagementTableDataService;
