import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getSSLTableDataService = createAsyncThunk(
  "sslManagement/getSSLTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.SSLManagement.GetSSLData,
        abortController && {
          signal: abortController.signal,
        }
      );

      let result =
        request.data?.map((certificate, index) => ({
          id: certificate?._id ?? index,
          createdAt: certificate?.created_at ?? null,
          expiresAt: certificate?.expire_at ?? null,
          domain: certificate?.domain ?? null,
          type: certificate?.type ?? null,
          status: certificate?.Is_Active === 1 ? "Active" : "Inactive",
          certificateData: certificate?.certificate_data ?? null,
          san: certificate?.san ?? null,
          subject: certificate?.subject ?? null,
        })) ?? [];

      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getSSLTableDataService;
