import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOS } from "./signingToolsSlice";

const OSOptions = [
  { id: 0, label: "All", value: "All" },
  { id: 1, label: "Windows", value: "Windows" },
  { id: 2, label: "Mac", value: "Mac" },
  { id: 3, label: "Linux", value: "Linux" },
];

export default function OSSelector() {
  const dispatch = useDispatch();

  const { selectedOS } = useSelector((state) => state.signingTools);

  function onOSChange(os) {
    dispatch(setSelectedOS(os));
  }

  return (
    <Box>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: "0.9rem", fontWeight: 700, mb: 0.5 }}
      >
        Select Operating System
      </Typography>
      <Select
        sx={{
          width: "180px",
          mr: 3,
          fontSize: "0.9rem",
          borderRadius: "5px",
          backgroundColor: "background.paper",

          ".MuiSelect-select": {
            py: 1,
          },

          fieldset: {
            border: "0px !important",
          },
        }}
        variant="outlined"
        id="operating-system"
        value={selectedOS}
        onChange={(event) => onOSChange(event.target.value)}
        renderValue={(selected) => {
          const selectedOption = OSOptions.find(
            (option) => option.value === selected
          );
          return selectedOption ? selectedOption.label : "--Select--";
        }}
      >
        {OSOptions.map((item) => (
          <MenuItem
            sx={{
              fontSize: "0.9rem",
            }}
            key={item.id}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
