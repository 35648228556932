import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { FileType, NotificationLabels } from "../../utils/constants";
import { store } from "../../lib/redux/store";
import downloadFile from "../../utils/downloadFile";

export default async function getLoggingSysLogService() {
  try {
    let { fromDate, toDate } = store.getState().logs.logsDateRange;

    Notification.success(NotificationLabels.DONWLOADING_SYS_LOG);

    const response = await Api.get(
      URL.Logs.GetSysLog + "?from_date=" + fromDate + "&to_date=" + toDate
    );

    let fileName = "LoggingReport.txt";

    downloadFile(response.data, fileName, FileType.TEXT);

    Notification.success(NotificationLabels.SYS_LOG_DOWNLOADED);
  } catch (error) {
    Notification.error(NotificationLabels.FAILED_TO_GET_SYS_LOG);
  }
}
