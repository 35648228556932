// React
import { useRef, useEffect } from "react";

// MUI
import Box from "@mui/material/Box";

// React Router
import { Link } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Constants
import { Status, Labels } from "../../utils/constants";

import getReportsTableDataService from "../../services/reports/getReportsTableDataService";
import { selectReportsTableData } from "./ReportsSlice";
import CustomTable from "../../components/ui/table/CustomTable";
import getReportsSysLogService from "../../services/reports/getSysLog";
import { setSelectedReportData } from "./ReportsSlice";
import { formatDateForUI } from "./utils";
import { Skeleton } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        mt: 3,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function ReportsTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, tableDataType, status, error } = useSelector(
    selectReportsTableData
  );

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getReportsTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return (
    <TableComponent
      dispatch={dispatch}
      tableData={tableData}
      tableDataType={tableDataType}
    />
  );
}

function TableComponent({ dispatch, tableData, tableDataType }) {
  function getTableOptionConfig() {
    let tableOptionConfig = {
      includeSYSLogExport: false,
      includePDFExport: true,
      includeCSVExport: true,
      includeShowMaxRows: true,
    };

    switch (tableDataType) {
      case "Signing Request": {
        tableOptionConfig.fileName = "Signing Request Report";
        break;
      }
      case "Approval Request": {
        tableOptionConfig.fileName = "Approval Request Report";
        break;
      }
      case "Audit Trial": {
        tableOptionConfig.fileName = "Audit Trial Report";
        if (checkUserPermission(Permissions.DownloadReportSysLog)) {
          tableOptionConfig.includeSYSLogExport = true;
          tableOptionConfig.sysLogExportService = getReportsSysLogService;
        }
        break;
      }
      case "Client": {
        tableOptionConfig.fileName = "Client Report";
        tableOptionConfig.includePDFExport = false;
        break;
      }
      default: {
        tableOptionConfig.fileName = "Certificate Report";
        break;
      }
    }

    return tableOptionConfig;
  }

  function formatTableData() {
    let columnData = [];

    switch (tableDataType) {
      case "Signing Request": {
        columnData = [
          {
            field: "jobID",
            headerName: Labels.JOB_ID,
            width: 200,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "applicationName",
            headerName: Labels.APPLICATION_NAME,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "submittedBy",
            headerName: Labels.SUBMITTED_BY,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "submittedOn",
            headerName: Labels.SUBMITTED_ON,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return formatDateForUI(params.value);
            },
            filterable: false,
          },
        ];
        break;
      }
      case "Approval Request": {
        columnData = [
          {
            field: "jobID",
            headerName: Labels.JOB_ID,
            width: 200,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "applicationName",
            headerName: Labels.APPLICATION_NAME,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "approver",
            headerName: Labels.APPROVER,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "approvedOn",
            headerName: Labels.APPROVED_ON,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return formatDateForUI(params.value);
            },
            filterable: false,
          },
        ];
        break;
      }
      case "Audit Trial": {
        columnData = [
          {
            field: "eventTime",
            headerName: Labels.EVENT_TIME,
            width: 250,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return formatDateForUI(params.value);
            },
            renderCell: (params) => renderDateCell(params),

            filterable: false,
          },
          {
            field: "category",
            headerName: Labels.CATEGORY,
            width: 250,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "applicationID",
            headerName: Labels.APPLICATION_ID,
            minWidth: 200,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "message",
            headerName: Labels.MESSAGE,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
        ];
        break;
      }
      case "Client": {
        columnData = [
          {
            field: "clientIP",
            headerName: Labels.CLIENT_IP,
            minWidth: 250,
            flex: 1,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "user",
            headerName: Labels.USER,
            minWidth: 250,
            flex: 1,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "createdAt",
            headerName: Labels.CREATED_DATE,
            minWidth: 250,
            flex: 1,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return formatDateForUI(params.value);
            },
          },
        ];
        break;
      }
      default: {
        columnData = [
          {
            field: "certificateName",
            headerName: Labels.CERTIFICATE_NAME,
            flex: 1,
            minWidth: 250,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "createdBy",
            headerName: Labels.CREATED_BY,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return params.value;
            },
          },
          {
            field: "createdDate",
            headerName: Labels.CREATED_DATE,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return formatDateForUI(params.value);
            },
            filterable: false,
          },
          {
            field: "expiryDate",
            headerName: Labels.EXPIRY_DATE,
            flex: 1,
            minWidth: 300,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "-";
              }
              return formatDateForUI(params.value);
            },
            filterable: false,
          },
        ];
        break;
      }
    }

    return columnData;
  }

  function renderDateCell(params) {
    if (params.value) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            onClick={() => {
              dispatch(setSelectedReportData(params.row));
            }}
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail`}
          >
            {formatDateForUI(params.value)}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  let columnData = formatTableData();
  let rowData = tableData;

  return (
    <>
      <CustomTable
        rowData={rowData}
        columnData={columnData}
        tableOptionConfig={getTableOptionConfig()}
      />
    </>
  );
}
