import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import InputLabel from "./InputLabel";

export default function DateInputComponent({
  id,
  setFieldValue,
  setFieldTouched,
  value,
  label,
  touched,
  error,
  minDate,
}) {
  const handleDateChange = (date) => {
    if (date) {
      const currentTime = moment().format("HH:mm:ss");

      const dateTime = moment(`${date.format("YYYY-MM-DD")}T${currentTime}`);

      const utcDateTime = moment.utc(dateTime);

      setFieldValue(id, utcDateTime.toISOString());
    }
  };

  return (
    <Box sx={{ position: "reltiave" }}>
      <InputLabel label={label} error={error} touched={touched} />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <DatePicker
          sx={{
            width: 1,

            "& input": {
              p: 0,
              pb: "5px",
            },

            fieldset: {
              borderLeftWidth: "0 !important",
              borderRightWidth: "0 !important",
              borderTopWidth: "0 !important",
              borderBottomWidth: 1,
              borderRadius: 0,
              outline: 0,
            },
          }}
          id={id}
          name={id}
          onChange={(event) => {
            handleDateChange(event);
          }}
          defaultValue={value ? moment(value) : null}
          minDate={minDate}
          onOpen={() => setFieldTouched(id, true)}
        />
      </Paper>
    </Box>
  );
}
