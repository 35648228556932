import { Box, Paper, TextField } from "@mui/material";
import InputLabel from "./InputLabel";
import Autocomplete from "@mui/material/Autocomplete";

export default function TextInputWithSuggestionComponent({
  label,
  error,
  touched,
  placeholder,
  id,
  setFieldValue,
  handleBlur,
  options: suggestions,
  isDisabled,
  optionIsString,
  value: defaultValue,
}) {
  return (
    <Box sx={{ position: "reltiave" }}>
      <InputLabel label={label} error={error} touched={touched} />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <Autocomplete
          disabled={isDisabled}
          className="custom-scrollbar"
          id={id}
          name={id}
          freeSolo
          onBlur={handleBlur}
          defaultValue={defaultValue}
          onChange={(_, value) => setFieldValue(id, value)}
          onInputChange={(_, value) => setFieldValue(id, value)}
          options={suggestions.map((suggestion) => suggestion)}
          getOptionLabel={(option) => (optionIsString ? option : option.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="standard"
            />
          )}
        />
      </Paper>
    </Box>
  );
}
