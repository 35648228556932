export default function InfoIcon({ width, fill }) {
  return (
    <svg width={width} viewBox="0 0 25 25" fill="none">
      <path
        d="M7.48406 0.962967C8.95009 0.343 10.562 0 12.2539 0C17.3298 0 21.6847 3.08607 23.5451 7.4844C24.1651 8.95043 24.5081 10.5623 24.5081 12.2542C24.5081 19.022 19.0217 24.5082 12.2539 24.5082C7.17816 24.5082 2.82323 21.4221 0.962858 17.024C0.342892 15.558 -0.000108719 13.9461 -0.000108719 12.2542C-0.000108719 7.17827 3.08596 2.82333 7.48406 0.962967ZM10.6247 19.8856H13.883V9.25307H10.6247V19.8856ZM10.863 7.64307C11.2251 7.97977 11.6855 8.148 12.2443 8.148C12.816 8.148 13.2829 7.97977 13.645 7.64307C14.0069 7.3066 14.188 6.89033 14.188 6.39497C14.188 5.887 14.0069 5.46443 13.645 5.12773C13.2829 4.79127 12.816 4.6228 12.2443 4.6228C11.6855 4.6228 11.2251 4.79127 10.863 5.12773C10.5008 5.46443 10.32 5.887 10.32 6.39497C10.32 6.89033 10.5008 7.3066 10.863 7.64307Z"
        fill={fill}
      />
    </svg>
  );
}
