import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { createSelector } from "reselect";
import { getUserDataService } from "../../services/auth/getUserDataService";
import { IdentityStoreType } from "../../utils/enums";

const initialState = {
  status: {
    userData: Status.Idle,
  },
  error: {
    userData: null,
  },
  ADToken: null,
  userToken: null,
  userName: null,
  userID: null,
  userRoles: [],
  userPermissions: {},
  authType: IdentityStoreType.AD,
  backendVersion: null,
  frontendVersion: null,
  initiateLogout: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setADToken: (state, action) => {
      state.ADToken = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
    initiateLogout: (state) => {
      state.initiateLogout = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // For User Token
      .addCase(getUserDataService.pending, (state) => {
        state.status.userData = Status.Loading;
        state.error.userData = null;
      })
      .addCase(getUserDataService.fulfilled, (state, action) => {
        state.userToken = action.payload.token;
        state.userName = action.payload.Name;
        state.userID = action.payload.Id;
        state.userRoles = action.payload.userRoles;
        state.status.userData = Status.Succeeded;
        state.backendVersion = action.payload.backend_version;
        state.frontendVersion = action.payload.frontend_version;
        state.error.userData = null;
        state.userPermissions = action.payload.userPermissions;
      })
      .addCase(getUserDataService.rejected, (state, action) => {
        state.userToken = null;
        state.userName = null;
        state.userID = null;
        state.userRoles = [];
        state.status.userData = Status.Failed;
        state.error.userData = action.error;
      });
  },
});

export const selectUserDataServiceStatus = createSelector(
  [(state) => state.auth.status.userData, (state) => state.auth.error.userData],

  (status, error) => ({
    status,
    error,
  })
);

export const selectAuthenticatedUserData = createSelector(
  [
    (state) => state.auth.userName,
    (state) => state.auth.userID,
    (state) => state.auth.userRoles,
    (state) => state.auth.frontendVersion,
    (state) => state.auth.backendVersion,
  ],
  (userName, userID, userRoles, frontendVersion, backendVersion) => ({
    userName,
    userID,
    userRoles,
    frontendVersion,
    backendVersion,
  })
);

export const { setADToken, setUserPermissions, initiateLogout } =
  authSlice.actions;

export default authSlice.reducer;
