import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { store } from "../../lib/redux/store";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef } from "react";
import getApplicationListService from "../../services/reports/getApplicationListService";
import { useSelector, useDispatch } from "react-redux";
import {
  selectApplicationList,
  selectUserList,
  setReportFilters,
} from "./ReportsSlice";
import { Status } from "../../utils/constants";
import getUserListService from "../../services/reports/getUserListService";
import { fetchReportsTableData } from "./ReportsSlice";

let reportTypeOptions = [
  { id: 1, label: "Signing Request", value: "Signing Request" },
  { id: 2, label: "Approval Request", value: "Approval Request" },
  { id: 3, label: "Audit Trial", value: "Audit Trial" },
  { id: 4, label: "Certificate", value: "Certificate" },
  { id: 5, label: "Client", value: "Client" },
];

let categoryOptions = [
  { id: 0, label: "None", value: null },
  {
    id: 1,
    label: "Crypto Operations",
    value: "Crypto Operations",
  },
  {
    id: 2,
    label: "Application Operations",
    value: "Application Operations",
  },
  { id: 3, label: "User Management", value: "User Management" },
  { id: 4, label: "System Settings", value: "System Settings" },
];

export default function ReportsHeaderContainer() {
  const dispatch = useDispatch();

  const applicationOptionsAbortControllerRef = useRef(null);

  const userOptionsAbortControllerRef = useRef(null);

  const { applicationOptions, status: applicationOptionsStatus } = useSelector(
    selectApplicationList
  );

  const reportsFetchingState = useSelector(
    (state) => state.reports.status.tableData
  );

  const { userOptions, status: userOptionsStatus } =
    useSelector(selectUserList);

  function isApplicationSelectComponentDisabled() {
    if (applicationOptionsStatus !== Status.Succeeded) {
      return true;
    } else {
      return false;
    }
  }

  function isUserSelectComponentDisabled() {
    if (userOptionsStatus !== Status.Succeeded) {
      return true;
    } else {
      return false;
    }
  }

  function getIntialValues() {
    let {
      reportType,
      category,
      users,
      applications,
      dateRange: { fromDate, toDate },
    } = store.getState().reports;

    return {
      reportType,
      applications,
      users,
      category,
      fromDate,
      toDate,
    };
  }

  const formik = useFormik({
    initialValues: getIntialValues(),
    validationSchema: Yup.object().shape({
      reportType: Yup.string().required("Selecte a report type"),
      applications: Yup.array()
        .nullable()
        .of(
          Yup.number()
            .positive()
            .integer()
            .required("Application ID must be a positive integer")
        ),
      users: Yup.array()
        .nullable()
        .of(
          Yup.number()
            .positive()
            .integer()
            .required("User ID must be a positive integer")
        ),
      category: Yup.string().nullable(),
      fromDate: Yup.date().required("From date is required"),
      toDate: Yup.date()
        .required("To date is required")
        .min(Yup.ref("fromDate"), "To date must be greater than From date"),
    }),
    onSubmit: (values) => {
      if (reportsFetchingState === Status.Loading) {
        return;
      }

      dispatch(setReportFilters(values));
      dispatch(fetchReportsTableData());
    },
  });

  // For Application List
  useEffect(() => {
    if (applicationOptionsStatus === Status.Idle) {
      applicationOptionsAbortControllerRef.current = new AbortController();

      dispatch(
        getApplicationListService({
          abortController: applicationOptionsAbortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationOptionsStatus]);

  // For Application List
  useEffect(() => {
    return () => {
      if (applicationOptionsAbortControllerRef.current) {
        applicationOptionsAbortControllerRef.current.abort();
      }
    };
  }, []);

  // For User List
  useEffect(() => {
    if (
      formik.values.reportType !== "Audit Trial" &&
      userOptionsStatus === Status.Idle
    ) {
      userOptionsAbortControllerRef.current = new AbortController();

      dispatch(
        getUserListService({
          abortController: userOptionsAbortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOptionsStatus, formik.values.reportType]);

  // For user List
  useEffect(() => {
    return () => {
      if (userOptionsAbortControllerRef.current) {
        userOptionsAbortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "space-between", alignItems: "center", gap: 2 }}
    >
      <Stack
        direction={"row"}
        sx={{ gap: 2, alignItems: "center", flexWrap: "wrap" }}
      >
        <SelectComponent
          id="reportType"
          formik={formik}
          label="Report Type"
          options={reportTypeOptions}
          width="140px"
        />
        <CustomDatePicker label="From Date" id="fromDate" formik={formik} />
        <CustomDatePicker
          label="To Date"
          id="toDate"
          formik={formik}
          minDate={formik.values["fromDate"]}
        />
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          height: "100%",
          gap: 2,
          alignItems: "end",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        {formik.values.reportType !== "Client" && (
          <MultiSelectComponent
            id="applications"
            label="Applications"
            formik={formik}
            options={applicationOptions}
            placeholderText={
              isApplicationSelectComponentDisabled()
                ? "Fetching Applications..."
                : "Select Application"
            }
            width="300px"
            disabled={isApplicationSelectComponentDisabled()}
          />
        )}

        {formik.values.reportType === "Audit Trial" ? (
          <SelectComponent
            id="category"
            label="Category"
            options={categoryOptions}
            formik={formik}
            width="170px"
            placeholder={"Select Category"}
          />
        ) : (
          formik.values.reportType !== "Client" && (
            <MultiSelectComponent
              id="users"
              label="Users"
              formik={formik}
              options={userOptions}
              placeholderText={
                isUserSelectComponentDisabled()
                  ? "Fetching Users..."
                  : "Select User"
              }
              width="250px"
              disabled={isUserSelectComponentDisabled()}
            />
          )
        )}

        <IconButton
          aria-label="search"
          onClick={() => {
            formik.submitForm();
          }}
          sx={{
            width: "fit-content",
            mb: "1px",
            p: 1,
            height: "fit-content",
            backgroundColor: "primary.main",
            borderRadius: "5px",

            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          {reportsFetchingState === Status.Loading ? (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: "white",
              }}
              size={20}
              thickness={3}
            />
          ) : (
            <SearchIcon sx={{ fill: "white" }} />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
}

function InputLabel({ label, error }) {
  return (
    <>
      <Typography
        variant="h6"
        sx={{ fontSize: "0.85rem", fontWeight: 600, mb: "3px" }}
      >
        {label}
      </Typography>
      {error ? (
        <Typography
          variant="subtitle1"
          sx={{
            width: "200%",
            position: "absolute",
            top: "105%",
            fontSize: "0.85rem",
            color: "error.dark",
          }}
        >
          {`(${error})`}
        </Typography>
      ) : null}
    </>
  );
}

function SelectComponent({ id, label, options, formik, width, placeholder }) {
  let { handleChange } = formik;

  let error = formik.errors[id];
  let value = formik.values[id];

  return (
    <Box sx={{ width: width || "150px", position: "relative" }}>
      <InputLabel label={label} error={error} />
      <Paper
        sx={{
          px: 1,
          pt: 1.05,
          pb: 0.85,
          borderRadius: "5px",
          ".MuiInputBase-root": {
            "&::before": {
              borderBottom: "0px",
            },
          },
        }}
        elevation={0}
      >
        <Select
          sx={{
            width: 1,
            ".MuiSelect-select": {
              padding: 0,
              fontSize: "0.85rem",
            },
          }}
          variant="standard"
          id={id}
          name={id}
          value={value || ""}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            const selectedOption = options.find(
              (option) => option.value === selected
            );
            return selectedOption ? selectedOption.label : placeholder;
          }}
        >
          {options.map((item) => (
            <MenuItem
              sx={{ fontSize: "0.85rem" }}
              key={item.id}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </Box>
  );
}

function MultiSelectComponent({
  id,
  formik,
  options,
  placeholderText,
  label,
  width,
  disabled,
}) {
  const { setFieldValue } = formik;
  const selectedValues = formik.values[id];
  const error = formik.errors[id];

  const theme = useTheme();

  // Menu Styles
  const MENU_HEIGHT = 50;
  const MENU_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: MENU_HEIGHT * 4.5 + MENU_PADDING_TOP,
      },
    },
  };

  function getStyles(option, selectedValues, theme) {
    return {
      fontWeight:
        selectedValues.indexOf(option.value) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    let values = event.target.value;
    values = values.filter((value) => value !== undefined);
    setFieldValue(id, values);
  };

  return (
    <Box sx={{ width: width || "150px", position: "relative" }}>
      <InputLabel label={label} error={error} />
      <Paper
        elevation={0}
        sx={{
          px: 1,
          py: 0.98,
          borderRadius: "5px",
          ".MuiInputBase-root": {
            "&::before": {
              borderBottom: "0px",
            },
          },
        }}
      >
        <Select
          disabled={disabled}
          variant="standard"
          id={id}
          multiple
          value={selectedValues}
          onChange={handleChange}
          sx={{
            width: 1,
            ".MuiSelect-select": {
              padding: 0,
              fontSize: "0.85rem",
            },
          }}
          displayEmpty
          renderValue={(selected) => (
            <Box
              className="custom-scroll-bar"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                listStyle: "none",
                mt: 0,
                p: 0,
                gap: "5px",
                maxHeight: "25px",
                overflowY: "auto",
              }}
            >
              {selected?.length > 0 ? (
                selected.map((value) => (
                  <Chip
                    size="small"
                    sx={{ fontSize: "0.75rem" }}
                    key={value}
                    label={
                      options.filter((item) => item.value === value)[0]
                        ?.label || value
                    }
                  />
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "0.85rem", color: "#989898" }}
                >
                  {placeholderText}
                </Typography>
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.length > 0 ? (
            options.map((item) => (
              <MenuItem
                sx={{ fontSize: "0.85rem" }}
                key={item.id}
                value={item.value}
                style={getStyles(item, selectedValues, theme)}
              >
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem sx={{ fontSize: "0.85rem" }} disabled={true}>
              No Data Found!
            </MenuItem>
          )}
        </Select>
      </Paper>
    </Box>
  );
}

function CustomDatePicker({ label, id, minDate, formik }) {
  let error = formik.errors[id];
  let value = formik.values[id];

  function handleDateChange(date) {
    if (date) {
      formik.setFieldValue(id, date.format("YYYY-MM-DD"));
    }
  }

  return (
    <Box sx={{ width: "140px", position: "relative" }}>
      <InputLabel label={label} error={error} />
      <Paper
        elevation={0}
        sx={{ px: 1, pt: 1.5, pb: 0.5, borderRadius: "5px" }}
      >
        <DatePicker
          timezone="utc"
          format="MMM D YYYY"
          sx={{
            width: 1,

            ".MuiInputBase-root": {
              pr: "5px",
            },
            "& input": {
              p: 0,
              fontSize: "0.85rem",
            },

            fieldset: {
              border: "0 !important",
            },
          }}
          id={id}
          onChange={(event) => {
            handleDateChange(event);
          }}
          defaultValue={value ? moment.utc(value, "YYYY-MM-DD") : null}
          minDate={minDate ? moment.utc(minDate, "YYYY-MM-DD") : null}
        />
      </Paper>
    </Box>
  );
}
