import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { ApplicationManagementFormTypes } from "../../../utils/enums";
import ApplicationForm from "./ApplicationForm";
import EnvironmentForm from "./EnvironmentForm.jsx";
import PolicyForm from "./PolicyForm.jsx";
import TeamForm from "./TeamForm.jsx";
import { memo } from "react";

const FormWrapper = memo(({ isNewApplication, applicationID }) => {
  let formType = useSelector(
    (state) => state.applicationManagement.formState.activeForm
  );

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        borderRadius: "5px",
        backgroundColor: "background.paper",
        boxShadow: "3px 0px 13px 2px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
        ...((formType === ApplicationManagementFormTypes.Environment ||
          formType === ApplicationManagementFormTypes.Policy ||
          formType === ApplicationManagementFormTypes.Team) && {
          "& > form": {
            height: "100%",
          },
        }),
      }}
    >
      {formType === ApplicationManagementFormTypes.Application && (
        <ApplicationForm
          isNewApplication={isNewApplication}
          applicationID={applicationID}
        />
      )}

      {formType === ApplicationManagementFormTypes.Environment && (
        <EnvironmentForm
          isNewApplication={isNewApplication}
          applicationID={applicationID}
        />
      )}

      {formType === ApplicationManagementFormTypes.Policy && (
        <PolicyForm
          isNewApplication={isNewApplication}
          applicationID={applicationID}
        />
      )}

      {formType === ApplicationManagementFormTypes.Team && (
        <TeamForm
          isNewApplication={isNewApplication}
          applicationID={applicationID}
        />
      )}
    </Box>
  );
});

export default FormWrapper;
