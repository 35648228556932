import Stack from "@mui/material/Stack";
import LogSearch from "../../features/logs/LogSearch";
import TimezoneSelector from "../../features/logs/TimezoneSelector";
import LogsTable from "../../features/logs/LogsTable";
import { useDispatch } from "react-redux";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";
import { resetState } from "../../features/logs/LogsSlice";

function Header() {
  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "space-between", alignItems: "end" }}
    >
      <LogSearch />
      <TimezoneSelector />
    </Stack>
  );
}

export default function Logs() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <Stack
        sx={{
          width: 1,
          height: 1,
        }}
      >
        <Header />
        <LogsTable />
      </Stack>
    </ErrorBoundary>
  );
}
