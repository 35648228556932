import { Stack, Button } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState, useEffect } from "react";
import { initializeForm } from "../../../features/forms/formSlice";
import { useDispatch, useSelector } from "react-redux";
import { FormType, Status } from "../../../utils/constants";
import { selectAvailableTemplateOptions } from "../../../features/email/emailSlice";
import { EmailConfigType, Permissions } from "../../../utils/enums";
import checkUserPermission from "../../../utils/checkUserPermission";

const tabLists = {
  "/system-setup/email/template": 0,
  "/system-setup/email/config": 1,
};

function Header({ pathname, navigate, dispatch }) {
  let isUserAllowedToManageEmailTemplate = checkUserPermission(
    Permissions.ManageEmailTemplate
  );
  let isUserAllowedToManageEmailConfig = checkUserPermission(
    Permissions.ManageEmailConfig
  );

  const [activeTab, setActiveTab] = useState(
    (() => {
      const matchingPath = Object.keys(tabLists).find((key) =>
        pathname.startsWith(key)
      );
      return matchingPath ? tabLists[matchingPath] : null;
    })()
  );

  useEffect(() => {
    const matchingPath = Object.keys(tabLists).find((key) =>
      pathname.startsWith(key)
    );

    setActiveTab(() => (matchingPath ? tabLists[matchingPath] : null));
  }, [pathname]);

  const { templateStatus, templateOptions } = useSelector(
    selectAvailableTemplateOptions
  );

  const handleChange = (_, newValue) => {
    if (newValue !== null) {
      setActiveTab(newValue);
      navigate(Object.keys(tabLists).find((key) => tabLists[key] === newValue));
    }
  };

  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <ToggleButtonGroup
        size="small"
        value={activeTab}
        exclusive
        onChange={handleChange}
        sx={{
          ".MuiButtonBase-root": {
            borderRadius: "5px",
            padding: "8px 20px",
            fontWeight: 600,

            "&:first-child": {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },

            "&:last-child": {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          },
          ".Mui-selected": {
            backgroundColor: "#1B2C58 !important",
            color: "#FFFFFF !important",
            "&:hover": {
              backgroundColor: "#1B2C58 !important",
              color: "#FFFFFF !important",
              opacity: 0.9,
            },
          },
        }}
      >
        <ToggleButton value={0}>Template</ToggleButton>
        <ToggleButton sx={{}} value={1}>
          Config
        </ToggleButton>
      </ToggleButtonGroup>

      {activeTab === 0 && isUserAllowedToManageEmailTemplate && (
        <Button
          variant="contained"
          size="medium"
          sx={{ borderRadius: "5px" }}
          disabled={
            templateStatus !== Status.Succeeded || templateOptions.length === 0
          }
          onClick={() =>
            dispatch(
              initializeForm({
                formType: FormType.EMAIL_TEMPLATE,
                serviceData: { newTemplate: true },
                dynamicOption: {
                  emailType: templateOptions,
                },
              })
            )
          }
        >
          Add Email Template
        </Button>
      )}

      {activeTab === 1 && isUserAllowedToManageEmailConfig && (
        <Button
          variant="contained"
          size="medium"
          sx={{ borderRadius: "5px" }}
          onClick={() =>
            dispatch(
              initializeForm({
                formType: FormType.EMAIL_CONFIG,
                initialValue: {
                  configType: EmailConfigType.SMTP,
                  host: "",
                  userName: "",
                  port: null,
                  password: "",
                },
                serviceData: { newConfig: true },
              })
            )
          }
        >
          Add SMTP Config
        </Button>
      )}
    </Stack>
  );
}

export default function SystemSetupEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Stack sx={{ width: 1, height: 1 }}>
      <Header pathname={pathname} navigate={navigate} dispatch={dispatch} />
      <Outlet />
    </Stack>
  );
}
