import { Box, Paper, TextField } from "@mui/material";
import InputLabel from "./InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

export default function TextInputComponent({
  label,
  error,
  touched,
  isDisabled,
  placeholder,
  id,
  handleChange,
  handleBlur,
  value,
  inputType,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box sx={{ position: "reltiave", width: 1 }}>
      <InputLabel label={label} error={error} touched={touched} />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <TextField
          disabled={isDisabled}
          placeholder={placeholder}
          id={id}
          name={id}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          variant="standard"
          type={showPassword ? "text" : inputType}
          InputProps={
            inputType === "password"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        />
      </Paper>
    </Box>
  );
}
