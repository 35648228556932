import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import getKeysAndCertsTableData from "../../services/keysAndCertificates/getKeysAndCertsTableData";
import { createSelector } from "reselect";
import getAdditionalDetailFromKeyID from "../../services/keysAndCertificates/getAdditionalDetailFromKeyID";

const initialState = {
  status: {
    keysAndCertsTableData: Status.Idle,
    additionalDetailFromKeyID: Status.Idle,
  },
  error: {
    keysAndCertsTableData: null,
    additionalDetailFromKeyID: null,
  },
  keysAndCertsTableData: [],
  keyNameInput: "",
  additionalDetailFromKeyID: {},
};

export const keysAndCertsSlice = createSlice({
  name: "keysAndCerts",
  initialState,
  reducers: {
    resetTableDataState: (state) => {
      state.status.keysAndCertsTableData = Status.Idle;
      state.error.keysAndCertsTableData = null;
    },
    resetDetailState: (state) => {
      state.status.keysAndCertsTableData = Status.Idle;
      state.error.keysAndCertsTableData = null;

      state.additionalDetailFromKeyID = null;

      state.status.additionalDetailFromKeyID = Status.Idle;
      state.error.additionalDetailFromKeyID = null;
    },
    setKeyNameInput: (state, action) => {
      state.keyNameInput = action.payload?.trim();
    },
  },
  extraReducers: (builder) => {
    builder
      // For keysAndCerts Table Data
      .addCase(getKeysAndCertsTableData.pending, (state) => {
        state.status.keysAndCertsTableData = Status.Loading;
      })
      .addCase(getKeysAndCertsTableData.fulfilled, (state, action) => {
        state.keysAndCertsTableData = action.payload;
        state.status.keysAndCertsTableData = Status.Succeeded;
      })
      .addCase(getKeysAndCertsTableData.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.keysAndCertsTableData = [];
          state.status.keysAndCertsTableData = Status.Failed;
          state.error.keysAndCertsTableData =
            action.payload ?? "something went wrong";
        }
      })
      // For ApplicationDetailFromKeyID
      .addCase(getAdditionalDetailFromKeyID.pending, (state) => {
        state.status.additionalDetailFromKeyID = Status.Loading;
      })
      .addCase(getAdditionalDetailFromKeyID.fulfilled, (state, action) => {
        state.additionalDetailFromKeyID = action.payload;
        state.status.additionalDetailFromKeyID = Status.Succeeded;
      })
      .addCase(getAdditionalDetailFromKeyID.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.additionalDetailFromKeyID = {};
          state.status.additionalDetailFromKeyID = Status.Failed;
          state.error.additionalDetailFromKeyID =
            action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectAdditionalDetailFromKeyID = createSelector(
  (state) => state.keysAndCerts.additionalDetailFromKeyID,
  (state) => state.keysAndCerts.status.additionalDetailFromKeyID,
  (state) => state.keysAndCerts.error.additionalDetailFromKeyID,
  (additionalDetail, status, error) => ({
    additionalDetail,
    status,
    error,
  })
);

export const selectKeysAndCertsTableData = createSelector(
  [
    (state) => state.keysAndCerts.keysAndCertsTableData,
    (state) => state.keysAndCerts.keyNameInput,
    (state) => state.keysAndCerts.status.keysAndCertsTableData,
    (state) => state.keysAndCerts.error.keysAndCertsTableData,
  ],

  (tableData, keyName, status, error) => {
    tableData =
      tableData?.filter((data) =>
        data.certificateName.toLowerCase().includes(keyName.toLowerCase())
      ) ?? [];
    return { tableData, status, error };
  }
);

export const selectKeyDataByKeyID = createSelector(
  [
    (state) => state.keysAndCerts.keysAndCertsTableData,
    (state) => state.keysAndCerts.status.keysAndCertsTableData,
    (state) => state.keysAndCerts.error.keysAndCertsTableData,
    (_, keyID) => keyID,
  ],
  (tableData, status, error, keyID) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedKeyData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedKeyData = tableData.filter(
        (data) => data.keyID === Number(keyID)
      );

      if (selectedKeyData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedKeyData: selectedKeyData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedKeyData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedKeyData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedKeyData: null,
      };
    }
  }
);

export const { resetTableDataState, setKeyNameInput, resetDetailState } =
  keysAndCertsSlice.actions;

export default keysAndCertsSlice.reducer;
