import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getAllApproverTypeService = createAsyncThunk(
  "applicationManagement/getAllApproverTypeService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.ApplicationManagement.GetAllApproverType,
        abortController && {
          signal: abortController.signal,
        }
      );

      let response = {};

      request.data?.forEach((approver) => {
        if (!response[approver.ApproverType] && approver.Is_deleted === false) {
          response[approver.ApproverType] = approver.ApproverTypeName;
        }
      });

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getAllApproverTypeService;
