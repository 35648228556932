import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { createSelector } from "reselect";
import getIdentityStoreTableDataService from "../../services/identityStore/getIdentityStoreTableData";

const initialState = {
  status: Status.Idle,
  error: null,
  tableData: [],
};

export const identityStoreSlice = createSlice({
  name: "identityStore",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = Status.Idle;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdentityStoreTableDataService.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getIdentityStoreTableDataService.fulfilled, (state, action) => {
        state.tableData = action.payload;
        state.status = Status.Succeeded;
      })
      .addCase(getIdentityStoreTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.tableData = [];
          state.status = Status.Failed;
          state.error = action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectIdentityStoreTableData = createSelector(
  [
    (state) => state.identityStore.status,
    (state) => state.identityStore.error,
    (state) => state.identityStore.tableData,
  ],

  (status, error, tableData) => ({
    tableData,
    status,
    error,
  })
);

export const selectIdentityDataByIdentityID = createSelector(
  [
    (state) => state.identityStore.tableData,
    (state) => state.identityStore.status,
    (state) => state.identityStore.error,
    (_, identityID) => identityID,
  ],
  (tableData, status, error, identityID) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedIdentityData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedIdentityData = tableData.filter(
        (data) => data.id === identityID
      );

      if (selectedIdentityData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedIdentityData: selectedIdentityData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedIdentityData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedIdentityData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedIdentityData: null,
      };
    }
  }
);

export const { resetState } = identityStoreSlice.actions;

export default identityStoreSlice.reducer;
