import { closeLoader, showLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import { Labels, NotificationLabels } from "../../utils/constants";
import { getCurrentUTCTime } from "../../utils/dateFormatters";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";
import getApplicationEnvironmentsDetailService from "./getApplicationEnvironmentsDetailService";
import { switchForm } from "../../features/applicationManagement/applicationManagementSlice";
import { ApplicationManagementFormTypes } from "../../utils/enums";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { navigateBack } from "../../features/applicationManagement/applicationManagementSlice";
import { updateCertificateTeamDetails } from "../../features/applicationManagement/applicationManagementSlice";

export default async function manageCertificateTeamService(certificates) {
  try {
    store.dispatch(showLoader());

    let isPolicyManagementAllowed = checkUserPermission(
      Permissions.ManageApplicationPolicy
    );

    let certificateTeamUpdateReqArr = certificates.map((data) =>
      Api.post(URL.ApplicationManagement.UpdateCertificateTeam, {
        certificate_id: data.id,
        team_list: data.teams.map((team) => team.id),
      })
    );

    await Promise.all(certificateTeamUpdateReqArr);

    Notification.success(NotificationLabels.CERTIFICATE_TEAM_UPDATED);

    if (!isPolicyManagementAllowed) {
      store.dispatch(navigateBack());
    } else {
      // Update redux store with latest value of certificate and teams
      let updatedCertificates = certificates.map((data) => ({
        certificateUID: data.id,
        certificateName: data.certificateName,
        teams: data.teams.map((team) => ({
          teamID: team.id,
          teamName: team.name,
          members: team.members.map((member) => member.id),
        })),
      }));

      store.dispatch(updateCertificateTeamDetails(updatedCertificates));
      store.dispatch(
        switchForm({
          activeForm: ApplicationManagementFormTypes.Policy,
          completedForm: ApplicationManagementFormTypes.Team,
        })
      );
    }
  } catch (error) {
    Notification.error(NotificationLabels.CERTIFICATE_TEAM_UPDATE_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
