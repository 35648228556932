import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { getCurrentUTCTime } from "../../utils/dateFormatters";
import { store } from "../../lib/redux/store";
import {
  setApplicationDetailForForm,
  switchForm,
} from "../../features/applicationManagement/applicationManagementSlice";
import { closeLoader, showLoader } from "../../features/loader/loaderSlice";
import { ApplicationManagementFormTypes } from "../../utils/enums";

export default async function addNewApplicationDetailService(data) {
  try {
    store.dispatch(showLoader());

    let reqBody = {
      project_name: data.applicationName,
      description: data.applicationDescription,
      business_unit: data.businessUnit,
      owner:
        data.applicationOwners?.map((owner) => ({
          id: owner.id,
          email: owner?.email,
          label: owner.label,
        })) ?? [],
      repository_url: data.repositoryURL,
      artifact_type: data.artifactTypes,
      status: data.status === "Active" ? "Activate" : "Deactivate",
      created_user: store.getState().auth.userID,
      created_date: getCurrentUTCTime(),
      Is_deleted: data.status === "Active" ? false : true,
    };

    const request = await Api.post(
      URL.ApplicationManagement.AddApplication,
      reqBody
    );

    let response = {
      id: request.data.id ?? null,
      applicationID: request.data?.id ?? null,
      applicationName: request.data?.project_name ?? null,
      applicationDescription: request.data?.description ?? null,
      isDeleted: request.data?.Is_deleted ?? true,
      applicationOwners: request.data?.owner ?? [],
      businessUnit: request.data?.business_unit ?? null,
      repositoryURL: request.data?.repository_url ?? null,
      artifactTypes: request.data?.artifact_type ?? [],
      status: request.data?.Is_deleted === false ? "Active" : "Inactive",
      createdBy: request.data?.created_user ?? null,
      createdOn: request.data?.created_date ?? null,
      modifiedBy: request.data?.modified_by ?? null,
      modifiedDate: request.data?.modified_date ?? null,
    };

    store.dispatch(setApplicationDetailForForm(response));

    Notification.success(NotificationLabels.APPLICATION_ADDED_SUCCESSFULLY);

    store.dispatch(
      switchForm({
        activeForm: ApplicationManagementFormTypes.Environment,
        completedForm: ApplicationManagementFormTypes.Application,
      })
    );
  } catch (error) {
    Notification.error(NotificationLabels.APPLICATION_ADDITION_UNSUCCESSFULL);
  } finally {
    store.dispatch(closeLoader());
  }
}
