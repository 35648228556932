import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import { store } from "../../lib/redux/store";
import { initializeModal } from "../../features/modals/modalSlice";
import { ModalType } from "../../utils/constants";
import { UserRoles } from "../../utils/enums";

export const getLicenseInfoService = createAsyncThunk(
  "auth/getLicenseInfoService",
  async (_, { rejectWithValue }) => {
    try {
      let request = await Api.get(URL.License.GetLicenseInfo);

      let response = null;

      if (request.data && request.data.length > 0) {
        response = {
          status: request.data[0]?.activation_status !== 0 || false,
          licenseName: request.data[0]?.hash_string ?? "-",
          activatedOn:
            formatDateToUserTimeZone(request.data[0]?.active_date) ?? "-",
          expiresOn:
            formatDateToUserTimeZone(request.data[0]?.expiry_date) ?? "-",
        };
      }

      if (request.data && request.data.length > 0) {
        let userRoles = store.getState().auth.userRoles;

        let userIsAdmin = userRoles.some(
          (role) => role === UserRoles.SystemAdmin
        );

        if (userIsAdmin) {
          let sslModalFlagRequest = await Api.get(
            URL.SSLManagement.CheckModalFlag
          );

          if (sslModalFlagRequest.data.flag === true) {
            store.dispatch(
              initializeModal({
                modalType: ModalType.SSL_FLAG_MODAL,
              })
            );
          }
        }
      }

      return response;
    } catch (error) {
      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);
