import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function FormFooter({
  handleFormClose,
  handleSubmit,
  CustomFooter,
  cancelButtonLabel,
  submitButtonLabel,
}) {
  return (
    <DialogActions
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 3,
        alignItems: "center",
        justifyContent: "space-between",
        px: 3.5,
        py: 2,
      }}
    >
      {CustomFooter && <CustomFooter />}

      <Stack
        sx={{
          ml: "auto",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Button
          size="large"
          sx={{ borderRadius: "5px" }}
          variant="outlined"
          onClick={() => handleFormClose()}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          sx={{ borderRadius: "5px" }}
          size="large"
          variant="contained"
          onClick={() => handleSubmit()}
        >
          {submitButtonLabel}
        </Button>
      </Stack>
    </DialogActions>
  );
}
