import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import { closeForm } from "../../features/forms/formSlice";
import getKeysAndCertsTableData from "./getKeysAndCertsTableData";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";

export default async function updateCertReproducibleBuildKeyUsage(
  formData,
  initialData,
  serviceData
) {
  try {
    if (formData.keyUsage === initialData.keyUsage) {
      store.dispatch(closeForm());
      return;
    }

    store.dispatch(showLoader());

    let requestBody = {
      id: serviceData.keyID,
      key_usage: formData.keyUsage === "None" ? null : formData.keyUsage,
    };

    await Api.put(
      URL.KeysAndCertificates.UpdateCertReproducibleBuildKeyUsage,
      requestBody
    );

    store.dispatch(getKeysAndCertsTableData());

    store.dispatch(closeForm());

    Notification.success(NotificationLabels.UPDATE_SUCCESSFUL);
  } catch (error) {
    Notification.error(NotificationLabels.UPDATE_UNSUCCESSFUL);
  } finally {
    store.dispatch(closeLoader());
  }
}
