import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";

const getHSMHealthService = createAsyncThunk(
  "dashboard/getHSMHealthService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      const response = await Api.get(
        URL.Dashboard.HSMHealth,
        abortController && {
          signal: abortController.signal,
        }
      );

      let data = {
        createdTime: response.data?.create_time
          ? formatDateToUserTimeZone(response.data.create_time)
          : null,
        status: response.data?.status ?? null,
        vendor: response.data?.vendor ?? null,
      };

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getHSMHealthService;
