import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { createSelector } from "reselect";
import getEmailTemplateTableDataService from "../../services/email/getEmailTemplateTableData";
import getEmailConfigTableDataService from "../../services/email/getEmailConfigTableData";
import { EmailTemplateType } from "../../utils/enums";

const initialState = {
  status: {
    templateTableData: Status.Idle,
    configTableData: Status.Idle,
  },
  error: {
    templateTableData: null,
    configTableData: null,
  },
  templateTableData: [],
  configTableData: [],
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    resetTemplateState: (state) => {
      state.status.templateTableData = Status.Idle;
      state.error.templateTableData = null;
    },
    resetConfigState: (state) => {
      state.status.configTableData = Status.Idle;
      state.error.configTableData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // For Email Template
      .addCase(getEmailTemplateTableDataService.pending, (state) => {
        state.status.templateTableData = Status.Loading;
      })
      .addCase(getEmailTemplateTableDataService.fulfilled, (state, action) => {
        state.templateTableData = action.payload;
        state.status.templateTableData = Status.Succeeded;
      })
      .addCase(getEmailTemplateTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.templateTableData = [];
          state.status.templateTableData = Status.Failed;
          state.error.templateTableData =
            action.payload ?? "something went wrong";
        }
      })
      // For Email Config
      .addCase(getEmailConfigTableDataService.pending, (state) => {
        state.status.configTableData = Status.Loading;
      })
      .addCase(getEmailConfigTableDataService.fulfilled, (state, action) => {
        state.configTableData = action.payload;
        state.status.configTableData = Status.Succeeded;
      })
      .addCase(getEmailConfigTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.configTableData = [];
          state.status.configTableData = Status.Failed;
          state.error.configTableData =
            action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectEmailTemplateTableData = createSelector(
  [
    (state) => state.email.status.templateTableData,
    (state) => state.email.error.templateTableData,
    (state) => state.email.templateTableData,
  ],

  (status, error, tableData) => ({
    tableData,
    status,
    error,
  })
);

const emailTemplateOptions = [
  { id: 1, label: "Signing Request Create", value: 1 },
  { id: 2, label: "Signing Request Approve", value: 2 },
  { id: 3, label: "Assign Signing User/Office", value: 3 },
  { id: 4, label: "Certificate Expiry", value: 4 },
  { id: 5, label: "System Maintenance", value: 5 },
];

export const selectAvailableTemplateOptions = createSelector(
  [
    (state) => state.email.status.templateTableData,
    (state) => state.email.templateTableData,
    (_, currentTemplateType) => currentTemplateType,
  ],

  (status, tableData, currentTemplateType) => {
    let availableTemplateTypes = Object.values(EmailTemplateType);

    if (tableData?.length > 0) {
      const templateTypesInTableData = tableData.map(
        (template) => template.templateType
      );
      availableTemplateTypes = availableTemplateTypes.filter(
        (type) => !templateTypesInTableData.includes(type)
      );
    }

    if (currentTemplateType) {
      availableTemplateTypes.push(currentTemplateType);
    }

    const filteredOptions = emailTemplateOptions.filter((option) =>
      availableTemplateTypes.includes(option.value)
    );

    return {
      templateStatus: status,
      templateOptions: filteredOptions,
    };
  }
);

export const selectEmailConfigTableData = createSelector(
  [
    (state) => state.email.status.configTableData,
    (state) => state.email.error.configTableData,
    (state) => state.email.configTableData,
  ],

  (status, error, tableData) => ({
    tableData,
    status,
    error,
  })
);

export const selectConfigDataByConfigID = createSelector(
  [
    (state) => state.email.configTableData,
    (state) => state.email.status.configTableData,
    (state) => state.email.error.configTableData,
    (_, configID) => configID,
  ],
  (tableData, status, error, configID) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedConfigData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedConfigData = tableData.filter(
        (data) => data.configID === Number(configID)
      );

      if (selectedConfigData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedConfigData: selectedConfigData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedConfigData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedConfigData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedConfigData: null,
      };
    }
  }
);

export const { resetTemplateState, resetConfigState } = emailSlice.actions;

export default emailSlice.reducer;
