import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/material";
import InputLabel from "./InputLabel";
export default function CheckBoxInputComponent({
  label,
  id,
  handleChange,
  value,
}) {
  return (
    <Box>
      <FormControlLabel
        sx={{
          ".MuiStack-root": {
            mb: 0,
          },
        }}
        control={
          <Checkbox
            size="medium"
            id={id}
            checked={value}
            onChange={handleChange}
          />
        }
        label={<InputLabel label={label} />}
      />
    </Box>
  );
}
