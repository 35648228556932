import { closeForm } from "../../features/forms/formSlice";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";
import getSSLTableDataService from "./getSSLTableData";

export default async function renewSSLCertificate(__) {
  try {
    store.dispatch(showLoader());

    let reqBody = {
      domain: window.location.hostname,
    };

    await Api.post(URL.SSLManagement.RenewSSL, reqBody);

    store.dispatch(getSSLTableDataService());

    Notification.success("Certificate renewed successfully");
    store.dispatch(closeForm());
  } catch (error) {
    let errorMessage = error?.response?.data?.error ?? null;
    Notification.error(errorMessage ?? "Failed to renew certificate");
  } finally {
    store.dispatch(closeLoader());
  }
}
