import { useSnackbar } from "notistack";

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

const Notification = {
  success(msg) {
    this.toast(msg, "success");
  },
  warning(msg) {
    this.toast(msg, "warning");
  },
  info(msg) {
    this.toast(msg, "info");
  },
  error(msg, autoHideDuration) {
    if (autoHideDuration) {
      this.toast(msg, "error", autoHideDuration);
    } else {
      this.toast(msg, "error");
    }
  },
  toast(msg, variant = "default", autoHideDuration=2000) {
    useSnackbarRef.enqueueSnackbar(msg, { variant, autoHideDuration });
  },
};

export default Notification;
