import { closeForm } from "../../features/forms/formSlice";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import { NotificationLabels } from "../../utils/constants";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";
import getAllTeamService from "./getAllTeamService";

export default async function manageTeamService(formData, _, serviceData) {
  try {
    store.dispatch(showLoader());
    let reqBody = {
      team_name: formData.name,
      member_ids: formData.members.map((member) => member.id),
      certificate_ids: serviceData.certificateIDs.map((cert) => cert.id),
    };
    await Api.put(
      URL.ApplicationManagement.ManageTeam + serviceData.teamID + "/",
      reqBody
    );
    Notification.success(NotificationLabels.TEAM_UPDATED_SUCCESSFULLY);
    store.dispatch(closeForm());
    store.dispatch(getAllTeamService());
  } catch (error) {
    Notification.error(NotificationLabels.TEAM_UPDATE_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
