import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import getAllCertificateService from "../applicationManagement/getAllCertificateService";
import { closeForm } from "../../features/forms/formSlice";
import getKeysAndCertsTableData from "./getKeysAndCertsTableData";

export default async function createImportCertificateService(formData, _, __) {
  try {
    store.dispatch(showLoader());

    let reqBody = new FormData();

    reqBody.append("certificate_name", formData.certificateName);
    reqBody.append("certificate_description", formData.certificateDescription);
    reqBody.append("password", formData.password);
    reqBody.append("isActivate", true);
    reqBody.append("isArchive", false);
    reqBody.append("scope", formData.scope);
    reqBody.append("Is_deleted", false);
    reqBody.append("created_user", store.getState().auth.userID);
    reqBody.append("file_uploaded", formData.certificateFile);
    reqBody.append(
      "key_usage",
      formData.keyUsage === "None" ? null : formData.keyUsage
    );

    await Api.post(URL.KeysAndCertificates.GenerateImportCertificate, reqBody, {
      headers: { "Content-type": "multipart/form-data" },
    });

    store.dispatch(getKeysAndCertsTableData({ abortController: null }));

    if (store.getState().applicationManagement.formActive) {
      store.dispatch(getAllCertificateService());
    }

    store.dispatch(closeLoader());
    store.dispatch(closeForm());
    Notification.success(NotificationLabels.CERTIFICATE_CREATED);
  } catch (error) {
    store.dispatch(closeLoader());
    Notification.error(NotificationLabels.CERTIFICATE_ADDITION_FAILED);
  }
}
