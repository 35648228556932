import Api from "../../lib/axios/api";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";

export default async function upadteModalFlag() {
  try {
    let reqBody = {
      flag: false,
    };

    await Api.put(URL.SSLManagement.CheckModalFlag, reqBody);
  } catch (error) {
    let errorMessage = error?.response?.data?.error ?? null;
    Notification.error(errorMessage ?? "Failed to update ssl flag");
  }
}
