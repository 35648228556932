export default function downloadFile(fileData, fileName, fileType) {
  try {
    let file = new Blob([fileData], {
      type: fileType,
    });

    let fileLink = window.URL.createObjectURL(file);
    let tempLink = document.createElement("a");

    tempLink.href = fileLink;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  } catch (error) {
    throw new Error(error);
  }
}
