// React Router
import { Outlet } from "react-router-dom";

// MUI
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../lib/mui/theme"; //Theme file for MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Custom Components
import AsideBar from "../components/layouts/AsideBar";
import NavBar from "../components/layouts/NavBar";
// import Form from "../features/forms/FormModal";
import Loader from "../features/loader/Loader";
import { SnackbarUtilsConfigurator } from "../utils/notificationConfiguration";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Modal from "../features/modals/Modal";
import FormWrapper from "../features/forms/FormWrapper";

export default function Layout() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid container sx={{ width: "100vw", height: "100vh" }}>
            {/* <Grid item xs="auto"> */}
            <AsideBar />
            {/* </Grid> */}
            <Grid
              item
              xs
              sx={{ width: "100%", overflow: "hidden", position: "relative" }}
            >
              <Box sx={{ mb: 5 }}>
                <NavBar />
              </Box>
              <Box
                component="main"
                className="custom-scroll-bar"
                sx={{
                  height: 1,
                  maxHeight: "calc(100vh - 150px)",
                  overflowY: "auto",
                  mb: 5,
                  mt: 0,
                  paddingInline: 5,
                  maxWidth: "1920px",
                  margin: "0 auto",
                }}
              >
                <Outlet />
              </Box>
              <FormWrapper />
              <Modal />
              <Loader />
              {/* <SnackbarUtilsConfigurator /> */}
            </Grid>
          </Grid>
        </LocalizationProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}
