import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLoader: false,
};

// This is the slice for the whole KeysAndCerts Rout, this slice has all the info for each feature in this rout.
export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.showLoader = true;
    },
    closeLoader: (state) => {
      state.showLoader = false;
    },
  },
});

export const { showLoader, closeLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
