import { Grid, Stack, Divider, Button, Skeleton } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import MultiSelectInputComponent from "../../../components/ui/inputComponents/MultiSelectInputComponent";
import TextInputComponent from "../../../components/ui/inputComponents/TextInputComponent";
import TextInputWithSuggestionComponent from "../../../components/ui/inputComponents/TextInputWithSuggestionComponent";
import { useSelector } from "react-redux";
import SelectInputComponent from "../../../components/ui/inputComponents/SelectInputComponent";
import { FormLabels, Status } from "../../../utils/constants";
import { useEffect } from "react";
import {
  selectApplicationFormState,
  setApplicationDetailForForm,
} from "../applicationManagementSlice";
import { useNavigate } from "react-router-dom";
import AsyncMultiSelectInputComponent from "../../../components/ui/inputComponents/AsyncMultiSelectInputComponent";
import getUsersFromAzure from "../../../services/applicationManagement/getUsersFromAzure";
import { ArtificatTypes } from "../../../utils/enums";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import addNewApplicationDetailService from "../../../services/applicationManagement/addNewApplicationDetailService";
import updateApplicationDetailService from "../../../services/applicationManagement/updateApplicationDetailService";
import { useDispatch } from "react-redux";
import { store } from "../../../lib/redux/store";

export default function ApplicationForm({
  isNewApplication,
  applicationID = null,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { applicationDetail, status, businessUnits } = useSelector((state) =>
    selectApplicationFormState(state, applicationID)
  );

  useEffect(() => {
    if (
      !isNewApplication &&
      status === Status.Succeeded &&
      !applicationDetail
    ) {
      navigate("/not-found");
    }

    if (
      !isNewApplication &&
      status === Status.Succeeded &&
      !store.getState().applicationManagement.formState.applicationDetail
    ) {
      dispatch(setApplicationDetailForForm(applicationDetail));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, applicationDetail]);

  if (!isNewApplication && status !== Status.Succeeded) {
    return <FormSkeleton navigate={navigate} />;
  }

  function getInitialValue() {
    if (!applicationDetail) {
      return {
        applicationName: "",
        applicationOwners: [],
        businessUnit: "",
        repositoryURL: "",
        status: "Active",
        artifactTypes: [],
        applicationDescription: "",
      };
    } else {
      return {
        applicationName: applicationDetail?.applicationName ?? "",
        applicationOwners: applicationDetail?.applicationOwners ?? [],
        businessUnit: applicationDetail?.businessUnit ?? "",
        repositoryURL: applicationDetail?.repositoryURL ?? "",
        status: applicationDetail?.status ?? "Inactive",
        artifactTypes: applicationDetail?.artifactTypes ?? [],
        applicationDescription: applicationDetail?.applicationDescription ?? "",
      };
    }
  }

  return (
    <Form
      initialValue={getInitialValue()}
      status={status}
      isNewApplication={isNewApplication}
      applicationID={applicationID}
      navigate={navigate}
      businessUnits={businessUnits}
      applicationDetail={applicationDetail}
    />
  );
}

function FormSkeleton({ navigate }) {
  return (
    <Stack direction="column" sx={{ gap: 2, height: 1 }}>
      <Grid
        container
        spacing={8}
        sx={{ py: 3, px: 4, overflowY: "auto", height: 1 }}
        className="custom-scroll-bar"
      >
        {Array.from({ length: 7 }, (_, index) => index).map((_, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Skeleton variant="rounded" width={"100%"} height={70} />
            </Grid>
          );
        })}
      </Grid>

      <Divider />

      <Stack
        sx={{
          ml: "auto",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          px: 3,
          py: 2,
        }}
      >
        <Button
          size="large"
          sx={{ borderRadius: "5px" }}
          variant="outlined"
          onClick={() => navigate("/application-management")}
        >
          {FormLabels.CANCEL}
        </Button>

        <Button
          sx={{ borderRadius: "5px" }}
          size="large"
          variant="contained"
          disabled={true}
        >
          {FormLabels.SAVE_AND_PROCEED}
        </Button>
      </Stack>
    </Stack>
  );
}

const validationSchema = Yup.object().shape({
  applicationName: Yup.string()
    .trim()
    .required("Application Name is required")
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9_ -]*$/, "Invalid application name"),
  applicationOwners: Yup.array().min(1, "Specify at least 1 owner"),
  businessUnit: Yup.string().trim().required("Business Unit is required"),
  repositoryURL: Yup.string()
    .url("Invalid URL format")
    .required("Repository URL is required"),
  status: Yup.string().trim().required("Status is required"),
  artifactTypes: Yup.array().min(1, "Select at least 1 artifact type"),
  applicationDescription: Yup.string()
    .trim()
    .required("Application description is required"),
});

function Form({
  initialValue,
  status,
  isNewApplication,
  applicationID,
  navigate,
  businessUnits: businessUnitOptions,
  applicationDetail,
}) {
  const theme = useTheme();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const cleanedValues = {};

      Object.keys(values).forEach((key) => {
        if (typeof values[key] === "string") {
          cleanedValues[key] = values[key].trim();
        } else {
          cleanedValues[key] = values[key];
        }
      });

      if (isNewApplication) {
        if (applicationDetail) {
          let applicationID = applicationDetail.applicationID;
          updateApplicationDetailService(cleanedValues, applicationID);
        } else {
          addNewApplicationDetailService(cleanedValues);
        }
      } else {
        updateApplicationDetailService(cleanedValues, applicationID);
      }
    },
  });

  let GridItemMixin = {
    breakInside: "avoid",
    mb: 5,
    columnSpan: 1,
  };

  return (
    <Stack
      direction="column"
      sx={{ gap: 2, height: 1, justifyContent: "space-between" }}
    >
      <Box
        sx={{
          columnCount: 1,
          py: 3,
          px: 4,
          overflowY: "auto",
          width: "100%",
          columnFill: "balance",
          gap: 5,

          [theme.breakpoints.up("md")]: {
            columnCount: 2,
          },
          // "@media (max-width: 1920px)": { columnCount: 3 },
          // [theme.breakpoints.up("xl")]: {
          //   columnCount: 4,
          // },
        }}
        className="custom-scroll-bar"
      >
        {/* For Application Name */}
        <Box sx={{ ...GridItemMixin }}>
          <TextInputComponent
            id="applicationName"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values["applicationName"]}
            label="Application Name"
            error={formik.errors["applicationName"]}
            placeholder={"Type Application Name"}
            touched={formik.touched["applicationName"]}
          />
        </Box>

        {/* For Business Unit */}
        <Box sx={{ ...GridItemMixin }}>
          <TextInputWithSuggestionComponent
            value={formik.values["businessUnit"]}
            error={formik.errors["businessUnit"]}
            options={businessUnitOptions}
            optionIsString={true}
            touched={formik.touched["businessUnit"]}
            handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
            id="businessUnit"
            label="Business Unit"
            placeholder={
              status !== Status.Succeeded
                ? "Fetching business units..."
                : "Select Business Unit"
            }
            isDisabled={status !== Status.Succeeded}
          />
        </Box>

        {/* For Repository URL */}
        <Box sx={{ ...GridItemMixin }}>
          <TextInputComponent
            id="repositoryURL"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values["repositoryURL"]}
            label="Repository URL"
            error={formik.errors["repositoryURL"]}
            placeholder={"Type Repository URL"}
            touched={formik.touched["repositoryURL"]}
          />
        </Box>

        {/* For Status */}
        <Box sx={{ ...GridItemMixin }}>
          <SelectInputComponent
            id="status"
            label="Status"
            error={formik.errors["status"]}
            touched={formik.touched["status"]}
            placeholder={"Select Application Status"}
            setFieldValue={formik.setFieldValue}
            handleBlur={formik.handleBlur}
            value={formik.values["status"]}
            options={["Active", "Inactive"]}
            optionIsString={true}
          />
        </Box>

        {/* For Application Description */}
        <Box sx={{ ...GridItemMixin }}>
          <TextInputComponent
            id="applicationDescription"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values["applicationDescription"]}
            label="Application Description"
            error={formik.errors["applicationDescription"]}
            placeholder={"Type Application Description"}
            touched={formik.touched["applicationDescription"]}
          />
        </Box>

        {/* For Artifact Types */}
        <Box sx={{ ...GridItemMixin }}>
          <MultiSelectInputComponent
            error={formik.errors["artifactTypes"]}
            touched={formik.touched["artifactTypes"]}
            setFieldValue={formik.setFieldValue}
            handleBlur={formik.handleBlur}
            id="artifactTypes"
            label="Artifact Types"
            options={ArtificatTypes}
            placeholder="Select Artifact Types"
            optionIsString={false}
            value={formik.values["artifactTypes"]}
          />
        </Box>

        {/* For Application Owners */}
        <Box sx={{ ...GridItemMixin }}>
          <AsyncMultiSelectInputComponent
            error={formik.errors["applicationOwners"]}
            touched={formik.touched["applicationOwners"]}
            value={formik.values["applicationOwners"]}
            setFieldValue={formik.setFieldValue}
            handleBlur={formik.handleBlur}
            id="applicationOwners"
            label="Application Owners"
            placeholder="Select Application Owners"
            optionFetchingFunction={getUsersFromAzure}
            multipleValues={true}
          />
        </Box>
      </Box>

      <Box>
        <Divider />

        <Stack
          sx={{
            width: 1,

            flexDirection: "row-reverse",
            gap: 2,
            alignItems: "center",
            px: 3,
            py: 2,
          }}
        >
          <Button
            sx={{ borderRadius: "5px" }}
            size="large"
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            {FormLabels.SAVE_AND_PROCEED}
          </Button>

          <Button
            size="large"
            sx={{ borderRadius: "5px" }}
            variant="outlined"
            onClick={() => navigate("/application-management")}
          >
            {FormLabels.CANCEL}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
