import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { Status } from "../../utils/constants";
import getLogsTableDataService from "../../services/logs/getLogsTableDataService";

function getInitialDateRange() {
  const currentDate = moment().utc().format("YYYY-MM-DD");
  const sevenDaysAgo = moment().subtract(7, "days").utc().format("YYYY-MM-DD");

  return { fromDate: sevenDaysAgo, toDate: currentDate };
}

const initialState = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/GMT",
  logsDateRange: getInitialDateRange(),
  logsTableData: [],
  logsTableDataLoadingState: Status.Idle,
  logsTableDataErrorState: null,
  selectedLogData: null,
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    fetchLogsTableData: (state) => {
      state.logsTableDataLoadingState = Status.Idle;
    },
    setLogsDateRange: (state, action) => {
      state.logsDateRange = action.payload;
    },
    resetState: (state) => {
      state.logsTableDataLoadingState = Status.Idle;
      state.logsTableDataErrorState = null;
    },
    setSelectedTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setSelectedLogData: (state, action) => {
      state.selectedLogData = action.payload;
    },
    resetSelectedLogData: (state) => {
      state.selectedLogData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogsTableDataService.pending, (state) => {
        state.logsTableDataLoadingState = Status.Loading;
      })
      .addCase(getLogsTableDataService.fulfilled, (state, action) => {
        state.logsTableData = action.payload;
        state.logsTableDataLoadingState = Status.Succeeded;
      })
      .addCase(getLogsTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.logsTableData = [];
          state.logsTableDataLoadingState = Status.Failed;
          state.logsTableDataErrorState =
            action.payload ?? "something went wrong";
        }
      });
  },
});

export const {
  fetchLogsTableData,
  setLogsDateRange,
  setSelectedTimezone,
  resetState,
  setSelectedLogData,
  resetSelectedLogData,
} = logsSlice.actions;

export default logsSlice.reducer;
