import { Select, Paper, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { initializeForm } from "../formSlice";
import { FormType } from "../../../utils/constants";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";

function getOptionsForCertType() {
  let options = [];

  let isAllowedToCreateSelfSigned = checkUserPermission(
    Permissions.CreateSelfSignedCertificate
  );

  let isAllowedToGenerateCSR = checkUserPermission(Permissions.GenerateCSR);

  let isAllowedToImportCertificate = checkUserPermission(
    Permissions.ImportCertificate
  );

  if (isAllowedToCreateSelfSigned) {
    options.push({
      id: 1,
      label: "Self Signed Certificate",
      value: FormType.SELF_SIGNED_CERTIFICATE,
    });
  }

  if (isAllowedToGenerateCSR) {
    options.push({
      id: 2,
      label: "Signing Request",
      value: FormType.SIGNING_REQUEST_CERTIFICATE,
    });
  }

  if (isAllowedToImportCertificate) {
    options.push({
      id: 3,
      label: "Import Certificate",
      value: FormType.IMPORT_CERTIFICATE,
    });
  }

  return options;
}

export default function CreateCertificateFormHeader() {
  const dispatch = useDispatch();

  const selectedFormType = useSelector((state) => state.form.formType);

  function handleCertTypeChange(certType) {
    dispatch(
      initializeForm({
        formType: certType,
      })
    );
  }

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "5px",
        boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        width: "210px",
        mr: 3,
      }}
    >
      <Select
        sx={{
          width: 1,
          ".MuiSelect-select": {
            py: 1,
          },
          fieldset: {
            border: "0px !important",
          },
        }}
        variant="outlined"
        value={selectedFormType}
        onChange={(event) => handleCertTypeChange(event.target.value)}
        renderValue={(selected) => {
          const selectedOption = getOptionsForCertType().find(
            (option) => option.value === selected
          );
          return selectedOption.label;
        }}
      >
        {getOptionsForCertType().map((option) => (
          <MenuItem
            sx={{
              fontSize: "0.9rem",
            }}
            key={option.id}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
}
