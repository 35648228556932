import { Box, Paper, Typography } from "@mui/material";
import InputLabel from "./InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function getStyles(option, selected, optionIsString) {
  if (optionIsString) {
    return {
      backgroundColor: selected === option && "rgba(27, 44, 88, 0.08)",
    };
  }
  return {
    backgroundColor:
      selected?.label === option?.label && "rgba(27, 44, 88, 0.08)",
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: "10px",
      borderRadius: "5px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function SelectInputComponent({
  label,
  error,
  touched,
  placeholder,
  id,
  handleBlur,
  value: selectedValue,
  options,
  optionIsString,
  isRequired = true,
  isDisabled = false,
  setFieldValue,
  size = "large",
}) {
  function getInitialValue() {
    if (selectedValue) {
      if (optionIsString) return selectedValue;

      return JSON.stringify(selectedValue);
    } else {
      return "";
    }
  }

  return (
    <Box sx={{ position: "relative", width: 1 }}>
      <InputLabel label={label} error={error} touched={touched} size={size} />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          ...(size === "small" && { px: 1.5, py: 0.75 }),
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <Select
          disabled={isDisabled}
          size="small"
          maxRows={3}
          sx={{ width: 1 }}
          variant="standard"
          id={id}
          name={id}
          value={getInitialValue()}
          onChange={(event) => {
            if (optionIsString) {
              setFieldValue(id, event.target.value);
            } else {
              setFieldValue(id, JSON.parse(event.target.value));
            }
          }}
          onBlur={handleBlur}
          displayEmpty
          MenuProps={MenuProps}
          renderValue={(selected) => {
            return selected ? (
              optionIsString ? (
                <Typography
                  variant="subtitle1"
                  sx={{ ...(size === "small" && { fontSize: "0.85rem" }) }}
                >
                  {selected}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{ ...(size === "small" && { fontSize: "0.85rem" }) }}
                >
                  {JSON.parse(selected).label}
                </Typography>
              )
            ) : (
              <Typography variant="subtitle1" sx={{ opacity: 0.5 }}>
                {placeholder}
              </Typography>
            );
          }}
        >
          {!isRequired && <MenuItem value={null}>None</MenuItem>}
          {options.map((option, index) => (
            <MenuItem
              style={getStyles(option, selectedValue, optionIsString)}
              key={optionIsString ? index : option.id}
              value={optionIsString ? option : JSON.stringify(option)}
              sx={{ ...(size === "small" && { fontSize: "0.8rem" }) }}
            >
              {optionIsString ? option : option.label}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </Box>
  );
}
