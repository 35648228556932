// React
import { useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetDetailState,
  selectKeyDataByKeyID,
} from "../../features/keysAndCertificates/keysAndCertsSlice";

// Router
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

// Custom Components
import KeyAndCertDetailModal from "../../features/keysAndCertificates/keyAndCertDetailModal";
import getKeysAndCertsTableData from "../../services/keysAndCertificates/getKeysAndCertsTableData";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import { useErrorBoundary } from "react-error-boundary";
import KeyAndCertDetailTable from "../../features/keysAndCertificates/KeyAndCertDetailTable";
import { Skeleton } from "@mui/material";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 280px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function KeyAndCertificateDetail({ dispatch }) {
  const { keyID } = useParams();
  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const { isTableDataFetched, error, selectedKeyData } = useSelector((state) =>
    selectKeyDataByKeyID(state, keyID)
  );

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(getKeysAndCertsTableData());
    }

    if (!selectedKeyData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyID, isTableDataFetched, error, selectedKeyData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedKeyData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <KeyAndCertDetailTable dispatch={dispatch} tableData={selectedKeyData} />

      <KeyAndCertDetailModal
        keyID={keyID}
        tableData={selectedKeyData}
        dispatch={dispatch}
      />
    </Box>
  );
}

export default function ErrorComponentWrappedKeyAndCertDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetDetailState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetDetailState())}
    >
      <KeyAndCertificateDetail dispatch={dispatch} />
    </ErrorBoundary>
  );
}
