// MUI
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { initializeForm } from "../../features/forms/formSlice";

// Custom Components
import SearchKeyName from "../../features/keysAndCertificates/SearchKeyName";
import KeysAndCertsTable from "../../features/keysAndCertificates/KeysAndCertsTable";
import { FormType } from "../../utils/constants";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { resetTableDataState } from "../../features/keysAndCertificates/keysAndCertsSlice";
import { useEffect } from "react";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";

function Header({ dispatch }) {
  let isAllowedToCreateSelfSigned = checkUserPermission(
    Permissions.CreateSelfSignedCertificate
  );

  let isAllowedToGenerateCSR = checkUserPermission(Permissions.GenerateCSR);

  let isAllowedToImportCertificate = checkUserPermission(
    Permissions.ImportCertificate
  );

  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <SearchKeyName />

      {(isAllowedToCreateSelfSigned ||
        isAllowedToGenerateCSR ||
        isAllowedToImportCertificate) && (
        <Button
          variant="contained"
          size="large"
          sx={{
            borderRadius: "5px",
          }}
          onClick={() =>
            dispatch(
              initializeForm({
                formType: isAllowedToCreateSelfSigned
                  ? FormType.SELF_SIGNED_CERTIFICATE
                  : isAllowedToGenerateCSR
                  ? FormType.SIGNING_REQUEST_CERTIFICATE
                  : FormType.IMPORT_CERTIFICATE,
              })
            )
          }
        >
          Add New Certificate
        </Button>
      )}
    </Stack>
  );
}

export default function KeysAndCertificatesIndex() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetTableDataState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetTableDataState())}
    >
      <Stack sx={{ width: 1, height: 1 }}>
        <Header dispatch={dispatch} />
        <KeysAndCertsTable />
      </Stack>
    </ErrorBoundary>
  );
}
