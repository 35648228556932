import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "react-json-view-lite/dist/index.css";
import { Stack } from "@mui/material";
import { Labels } from "../../utils/constants";
import { formatDateForUI } from "./utils";
import Grid from "@mui/material/Grid";

export default function ReportDetailModal({ reportDetail }) {
  function formatDataForUI() {
    let arr = [
      {
        label: Labels.EVENT_TIME,
        value: reportDetail?.eventTime
          ? formatDateForUI(reportDetail.eventTime)
          : "-",
      },
      {
        label: Labels.LOCATION,
        value: reportDetail?.location || "-",
      },
      {
        label: Labels.APPLICATION_ID,
        value:
          reportDetail.applicationID >= 0 ? reportDetail.applicationID : "-",
      },
      {
        label: Labels.VALIDITY_PERIOD,
        value: reportDetail?.validityPeriod || "-",
      },
      {
        label: Labels.CREATED_USER,
        value: reportDetail.createdUser >= 0 ? reportDetail.createdUser : "-",
      },
      {
        label: Labels.SCOPE,
        value: reportDetail?.scope || "-",
      },
      {
        label: Labels.USERNAME,
        value: reportDetail?.userName || "-",
      },
      { label: Labels.IP_ADDRESS, value: reportDetail?.IPAddress || "-" },
      { label: Labels.USER, value: reportDetail?.user || "-" },
      {
        label: Labels.CREATED_DATE,
        value: reportDetail?.createdDate
          ? formatDateForUI(reportDetail.createdDate)
          : "-",
      },
      {
        label: Labels.STATUS,
        value: reportDetail?.status || "-",
      },
      {
        label: Labels.LEVEL,
        value: reportDetail?.level || "-",
      },
      {
        label: Labels.KEY_ALGORITHM,
        value: reportDetail?.keyAlgorithm || "-",
      },
      {
        label: Labels.ORGANIZATION_NAME,
        value: reportDetail?.organizationName || "-",
      },
      {
        label: Labels.ORGANIZATION_UNIT,
        value: reportDetail?.organizationUnit || "-",
      },
      {
        label: Labels.CATEGORY,
        value: reportDetail?.category || "-",
      },
      {
        label: Labels.DESCRIPTION,
        value: reportDetail?.description || "-",
      },
      { label: Labels.MESSAGE, value: reportDetail?.message || "-" },
    ];

    return arr;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 280px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "hidden",
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center", pb: 2 }}
      >
        <Typography color="primary.main" variant="h6">
          Report Detail
        </Typography>
      </Stack>
      <Box
        className="custom-scroll-bar"
        sx={{
          height: "90%",
          mt: 1,
          overflowY: "auto",
          "&>div": {
            background: "none",
          },
        }}
      >
        <Grid container spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              <Typography variant="subtitle1">{item.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
