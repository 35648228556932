import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { getSigningProjectsTableDataService } from "../../services/signingProjects/signingProjectsService";
import { createSelector } from "@reduxjs/toolkit";

const initialState = {
  signingProjectsTableData: [],
  signingProjectsTableDataLoadingState: Status.Idle,
  signingProjectsTableDataErrorState: null,
};

export const signingProjectsSlice = createSlice({
  name: "signingProjects",
  initialState,
  reducers: {
    resetSigningProjectsTableState: (state) => {
      state.signingProjectsTableDataLoadingState = Status.Idle;
      state.signingProjectsTableDataErrorState = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // For signingProjects Table Data
      .addCase(getSigningProjectsTableDataService.pending, (state) => {
        state.signingProjectsTableDataLoadingState = Status.Loading;
      })
      .addCase(
        getSigningProjectsTableDataService.fulfilled,
        (state, action) => {
          state.signingProjectsTableData = action.payload;
          state.signingProjectsTableDataLoadingState = Status.Succeeded;
        }
      )
      .addCase(getSigningProjectsTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.signingProjectsTableData = [];
          state.signingProjectsTableDataLoadingState = Status.Failed;
          state.signingProjectsTableDataErrorState =
            action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectSigningProjectsTableData = createSelector(
  [
    (state) => state.signingProjects.signingProjectsTableData,
    (state) => state.signingProjects.signingProjectsTableDataLoadingState,
    (state) => state.signingProjects.signingProjectsTableDataErrorState,
  ],

  (tableData, status, error) => ({ tableData, status, error })
);

export const selectProjectDataByProjectID = createSelector(
  [
    (state) => state.signingProjects.signingProjectsTableData,
    (state) => state.signingProjects.igningProjectsTableDataLoadingState,
    (state) => state.signingProjects.signingProjectsTableDataErrorState,
    (_, projectID) => projectID,
  ],
  (tableData, status, error, projectID) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedProjectData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedProjectData = tableData.filter(
        (data) => data.id === Number(projectID)
      );

      if (selectedProjectData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedProjectData: selectedProjectData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedProjectData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedProjectData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedProjectData: null,
      };
    }
  }
);

export const { resetSigningProjectsTableState } = signingProjectsSlice.actions;

export default signingProjectsSlice.reducer;
