import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { store } from "../../lib/redux/store";

const getLogsTableDataService = createAsyncThunk(
  "logs/getLogsTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let { fromDate, toDate } = store.getState().logs.logsDateRange;

      const logsDataRequest = await Api.get(
        URL.Logs.GetLogs + "?from_date=" + fromDate + "&to_date=" + toDate,
        abortController && {
          signal: abortController.signal,
        }
      );

      return logsDataRequest.data[1] ?? [];
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getLogsTableDataService;
