import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { Labels, NotificationLabels } from "../../utils/constants";
import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import getApplicationTableDataService from "./getApplicationTableDataService";

export default async function editApplicationStatusService({
  currentStatus,
  applicationID,
}) {
  try {
    store.dispatch(showLoader());

    let reqBody = {
      id: applicationID,
      Is_deleted: currentStatus === Labels.ACTIVE ? true : false,
    };

    await Api.patch(
      URL.ApplicationManagement.EditApplicationStatus + reqBody.id + "/",
      reqBody
    );

    if (currentStatus === Labels.ACTIVE) {
      Notification.success(
        NotificationLabels.APPLICATION_DEACTIVATED_SUCCESSFULLY
      );
    } else {
      Notification.success(
        NotificationLabels.APPLICATION_ACTIVATED_SUCCESSFULLY
      );
    }

    store.dispatch(getApplicationTableDataService());
  } catch (error) {
    if (currentStatus === Labels.ACTIVE) {
      Notification.error(NotificationLabels.APPLICATION_DEACTIVATION_FAILED);
    } else {
      Notification.error(NotificationLabels.APPLICATION_ACTIVATION_FAILED);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
