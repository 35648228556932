// React
import { useRef, useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Constants
import { Status } from "../../utils/constants";

import getEmailConfigTableDataService from "../../services/email/getEmailConfigTableData";
import { Button, Stack, Box } from "@mui/material";
import { Labels } from "../../utils/constants";
import { selectEmailConfigTableData } from "./emailSlice";
import { EmailConfigType } from "../../utils/enums";
import CustomTable from "../../components/ui/table/CustomTable";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import Skeleton from "@mui/material/Skeleton";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        mt: 3,
        width: 1,
        height: "calc(100% - 70px)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function EmailConfigTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(selectEmailConfigTableData);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getEmailConfigTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (status !== Status.Succeeded && tableData.length === 0) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} dispatch={dispatch} />;
}

function TableComponent({ tableData, dispatch }) {
  let isUserAllowedToManageEmailConfig = checkUserPermission(
    Permissions.ManageEmailConfig
  );

  const rowData = tableData;

  function getConfigTypeName(typeID) {
    let configName = "";

    switch (typeID) {
      case EmailConfigType.SMTP:
        configName = "SMTP";
        break;

      default:
        configName = "-";
        break;
    }

    return configName;
  }

  function handleEditClicked(data) {
    dispatch(
      initializeForm({
        formType: FormType.EMAIL_CONFIG,
        initialValue: {
          configType: data.configType,
          host: data.host,
          userName: data.userName,
          port: data.port,
          password: data.password,
        },
        serviceData: { ...data, newConfig: false },
      })
    );
  }

  const columnData = [
    {
      field: "host",
      headerName: Labels.HOST,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => renderHostCell(params.row),
    },
    {
      field: "configType",
      headerName: Labels.TYPE,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return getConfigTypeName(params.value);
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderHostCell(data) {
    if (data?.host) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${data.configID}`}
          >
            {data.host}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderActionCell(rowData) {
    return (
      <>
        {isUserAllowedToManageEmailConfig ? (
          <Stack
            direction="row"
            sx={{
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
                paddingInline: "30px",
              }}
              onClick={() => handleEditClicked(rowData)}
            >
              {Labels.EDIT}
            </Button>
            <Button
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
              }}
              variant="outlined"
              onClick={() => {
                if (rowData.status === "Active") {
                  dispatch(
                    initializeModal({
                      modalType: ModalType.ARCHIVE_EMAIL_CONFIG,
                      modalData: {
                        configID: rowData.configID,
                      },
                    })
                  );
                } else {
                  dispatch(
                    initializeModal({
                      modalType: ModalType.RESTORE_EMAIL_CONFIG,
                      modalData: {
                        configID: rowData.configID,
                      },
                    })
                  );
                }
              }}
            >
              {rowData.status === "Active" ? Labels.ARCHIVE : Labels.RESTORE}
            </Button>
          </Stack>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return <CustomTable rowData={rowData} columnData={columnData} />;
}
