import { createSelector, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import getApplicationTableDataService from "../../services/applicationManagement/getApplicationTableDataService";
import getApplicationEnvironmentsDetailService from "../../services/applicationManagement/getApplicationEnvironmentsDetailService";
import getAllCertificateService from "../../services/applicationManagement/getAllCertificateService";
import getAllEnvironmentService from "../../services/applicationManagement/getAllEnvironmentService";
import getAllApproverTypeService from "../../services/applicationManagement/getAllApproverTypeService";
import { ApplicationManagementFormTypes } from "../../utils/enums";
import getAllTeamService from "../../services/applicationManagement/getAllTeamService";
import getAllMemberService from "../../services/applicationManagement/getAllMemberService";

const initialState = {
  status: {
    applicationTableData: Status.Idle,
    selectedApplicationEnvironmentsDetail: Status.Idle,
  },
  error: {
    applicationTableData: null,
    selectedApplicationEnvironmentsDetail: null,
  },
  applicationTableData: [],
  searchedApplicationName: "",
  selectedApplicationEnvironmentsDetail: [],
  formActive: false,
  formState: {
    // For Application
    applicationDetail: null,
    businessUnits: [],
    allApplicationStatus: Status.Idle,
    allApplicationError: null,

    // For Environemnt Detail
    environmentDetail: [],

    environmentDetailStatus: Status.Idle,
    environmentDetailError: null,

    // For Certificate Team Detail
    certificateTeamDetail: [],

    // For Teams
    teams: [],
    teamStatus: Status.Idle,
    teamError: null,

    // For Members
    members: [],
    memberStatus: Status.Idle,
    memberError: null,

    // For Certificates
    certificates: [],
    certificateStatus: Status.Idle,
    certificateError: null,

    // For Environments
    environments: [],
    environmentStatus: Status.Idle,
    environmentError: null,

    // For Approvers
    approverTypes: {},
    approverTypeStatus: Status.Idle,
    approverTypeError: null,

    // For Form Type
    activeForm: ApplicationManagementFormTypes.Application,
    completedForms: [],

    navigateBack: false,
  },
};

export const applicationManagementSlice = createSlice({
  name: "applicationManagement",
  initialState,
  reducers: {
    resetApplicationTableDataState: (state) => {
      state.status.applicationTableData = Status.Idle;
      state.error.applicationTableData = null;
    },
    resetDetailState: (state) => {
      state.status.selectedApplicationEnvironmentsDetail = Status.Idle;
      state.error.selectedApplicationEnvironmentsDetail = null;

      state.selectedApplicationEnvironmentsDetail = [];

      state.status.applicationTableData = Status.Idle;
      state.error.applicationTableData = null;
    },
    resetApplicationFormStates: (state) => {
      // For Application
      state.formState.businessUnits = [];
      state.formState.applicationDetail = null;
      state.formState.allApplicationStatus = Status.Idle;
      state.formState.allApplicationError = null;

      // For Environment Detail
      state.formState.environmentDetail = [];
      state.formState.environmentDetailStatus = Status.Idle;
      state.formState.environmentDetailError = null;

      // For Certificate Team Details
      state.formState.certificateTeamDetail = [];

      // For Teams
      state.formState.teams = [];
      state.formState.teamStatus = Status.Idle;
      state.formState.teamError = null;

      // For Members
      state.formState.members = [];
      state.formState.memberStatus = Status.Idle;
      state.formState.memberError = null;

      // For Certificates
      state.formState.certificates = [];
      state.formState.certificateStatus = Status.Idle;
      state.formState.certificateError = null;

      // For Environments
      state.formState.environments = [];
      state.formState.environmentStatus = Status.Idle;
      state.formState.environmentError = null;

      // For Approvers
      state.formState.approverTypes = [];
      state.formState.approverTypeStatus = Status.Idle;
      state.formState.approverTypeError = null;

      // For Form Type
      state.formState.activeForm = ApplicationManagementFormTypes.Application;
      state.formState.completedForms = [];

      state.formState.navigateBack = false;
    },
    setSearchedApplicationName: (state, action) => {
      state.searchedApplicationName = action.payload;
    },
    toggleForm: (state, action) => {
      if (!state.formActive) {
        state.formState.completedForms = action.payload.isNewApplication
          ? []
          : [
              ApplicationManagementFormTypes.Application,
              ApplicationManagementFormTypes.Environment,
              ApplicationManagementFormTypes.Team,
              ApplicationManagementFormTypes.Policy,
            ];
      }
      state.formActive = !state.formActive;
    },
    setApplicationDetailForForm: (state, action) => {
      state.formState.applicationDetail = action.payload;
    },
    switchForm: (state, action) => {
      state.formState.activeForm = action.payload.activeForm;

      if (
        action.payload.completedForm &&
        !state.formState.completedForms.includes(action.payload.completedForm)
      ) {
        state.formState.completedForms = [
          ...state.formState.completedForms,
          action.payload.completedForm,
        ];
      }
    },
    navigateBack: (state) => {
      state.formState.navigateBack = true;
    },
    updateCertificateTeamDetails: (state, action) => {
      state.formState.certificateTeamDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // For Application Table Data
      .addCase(getApplicationTableDataService.pending, (state) => {
        if (state.formActive) {
          state.formState.allApplicationStatus = Status.Loading;
        } else {
          state.status.applicationTableData = Status.Loading;
        }
      })
      .addCase(getApplicationTableDataService.fulfilled, (state, action) => {
        if (state.formActive) {
          state.formState.allApplicationStatus = Status.Succeeded;
          state.formState.businessUnits = action.payload.businessUnits;
        } else {
          state.status.applicationTableData = Status.Succeeded;
        }
        state.applicationTableData = action.payload.applications;
      })
      .addCase(getApplicationTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          if (state.formActive) {
            state.formState.allApplicationStatus = Status.Failed;
            state.formState.allApplicationError =
              action.payload ?? "something went wrong";
          } else {
            state.status.applicationTableData = Status.Failed;
            state.error.applicationTableData =
              action.payload ?? "something went wrong";
          }
          state.applicationTableData = [];
        }
      })
      // For Application Environment Data
      .addCase(getApplicationEnvironmentsDetailService.pending, (state) => {
        if (state.formActive) {
          state.formState.environmentDetailStatus = Status.Loading;
        } else {
          state.status.selectedApplicationEnvironmentsDetail = Status.Loading;
        }
      })
      .addCase(
        getApplicationEnvironmentsDetailService.fulfilled,
        (state, action) => {
          if (state.formActive) {
            state.formState.environmentDetailStatus = Status.Succeeded;
            state.formState.environmentDetail =
              action.payload.environmentDetail;
            state.formState.certificateTeamDetail =
              action.payload.certificateTeamDetails;
          } else {
            state.status.selectedApplicationEnvironmentsDetail =
              Status.Succeeded;
            state.selectedApplicationEnvironmentsDetail =
              action.payload.environmentDetail;
          }
        }
      )
      .addCase(
        getApplicationEnvironmentsDetailService.rejected,
        (state, action) => {
          if (action.error.name === "CanceledError") {
            return;
          } else {
            if (state.formActive) {
              state.formState.environmentDetailStatus = Status.Failed;
              state.formState.environmentDetailError =
                action.payload ?? "something went wrong";
              state.formState.environmentDetail = [];
              state.formState.certificateTeamDetail = [];
            } else {
              state.status.selectedApplicationEnvironmentsDetail =
                Status.Failed;
              state.error.selectedApplicationEnvironmentsDetail =
                action.payload ?? "something went wrong";
              state.selectedApplicationEnvironmentsDetail = [];
            }
          }
        }
      )
      // For Fetching All Certificate
      .addCase(getAllCertificateService.pending, (state) => {
        state.formState.certificateStatus = Status.Loading;
      })
      .addCase(getAllCertificateService.fulfilled, (state, action) => {
        state.formState.certificates = action.payload;
        state.formState.certificateStatus = Status.Succeeded;
      })
      .addCase(getAllCertificateService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.formState.certificates = [];
          state.formState.certificateStatus = Status.Failed;
          state.formState.certificateError =
            action.payload ?? "something went wrong";
        }
      })
      // For Fetching All Environment
      .addCase(getAllEnvironmentService.pending, (state) => {
        state.formState.environmentStatus = Status.Loading;
      })
      .addCase(getAllEnvironmentService.fulfilled, (state, action) => {
        state.formState.environments = action.payload;
        state.formState.environmentStatus = Status.Succeeded;
      })
      .addCase(getAllEnvironmentService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.formState.environments = [];
          state.formState.environmentStatus = Status.Failed;
          state.formState.environmentError =
            action.payload ?? "something went wrong";
        }
      })
      // For Fetching All Approver Type
      .addCase(getAllApproverTypeService.pending, (state) => {
        state.formState.approverTypeStatus = Status.Loading;
      })
      .addCase(getAllApproverTypeService.fulfilled, (state, action) => {
        state.formState.approverTypeStatus = Status.Succeeded;
        state.formState.approverTypes = action.payload;
      })
      .addCase(getAllApproverTypeService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.formState.approverTypes = {};
          state.formState.approverTypeStatus = Status.Failed;
          state.formState.approverTypeError =
            action.payload ?? "something went wrong";
        }
      })
      // For Fetching All Teams
      .addCase(getAllTeamService.pending, (state) => {
        state.formState.teamStatus = Status.Loading;
      })
      .addCase(getAllTeamService.fulfilled, (state, action) => {
        state.formState.teamStatus = Status.Succeeded;
        state.formState.teams = action.payload;
      })
      .addCase(getAllTeamService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.formState.teams = [];
          state.formState.teamStatus = Status.Failed;
          state.formState.teamError = action.payload ?? "something went wrong";
        }
      })
      // For Fetching All Members
      .addCase(getAllMemberService.pending, (state) => {
        state.formState.memberStatus = Status.Loading;
      })
      .addCase(getAllMemberService.fulfilled, (state, action) => {
        state.formState.memberStatus = Status.Succeeded;
        state.formState.members = action.payload;
      })
      .addCase(getAllMemberService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.formState.members = [];
          state.formState.memberStatus = Status.Failed;
          state.formState.memberError =
            action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectApplicationTableData = createSelector(
  [
    (state) => state.applicationManagement.applicationTableData,
    (state) => state.applicationManagement.error.applicationTableData,
    (state) => state.applicationManagement.status.applicationTableData,
    (state) => state.applicationManagement.searchedApplicationName,
  ],

  (tableData, error, status, searchedApplicationName) => {
    tableData = tableData?.filter((data) =>
      data.applicationName
        .toLowerCase()
        .includes(searchedApplicationName.toLowerCase())
    );
    return {
      tableData,
      error,
      status,
    };
  }
);

export const selectApplicationDataByAppID = createSelector(
  [
    (state) => state.applicationManagement.applicationTableData,
    (state) => state.applicationManagement.status.applicationTableData,
    (state) => state.applicationManagement.error.applicationTableData,
    (_, applicationID) => applicationID,
  ],
  (tableData, status, error, applicationID) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedApplicationData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedApplicationData = tableData.filter(
        (data) => data.id === Number(applicationID)
      );

      if (selectedApplicationData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedApplicationData: selectedApplicationData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedApplicationData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedApplicationData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedApplicationData: null,
      };
    }
  }
);

export const selectApplicationEnvironmentsDetail = createSelector(
  (state) => state.applicationManagement.selectedApplicationEnvironmentsDetail,
  (state) =>
    state.applicationManagement.status.selectedApplicationEnvironmentsDetail,
  (state) =>
    state.applicationManagement.error.selectedApplicationEnvironmentsDetail,
  (applicationEnvrionmentsDetail, status, error) => ({
    applicationEnvrionmentsDetail,
    status,
    error,
  })
);

// For Application Form
export const selectApplicationFormState = createSelector(
  [
    (state) => state.applicationManagement.formState.applicationDetail,
    (state) => state.applicationManagement.applicationTableData,
    (state) => state.applicationManagement.formState.allApplicationStatus,
    (state) => state.applicationManagement.formState.businessUnits,
    (_, applicationID) => applicationID,
  ],
  (
    applicationDetail,
    allApplications,
    status,
    businessUnits,
    applicationID
  ) => {
    if (applicationDetail) {
      return {
        applicationDetail,
        status,
        businessUnits,
      };
    } else if (applicationID !== null) {
      let selectedApplicationData = allApplications?.filter(
        (data) => data.id === Number(applicationID)
      );

      if (selectedApplicationData?.length > 0) {
        return {
          applicationDetail: selectedApplicationData[0],
          status,
          businessUnits,
        };
      } else {
        return {
          applicationDetail: null,
          status,
          businessUnits,
        };
      }
    } else {
      return {
        applicationDetail: null,
        status,
        businessUnits,
      };
    }
  }
);

// For Certificate Form
export const selectCertificateFormState = createSelector(
  [
    (state) => state.applicationManagement.formState.environments,
    (state) => state.applicationManagement.formState.environmentStatus,
    (state) => state.applicationManagement.formState.certificates,
    (state) => state.applicationManagement.formState.certificateStatus,
  ],
  (environments, environmentStatus, certificates, certificateStatus) => ({
    environments,
    environmentStatus,
    certificates,
    certificateStatus,
  })
);

export const {
  resetApplicationTableDataState,
  resetDetailState,
  setSearchedApplicationName,
  resetApplicationFormStates,
  toggleForm,
  setApplicationDetailForForm,
  setDeletedEnvironmentIDS,
  switchForm,
  navigateBack,
  updateCertificateTeamDetails,
} = applicationManagementSlice.actions;

export default applicationManagementSlice.reducer;
