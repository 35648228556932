import { store } from "../../lib/redux/store";
import { closeLoader, showLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import getSigningRequestsTableDataService from "./getSigningRequestsTableDataService";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { SigningRequestStatus } from "../../utils/enums";
import { closeForm } from "../../features/forms/formSlice";
import { navigateBack } from "../../features/signingRequests/signingRequestsSlice";

export default async function manageSigningRequestService(
  formData,
  _,
  serviceData
) {
  try {
    store.dispatch(showLoader());

    if (formData.needMFA) {
      try {
        const validationRes = await Api.post(
          URL.SigningRequests.ValidateOTP + parseInt(formData.otp)
        );

        if (validationRes.data[0].Message === "Invalid") {
          throw new Error("Invalid OTP");
        }

        if (serviceData.type === "approve") {
          let reqBody = {
            id: serviceData.jobID,
            signingRequest_id: serviceData.requestID,
            approvalUser_id: store.getState().auth.userID,
            comment: formData.comment,
          };

          await Api.put(
            URL.SigningRequests.Approval + reqBody.id + "/",
            reqBody
          );

          Notification.success(NotificationLabels.SIGNING_REQUEST_APPROVED);
        } else {
          let reqBody = {
            id: serviceData.requestID,
            comment: formData.comment,
            code: parseInt(formData.otp),
            status: SigningRequestStatus.Rejected,
          };

          await Api.patch(
            URL.SigningRequests.Rejection + reqBody.id + "/",
            reqBody
          );

          Notification.success(NotificationLabels.SIGNING_REQUEST_REJECTED);
        }
      } catch (error) {
        Notification.error(NotificationLabels.INVALID_OTP);
        store.dispatch(closeLoader());
        return;
      }
    } else {
      if (serviceData.type === "approve") {
        let reqBody = {
          id: serviceData.jobID,
          signingRequest_id: serviceData.requestID,
          approvalUser_id: store.getState().auth.userID,
          comment: formData.comment,
        };

        await Api.put(URL.SigningRequests.Approval + reqBody.id + "/", reqBody);

        Notification.success(NotificationLabels.SIGNING_REQUEST_APPROVED);
      } else {
        let reqBody = {
          id: serviceData.requestID,
          comment: formData.comment,
          code: null,
          status: SigningRequestStatus.Rejected,
        };

        await Api.patch(
          URL.SigningRequests.Rejection + reqBody.id + "/",
          reqBody
        );
        Notification.success(NotificationLabels.SIGNING_REQUEST_REJECTED);
      }
    }

    store.dispatch(closeForm());

    store.dispatch(navigateBack());

    store.dispatch(
      getSigningRequestsTableDataService({
        isUserProjectManager: true,
      })
    );
  } catch (error) {
    if (serviceData.type === "approve") {
      Notification.error(NotificationLabels.SIGNING_REQUEST_APPROVE_FAILED);
    } else {
      Notification.error(NotificationLabels.SIGNING_REQUEST_REJECT_FAILED);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
