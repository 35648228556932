import { createSlice } from "@reduxjs/toolkit";
import { FormType } from "../../utils/constants";
import formData from "./formsConfig";

const initialState = {
  formType: FormType.NONE,
  initialValue: null,
  isFetchingInitialValue: false,
  serviceData: null,
  customSetupData: null,
  dynamicOption: null,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    initializeForm: (state, action) => {
      let { shouldFetchInitialValues, initialValue } =
        formData[action.payload.formType];

      state.formType = action.payload.formType;
      state.serviceData = action.payload?.serviceData ?? null;
      state.customSetupData = action.payload?.customSetupData ?? null;

      if (shouldFetchInitialValues) {
        state.isFetchingInitialValue = true;
      } else {
        state.initialValue = action.payload?.initialValue ?? initialValue;
      }

      if (action.payload.dynamicOption) {
        state.dynamicOption = action.payload.dynamicOption;
      }
    },

    initialValueFetched: (state, action) => {
      state.initialValue = action.payload;
      state.isFetchingInitialValue = false;
    },

    closeForm: (state) => {
      state.formType = FormType.NONE;
      state.initialValue = null;
      state.isFetchingInitialValue = false;
      state.serviceData = null;
      state.customSetupData = null;
      state.dynamicOption = null;
    },
  },
});

export const { initializeForm, initialValueFetched, closeForm } =
  formSlice.actions;

export default formSlice.reducer;
