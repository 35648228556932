import { Box, Button, Grid } from "@mui/material";
import { Labels, NotificationLabels, Status } from "../../utils/constants";
import CustomTable from "../../components/ui/table/CustomTable";
import { PermissionDescription, UserRoles } from "../../utils/enums";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetPermissionState, selectRolePermissionStates } from "./userSlice";
import {
  DialogContent,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  CircularProgress,
  DialogActions,
  Stack,
} from "@mui/material";
import getRolePermissionService from "../../services/user/getRolePermissionService";
import Notification from "../../utils/notificationConfiguration";
import { Formik, Form, FieldArray } from "formik";
import IconButton from "@mui/material/IconButton";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import updatePermissionService from "../../services/user/updatePermissionService";
import Switch from "@mui/material/Switch";

function PermissionManagement({ role, setFormState }) {
  const abortControllerRef = useRef(null);

  const dispatch = useDispatch();

  const { status, error, permissions } = useSelector(
    selectRolePermissionStates
  );

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    dispatch(
      getRolePermissionService({
        abortController: abortControllerRef.current,
        role,
      })
    );

    return () => {
      handleFormClose();

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      dispatch(resetPermissionState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    Notification.error(NotificationLabels.FAIL_TO_SET_UP_PERMISSION_FORM);

    handleFormClose();
  }

  function handleFormClose() {
    setFormState({
      showForm: false,
      role: null,
    });
  }

  if (status !== Status.Succeeded) {
    return (
      <Dialog
        fullWidth={true}
        open={true}
        maxWidth="md"
        sx={{
          ".MuiPaper-root": {
            borderRadius: "5px",
            backgroundColor: "tertiary.main",
          },
        }}
      >
        <DialogTitle
          sx={{
            px: 3.5,
            py: 1.75,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "primary.main" }}
          >{`${role.label} Permission Management`}</Typography>
        </DialogTitle>

        <Divider />

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              color: "primary.main",
              fontSize: "1.2rem",
            }}
          >
            Setting up the form please wait!
          </Typography>

          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Formik
      initialValues={{
        permissions: permissions,
      }}
      onSubmit={(values) => {
        updatePermissionService(values.permissions, role, handleFormClose);
      }}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form noValidate autoComplete="off">
          <FieldArray name="permissions">
            {() => (
              <Dialog
                fullWidth={true}
                open={true}
                maxWidth="md"
                onClose={() => handleFormClose()}
                sx={{
                  ".MuiDialog-container > .MuiPaper-root": {
                    width: 1,
                    height: "fit-content",
                    boxShadow: "3px 0px 13px 2px rgba(0, 0, 0, 0.05)",
                    overflow: "hidden",
                    borderRadius: "5px",
                    backgroundColor: "tertiary.main",
                  },
                }}
                autoComplete="off"
              >
                <DialogTitle
                  sx={{
                    px: 3.5,
                    py: 1.75,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "primary.main" }}
                  >{`${role.label} Permission Management`}</Typography>
                </DialogTitle>

                <FormCloseIcon handleFormClose={handleFormClose} />

                <Divider />

                <DialogContent sx={{ px: 3.5, pt: 3, pb: 4 }}>
                  <Grid container spacing={4}>
                    {values.permissions.map((detail, index) => (
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <ParticularPermission
                          id={index}
                          detail={detail}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </DialogContent>

                <Divider />

                <DialogActions
                  sx={{
                    width: 1,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 3,
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: 3.5,
                    py: 2,
                  }}
                >
                  <Stack
                    sx={{
                      ml: "auto",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="large"
                      sx={{ borderRadius: "5px" }}
                      variant="outlined"
                      onClick={() => handleFormClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ borderRadius: "5px" }}
                      size="large"
                      variant="contained"
                      onClick={() => submitForm()}
                    >
                      Update
                    </Button>
                  </Stack>
                </DialogActions>
              </Dialog>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}

function ParticularPermission({ id, detail, setFieldValue }) {
  let permissionStatusID = `permissions.${id}.status`;

  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={detail.status}
          onChange={(event) =>
            setFieldValue(permissionStatusID, event.target.checked)
          }
        />
        // <Checkbox
        //   checked={detail.status}
        //   onChange={(event) =>
        //     setFieldValue(permissionStatusID, event.target.checked)
        //   }
        // />
      }
      label={
        <Tooltip
          title={
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", p: 1, textAlign: "center" }}
            >
              {PermissionDescription[detail.id]}
            </Typography>
          }
          arrow
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
              ml: "5px",
            }}
          >
            {detail.label}
          </Typography>
        </Tooltip>
      }
    />
  );
}

function FormCloseIcon({ handleFormClose }) {
  return (
    <IconButton
      onClick={() => handleFormClose()}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <HighlightOffOutlinedIcon
        fontSize="small"
        color="primary"
        sx={{
          opacity: 0.8,
          "&:hover": {
            opacity: 1,
          },
        }}
      />
    </IconButton>
  );
}

export default function UserPermissionTable() {
  const [formState, setFormState] = useState({
    showForm: false,
    role: null,
  });

  const rowData = [
    {
      id: UserRoles.SystemAdmin.id,
      label: UserRoles.SystemAdmin.label,
      role: UserRoles.SystemAdmin,
      description:
        "Oversees and manages the entire Codesign Secure platform, ensuring system integrity, security, and accessibility.",
    },
    {
      id: UserRoles.ProjectManager.id,
      label: UserRoles.ProjectManager.label,
      role: UserRoles.ProjectManager,
      description:
        "Coordinates application timelines, resources, and team collaboration to meet project objectives efficiently.",
    },
    {
      id: UserRoles.SecurityOfficer.id,
      label: UserRoles.SecurityOfficer.label,
      role: UserRoles.SecurityOfficer,
      description:
        "Focuses on implementing and maintaining robust security measures to protect data and manage platform vulnerabilities.",
    },
    {
      id: UserRoles.Auditor.id,
      label: UserRoles.Auditor.label,
      role: UserRoles.Auditor,
      description:
        "Conducts thorough reviews and audits of platform processes and security to ensure compliance with standards and regulations.",
    },
    {
      id: UserRoles.Developer.id,
      label: UserRoles.Developer.label,
      role: UserRoles.Developer,
      description:
        "Maintains platform features, working on software development and technical problem-solving.",
    },
  ];

  const columnData = [
    {
      field: "label",
      headerName: Labels.ROLE,
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: "description",
      headerName: Labels.DESCRIPTION,
      flex: 1,
      minWidth: 300,
      filterable: false,
      sortable: false,
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 250,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderActionCell(rowData) {
    return (
      <Box
        sx={{
          width: 1,
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{
            fontSize: "0.9rem",
            borderRadius: "5px",
          }}
          onClick={() => {
            setFormState({
              showForm: true,
              role: rowData.role,
            });
          }}
        >
          {Labels.EDIT_PERMISSION}
        </Button>
      </Box>
    );
  }

  return (
    <>
      <CustomTable rowData={rowData} columnData={columnData} />
      {formState.showForm && (
        <PermissionManagement
          setFormState={setFormState}
          role={formState.role}
        />
      )}
    </>
  );
}
