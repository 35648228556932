import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Status } from "../../../utils/constants";
import {
  Stack,
  Box,
  Button,
  Skeleton,
  Divider,
  Paper,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { FormLabels } from "../../../utils/constants";
import { store } from "../../../lib/redux/store";
import { useDispatch } from "react-redux";
import {
  Formik,
  Form as FormikForm,
  FieldArray,
  getIn,
  useFormik,
} from "formik";
import * as Yup from "yup";
import AsyncMultiSelectInputComponent from "../../../components/ui/inputComponents/AsyncMultiSelectInputComponent";
import TextInputComponent from "../../../components/ui/inputComponents/TextInputComponent";
import getUsersFromAzure from "../../../services/applicationManagement/getUsersFromAzure";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DialogTitle, DialogActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SelectInputComponent from "../../../components/ui/inputComponents/SelectInputComponent";
import manageApplicationPolicyService from "../../../services/applicationManagement/manageApplicationPolicyService";
import noDataFoundPng from "../../../assets/images/no-data-found-png.png";
import { switchForm } from "../applicationManagementSlice";
import { ApplicationManagementFormTypes } from "../../../utils/enums";

export default function PolicyForm({ isNewApplication }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const navigateBack = useSelector(
  //   (state) => state.applicationManagement.formState.navigateBack
  // );

  // if (navigateBack) {
  //   navigate("/application-management");
  // }

  const [approverFormState, setApproverFormState] = useState({
    showForm: false,
    approverFormID: null,
    availableApproverTypes: [],
    setFieldValue: null,
    approverDetails: [],
  });

  let environmentDetailStatus = useSelector(
    (state) => state.applicationManagement.formState.environmentDetailStatus
  );

  let approverTypeStatus = useSelector(
    (state) => state.applicationManagement.formState.approverTypeStatus
  );

  // For Loader
  if (isNewApplication) {
    if (
      environmentDetailStatus === Status.Loading ||
      approverTypeStatus === Status.Loading
    ) {
      return <FormSkeleton navigate={navigate} />;
    }
  } else {
    if (
      environmentDetailStatus !== Status.Succeeded ||
      approverTypeStatus !== Status.Succeeded
    ) {
      return <FormSkeleton navigate={navigate} />;
    }
  }

  if (
    store.getState().applicationManagement.formState.environmentDetail
      .length === 0
  ) {
    return (
      <Stack
        sx={{
          width: 1,
          height: 1,
          alignItems: "center",
          justifyContent: "center",
          P: 2,
        }}
      >
        <Box sx={{ width: "100px", aspectRatio: 1 }}>
          <img src={noDataFoundPng} alt="No Data Found" />
        </Box>
        <Typography
          variant="h6"
          sx={{ fontSize: "1.25rem", fontWeight: 700 }}
          color="primary.main"
        >
          No Environment Found!
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2, borderRadius: "5px" }}
          onClick={() =>
            dispatch(
              switchForm({
                activeForm: ApplicationManagementFormTypes.Environment,
              })
            )
          }
        >
          Add Environment
        </Button>
      </Stack>
    );
  }

  function getInitialValue() {
    let environmentDetails =
      store.getState().applicationManagement.formState.environmentDetail;

    let initialValue =
      environmentDetails?.map((envData) => ({
        environmentName: envData.environmentName,
        environmentID: envData.environmentID,
        signingUsers:
          envData.signingUsers?.map((user) => ({
            userID: user.userID,
            label: user.userName,
            isNewlyAdded: false,
          })) ?? [],
        needMFA: envData.needMFA,
        approverDetails: envData.approverDetails
          ?.map((approver) => ({
            id: approver.approverID,
            typeID: approver.approverType,
            type:
              store.getState().applicationManagement.formState.approverTypes[
                approver.approverType
              ] ?? approver.approverType,
            level: approver.approverLevel,
            minCount: approver.approverMinCount,
            quorumUsers: approver.approverQuorumUsers ?? [],
            isNewlyAdded: false,
          }))
          .sort((a, b) => a.level - b.level),
      })) ?? [];

    return initialValue;
  }

  return (
    <>
      <FormContainer
        initialValue={getInitialValue()}
        navigate={navigate}
        setApproverFormState={setApproverFormState}
      />
      {approverFormState.showForm && (
        <ApproverForm
          formState={approverFormState}
          setFormState={setApproverFormState}
        />
      )}
    </>
  );
}

function FormSkeleton({ navigate }) {
  return (
    <Stack direction="column" sx={{ gap: 2, height: 1, width: 1 }}>
      <Stack
        sx={{
          flexDirection: "column",
          gap: 3,
          width: 1,
          height: "100%",
          px: 3,
          pt: 3,
          overflowY: "auto",
        }}
        className="custom-scroll-bar"
      >
        {Array.from({ length: 5 }, (_, index) => index).map((_) => (
          <Skeleton
            variant="rounded"
            sx={{ width: 1, minHeight: "200px", borderRadius: "5px" }}
          />
        ))}
      </Stack>

      <Box>
        <Divider />

        <Stack
          sx={{
            width: 1,
            flexDirection: "row-reverse",
            gap: 2,
            alignItems: "center",
            px: 3,
            py: 2,
          }}
        >
          <Button
            sx={{ borderRadius: "5px" }}
            size="large"
            variant="contained"
            disabled={true}
          >
            {FormLabels.FINISH}
          </Button>

          <Button
            size="large"
            sx={{ borderRadius: "5px" }}
            variant="outlined"
            onClick={() => navigate("/application-management")}
          >
            {FormLabels.CANCEL}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}

// Per Policy Validation Schema
const PolicySchema = Yup.object();

function Footer({ navigate, submitForm }) {
  return (
    <Box>
      <Divider />

      <Stack
        sx={{
          width: 1,
          gap: 2,
          alignItems: "center",
          flexDirection: "row-reverse",
          px: 3,
          py: 2,
        }}
      >
        <Button
          sx={{ borderRadius: "5px" }}
          size="large"
          variant="contained"
          onClick={() => submitForm()}
        >
          {FormLabels.FINISH}
        </Button>
        <Button
          size="large"
          sx={{ borderRadius: "5px" }}
          variant="outlined"
          onClick={() => navigate("/application-management")}
        >
          {FormLabels.CANCEL}
        </Button>
      </Stack>
    </Box>
  );
}

function FormContainer({ initialValue, navigate, setApproverFormState }) {
  const deletedApproverIDS = useRef(
    (() => {
      let deletedApproverObject = {};

      initialValue.forEach((env) => {
        deletedApproverObject[env.environmentID] = [];
      });

      return deletedApproverObject;
    })()
  );

  return (
    <Formik
      initialValues={{
        policies: initialValue,
      }}
      validationSchema={Yup.object().shape({
        policies: Yup.array().of(PolicySchema),
      })}
      onSubmit={(values) => {
        manageApplicationPolicyService(
          initialValue,
          values.policies,
          deletedApproverIDS.current
        );
      }}
    >
      {({ values, touched, errors, handleBlur, setFieldValue, submitForm }) => (
        <FormikForm noValidate autoComplete="off">
          <FieldArray name="environments">
            {() => (
              <>
                <Stack
                  sx={{
                    flexDirection: "column",
                    gap: 3,
                    width: 1,
                    px: 3,
                    overflowY: "auto",
                    height: "calc(100% - 72px)",
                  }}
                >
                  <Divider sx={{ borderColor: "transparent" }} />

                  {values.policies.map((policy, index) => (
                    <ParticularPolicyForm
                      key={index}
                      id={index}
                      errors={errors}
                      policy={policy}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      deletedApproverIDS={deletedApproverIDS}
                      setApproverFormState={setApproverFormState}
                    />
                  ))}

                  <Divider sx={{ borderColor: "transparent" }} />
                </Stack>

                <Footer navigate={navigate} submitForm={submitForm} />
              </>
            )}
          </FieldArray>
        </FormikForm>
      )}
    </Formik>
  );
}

function ParticularPolicyForm({
  id,
  policy,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  deletedApproverIDS,
  setApproverFormState,
}) {
  let signingUsersFormID = `policies.${id}.signingUsers`;
  let approversFormID = `policies.${id}.approverDetails`;
  let needMFAFormID = `policies.${id}.needMFA`;

  let allApproverTypes =
    store.getState().applicationManagement.formState.approverTypes;

  let availableApproverTypes =
    Object.keys(allApproverTypes)?.filter((typeID) => {
      let approverAlreadyExist = policy.approverDetails.some(
        (approver) => approver.typeID === Number(typeID)
      );

      return !approverAlreadyExist;
    }) ?? [];

  function openApproverForm() {
    setApproverFormState({
      showForm: true,
      approverFormID: approversFormID,
      availableApproverTypes: availableApproverTypes,
      setFieldValue: setFieldValue,
      approverDetails: policy.approverDetails,
    });
  }

  return (
    <Paper
      elevation={0}
      key={id}
      sx={{
        width: 1,
        height: "fit-content",
        px: 2.5,
        pt: 1.5,
        pb: 3,
        borderRadius: "5px",
        border: "1px solid #C4C7D0",
        backgroundColor: "#fafbfc",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 5,
      }}
    >
      <Box sx={{ flex: 1, minWidth: "200px" }}>
        <TextInputComponent
          label="Environment"
          isDisabled={true}
          value={policy.environmentName}
        />
      </Box>

      <Box sx={{ flex: 2, minWidth: "250px" }}>
        <AsyncMultiSelectInputComponent
          label="Signing Users"
          value={policy.signingUsers}
          id={signingUsersFormID}
          placeholder="Select Signing User"
          error={getIn(errors, signingUsersFormID)}
          touched={getIn(touched, signingUsersFormID)}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          optionFetchingFunction={getUsersFromAzure}
          equalityMethod={"label"}
          multipleValues={true}
        />
      </Box>

      <Box sx={{ flex: 2, minWidth: "250px" }}>
        <MultiChipInput
          environmentID={policy.environmentID}
          formID={approversFormID}
          setFieldValue={setFieldValue}
          approvers={policy.approverDetails}
          deletedApproverIDS={deletedApproverIDS}
        />
      </Box>

      <Stack
        sx={{
          minWidth: "140px",
          flex: 1,
          width: 1,
          height: "100px",
          flexDirection: "column",
          gap: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={policy.needMFA}
              onChange={(event) =>
                setFieldValue(needMFAFormID, event.target.checked)
              }
            />
          }
          label={
            <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 600 }}>
              Need MFA
            </Typography>
          }
        />
        <Button
          disableElevation
          variant="contained"
          size="small"
          sx={{
            borderRadius: "5px",
          }}
          disabled={availableApproverTypes.length === 0}
          onClick={() => openApproverForm()}
        >
          Add Approver
        </Button>
      </Stack>
    </Paper>
  );
}

function MultiChipInput({
  environmentID,
  formID,
  setFieldValue,
  approvers,
  deletedApproverIDS,
}) {
  function handleDelete(approverDetail, id) {
    let newApprovers = [...approvers.filter((_, index) => index !== id)];

    if (approverDetail.isNewlyAdded === false) {
      deletedApproverIDS.current[environmentID].push(approverDetail.id);
    }

    setFieldValue(formID, newApprovers);
  }

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <Typography
        variant="h6"
        sx={{ fontSize: "1rem", fontWeight: 600, mb: 1 }}
      >
        Approvers
      </Typography>

      <Stack
        sx={{
          width: 1,
          height: "fit-content",
          minHeight: "60px",
          p: 2,
          flexDirection: "row",
          gap: 2,
          flexWrap: "wrap",
          border: "1px solid #e0e3ea",
          borderRadius: "5px",
          ...(approvers.length === 0 && {
            justifyContent: "center",
            alignItems: "center",
          }),
        }}
      >
        {approvers.length > 0 ? (
          approvers.map((approver, index) => (
            <>
              {approver.type === "Quorum" ? (
                <Tooltip
                  title={
                    <Stack sx={{ p: 1, flexDirection: "column", gap: 1 }}>
                      <Typography
                        color="inherit"
                        sx={{
                          fontSize: "0.9rem",
                        }}
                      >
                        Approvers
                        {
                          <Typography
                            color="inherit"
                            sx={{ fontSize: "0.75rem" }}
                          >
                            {approver.quorumUsers
                              ?.map((user) => user.userName)
                              .join(", ")}
                          </Typography>
                        }
                      </Typography>

                      <Typography
                        color="inherit"
                        sx={{
                          fontSize: "0.9rem",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        Min Count :
                        {
                          <Typography
                            color="inherit"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            {approver.minCount}
                          </Typography>
                        }
                      </Typography>
                    </Stack>
                  }
                  arrow
                >
                  <Chip
                    sx={{ fontSize: "0.8rem" }}
                    label={`${approver.type} - Level ${index + 1}`}
                    variant="outlined"
                    onDelete={() => handleDelete(approver, index)}
                  />
                </Tooltip>
              ) : (
                <Chip
                  sx={{ fontSize: "0.8rem" }}
                  label={`${approver.type} - Level ${index + 1}`}
                  variant="outlined"
                  onDelete={() => handleDelete(approver, index)}
                />
              )}
            </>
          ))
        ) : (
          <Typography
            variant="h6"
            sx={{ fontSize: "1rem", fontWeight: 600, opacity: 0.4 }}
          >
            No Approver Defined
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

function ApproverForm({ formState, setFormState }) {
  let allApproverTypes =
    store.getState().applicationManagement.formState.approverTypes ?? [];

  let { availableApproverTypes } = formState;

  const formik = useFormik({
    initialValues: {
      approverType: null,
      minCount: 1,
      approvers: [],
    },
    validationSchema: Yup.object().shape({
      approverType: Yup.object().required("Approver Type is required"),
      minCount: Yup.number().when(
        ["approverType"],
        ([approverType], schema) => {
          return approverType?.label === "Quorum"
            ? schema.min(1, "Minimum Count can't be less then 1")
            : schema;
        }
      ),
      approvers: Yup.array().when(
        ["approverType", "minCount"],
        (values, schema) => {
          const [approverType, minCount] = values;
          return approverType?.label === "Quorum"
            ? schema.min(
                minCount,
                `Specify at least ${minCount} ${
                  minCount === 1 ? "approver" : "approvers"
                }`
              )
            : schema;
        }
      ),
    }),
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  function handleFormClose() {
    setFormState({
      showForm: false,
      approverFormID: null,
      availableApproverTypes: [],
      setFieldValue: null,
      approverDetails: [],
    });
  }

  function handleFormSubmit(values) {
    let { setFieldValue, approverFormID, approverDetails } = formState;

    let approverDetail = {
      id: null,
      typeID: values.approverType.value,
      type: values.approverType.label,
      quorumUsers:
        values.approvers?.map((user) => ({
          searchedUserName: user.email,
          email: user.email,
          userName: user.email,
        })) ?? [],
      isNewlyAdded: true,
      ...(values.approverType.label === "Quorum" && {
        minCount: values.minCount,
      }),
    };

    setFieldValue(approverFormID, [...approverDetails, approverDetail]);

    handleFormClose();
  }

  function FormCloseIcon() {
    return (
      <IconButton
        onClick={() => handleFormClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <HighlightOffOutlinedIcon
          fontSize="small"
          color="primary"
          sx={{
            opacity: 0.8,
            "&:hover": {
              opacity: 1,
            },
          }}
        />
      </IconButton>
    );
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={true}
      sx={{
        ".MuiDialog-container > .MuiPaper-root": {
          width: 1,
          height: "fit-content",
          boxShadow: "3px 0px 13px 2px rgba(0, 0, 0, 0.05)",
          overflow: "hidden",
          borderRadius: "5px",
          backgroundColor: "tertiary.main",
        },
      }}
      component="form"
      autoComplete="off"
    >
      <DialogTitle
        sx={{
          px: 3.5,
          py: 1.75,
          width: 1,
        }}
      >
        <Typography variant="h6" sx={{ color: "primary.main" }}>
          Add Approver
        </Typography>
      </DialogTitle>

      <FormCloseIcon />

      <Divider />

      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <SelectInputComponent
          id="approverType"
          label="Approver Type"
          error={formik.errors["approverType"]}
          touched={formik.touched["approverType"]}
          placeholder="Select Approver Type"
          handleBlur={formik.handleBlur}
          setFieldValue={formik.setFieldValue}
          optionIsString={false}
          value={formik.values["approverType"]}
          options={
            availableApproverTypes?.map((typeID) => ({
              id: parseInt(typeID),
              label: allApproverTypes[typeID],
              value: parseInt(typeID),
            })) ?? []
          }
        />

        {formik.values["approverType"]?.label === "Quorum" && (
          <>
            <TextInputComponent
              id="minCount"
              label="Minimum Count"
              error={formik.errors["minCount"]}
              touched={formik.touched["minCount"]}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              value={formik.values["minCount"]}
              inputType="number"
            />

            <AsyncMultiSelectInputComponent
              label="Approvers"
              value={formik.values["approvers"]}
              id={"approvers"}
              placeholder="Select Approvers"
              error={formik.errors["approvers"]}
              touched={formik.touched["approvers"]}
              setFieldValue={formik.setFieldValue}
              handleBlur={formik.handleBlur}
              optionFetchingFunction={getUsersFromAzure}
              multipleValues={true}
            />
          </>
        )}
      </DialogContent>

      <Divider />

      <DialogActions
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          px: 3.5,
          py: 2,
        }}
      >
        <Button
          size="large"
          sx={{ borderRadius: "5px" }}
          variant="outlined"
          onClick={() => handleFormClose()}
        >
          Close
        </Button>

        <Button
          sx={{ borderRadius: "5px" }}
          size="large"
          variant="contained"
          onClick={() => formik.submitForm()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
