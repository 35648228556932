import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../../features/auth/authSlice";
import dashboardSlice from "../../features/dashboard/dashboardSlice";
import keysAndCertsSlice from "../../features/keysAndCertificates/keysAndCertsSlice";
import loaderSlice from "../../features/loader/loaderSlice";
import modalSlice from "../../features/modals/modalSlice";
import signingToolsSlice from "../../features/signingTools/signingToolsSlice";
import logsSlice from "../../features/logs/LogsSlice";
import reportsSlice from "../../features/reports/ReportsSlice";
import applicationManagementSlice from "../../features/applicationManagement/applicationManagementSlice";
import backupSlice from "../../features/backup/backupSlice";
import emailSlice from "../../features/email/emailSlice";
import formSlice from "../../features/forms/formSlice";
import identityStoreSlice from "../../features/identityStore/identityStoreSlice";
import signingRequestsSlice from "../../features/signingRequests/signingRequestsSlice";
import userSlice from "../../features/user/userSlice";
import signingProjects from "../../features/signingProjects/SigningProjectsSlice";
import licenseSlice from "../../features/license/licenseSlice";
import sslManagementSlice from "../../features/sslCertificateManagement/sslManagementSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    dashboard: dashboardSlice,
    keysAndCerts: keysAndCertsSlice,
    form: formSlice,
    loader: loaderSlice,
    modal: modalSlice,
    signingTools: signingToolsSlice,
    logs: logsSlice,
    reports: reportsSlice,
    backup: backupSlice,
    email: emailSlice,
    identityStore: identityStoreSlice,
    signingRequests: signingRequestsSlice,
    applicationManagement: applicationManagementSlice,
    user: userSlice,
    signingProjects: signingProjects,
    license: licenseSlice,
    sslManagement: sslManagementSlice,
  },
});
