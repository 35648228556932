import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";

const getApplicationListService = createAsyncThunk(
  "reports/getApplicationListService",
  async (serviceConfig) => {
    try {
      let abortController = serviceConfig?.abortController;

      const request = await Api.get(
        URL.Reports.GetApplicationList,
        abortController && {
          signal: abortController.signal,
        }
      );

      const response =
        request.data?.map((item) => ({
          id: item.id,
          label: item.project_name,
          value: item.id,
        })) ?? [];

      return response;
    } catch (error) {
      if (error.message !== "canceled") {
        Notification.error(NotificationLabels.FAILED_TO_GET_APPLICATIONS);
      }
      throw error;
    }
  }
);

export default getApplicationListService;
