import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import getQuickInsightService from "../../services/dashboard/getQuickInsightService";
import { selectQuickInsight, resetQuickInsight } from "./dashboardSlice";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { Status } from "../../utils/constants";
import { useErrorBoundary } from "react-error-boundary";
import { store } from "../../lib/redux/store";

function LoadingSkeleton() {
  return (
    <Box sx={{ width: 1, height: 1 }}>
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          minHeight: "135px",
          height: "100%",
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function QuickInsight() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { data, status, error } = useSelector(selectQuickInsight);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getQuickInsightService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (data == null && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
        height: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{ flexShrink: 0, p: 2, py: 1.5, fontSize: "1.2rem", lineHeight: 1 }}
      >
        Quick Insight
      </Typography>

      <Divider />

      <Stack
        direction={"row"}
        sx={{
          flex: 1,
          gap: 3,
          py: 1.5,
          px: 2.25,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 800,
              borderBottom: 4,
              borderColor: "secondary.dark",
              width: "fit-content",
              fontSize: "1.15rem",
              mb: 1,
            }}
            variant="h6"
          >
            {data.approvals}
          </Typography>
          <Typography
            component={"span"}
            variant="subtitle1"
            sx={{ fontSize: "0.95rem" }}
          >
            Approvals
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              fontWeight: 800,
              borderBottom: 4,
              borderColor: "secondary.dark",
              width: "fit-content",
              fontSize: "1.15rem",
              mb: 1,
            }}
            variant="h6"
          >
            {data.totalRequests}
          </Typography>
          <Typography
            component={"span"}
            variant="subtitle1"
            sx={{ fontSize: "0.95rem" }}
          >
            Total Requests
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
}
