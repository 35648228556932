import { Box } from "@mui/material";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../components/ui/ErrorComponent";
import SigningRequestsTable from "../../features/signingRequests/SigningRequestsTable";
import { useDispatch } from "react-redux";
import { resetState } from "../../features/signingRequests/signingRequestsSlice";

export default function SigningRequestIndex() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <Box sx={{ width: 1, height: 1 }}>
        <SigningRequestsTable />
      </Box>
    </ErrorBoundary>
  );
}
