export default function TableOptionIcon({ fill, width }) {
  return (
    <svg width={width} viewBox="0 0 34 34" fill="none">
      <path
        d="M4.25 17.7083L4.25 7.08333C4.25 6.33189 4.54851 5.61122 5.07987 5.07986C5.61122 4.54851 6.33189 4.25 7.08333 4.25L26.9167 4.25C27.6681 4.25 28.3888 4.54851 28.9201 5.07986C29.4515 5.61122 29.75 6.33189 29.75 7.08333L29.75 26.9167C29.75 27.6681 29.4515 28.3888 28.9201 28.9201C28.3888 29.4515 27.6681 29.75 26.9167 29.75L16.2917 29.75M19.8333 4.25L19.8333 29.75M29.75 14.1667L4.25 14.1667M7.08333 22.6667L7.08333 31.1667M7.08333 31.1667L11.3333 26.9167M7.08333 31.1667L2.83333 26.9167"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
