import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IdentityStoreTable from "../../../features/identityStore/IdentityStoreTable";
import { useDispatch } from "react-redux";
import { initializeForm } from "../../../features/forms/formSlice";
import { FormType } from "../../../utils/constants";
import { useEffect } from "react";
import { resetState } from "../../../features/identityStore/identityStoreSlice";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";

function Header({ dispatch }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={{ flexDirection: "row", justifyContent: "flex-end" }}>
      <Button
        variant="contained"
        size="large"
        sx={{ borderRadius: "5px" }}
        onClick={handleClick}
      >
        Add Identity Store
      </Button>
      <Menu
        sx={{
          ".MuiPaper-root": {
            borderRadius: "5px",
            mt: "5px",
            width: "170px",

            ul: {
              p: 0,
              li: {
                fontSize: "0.9rem",
                fontWeight: 600,
              },
            },
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(
              initializeForm({
                formType: FormType.AD_IDENTITY,
                serviceData: { new: true },
              })
            );
            handleClose();
          }}
        >
          Add AD
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              initializeForm({
                formType: FormType.SSO_IDENTITY,
                serviceData: { new: true },
              })
            );
            handleClose();
          }}
        >
          Add SSO
        </MenuItem>
      </Menu>
    </Stack>
  );
}

export default function IdentityStoreIndex() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <Box sx={{ width: 1, height: 1 }}>
        {checkUserPermission(Permissions.ManageIdentityStore) && (
          <Header dispatch={dispatch} />
        )}

        <IdentityStoreTable />
      </Box>
    </ErrorBoundary>
  );
}
