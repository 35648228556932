// React
import { useRef, useEffect } from "react";

// MUI
import Button from "@mui/material/Button";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { selectBackupTableData } from "./backupSlice";

// Constants
import { Status, Labels } from "../../utils/constants";

// Custom Components
import CustomTable from "../../components/ui/table/CustomTable";
import { Stack } from "@mui/material";
import getBackupTableDataService from "../../services/backup/getBackupTableData";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import downloadBackup from "../../services/backup/downloadBackup";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        mt: 3,
        width: 1,
        height: "calc(100% - 70px)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function BackupTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(selectBackupTableData);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getBackupTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }
  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} dispatch={dispatch} />;
}

function TableComponent({ tableData, dispatch }) {
  const isUserAllowedToManageBackup = checkUserPermission(
    Permissions.ManageBackup
  );

  const rowData = tableData;

  const columnData = [
    {
      field: "backup",
      headerName: Labels.BACKUP_NAME,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        if (!params.value) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "event_time",
      headerName: Labels.EVENT_TIME,
      flex: 1,
      minWidth: 200,
      filterable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return "-";
        }
        return formatDateToUserTimeZone(params.value);
      },
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderActionCell(rowData) {
    return (
      <>
        {isUserAllowedToManageBackup ? (
          <Stack
            direction="row"
            sx={{
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
              }}
              onClick={() => downloadBackup({ backupName: rowData.backup })}
            >
              {Labels.DOWNLOAD}
            </Button>
            <Button
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
              }}
              variant="outlined"
              onClick={() => {
                dispatch(
                  initializeModal({
                    modalType: ModalType.RESTORE_BACKUP,
                    modalData: {
                      backupName: rowData.backup,
                    },
                  })
                );
              }}
            >
              {Labels.RESTORE}
            </Button>
          </Stack>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </>
    );
  }

  return (
    <CustomTable
      showTableOptions={true}
      rowData={rowData}
      columnData={columnData}
      valueToBeUsedAsID="backup"
      {...(!isUserAllowedToManageBackup && { tableHeight: "100%" })}
    />
  );
}
