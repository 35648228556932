import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { IdentityStoreType } from "../../utils/enums";
import getIdentityStoreTableDataService from "./getIdentityStoreTableData";

export default async function restoreIdentity({ identityID, identityType }) {
  try {
    store.dispatch(showLoader());

    let request = null;

    let requestBody = {
      id: identityID,
      Is_deleted: false,
    };

    if (identityType === IdentityStoreType.AD) {
      request = Api.patch(
        URL.IdentityStore.ManageAD + identityID + "/",
        requestBody
      );
    } else if (identityType === IdentityStoreType.SSO) {
      request = Api.patch(
        URL.IdentityStore.ManageSSO + identityID + "/",
        requestBody
      );
    }

    await request;

    Notification.success(NotificationLabels.IDENTITY_RESTORED);
    store.dispatch(getIdentityStoreTableDataService());
  } catch (error) {
    Notification.error(NotificationLabels.IDENTITY_RESTORE_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
