import { Box, Paper, TextField, Stack, Button, Chip } from "@mui/material";
import InputLabel from "./InputLabel";
import { useRef } from "react";

export default function MultiTextInputComponent({
  label,
  error,
  touched,
  placeholder,
  id,
  setFieldValue,
  handleBlur,
  value,
}) {
  const textInputRef = useRef(null);

  function handleSubmit() {
    let currentValue = textInputRef.current.value.trim();
    if (currentValue.length === 0) {
      return;
    }
    let values = [...value];
    if (!values.includes(currentValue)) {
      values.push(currentValue);
      setFieldValue(id, values);
    }
    textInputRef.current.value = "";
  }

  function handleDelete(selectedValue) {
    let values = [...value];
    let indexOfValue = values.findIndex((item) => item === selectedValue);
    if (indexOfValue !== -1) {
      values.splice(indexOfValue, 1);
      setFieldValue(id, values);
    }
  }

  function CustomChip({ selectedValue }) {
    return (
      <Chip
        onDelete={() => handleDelete(selectedValue)}
        size="small"
        sx={{ fontSize: "0.75rem" }}
        label={selectedValue}
      />
    );
  }

  return (
    <Box sx={{ position: "reltiave" }}>
      <InputLabel label={label} error={error} touched={touched} />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        {value?.length > 0 && (
          <Box
            className="custom-scroll-bar horizontal"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              overflow: "auto",
              pb: "5px",
            }}
          >
            {value.map((item, index) => (
              <CustomChip key={index} selectedValue={item} />
            ))}
          </Box>
        )}
        <Stack direction="row" sx={{ gap: 2 }}>
          <TextField
            inputRef={textInputRef}
            placeholder={placeholder}
            id={id}
            name={id}
            onBlur={handleBlur}
            variant="standard"
            sx={{ flex: 1 }}
          />
          <Button
            size="small"
            disableElevation
            sx={{ fontSize: "0.8rem", borderRadius: "5px" }}
            variant="contained"
            type="button"
            onClick={() => handleSubmit()}
          >
            Add
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
}
