import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { navigateBack } from "../../features/license/licenseSlice";

export default async function updateLicenseService(licenseKey, type) {
  try {
    store.dispatch(showLoader());

    let reqBody = {
      Registration_key: licenseKey,
    };

    await Api.post(URL.License.ActivateLicense, reqBody);

    if (type === "renew" || type === "expired") {
      Notification.success(NotificationLabels.LICENSE_RENEWED);
    } else {
      Notification.success(NotificationLabels.LICENSE_ACTIVATED);
    }

    store.dispatch(navigateBack());
  } catch (error) {
    if (type === "renew" || type === "expired") {
      Notification.error(NotificationLabels.FAILED_TO_RENEW_LICENSE);
    } else {
      Notification.error(NotificationLabels.FAILED_TO_ACTIVATE_LICENSE);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
