import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import { resetUserManagementState } from "../../../features/user/userSlice";
import UserManagementTable from "../../../features/user/UserManagementTable";

export default function UserManagement() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetUserManagementState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetUserManagementState())}
    >
      <UserManagementTable />
    </ErrorBoundary>
  );
}
