import { createSelector, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import getSigningToolServiceData from "../../services/signingTools/getSigningToolServiceData";

const initialState = {
  status: Status.Idle,
  error: null,
  selectedOS: "All",
  searchedToolName: null,
  signingToolsData: [],
};

export const signingToolsSlice = createSlice({
  name: "signingTools",
  initialState,
  reducers: {
    setSearchedToolName: (state, action) => {
      state.searchedToolName = action.payload;
    },
    setSelectedOS: (state, action) => {
      state.selectedOS = action.payload;
    },
    resetState: (state) => {
      state.status = Status.Idle;
      state.error = null;
      state.searchedToolName = null;
      state.selectedOS = "All";
    },
  },
  extraReducers: (builder) => {
    builder
      // For Fetching Tools Data
      .addCase(getSigningToolServiceData.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getSigningToolServiceData.fulfilled, (state, action) => {
        state.signingToolsData = action.payload;
        state.status = Status.Succeeded;
      })
      .addCase(getSigningToolServiceData.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.signingToolsData = [];
          state.error = action.payload ?? "something went wrong";
          state.status = Status.Failed;
        }
      });
  },
});

export const selectSigningToolState = createSelector(
  [
    (state) => state.signingTools.signingToolsData,
    (state) => state.signingTools.searchedToolName,
    (state) => state.signingTools.selectedOS,
    (state) => state.signingTools.status,
    (state) => state.signingTools.error,
  ],
  (toolsData, searchedToolName, selectedOS, status, error) => {
    if (toolsData.length > 0) {
      let filteredSigningToolData = toolsData.filter((toolData) => {
        let OSFilterResult = true;
        let searchNameResult = true;

        if (selectedOS !== "All" && toolData.os === selectedOS) {
          OSFilterResult = true;
        } else if (selectedOS !== "All") {
          OSFilterResult = false;
        }

        if (
          searchedToolName?.trim()?.length > 0 &&
          toolData.toolName
            .toLowerCase()
            .includes(searchedToolName.trim().toLowerCase())
        ) {
          searchNameResult = true;
        } else if (searchedToolName?.trim()?.length > 0) {
          searchNameResult = false;
        } else {
          searchNameResult = true;
        }

        return OSFilterResult && searchNameResult;
      });

      if (filteredSigningToolData.length > 0) {
        return {
          status: status,
          error: error,
          signingToolsData: filteredSigningToolData,
        };
      }
    }

    return {
      status: status,
      error: error,
      signingToolsData: [],
    };
  }
);

export const { setSelectedOS, setSearchedToolName, resetState } =
  signingToolsSlice.actions;

export default signingToolsSlice.reducer;
