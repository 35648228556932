import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Labels } from "../../utils/constants";
import Box from "@mui/material/Box";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IdentityStoreType, IdentityStoreADType } from "../../utils/enums";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { Typography } from "@mui/material";

export default function IdentityDetailTable({ identityDetail }) {
  let isUserAllowedToManageIdentityStore = checkUserPermission(
    Permissions.ManageIdentityStore
  );

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  function handleEditClicked() {
    setAnchorEl(null);
    if (identityDetail.identityType === IdentityStoreType.AD) {
      if (identityDetail.ADType === IdentityStoreADType.AzureAD) {
        dispatch(
          initializeForm({
            formType: FormType.AD_IDENTITY,
            initialValue: {
              ADType: {
                id: 1,
                label: "Azure AD",
                value: IdentityStoreADType.AzureAD,
              },
              clientID: identityDetail.clientID,
              secretKey: identityDetail.secretKey,
              callbackURL: identityDetail.callbackURL,
              connectionURL: identityDetail.connectionURL,
            },
            serviceData: { ...identityDetail, new: false },
          })
        );
      } else if (identityDetail.ADType === IdentityStoreADType.LocalAD) {
        dispatch(
          initializeForm({
            formType: FormType.AD_IDENTITY,
            initialValue: {
              ADType: {
                id: 2,
                label: "Local AD",
                value: IdentityStoreADType.LocalAD,
              },
              clientID: identityDetail.clientID,
              secretKey: "",
              callbackURL: "",
              connectionURL: identityDetail.connectionURL,
            },
            serviceData: { ...identityDetail, new: false },
          })
        );
      }
    } else if (identityDetail.identityType === IdentityStoreType.SSO) {
      dispatch(
        initializeForm({
          formType: FormType.SSO_IDENTITY,
          initialValue: {
            clientID: identityDetail.clientID,
            secretKey: identityDetail.secretKey,
            callbackURL: identityDetail.callbackURL,
            connectionURL: identityDetail.connectionURL,
            logoutURL: identityDetail.logoutURL,
          },
          serviceData: { ...identityDetail, new: false },
        })
      );
    }
  }

  function handleStatusClicked() {
    setAnchorEl(null);
    if (identityDetail.status === "Active") {
      dispatch(
        initializeModal({
          modalType: ModalType.ARCHIVE_IDENTITY,
          modalData: {
            identityID: identityDetail.identityID,
            identityType: identityDetail.identityType,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.RESTORE_IDENTITY,
          modalData: {
            identityID: identityDetail.identityID,
            identityType: identityDetail.identityType,
          },
        })
      );
    }
  }

  function renderActionCell() {
    return (
      <Box
        sx={{
          width: 1,
          height: 1,
        }}
      >
        {isUserAllowedToManageIdentityStore ? (
          <>
            <IconButton
              onClick={(event) => setAnchorEl(event.target)}
              size="small"
              color="primary"
              aria-label="more actions"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              sx={{
                "& .MuiPaper-root .MuiList-root": {
                  minWidth: "150px",
                  py: 0,
                  "& .MuiButtonBase-root": {
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    bgcolor: "tertiary.light",
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    "&:hover": {
                      bgcolor: "tertiary.main",
                    },
                    "& .MuiListItemText-root .MuiTypography-root": {
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    },
                  },
                },
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleEditClicked();
                  setAnchorEl(null);
                }}
              >
                {Labels.EDIT}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleStatusClicked();
                }}
              >
                {identityDetail?.status === Labels.ACTIVE
                  ? Labels.ARCHIVE
                  : Labels.RESTORE}
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </Box>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow className="custom-scroll-bar">
            <TableCell>{Labels.CLIENT_ID}</TableCell>
            <TableCell>{Labels.CALLBACK_URL}</TableCell>
            <TableCell>{Labels.CONNECTION_URL}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell
              sx={{
                maxWidth: 200,
              }}
            >
              {identityDetail?.clientID ?? "-"}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: 200,
              }}
            >
              {identityDetail?.callbackURL ?? "-"}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: 200,
              }}
            >
              {identityDetail?.connectionURL ?? "-"}
            </TableCell>
            <TableCell
              sx={{
                width: 150,
              }}
            >
              {renderStatusCell(identityDetail?.status ?? null)}
            </TableCell>
            <TableCell
              sx={{
                width: 150,
              }}
            >
              {renderActionCell()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
