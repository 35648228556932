import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "../../utils/constants";
import { selectIdentityStoreTableData } from "./identityStoreSlice";
import getIdentityStoreTableDataService from "../../services/identityStore/getIdentityStoreTableData";
import { Labels } from "../../utils/constants";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomTable from "../../components/ui/table/CustomTable";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import {
  IdentityStoreADType,
  IdentityStoreType,
  Permissions,
} from "../../utils/enums";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import Skeleton from "@mui/material/Skeleton";
import { useErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../utils/checkUserPermission";
import { Typography } from "@mui/material";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        mt: 3,
        width: 1,
        height: "calc(100% - 70px)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function IdentityStoreTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(
    selectIdentityStoreTableData
  );

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getIdentityStoreTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} dispatch={dispatch} />;
}

function TableComponent({ tableData, dispatch }) {
  let isUserAllowedToManageIdentityStore = checkUserPermission(
    Permissions.ManageIdentityStore
  );

  const [actionMenuState, setActionMenuState] = useState({
    anchorEl: null,
    selectedRowData: null,
  });

  const rowData = tableData;

  const columnData = [
    {
      field: "clientID",
      headerName: Labels.CLIENT_ID,
      width: 300,
      renderCell: (params) => renderClientIDCell(params.row),
    },
    {
      field: "callbackURL",
      headerName: Labels.CALLBACK_URL,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "connectionURL",
      headerName: Labels.CONNECTION_URL,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      minWidth: 150,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderClientIDCell(data) {
    if (data?.clientID) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${data.id}`}
          >
            {data.clientID}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderActionCell(rowData) {
    return (
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {isUserAllowedToManageIdentityStore ? (
          <IconButton
            onClick={(event) =>
              setActionMenuState({
                anchorEl: event.target,
                selectedRowData: rowData,
              })
            }
            size="small"
            color="primary"
          >
            <MoreVertIcon />
          </IconButton>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </Box>
    );
  }

  function handleEditClicked(data) {
    if (data.identityType === IdentityStoreType.AD) {
      if (data.ADType === IdentityStoreADType.AzureAD) {
        dispatch(
          initializeForm({
            formType: FormType.AD_IDENTITY,
            initialValue: {
              ADType: {
                id: 1,
                label: "Azure AD",
                value: IdentityStoreADType.AzureAD,
              },
              clientID: data.clientID,
              secretKey: data.secretKey,
              callbackURL: data.callbackURL,
              connectionURL: data.connectionURL,
            },
            serviceData: { ...data, new: false },
          })
        );
      } else if (data.ADType === IdentityStoreADType.LocalAD) {
        dispatch(
          initializeForm({
            formType: FormType.AD_IDENTITY,
            initialValue: {
              ADType: {
                id: 2,
                label: "Local AD",
                value: IdentityStoreADType.LocalAD,
              },
              clientID: data.clientID,
              secretKey: "",
              callbackURL: "",
              connectionURL: data.connectionURL,
            },
            serviceData: { ...data, new: false },
          })
        );
      }
    } else if (data.identityType === IdentityStoreType.SSO) {
      dispatch(
        initializeForm({
          formType: FormType.SSO_IDENTITY,
          initialValue: {
            clientID: data.clientID,
            secretKey: data.secretKey,
            callbackURL: data.callbackURL,
            connectionURL: data.connectionURL,
            logoutURL: data.logoutURL,
          },
          serviceData: { ...data, new: false },
        })
      );
    }
  }

  function handleStatusClicked(data) {
    if (data.status === "Active") {
      dispatch(
        initializeModal({
          modalType: ModalType.ARCHIVE_IDENTITY,
          modalData: {
            identityID: data.identityID,
            identityType: data.identityType,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.RESTORE_IDENTITY,
          modalData: {
            identityID: data.identityID,
            identityType: data.identityType,
          },
        })
      );
    }
  }

  function ActionMenu() {
    let { selectedRowData, anchorEl } = actionMenuState;
    return (
      <Menu
        sx={{
          "& .MuiPaper-root .MuiList-root": {
            minWidth: "150px",
            py: 0,
            "& .MuiButtonBase-root": {
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "tertiary.light",
              fontSize: "0.9rem",
              fontWeight: 500,
              "&:hover": {
                bgcolor: "tertiary.main",
              },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontSize: "0.9rem",
                fontWeight: 500,
              },
            },
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setActionMenuState({ anchorEl: null, selectedRowData: null });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditClicked(selectedRowData);
            setActionMenuState({ anchorEl: null, selectedRowData: null });
          }}
        >
          {Labels.EDIT}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStatusClicked(selectedRowData);
            setActionMenuState({ anchorEl: null, selectedRowData: null });
          }}
        >
          {selectedRowData?.status === Labels.ACTIVE
            ? Labels.ARCHIVE
            : Labels.RESTORE}
        </MenuItem>
      </Menu>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return (
    <>
      <CustomTable
        rowData={rowData}
        columnData={columnData}
        {...(!isUserAllowedToManageIdentityStore && { tableHeight: "100%" })}
      />
      <ActionMenu />
    </>
  );
}
