import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { resetConfigState } from "../../features/email/emailSlice";
import getEmailConfigTableDataService from "./getEmailConfigTableData";

export default async function archiveEmailConfig({ configID }) {
  try {
    store.dispatch(showLoader());

    let requestBody = {
      id: configID,
      Is_deleted: true,
    };

    await Api.patch(URL.Email.ManageConfig + configID + "/", requestBody);

    Notification.success(NotificationLabels.CONFIG_ARCHIVED);
    store.dispatch(getEmailConfigTableDataService());
  } catch (error) {
    Notification.error(NotificationLabels.CONFIG_ARCHIVE_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
