import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import getUserPermission from "../auth/getUserPermission";

export default async function updatePermissionService(
  permissions,
  role,
  closeForm
) {
  try {
    store.dispatch(showLoader());

    let approvedPermissionIDs = [];

    if (permissions?.length > 0) {
      permissions.forEach((permission) => {
        if (permission.status) {
          approvedPermissionIDs.push(permission.id);
        }
      });
    }

    let reqBody = {
      usergroupid: role.id,
      pids: approvedPermissionIDs,
    };

    await Api.post(URL.User.UpdatePermissions, reqBody);

    let userRoles = store.getState().auth.userRoles;

    await getUserPermission(userRoles);

    closeForm();

    Notification.success(NotificationLabels.PERMISSIONS_UPDATED);
  } catch (error) {
    Notification.error(NotificationLabels.FAIL_TO_UPDATE_PERMISSIONS);
  } finally {
    store.dispatch(closeLoader());
  }
}
