import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";
import { useState, useRef } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { resetState } from "../../../features/sslCertificateManagement/sslManagementSlice";
import { initializeForm } from "../../../features/forms/formSlice";
import { FormType } from "../../../utils/constants";
import { initializeModal } from "../../../features/modals/modalSlice";
import { ModalType } from "../../../utils/constants";
import SSLTable from "../../../features/sslCertificateManagement/SSLTable";
import ErrorComponent from "../../../components/ui/ErrorComponent";

function SSLOptionGroup({ dispatch }) {
  let options = ["Generate CSR", "Import Certificate", "Renew Certificate"];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    if (options[selectedIndex] === "Generate CSR") {
      dispatch(
        initializeForm({
          formType: FormType.Generate_CSR_FOR_SSL,
        })
      );
    } else if (options[selectedIndex] === "Import Certificate") {
      dispatch(
        initializeForm({
          formType: FormType.IMPORT_SSL_CERTIFICATE,
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.RENEW_SSL_CERTIFICATE,
        })
      );
    }
  };

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        sx={{
          "& > .MuiButtonBase-root": {
            p: 0.5,
            fontSize: "0.9rem",

            "&:nth-child(1)": {
              px: 1.5,
              borderBottomLeftRadius: "5px",
              borderTopLeftRadius: "5px",
            },

            "&:nth-child(2)": {
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
            },
          },
        }}
      >
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                borderRadius: "5px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      sx={{ fontSize: "0.9rem" }}
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function Header({ dispatch }) {
  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "flex-end", alignItems: "center" }}
    >
      <SSLOptionGroup dispatch={dispatch} />
    </Stack>
  );
}

export default function SSLManagement() {
  const isUserAllowedToManageSSL = checkUserPermission(
    Permissions.ManageSSLCertificate
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <Stack sx={{ width: 1, height: 1 }}>
        {isUserAllowedToManageSSL && <Header dispatch={dispatch} />}

        <SSLTable />
      </Stack>
    </ErrorBoundary>
  );
}
