// React
import { useState, useEffect, useRef, memo } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import getExpiringCertificateService from "../../services/dashboard/getExpiringCertificateService";
import { selectExpiringCertificates } from "./dashboardSlice";

// MUI
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/system";

// Chart JS
import { Bar } from "react-chartjs-2";

import { Status } from "../../utils/constants";

import { Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import noDataFoundPng from "../../assets/images/no-data-found-png.png";

import { useErrorBoundary } from "react-error-boundary";

function LoadingSkeleton() {
  return (
    <Box sx={{ width: 1, height: 1 }}>
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
          minHeight: "250px",
        }}
      />
    </Box>
  );
}

const ExpiringCertsChart = memo(function ({ data, setSelectedBarIndex }) {
  let expiringCount = data.map((item) => item.value);

  let chartReference = useRef(null);

  const chartData = {
    labels: ["7 Days", "30 Days", "60 Days", "90 Days", "Above 90 Days"],
    datasets: [
      {
        label: "Expiring certificate count",
        backgroundColor: "#78A1DE",
        barPercentage: 0.5,
        borderWidth: 1,
        hoverBackgroundColor: "#3A79D8",
        data: expiringCount,
      },
    ],
  };

  const handleBarClick = (event) => {
    if (chartReference.current) {
      const points = chartReference.current.getElementsAtEventForMode(
        event,
        "nearest",
        { intersect: true },
        true
      );

      if (points.length > 0) {
        const firstPoint = points[0];
        setSelectedBarIndex(firstPoint.index);
      }
    }
  };

  const chartOption = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
    onClick: handleBarClick,
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
        overflow: "hidden",
        "@media (max-width: 1100px)": { minHeight: "300px" },
      }}
    >
      <Typography
        variant="h6"
        sx={{ px: 2, py: 1.5, fontSize: "1.2rem", lineHeight: 1 }}
      >
        Expiring Certificates
      </Typography>

      <Divider />

      <Box
        sx={{
          width: "100%",
          height: "100%",
          px: 2,
          py: 1,
          position: "relative",
        }}
      >
        <Bar
          ref={(reference) => (chartReference.current = reference)}
          data={chartData}
          options={chartOption}
        />
      </Box>
    </Paper>
  );
});

function ExpiringCertsTable({ data, selectedBarIndex }) {
  let label = null;

  let certificates = [];

  if (selectedBarIndex) {
    label = data[selectedBarIndex].label;
    certificates = data[selectedBarIndex].certificates;
  } else if (data?.length > 0) {
    let index = data.findIndex((item) => item.value > 0);

    if (index !== -1) {
      certificates = data[index].certificates;
      label = data[index].label;
    }
  }

  if (certificates.length === 0) {
    return (
      <Paper
        elevation={0}
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            width: 1,
            height: 1,
          }}
        >
          <Box sx={{ width: "50px" }}>
            <img width={"100%"} src={noDataFoundPng} alt="No Data Found" />
          </Box>
          <Typography
            variant="h6"
            sx={{ fontSize: "1rem", fontWeight: 700 }}
            color="primary.main"
          >
            No Certificate is Expiring
          </Typography>
        </Box>
      </Paper>
    );
  }

  const columnData = [
    {
      field: "certificateName",
      headerName: "Certificate Name",
      flex: 1,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      flex: 1,
    },
  ];

  const rowData = certificates.map((data, index) => ({
    id: index,
    certificateName: data?.certificate_name ?? null,
    expiryDate: data?.expiry_date
      ? formatDateToUserTimeZone(data.expiry_date)
      : null,
  }));

  return (
    <Paper
      elevation={0}
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
        "@media (max-width: 1100px)": { minHeight: "300px" },
      }}
    >
      <Typography
        variant="h6"
        sx={{ p: 2, py: 1.5, fontSize: "1.2rem", lineHeight: 1 }}
      >
        {label} : {certificates.length}
      </Typography>

      <Divider />

      <DataGrid
        rows={rowData}
        columns={columnData}
        disableColumnSelector
        disableRowSelectionOnClick
        autoPageSize
        initialState={{
          sorting: {
            sortModel: [{ field: "expiryDate", sort: "asc" }],
          },
        }}
      />
    </Paper>
  );
}

export default function ExpiringCertificates() {
  const theme = useTheme();

  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { data, status, error } = useSelector(selectExpiringCertificates);

  const [selectedBarIndex, setSelectedBarIndex] = useState(null);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getExpiringCertificateService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (data == null && status !== Status.Succeeded) {
    return (
      <Box
        sx={{
          flex: 1,
          flexShrink: 0,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr",
          gap: 2,
        }}
      >
        <LoadingSkeleton />
        <LoadingSkeleton />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 2,
        width: 1,
        height: 1,
        minHeight: "280px",
        "@media (max-width: 1100px)": { gridTemplateColumns: "1fr" },
      }}
    >
      <ExpiringCertsChart
        data={data}
        setSelectedBarIndex={setSelectedBarIndex}
        theme={theme}
      />

      <ExpiringCertsTable
        data={data}
        selectedBarIndex={selectedBarIndex}
        theme={theme}
      />
    </Box>
  );
}
