// React
import { useRef } from "react";

// Redux
import { useDispatch } from "react-redux";
import { setSearchedToolName } from "./signingToolsSlice";

// MUI
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
import { store } from "../../lib/redux/store";

export default function SearchSigningTool() {
  const dispatch = useDispatch();

  const defaultSearchedToolName = useRef(
    (() => store.getState().signingTools.searchedToolName)()
  );

  const debouncedOnInputChange = debounce((toolName) => {
    dispatch(setSearchedToolName(toolName));
  }, 200);

  function onInputChange(event) {
    let toolName = event.target.value;
    debouncedOnInputChange(toolName);
  }

  return (
    <TextField
      sx={{
        width: 300,
        "& .MuiInputBase-root": {
          backgroundColor: "background.paper",
          pr: 0,
          borderRadius: "5px",

          "& fieldset": {
            border: "2px solid",
            borderColor: "background.paper",
          },
          "&:hover fieldset": {
            borderColor: "tertiary.main",
          },
          "&.Mui-focused fieldset": {
            borderColor: "divider",
          },
        },
      }}
      defaultValue={defaultSearchedToolName.current}
      size="small"
      id="tool-name"
      variant="outlined"
      placeholder="Search Signing Tool"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={onInputChange}
    />
  );
}
