import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";

const tabLists = {
  "/system-setup/identity-store": 0,
  "/system-setup/backup": 1,
  "/system-setup/user/management": 2,
  "/system-setup/user/permissions": 2,
  "/system-setup/email/template": 3,
  "/system-setup/email/config": 3,
  "/system-setup/signing-projects": 4,
  "/system-setup/ssl-tls-management": 5,
};

export default function SystemSetupIndex() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState(
    (() => {
      const matchingPath = Object.keys(tabLists).find((key) =>
        pathname.startsWith(key)
      );
      return matchingPath ? tabLists[matchingPath] : null;
    })()
  );

  useEffect(() => {
    const matchingPath = Object.keys(tabLists).find((key) =>
      pathname.startsWith(key)
    );

    setActiveTab(() => (matchingPath ? tabLists[matchingPath] : null));
  }, [pathname]);

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
    navigate(Object.keys(tabLists).find((key) => tabLists[key] === newValue));
  };

  return (
    <Stack direction="column" sx={{ width: 1, height: 1, gap: 3 }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "#D6D5D5",
          ".MuiTabs-root": { minHeight: "unset" },
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="black"
          scrollButtons={false}
          sx={{
            ".MuiTabs-flexContainer": {
              gap: 5,
            },
            ".MuiButtonBase-root": {
              padding: "0px 0px 12px 0px",
              minHeight: "unset",
              minWidth: "fit-content",
              fontWeight: 700,
              color: "black",
              textTransform: "none",
              fontSize: "1rem",
            },
          }}
        >
          {checkUserPermission(Permissions.ViewSystemSetup) && (
            <Tab value={0} label="Identity Store" />
          )}
          {checkUserPermission(Permissions.ViewSystemSetupBackup) && (
            <Tab value={1} label="Backup" />
          )}
          {(checkUserPermission(Permissions.ViewSystemSetupUserManagement) ||
            checkUserPermission(
              Permissions.ViewSystemSetupUserPermissions
            )) && <Tab value={2} label="User" />}
          {checkUserPermission(Permissions.ViewSystemSetupEmail) && (
            <Tab value={3} label="Email" />
          )}
          {checkUserPermission(Permissions.ViewSystemSetupSigningProjects) && (
            <Tab value={4} label="Signing Projects" />
          )}
          <Tab value={5} label="SSL / TLS Management" />
        </Tabs>
      </Box>
      <Box sx={{ width: 1, flex: 1, overflowY: "auto" }}>
        <Outlet />
      </Box>
    </Stack>
  );
}
