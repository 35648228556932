import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import getSigningRequestsTableDataService from "../../services/signingRequests/getSigningRequestsTableDataService";
import { selectRequestDetailByReqID } from "../../features/signingRequests/signingRequestsSlice";
import { Box } from "@mui/material";
import SigningRequestDetailTable from "../../features/signingRequests/SigningRequestDetailTable";
import SigningRequestDetailModal from "../../features/signingRequests/SigningRequestDetailModal";
import { Skeleton } from "@mui/material";
import { resetState } from "../../features/signingRequests/signingRequestsSlice";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import { useErrorBoundary } from "react-error-boundary";
function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 280px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function SigningRequestDetail({ dispatch }) {
  const { requestID } = useParams();

  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const navigateBack = useSelector(
    (state) => state.signingRequests.navigateBack
  );

  if (navigateBack) {
    navigate("/signing-request");
  }

  const { isTableDataFetched, error, selectedReqData } = useSelector((state) =>
    selectRequestDetailByReqID(state, requestID)
  );

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(
        getSigningRequestsTableDataService({
          isUserProjectManager: true,
        })
      );
    }

    if (!selectedReqData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestID, isTableDataFetched, error, selectedReqData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedReqData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <SigningRequestDetailTable
        requestData={selectedReqData}
        dispatch={dispatch}
      />
      <SigningRequestDetailModal requestDetail={selectedReqData} />
    </Box>
  );
}

export default function ErrorComponentWrappedSigningRequestDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <SigningRequestDetail dispatch={dispatch} />
    </ErrorBoundary>
  );
}
