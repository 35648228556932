import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import {
  resetUserManagementState,
  selectUserDetailsByUserID,
} from "../../../features/user/userSlice";
import getUserManagementTableDataService from "../../../services/user/getUserManagementTableDataService";
import UserDetailTable from "../../../features/user/UserDetailTable";
import UserManagementDetailModal from "../../../features/user/UserManagementDetailModal";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        pt: 3,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 380px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function UserManagementDetail() {
  const { userID } = useParams();

  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateBack = useSelector((state) => state.user.navigateBack);

  if (navigateBack) {
    navigate("/system-setup/user/management");
  }

  const { isUserDataFetched, error, selectedUserData } = useSelector((state) =>
    selectUserDetailsByUserID(state, userID)
  );

  useEffect(() => {
    if (!isUserDataFetched && !error) {
      dispatch(getUserManagementTableDataService());
    }

    if (!selectedUserData && isUserDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, isUserDataFetched, error, selectedUserData]);

  if (error) {
    showBoundary(error);
  }

  if (!isUserDataFetched || !selectedUserData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1, pt: 3 }}>
      <UserDetailTable dispatch={dispatch} userDetail={selectedUserData} />
      <UserManagementDetailModal userDetail={selectedUserData} />
    </Box>
  );
}

export default function ErrorComponentWrappedUserManagementDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetUserManagementState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetUserManagementState())}
    >
      <UserManagementDetail dispatch={dispatch} />
    </ErrorBoundary>
  );
}
