import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getAllMemberService = createAsyncThunk(
  "applicationManagement/getAllMemberService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.ApplicationManagement.GetAllMembers,
        abortController && {
          signal: abortController.signal,
        }
      );

      let activeMembers = request.data?.filter(
        (member) => member.is_active === true
      );

      let response =
        activeMembers?.map((member) => ({
          id: member.id,
          name: member.username,
          label: member.username,
        })) ?? [];

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getAllMemberService;
