import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { UserRoles } from "../../utils/enums";

const getRolePermissionService = createAsyncThunk(
  "user/getRolePermissionService",
  async (serviceConfig) => {
    try {
      let abortController = serviceConfig?.abortController;
      let role = serviceConfig.role;

      let response = await Api.get(
        URL.User.GetPermissions + role.id + "/",
        abortController && {
          signal: abortController.signal,
        }
      );

      let allPermissions = response.data ?? [];

      let roleSpecificPermissions = [];

      if (role === UserRoles.ProjectManager) {
        allPermissions?.forEach((permission) => {
          if (permission.pid >= 10 && permission.pid <= 16) {
            roleSpecificPermissions.push({
              id: permission.pid,
              label: permission.pname,
              status: permission.status,
            });
          }
        });
      } else if (role === UserRoles.SecurityOfficer) {
        allPermissions?.forEach((permission) => {
          if (permission.pid >= 20 && permission.pid <= 27) {
            roleSpecificPermissions.push({
              id: permission.pid,
              label: permission.pname,
              status: permission.status,
            });
          }
        });
      } else if (role === UserRoles.Auditor) {
        allPermissions?.forEach((permission) => {
          if (permission.pid >= 30 && permission.pid <= 34) {
            roleSpecificPermissions.push({
              id: permission.pid,
              label: permission.pname,
              status: permission.status,
            });
          }
        });
      } else if (role === UserRoles.Developer) {
        allPermissions?.forEach((permission) => {
          if (permission.pid >= 51 && permission.pid <= 52) {
            roleSpecificPermissions.push({
              id: permission.pid,
              label: permission.pname,
              status: permission.status,
            });
          }
        });
      } else {
        roleSpecificPermissions =
          allPermissions?.map((permission) => ({
            id: permission.pid,
            label: permission.pname,
            status: permission.status,
          })) ?? [];
      }

      return roleSpecificPermissions;
    } catch (error) {
      throw error;
    }
  }
);

export default getRolePermissionService;
