import { Paper, Stack, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFoundComponent() {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={0}
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
      }}
    >
      <Stack
        direction="column"
        sx={{ textAlign: "center", width: "fit-content" }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#CECCCC",
            fontSize: "8rem",
            fontWeight: 700,
            lineHeight: 1,
          }}
        >
          {404}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#CECCCC",
            fontSize: "1.75rem",
            fontWeight: 800,
            width: 1,
            maxWidth: "800px",
            mb: 2.5,
            wordBreak: "break-word",
          }}
        >
          {"Page not found: The page you are looking for doesn't exist"}
        </Typography>
        <Button
          size="large"
          variant="contained"
          onClick={() => navigate("/")}
          sx={{
            borderRadius: "5px",
            fontSize: "1.25rem",
            fontWeight: 700,
            px: 5,
            margin: "0 auto",
          }}
        >
          Go To Dashboard
        </Button>
      </Stack>
    </Paper>
  );
}
