import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getQuickInsightService = createAsyncThunk(
  "dashboard/getQuickInsightService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      const response = await Api.get(
        URL.Dashboard.QuickInsight,
        abortController && {
          signal: abortController.signal,
        }
      );

      if (response.data?.length <= 0) {
        return {
          approvals: 0,
          totalRequests: 0,
          trackMyRequests: 0,
        };
      }

      let insightData = {
        approvals: response.data[0]?.approval ?? 0,
        totalRequests: response.data[0]?.total_request ?? 0,
        trackMyRequests: response.data[0]?.track_my_request ?? 0,
      };

      return insightData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      // let { rejectWithValue } = serviceConfig;

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getQuickInsightService;
