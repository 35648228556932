import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getAllEnvironmentService = createAsyncThunk(
  "applicationManagement/getAllEnvironmentService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.ApplicationManagement.GetAllEnvironment,
        abortController && {
          signal: abortController.signal,
        }
      );

      let response =
        request.data?.map((data, index) => ({
          id: data?.id ?? index,
          label: data?.Enviornment_Name ?? null,
        })) || [];

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getAllEnvironmentService;
