import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import getKeysAndCertsTableData from "./getKeysAndCertsTableData";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels, Labels } from "../../utils/constants";

export default async function updateKeyAndCertStatusService({
  currentStatus,
  keyID,
  certificateName,
}) {
  try {
    store.dispatch(showLoader());

    let keyReqBody = {
      id: keyID,
      Is_deleted: currentStatus === Labels.ACTIVE ? true : false,
    };

    certificateName = certificateName + ".crt";

    let certificateReqBody = new FormData();

    certificateReqBody.append("certificate_name", certificateName);

    const keyRequest = Api.patch(
      URL.KeysAndCertificates.updateKeyAndCertStatus + keyID + "/",
      keyReqBody
    );

    const certificateRequest = Api.post(
      currentStatus === Labels.ACTIVE
        ? URL.KeysAndCertificates.UpdateCertificateStatusToInActive
        : URL.KeysAndCertificates.UpdateCertificateStatusToActive,
      certificateReqBody
    );

    const [_, certificateUpdateResponse] = await Promise.all([
      keyRequest,
      certificateRequest,
    ]);

    let response = certificateUpdateResponse.data;

    if (response.Result !== "Success") {
      throw new Error("Status update failed!");
    }

    store.dispatch(getKeysAndCertsTableData());

    if (currentStatus === Labels.ACTIVE) {
      Notification.success(NotificationLabels.KEY_DEACTIVATED_SUCCESSFULLY);
    } else {
      Notification.success(NotificationLabels.KEY_ACTIVATED_SUCCESSFULLY);
    }
  } catch (error) {
    if (currentStatus === Labels.ACTIVE) {
      Notification.error(NotificationLabels.KEY_DEACTIVATED_UNSUCCESSFULLY);
    } else {
      Notification.error(NotificationLabels.KEY_ACTIVATED_UNSUCCESSFULLY);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
