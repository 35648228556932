import { useDispatch, useSelector } from "react-redux";
import formsConfig from "./formsConfig";
import { useEffect } from "react";
import { useFormik } from "formik";
import { FormSize, FormType } from "../../utils/constants";
import Dialog from "@mui/material/Dialog";
import FormHeader from "./components/FormHeader";
import IconButton from "@mui/material/IconButton";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Divider from "@mui/material/Divider";
import FormFooter from "./components/FormFooter";
import FormBody from "./components/FormBody";
import { DialogContent, Typography, CircularProgress } from "@mui/material";
import { closeForm } from "./formSlice";

export default function FormWrapper() {
  const dispatch = useDispatch();

  const {
    formType,
    serviceData,
    isFetchingInitialValue: fetchInitialValue,
  } = useSelector((state) => state.form);

  const formSetupData = formsConfig[formType];

  useEffect(() => {
    if (fetchInitialValue) {
      formSetupData.initialValueFetchingService(serviceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  function handleFormClose() {
    dispatch(closeForm());
  }

  function handleFormSubmit(formValue, initialValue) {
    formSetupData.formSubmisionService(formValue, initialValue, serviceData);
  }

  if (formType === FormType.NONE) {
    return null;
  }

  if (fetchInitialValue) {
    return (
      <Dialog
        fullWidth={true}
        open={formType !== FormType.NONE && fetchInitialValue}
        maxWidth={FormSize.SMALL}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "5px",
            backgroundColor: "tertiary.main",
          },
        }}
      >
        <FormHeader title={formSetupData.title} />

        <Divider />

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              color: "primary.main",
              fontSize: "1.2rem",
            }}
          >
            Setting up the form please wait!
          </Typography>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Form
      handleFormClose={handleFormClose}
      handleFormSubmit={handleFormSubmit}
      formSetupData={formSetupData}
    />
  );
}

function Form({ handleFormClose, handleFormSubmit, formSetupData }) {
  const initialValue = useSelector((state) => state.form.initialValue);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: formSetupData?.validationSchema || null,
    onSubmit: (values) => {
      const cleanedValues = {};

      Object.keys(values).forEach((key) => {
        if (typeof values[key] === "string") {
          cleanedValues[key] = values[key].trim();
        } else {
          cleanedValues[key] = values[key];
        }
      });
      handleFormSubmit(cleanedValues, initialValue);
    },
  });

  useEffect(() => {
    return () => {
      formik.setValues(initialValue);
      formik.setErrors({});
      formik.setTouched({});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <Dialog
      disableEnforceFocus
      fullWidth={true}
      open={true}
      maxWidth={formSetupData.width}
      onClose={() => handleFormClose()}
      sx={{
        ".MuiDialog-container > .MuiPaper-root": {
          width: 1,
          height: "fit-content",
          boxShadow: "3px 0px 13px 2px rgba(0, 0, 0, 0.05)",
          overflow: "hidden",
          borderRadius: "5px",
          backgroundColor: "tertiary.main",
        },
      }}
      component="form"
      autoComplete="off"
    >
      <FormHeader
        title={formSetupData.title}
        CustomHeader={formSetupData?.HeaderComponent ?? null}
        dynamicTitleCreator={formSetupData?.dynamicTitleCreator ?? null}
      />

      <FormCloseIcon handleFormClose={handleFormClose} />

      <Divider />

      <FormBody formik={formik} formSetupData={formSetupData} />

      <Divider />

      <FormFooter
        cancelButtonLabel={formSetupData.buttonLabelForCancel}
        submitButtonLabel={formSetupData.buttonLabelForSubmit}
        CustomFooter={formSetupData?.FooterComponent ?? null}
        handleFormClose={handleFormClose}
        handleSubmit={formik.handleSubmit}
      />
    </Dialog>
  );
}

function FormCloseIcon({ handleFormClose }) {
  return (
    <IconButton
      onClick={() => handleFormClose()}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <HighlightOffOutlinedIcon
        fontSize="small"
        color="primary"
        sx={{
          opacity: 0.8,
          "&:hover": {
            opacity: 1,
          },
        }}
      />
    </IconButton>
  );
}
