import { store } from "../lib/redux/store";

export default function checkUserPermission(permissionID) {
  let userPermissions = store.getState().auth.userPermissions;

  if (userPermissions[permissionID]) {
    return userPermissions[permissionID].status;
  }

  return false;
}
