import { closeForm } from "../../features/forms/formSlice";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import { NotificationLabels } from "../../utils/constants";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";
import getAllEnvironmentService from "./getAllEnvironmentService";

export default async function addNewEnvironmentService(formData) {
  try {
    store.dispatch(showLoader());

    let currentEnvironments =
      store.getState().applicationManagement.formState.environments;

    let doesEnvironmentAlreadyExist = currentEnvironments?.find(
      (env) =>
        env.label.trim().toLowerCase() ===
        formData.environmentName.trim().toLowerCase()
    );

    if (doesEnvironmentAlreadyExist) {
      Notification.error(NotificationLabels.ENVIRONMENT_ALREADY_EXIST);
      return;
    }

    let userID = store.getState().auth.userID;

    let reqBody = {
      Enviornment_Name: formData.environmentName,
      status: "Activate",
      created_user: userID,
      Is_deleted: false,
    };

    await Api.post(URL.ApplicationManagement.AddEnvironment, reqBody);

    Notification.success(NotificationLabels.ENVIRONMENT_CREATED_SUCCESSFULLY);

    store.dispatch(closeForm());

    store.dispatch(getAllEnvironmentService());
  } catch (error) {
    Notification.error(NotificationLabels.ENVIRONMENT_CREATION_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
