import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import SearchApplication from "../../features/applicationManagement/searchApplication";
import ApplicationTable from "../../features/applicationManagement/ApplicationTable";
import { resetApplicationTableDataState } from "../../features/applicationManagement/applicationManagementSlice";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";

function Header() {
  const navigate = useNavigate();

  let isAllowedToAddApplication = checkUserPermission(
    Permissions.ManageApplication
  );

  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <SearchApplication />

      {isAllowedToAddApplication && (
        <Button
          variant="contained"
          size="large"
          sx={{ borderRadius: "5px" }}
          onClick={() => {
            navigate("new");
          }}
        >
          Add New Application
        </Button>
      )}
    </Stack>
  );
}

export default function ApplicationManagementIndex() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetApplicationTableDataState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetApplicationTableDataState())}
    >
      <Box sx={{ width: 1, height: 1 }}>
        <Header />
        <ApplicationTable />
      </Box>
    </ErrorBoundary>
  );
}
