import axios from "axios";
import { BackendSettings } from "../../utils/constants";
import { store } from "../redux/store";
import Notification from "../../utils/notificationConfiguration";
import { initiateLogout } from "../../features/auth/authSlice";

const Api = axios.create({
  baseURL: BackendSettings.BaseURL,
});

Api.interceptors.request.use(
  function (config) {
    let token = store.getState().auth.userToken ?? null;
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 403) {
      Notification.info(
        "Authentication Error: Please login again. You will be automatically logged out in 5 second."
      );

      setTimeout(() => {
        store.dispatch(initiateLogout());
      }, 5000);
    }
    if (error.response && error.response.status === 440) {
      Notification.info(
        "Permission Error: Your permissions have been updated. The UI will reload in 5 seconds"
      );

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
    return Promise.reject(error);
  }
);

export default Api;
