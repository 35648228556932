import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function TriggerSetupFormFooter() {
  const { email } = useSelector((state) => state.form.customSetupData);

  return (
    <Box>
      <Typography variant="h6" fontSize="1rem" color="primary">
        {email}
      </Typography>
      <Typography variant="subtitle1" color="text.placeholder">
        Are you sure you want to trigger this email?
      </Typography>
    </Box>
  );
}
