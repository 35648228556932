import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Labels } from "../../utils/constants";
import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { Typography } from "@mui/material";

export default function ApplicationDetailTable({
  applicationDetail,
  dispatch,
  navigate,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleEditApplicationClicked() {
    navigate(`/application-management/${applicationDetail.applicationID}`);
    setAnchorEl(null);
  }

  function handleEditStatusClicked() {
    if (applicationDetail?.status === Labels.ACTIVE) {
      dispatch(
        initializeModal({
          modalType: ModalType.DEACTIVATE_APPLICATION,
          modalData: {
            applicationID: applicationDetail.applicationID,
            currentStatus: applicationDetail.status,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.ACTIVATE_APPLICATION,
          modalData: {
            applicationID: applicationDetail.applicationID,
            currentStatus: applicationDetail.status,
          },
        })
      );
    }
    setAnchorEl(null);
  }

  function renderActionCell() {
    if (checkUserPermission(Permissions.ManageApplication)) {
      return (
        <Box
          sx={{
            width: 1,
            height: 1,
          }}
        >
          <IconButton
            onClick={(event) => setAnchorEl(event.target)}
            size="small"
            color="primary"
            aria-label="more actions"
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            sx={{
              "& .MuiPaper-root .MuiList-root": {
                minWidth: "150px",
                py: 0,
                "& .MuiButtonBase-root": {
                  py: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  bgcolor: "tertiary.light",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "tertiary.main",
                  },
                  "& .MuiListItemText-root .MuiTypography-root": {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                },
              },
            }}
            id="action-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {applicationDetail?.status === Labels.ACTIVE && (
              <MenuItem onClick={() => handleEditApplicationClicked()}>
                {Labels.EDIT}
              </MenuItem>
            )}
            <MenuItem onClick={() => handleEditStatusClicked()}>
              {applicationDetail?.status === Labels.ACTIVE
                ? "Deactivate"
                : "Activate"}
            </MenuItem>
          </Menu>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          width: 1,
          height: 1,
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
          -
        </Typography>
      </Box>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow
            className="custom-scroll-bar"
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{Labels.APPLICATION_NAME}</TableCell>
            <TableCell>{Labels.APPLICATION_OWNERS}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.APPLICATION_DESCRIPTION}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{applicationDetail?.applicationName ?? "-"}</TableCell>
            <TableCell>
              {applicationDetail?.applicationOwners[0]?.label ?? "-"}
            </TableCell>
            <TableCell>{renderStatusCell(applicationDetail?.status)}</TableCell>
            <TableCell
              sx={{
                maxWidth: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {applicationDetail?.applicationDescription ?? "-"}
            </TableCell>
            <TableCell>{renderActionCell()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
