import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";

export default async function restoreBackup({ backupName }) {
  try {
    store.dispatch(showLoader());

    await Api.post(URL.Backup.RestoreBackup, {
      file_name: backupName,
    });

    Notification.success(NotificationLabels.BACKUP_RESTORED);
  } catch (error) {
    Notification.error(NotificationLabels.BACKUP_RESTORE_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
