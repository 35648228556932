// React
import { useRef, useEffect, useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Constants
import { Status, Labels } from "../../utils/constants";

// Custom Components
import CustomTable from "../../components/ui/table/CustomTable";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useErrorBoundary } from "react-error-boundary";
import { selectSSLTableData } from "./sslManagementSlice";
import getSSLTableDataService from "../../services/sslManagement/getSSLTableData";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { Link } from "react-router-dom";
import { Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        mt: 3,
        width: 1,
        height: "calc(100% - 70px)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function SSLTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(selectSSLTableData);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getSSLTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} dispatch={dispatch} />;
}

function TableComponent({ tableData, dispatch }) {
  const isUserAllowedToManageSSL = checkUserPermission(
    Permissions.ManageSSLCertificate
  );

  const [actionMenuState, setActionMenuState] = useState({
    anchorEl: null,
    selectedRowData: null,
  });

  const rowData = tableData;

  const columnData = [
    {
      field: "id",
      headerName: Labels.UID,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => renderUIDCell(params.row),
    },
    {
      field: "domain",
      headerName: Labels.DOMAIN,
      flex: 1,
      minWidth: 400,
      valueFormatter: (params) => {
        if (!params.value) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "expiresAt",
      headerName: Labels.EXPIRES_AT,
      flex: 1,
      minWidth: 150,
      filterable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return "-";
        }
        return formatDateToUserTimeZone(params.value);
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      width: 200,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderActionCell(rowData) {
    return (
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {isUserAllowedToManageSSL ? (
          <IconButton
            onClick={(event) =>
              setActionMenuState({
                anchorEl: event.target,
                selectedRowData: rowData,
              })
            }
            size="small"
            color="primary"
          >
            <MoreVertIcon />
          </IconButton>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </Box>
    );
  }

  function ActionMenu() {
    let { selectedRowData, anchorEl } = actionMenuState;

    return (
      <Menu
        sx={{
          "& .MuiPaper-root .MuiList-root": {
            minWidth: "150px",
            py: 0,
            "& .MuiButtonBase-root": {
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "tertiary.light",
              fontSize: "0.9rem",
              fontWeight: 500,
              "&:hover": {
                bgcolor: "tertiary.main",
              },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontSize: "0.9rem",
                fontWeight: 500,
              },
            },
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setActionMenuState({ anchorEl: null, selectedRowData: null });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {selectedRowData?.status !== "Active" && (
          <MenuItem
            onClick={() => {
              dispatch(
                initializeModal({
                  modalType: ModalType.DELETE_SSL_CERTIFICATE,
                  modalData: {
                    certificateID: selectedRowData.id,
                  },
                })
              );
              setActionMenuState({ anchorEl: null, selectedRowData: null });
            }}
          >
            {Labels.DELETE}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            downloadCertificate(selectedRowData);
          }}
        >
          {Labels.DOWNLOAD}
        </MenuItem>
      </Menu>
    );
  }

  function downloadCertificate(certificateDetail) {
    const blob = new Blob([certificateDetail.certificateData], {
      type: "application/x-pem-file",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${certificateDetail.domain}.pem`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  function renderUIDCell(data) {
    if (data?.id) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${data.id}`}
          >
            {data.id}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return (
    <>
      <CustomTable
        showTableOptions={true}
        rowData={rowData}
        columnData={columnData}
        sortingField="status"
        {...(!isUserAllowedToManageSSL && { tableHeight: "100%" })}
      />
      <ActionMenu />
    </>
  );
}
