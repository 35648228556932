import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { store } from "../../lib/redux/store";
import { setTableDataType } from "../../features/reports/ReportsSlice";

function requestURLConstructor() {
  let url = "";

  let {
    dateRange: { fromDate, toDate },
    reportType,
    category,
    applications,
    users,
  } = store.getState().reports;

  switch (reportType) {
    case "Signing Request": {
      url = `${URL.Reports.GetSigningRequestReportData}`;
      break;
    }
    case "Approval Request": {
      url = `${URL.Reports.GetApprovalRequestReportData}`;
      break;
    }
    case "Audit Trial": {
      url = `${URL.Reports.GetAuditTrialReportData}`;
      break;
    }
    case "Certificate": {
      url = `${URL.Reports.GetCertificateRequestReportData}`;
      break;
    }
    default: {
      url = `${URL.Reports.GetClientData}`;
    }
  }

  url = `${url}?from_date=${fromDate}&to_date=${toDate}`;

  if (applications.length > 0 && reportType !== "Client") {
    url = `${url}&application_id=${applications.join(", ")}`;
  }

  if (
    reportType !== "Audit Trial" &&
    reportType !== "Client" &&
    users.length > 0
  ) {
    url = `${url}&user_id=${users.join(", ")}`;
  }

  if (reportType === "Audit Trial" && category) {
    url = `${url}&category=${category}`;
  }

  return url;
}

function formatResponse(reportType, response) {
  let formattedResponse = null;

  switch (reportType) {
    case "Signing Request": {
      formattedResponse =
        response?.map((data, index) => ({
          id: data?.job_id >= 0 ? data.job_id : index,
          jobID: data?.job_id >= 0 ? data.job_id : null,
          applicationName: data?.application || null,
          submittedBy: data?.requested_by || null,
          submittedOn: data?.timestamp || null,
        })) || [];
      break;
    }
    case "Approval Request": {
      formattedResponse =
        response?.map((data, index) => ({
          id: data?.job_id >= 0 ? data.job_id : index,
          jobID: data?.job_id >= 0 ? data.job_id : null,
          applicationName: data?.application || null,
          approver: data?.approver || null,
          approvedOn: data?.timestamp || null,
        })) || [];
      break;
    }
    case "Audit Trial": {
      formattedResponse =
        response?.map((data, index) => ({
          id: index,
          applicationID: data?.application_id >= 0 ? data.application_id : null,
          category: data?.category || null,
          eventTime: data?.event_time || null,
          message: data?.message || null,
          location: data?.location || null,
          validityPeriod: data?.validity_period || null,
          createdUser: data?.created_user >= 0 ? data.created_user : null,
          scope: data?.scope || null,
          userName: data?.username || null,
          IPAddress: data?.ip || null,
          user: data?.user || null,
          createdDate: data?.created_date || null,
          status: data?.status || null,
          keyAlgorithm: data?.key_algorithm || null,
          level: data?.level || null,
          description: data?.description || null,
          organizationName: data?.organization_name || null,
          organizationUnit: data?.organization_unit || null,
          error: data?.error || null,
        })) || [];
      break;
    }
    case "Certificate": {
      formattedResponse =
        response?.map((data, index) => ({
          id: data?.id >= 0 ? data.id : index,
          certificateName: data?.certificate_name || null,
          createdBy: data?.created_by || null,
          createdUser: data?.created_user >= 0 ? data.created_user : null,
          createdDate: data?.created_date,
          expiryDate: data?.expiry_date,
        })) || [];
      break;
    }
    default: {
      formattedResponse =
        response?.map((data, index) => ({
          id: data?.created_at ?? index,
          clientIP: data?.client_ip ?? null,
          user: data?.user ?? null,
          createdAt: data?.created_at ?? null,
        })) ?? [];
      break;
    }
  }

  return formattedResponse;
}

const getReportsTableDataService = createAsyncThunk(
  "reports/getReportsTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let { reportType } = store.getState().reports;

      const request = await Api.get(
        requestURLConstructor(),
        abortController && {
          signal: abortController.signal,
        }
      );

      store.dispatch(setTableDataType(reportType));

      return formatResponse(reportType, request.data);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getReportsTableDataService;
