import Stack from "@mui/material/Stack";
import ReportsHeader from "../../features/reports/ReportsHeader";
import ReportsTable from "../../features/reports/ReportsTable";
import { useDispatch } from "react-redux";
import { resetReportsState } from "../../features/reports/ReportsSlice";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";

export default function Reports() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetReportsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetReportsState())}
    >
      <Stack
        sx={{
          width: 1,
          height: 1,
        }}
      >
        <ReportsHeader />
        <ReportsTable />
      </Stack>
    </ErrorBoundary>
  );
}
