import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import {
  resetState,
  selectCertificateDataByID,
} from "../../../features/sslCertificateManagement/sslManagementSlice";
import getSSLTableDataService from "../../../services/sslManagement/getSSLTableData";
import CertificateDetailTable from "../../../features/sslCertificateManagement/CertificateDetailTable";
import CertificateDetailModal from "../../../features/sslCertificateManagement/CertificateDetailModal";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        pt: 3,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 380px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function SSLDetail({ dispatch }) {
  const { certificateID } = useParams();

  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const { isTableDataFetched, error, selectedCertificateData } = useSelector(
    (state) => selectCertificateDataByID(state, certificateID)
  );

  const navigateBack = useSelector((state) => state.sslManagement.navigateBack);

  if (navigateBack) {
    navigate("/system-setup/ssl-tls-management");
  }

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(getSSLTableDataService());
    }

    if (!selectedCertificateData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateID, isTableDataFetched, error, selectedCertificateData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedCertificateData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1, pt: 3 }}>
      <CertificateDetailTable certificateDetail={selectedCertificateData} />
      <CertificateDetailModal certificateDetail={selectedCertificateData} />
    </Box>
  );
}

export default function ErrorComponentWrappedSSLDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <SSLDetail dispatch={dispatch} />
    </ErrorBoundary>
  );
}
