import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Labels } from "../../utils/constants";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import { SigningRequestStatus } from "../../utils/enums";

export default function SigningRequestDetailModal({ requestDetail }) {
  function renderStatusCell(requestData) {
    let { status, approvalStatus } = requestData;

    let cellText = "";

    switch (status) {
      case SigningRequestStatus.ApprovePending:
        if (approvalStatus) {
          cellText = "In Progress";
        } else {
          cellText = "Pending";
        }
        break;
      case SigningRequestStatus.Approved:
        cellText = "Approved";
        break;
      case SigningRequestStatus.Signed:
        cellText = "Signed";
        break;
      case SigningRequestStatus.Rejected:
        cellText = "Rejected";
        break;
      default:
        cellText = "Pending";
        break;
    }

    function getBGColorForCell(cellText) {
      switch (cellText) {
        case "Pending": {
          return "#fffbb0";
        }
        case "Approved": {
          return "success.main";
        }
        case "Signed": {
          return "";
        }
        case "In Progress": {
          return "#fffbb0";
        }
        case "Rejected": {
          return "error.main";
        }
        default: {
          return "";
        }
      }
    }

    return (
      <Box
        sx={{
          paddingInline: 2,
          paddingBlock: 0.75,
          color: "primary.main",
          borderRadius: "5px",
          backgroundColor: getBGColorForCell(cellText),
          fontWeight: cellText === "Signed" ? 700 : 500,
          width: "fit-content",
        }}
      >
        {cellText}
      </Box>
    );
  }

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.REQUEST_ID,
        value: requestDetail?.requestID ?? "-",
      },
      {
        label: Labels.ENVIRONMENT_NAME,
        value: requestDetail?.environmentName ?? "-",
      },
      {
        label: Labels.APPLICATION_NAME,
        value: requestDetail?.applicationName ?? "-",
      },
      {
        label: Labels.SUBMITTED_ON,
        value: requestDetail
          ? formatDateToUserTimeZone(requestDetail.submittedOn)
          : "-",
      },
      {
        label: Labels.STATUS,
        component: renderStatusCell(requestDetail),
      },
      {
        label: Labels.COMMENT,
        value: requestDetail?.comment || "-",
      },
    ];

    return arr;
  }

  return (
    <Box
      className="custom-scroll-bar"
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 280px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            Request Detail
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>
              {item.component ? (
                item.component
              ) : (
                <Typography variant="subtitle1">{item.value}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
