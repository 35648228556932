import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Labels } from "../../utils/constants";
import { EmailConfigType } from "../../utils/enums";

export default function ConfigDetailModal({ configDetail }) {
  function getConfigTypeName(typeID) {
    let configName = "";

    switch (typeID) {
      case EmailConfigType.SMTP:
        configName = "SMTP";
        break;

      default:
        configName = "-";
        break;
    }

    return configName;
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.TYPE,
        value: getConfigTypeName(configDetail?.configType),
      },
      {
        label: Labels.HOST,
        value: configDetail?.host ?? "-",
      },
      { label: Labels.PORT, value: configDetail?.port ?? "-" },
      {
        label: Labels.USERNAME,
        value: configDetail?.userName ?? "-",
      },
      {
        label: Labels.STATUS,
        component: renderStatusCell(configDetail.status),
      },
    ];

    return arr;
  }

  return (
    <Box
      className="custom-scroll-bar"
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        // height: "calc(100% - 280px)",
        height: "calc(100% - 400px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            More Information
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              {item.component ? (
                item.component
              ) : (
                <Typography variant="subtitle1">{item.value}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
