import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { navigateBack } from "../../features/applicationManagement/applicationManagementSlice";

function createSigningUserAdditionReq(environmentID, user) {
  let requestBody = {
    env_cert_mapping_id: environmentID,
    searchedUserName: user.email,
    email: user.email,
  };

  return function () {
    return Api.post(
      URL.ApplicationManagement.ManageSigningUserForPolicy,
      requestBody
    );
  };
}

function createSigningUserRemovalReq(user) {
  return function () {
    return Api.delete(
      URL.ApplicationManagement.ManageSigningUserForPolicy + user.userID + "/"
    );
  };
}

function createUpdateMFAReq(environmentID, needMFA) {
  let requestBody = {
    id: environmentID,
    need_mfa: needMFA,
  };

  return function () {
    return Api.patch(
      URL.ApplicationManagement.ManageMFA + environmentID + "/",
      requestBody
    );
  };
}

function createApproverRemovalReq(approverID) {
  return function () {
    return Api.delete(
      URL.ApplicationManagement.ManageApprover + approverID + "/"
    );
  };
}

function createNonQuorumApproverAdditionReq(environmentID, approver, level) {
  let requestBody = {
    env_cert_mapping_id: environmentID,
    ApproverTypeID: parseInt(approver.typeID),
    quorumUsers: [],
    level: level,
  };

  return function () {
    return Api.post(URL.ApplicationManagement.ManageApprover, requestBody);
  };
}

function createQuorumApproverAdditionReq(environmentID, approver, level) {
  let requestBody = {
    env_cert_mapping_id: environmentID,
    ApproverTypeID: parseInt(approver.typeID),
    min_count: approver.minCount,
    quorumUsers:
      approver.quorumUsers?.map((user) => ({
        email: user.email,
        searchedUserName: user.searchedUserName,
      })) ?? [],
    level: level,
  };

  return function () {
    Api.post(URL.ApplicationManagement.ManageApprover, requestBody);
  };
}

export default async function manageApplicationPolicyService(
  initialPolicies,
  finalPolicies,
  deletedApprovers
) {
  try {
    store.dispatch(showLoader());

    let requestArr = [];
    let deleteApproverReqArr = [];

    finalPolicies.forEach((policy, index) => {
      let environmentID = policy.environmentID;

      // For Signing Users
      // ---------------------------------------------------
      let prevSigningUserList = initialPolicies[index].signingUsers;
      let newSigningUserList = policy.signingUsers;

      // For deleting signing Users
      prevSigningUserList
        .filter(
          (prevUser) =>
            !newSigningUserList.some(
              (newUser) => newUser.label === prevUser.label
            )
        )
        ?.forEach((user) => {
          requestArr.push(createSigningUserRemovalReq(user));
        });

      // For Adding Signing User
      newSigningUserList
        ?.filter(
          (user) =>
            user?.isNewlyAdded !== false &&
            !prevSigningUserList.some(
              (prevUser) => prevUser.label === user.label
            )
        )
        ?.forEach((user) => {
          requestArr.push(createSigningUserAdditionReq(environmentID, user));
        });
      // ---------------------------------------------------

      // For MFA
      // ---------------------------------------------------
      requestArr.push(createUpdateMFAReq(environmentID, policy.needMFA));
      // ---------------------------------------------------

      // For Approvals
      // ---------------------------------------------------

      // For Deleting Approvers
      let deletedApproverIDS = deletedApprovers[environmentID];

      deletedApproverIDS?.forEach((approverID) => {
        deleteApproverReqArr.push(createApproverRemovalReq(approverID));
      });

      // For Adding Approver
      policy.approverDetails?.forEach((approver, index) => {
        if (approver.isNewlyAdded === true) {
          if (approver.type === "Quorum") {
            requestArr.push(
              createQuorumApproverAdditionReq(
                environmentID,
                approver,
                index + 1
              )
            );
          } else {
            requestArr.push(
              createNonQuorumApproverAdditionReq(
                environmentID,
                approver,
                index + 1
              )
            );
          }
        }
      });
      // ---------------------------------------------------
    });

    // async function executeRequestArr() {
    //   try {
    //     for (let request of requestArr) {
    //       try {
    //         await request();
    //       } catch (error) {
    //         console.error("Request failed:", error);
    //         throw error;
    //       }
    //     }
    //   } catch (error) {
    //     throw error;
    //   } finally {
    //     store.dispatch(closeLoader());
    //     Notification.success(NotificationLabels.APPLICATION_POLICY_UPDATED);
    //   }
    // }

    async function executeRequestArr() {
      try {
        await Promise.all(requestArr.map((request) => request()));
      } catch (error) {
        throw error;
      } finally {
        store.dispatch(closeLoader());
        store.dispatch(navigateBack());
        Notification.success(NotificationLabels.APPLICATION_POLICY_UPDATED);
      }
    }

    async function executeDeleteApproverReqArr() {
      try {
        for (let request of deleteApproverReqArr) {
          try {
            await request();
          } catch (error) {
            throw error;
          }
        }
      } catch (error) {
        throw error;
      } finally {
        await executeRequestArr();
      }
    }

    executeDeleteApproverReqArr();
  } catch (error) {
    Notification.error(NotificationLabels.FAIL_TO_UPDATE_APPLICATION_POLICY);
  }
}
