import moment from "moment-timezone";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box } from "@mui/material";

export function fromatDateForUI(date, timezone) {
  if (!date) {
    return "-";
  }
  return moment.utc(date).tz(timezone).format("MMM DD, yyyy h:mm:ss a");
}

export function renderStatusCell(value) {
  if (value) {
    return (
      <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
        {value !== "ERROR" ? (
          <CheckBoxIcon fontSize="medium" sx={{ fill: "#67A749" }} />
        ) : (
          <DisabledByDefaultIcon fontSize="medium" sx={{ fill: "#FF857E" }} />
        )}
      </Box>
    );
  } else {
    return <span>-</span>;
  }
}
