import { createSlice } from "@reduxjs/toolkit";
import { ModalType } from "../../utils/constants";

const initialState = {
  modalType: ModalType.NONE,
  modalData: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    initializeModal: (state, action) => {
      state.modalType = action.payload.modalType;
      state.modalData = action.payload?.modalData || null;
    },
    closeModal: (state) => {
      state.modalType = ModalType.NONE;
      state.modalData = null;
    },
  },
});

export const { initializeModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
