import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";

export default async function getReportsSysLogService() {
  try {
    const request = await Api.get(URL.Reports.GetSysLog);

    let response = request.data;

    let fileName = "AuditTrialReport.txt";
    let file = new Blob([response], {
      type: "text/plain;charset=utf-8",
    });
    var sysLogFile = window.URL.createObjectURL(file);
    let tempLink = document.createElement("a");
    tempLink.href = sysLogFile;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  } catch (error) {
    Notification.error(NotificationLabels.FAILED_TO_GET_SYS_LOG);
  }
}
