// React
import { useEffect } from "react";

// MUI
import Box from "@mui/material/Box";

// Redux
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Utils
import { resetSelectedLogData } from "../../features/logs/LogsSlice";
import { useNavigate } from "react-router-dom";
import LogsDetailTable from "../../features/logs/LogsDetailTable";
import LogsDetailModal from "../../features/logs/LogsDetailModal";
import { Skeleton } from "@mui/material";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 280px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

const LogsDetail = function () {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const logData = useSelector((state) => state.logs.selectedLogData);

  useEffect(() => {
    if (!logData) {
      navigate("/logs");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logData]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedLogData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!logData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <LogsDetailTable logDetail={logData} />
      <LogsDetailModal detailObj={logData.Details} />
    </Box>
  );
};

export default LogsDetail;
