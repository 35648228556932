// export default function SigningToolsSideBar({ fill, width }) {
//   return (
//     <svg viewBox="0 0 26 27" fill={fill} width={width}>
//       <path d="M19.5929 13.5L25.4429 7.65C26.1857 6.90714 26.1857 5.79286 25.4429 5.05L21.5429 1.15C20.8 0.407143 19.6857 0.407143 18.9429 1.15L13 6.90714L7.15 1.05714C6.40714 0.314286 5.29286 0.314286 4.55 1.05714L0.557143 4.95714C-0.185714 5.7 -0.185714 6.81429 0.557143 7.55714L6.40714 13.5L9.68575e-08 19.9071V26.5H6.59286L13 20.0929L18.85 25.9429C19.5929 26.6857 20.7071 26.6857 21.45 25.9429L25.35 22.0429C26.0929 21.3 26.0929 20.1857 25.35 19.4429L19.5929 13.5ZM20.2429 2.35714L24.1429 6.25714L18.2929 12.1071L14.3929 8.20714L20.2429 2.35714ZM5.75714 24.6429H1.85714V20.7429L7.70714 14.8929L11.6071 18.7929L5.75714 24.6429ZM20.2429 24.6429L1.85714 6.25714L5.75714 2.35714L9.00714 5.60714L7.05714 7.55714L8.35714 8.85714L10.3071 6.90714L14.2071 10.8071L12.2571 12.7571L13.5571 14.0571L15.5071 12.1071L19.4071 16.0071L17.6429 18.05L18.9429 19.35L20.8929 17.4L24.1429 20.65L20.2429 24.6429Z" />
//     </svg>
//   );
// }

export default function SigningToolsSideBar({ fill, width }) {
  return (
    <svg width={width} fill={fill} viewBox="0 -25 424 424">
      <path d="m167.29 272.13c-1.6016 0-3.2148-0.55078-4.5312-1.6719l-45.297-38.57c-1.5625-1.332-2.4609-3.2773-2.4609-5.332 0-2.0508 0.90234-3.9961 2.4609-5.3281l45.297-38.57c2.9414-2.5078 7.3594-2.1523 9.8672 0.78906 2.5039 2.9453 2.1523 7.3633-0.79297 9.8672l-39.035 33.242 39.035 33.242c2.9453 2.5078 3.2969 6.9258 0.79297 9.8672-1.3867 1.6289-3.3555 2.4648-5.3359 2.4648z" />
      <path d="m256.71 272.13c-1.9805 0-3.9492-0.83594-5.332-2.4648-2.5078-2.9414-2.1523-7.3594 0.78906-9.8672l39.035-33.242-39.035-33.242c-2.9414-2.5039-3.2969-6.9219-0.78906-9.8672 2.5039-2.9414 6.9258-3.2969 9.8633-0.78906l45.297 38.57c1.5625 1.332 2.4609 3.2773 2.4609 5.3281 0 2.0547-0.89844 4-2.4609 5.332l-45.297 38.57c-1.3164 1.1211-2.9297 1.6719-4.5312 1.6719z" />
      <path d="m195.96 301.06c-0.48047 0-0.96484-0.050781-1.4531-0.15234-3.7812-0.79688-6.2031-4.5117-5.4062-8.293l28.23-134.04c0.79687-3.7812 4.5078-6.2031 8.293-5.4062 3.7812 0.79688 6.2031 4.5078 5.4062 8.293l-28.23 134.04c-0.69531 3.2969-3.6016 5.5586-6.8398 5.5586z" />
      <path d="m377 374.09h-330c-25.914 0-47-21.082-47-47v-280.09c0-25.914 21.086-47 47-47h330c25.914 0 47 21.086 47 47v280.09c0 25.918-21.086 47-47 47zm-330-360.09c-18.195 0-33 14.805-33 33v280.09c0 18.195 14.805 33 33 33h330c18.195 0 33-14.805 33-33v-280.09c0-18.195-14.805-33-33-33z" />
      <path d="m417 112.09h-410c-3.8672 0-7-3.1328-7-7 0-3.8633 3.1328-7 7-7h410c3.8672 0 7 3.1367 7 7 0 3.8672-3.1328 7-7 7z" />
      <path d="m119.6 78.594c-12.211 0-22.152-9.9414-22.152-22.152 0-12.215 9.9414-22.152 22.152-22.152 12.215 0 22.152 9.9375 22.152 22.152 0 12.211-9.9375 22.152-22.152 22.152zm0-30.305c-4.4922 0-8.1523 3.6562-8.1523 8.1523s3.6602 8.1523 8.1523 8.1523c4.4961 0 8.1523-3.6562 8.1523-8.1523s-3.6562-8.1523-8.1523-8.1523z" />
      <path d="m51.539 78.379c-12.215 0-22.152-9.9375-22.152-22.152 0-12.215 9.9375-22.152 22.152-22.152 12.215 0 22.152 9.9375 22.152 22.152 0 12.215-9.9375 22.152-22.152 22.152zm0-30.305c-4.4922 0-8.1523 3.6602-8.1523 8.1523 0 4.4961 3.6602 8.1523 8.1523 8.1523 4.4961 0 8.1523-3.6562 8.1523-8.1523 0-4.4922-3.6562-8.1523-8.1523-8.1523z" />
      <path d="m187.66 78.805c-12.211 0-22.148-9.9375-22.148-22.152 0-12.211 9.9375-22.148 22.148-22.148 12.215 0 22.152 9.9375 22.152 22.148 0 12.215-9.9375 22.152-22.152 22.152zm0-30.305c-4.4922 0-8.1484 3.6562-8.1484 8.1523s3.6562 8.1523 8.1484 8.1523c4.4961 0 8.1523-3.6562 8.1523-8.1523s-3.6562-8.1523-8.1523-8.1523z" />
    </svg>
  );
}
