import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { Labels } from "../../utils/constants";

const getEmailTemplateTableDataService = createAsyncThunk(
  "email/getEmailTemplateTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let response = await Api.get(
        URL.Email.GetTemplateTableData,
        abortController && {
          signal: abortController.signal,
        }
      );

      let result =
        response.data?.map((template, index) => ({
          id: template?.id >= 0 ? template.id : index,
          templateID: template?.id >= 0 ? template.id : null,
          templateSubject: template?.subject || null,
          status:
            template.Is_deleted === false ? Labels.ACTIVE : Labels.IN_ACTIVE,
          templateContent: template?.content || null,
          templateType:
            template?.template_type >= 0 ? template.template_type : null,
        })) ?? [];

      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getEmailTemplateTableDataService;
