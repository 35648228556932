import Stack from "@mui/material/Stack";
import OSSelector from "../features/signingTools/OSSelector";
import SearchSigningTool from "../features/signingTools/SearchSigningTool";
import SigningToolsList from "../features/signingTools/SigningToolsList";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetState } from "../features/signingTools/signingToolsSlice";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../components/ui/ErrorComponent";

function Header() {
  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "space-between", alignItems: "end", px: 2 }}
    >
      <OSSelector />
      <SearchSigningTool />
    </Stack>
  );
}

export default function SigningTools() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <Stack
        sx={{
          width: 1,
          height: 1,
          gap: 3,
        }}
      >
        <Header />
        <SigningToolsList />
      </Stack>
    </ErrorBoundary>
  );
}
