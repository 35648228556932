// React
import { useState, useEffect, useRef } from "react";

// MUI
import Box from "@mui/material/Box";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import _debounce from "lodash/debounce";

// Custom Component
import CustomPagination from "./CustomPagination";
import CustomToolBar from "./CustomToolBar";
import NoRowsComponent from "./NoRowsComponent";

// ----#CustomTable----
export default function CustomTable({
  rowData,
  columnData,
  valueToBeUsedAsID = null,
  tableOptionConfig = {
    includeShowMaxRows: true,
    includePDFExport: false,
    includeSYSLogExport: false,
    includeCSVExport: false,
  },
  sortingField = "id",
  sortingType = "asc",
  tableHeight = null,
}) {
  const tableContainerRef = useRef(null);

  const apiRef = useGridApiRef();

  const [showCustomPagination, setShowCustomPagination] = useState(false);

  const [showAllRows, setShowAllRows] = useState(false);

  const [tableResized, triggerTableResizeHanlder] = useState(0);

  useEffect(() => {
    let element = tableContainerRef.current;
    const resizeObserver = new ResizeObserver(
      _debounce(() => {
        triggerTableResizeHanlder((prev) => prev + 1);
      }, 200)
    );

    resizeObserver.observe(element);

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
        resizeObserver.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tableResized > 0) {
      if (tableContainerRef.current) {
        const virtualScrollerElement = tableContainerRef.current.querySelector(
          ".MuiDataGrid-virtualScroller"
        );

        if (virtualScrollerElement) {
          const rect = virtualScrollerElement.getBoundingClientRect();
          const width = rect.width;
          const height = rect.height;

          handleTableResize(width, height, showAllRows);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableResized]);

  useEffect(() => {
    if (showAllRows) {
      apiRef.current.setPageSize(100);
    } else {
      triggerTableResizeHanlder((prev) => prev + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllRows]);

  const handleTableResize = (width, height, showAllRows) => {
    // For Pagination
    if (width >= 700) {
      setShowCustomPagination(true);
    } else {
      setShowCustomPagination(false);
    }

    // For Table Rows
    if (height >= 50 && !showAllRows) {
      apiRef.current.setPageSize(Math.floor(height / 52));
    } else if (!showAllRows) {
      apiRef.current.setPageSize(5);
    }
  };

  function handlePDFExport() {
    apiRef.current.exportDataAsPrint({
      copyStyles: true,
      allColumns: true,
      hideFooter: true,
    });
  }

  function handleCSVExport() {
    apiRef.current.exportDataAsCsv({
      fileName: tableOptionConfig.fileName,
      allColumns: true,
    });
  }

  function handleSysLogExport() {
    tableOptionConfig.sysLogExportService();
  }

  // Mixins
  const tableMixin = {
    "--unstable_DataGrid-radius": "0px",
    border: "none",
    overflow: "visible",
    boxSizing: "border-box",
    height: "100%",

    ".MuiDataGrid-main": {
      gap: "20px",
      borderRadius: "5px",
      overflow: "hidden",
      height: "100%",
    },

    ".MuiDataGrid-columnHeaders": {
      backgroundColor: "background.paper",
      borderRadius: "5px",
      boxShadow: "3px 0px 13px 2px rgba(0, 0, 0, 0.1)",

      ".MuiDataGrid-columnHeader": {
        outline: "none !important",
        "&:nth-child(1)": {
          padding: "0px 10px 0px 30px",
        },
        "&:last-child": {
          padding: "0px 30px 0px 10px",
        },
      },

      ".MuiDataGrid-columnHeaderTitle": {
        fontSize: "0.9rem",
        fontWeight: 700,
        color: "primary.main",
      },
    },

    ".MuiDataGrid-virtualScroller": {
      backgroundColor: "background.paper",
      borderRadius: "5px",
      boxShadow: "0px -1px 15px -1px rgba(0,0,0,0.1)",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: "100%",
      overflowY: "auto",
      position: "relative",

      ".MuiDataGrid-cell:focus": {
        outline: "none",
      },
      ".MuiDataGrid-cell--withRenderer": {
        outline: "none",
      },

      ".MuiDataGrid-row .MuiDataGrid-cell": {
        "&:nth-child(1)": {
          padding: "0px 10px 0px 30px",
        },
        "&:last-child": {
          padding: "0px 30px 0px 10px",
        },
      },

      ".MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered": {
        background: "none",
      },
    },

    ".MuiDataGrid-footerContainer": {
      border: 0,
      backgroundColor: "background.paper",
      position: "relative",
      zIndex: "1",
      paddingInline: "10px",
      borderRadius: "5px",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      boxShadow: "0px 8px 15px -1px rgba(0,0,0,0.1)",
      borderTop: "1px solid #eeeded",
    },

    ".MuiDataGrid-overlayWrapper ~ .MuiDataGrid-virtualScrollerContent": {
      display: "none",
    },

    ".MuiDataGrid-overlayWrapper ": {
      width: 1,
      height: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    ".MuiDataGrid-overlayWrapperInner": {
      width: "fit-content !important",
      height: "fit-content !important",
    },
  };

  return (
    <Box
      ref={tableContainerRef}
      sx={{
        width: 1,
        pt: 3,
        pb: 2,
        height: tableHeight ? tableHeight : "calc(100% - 70px)",
        // height: "100%",
        position: "relative",
      }}
    >
      <DataGrid
        apiRef={apiRef}
        sx={{
          ...tableMixin,
        }}
        disableColumnSelector
        disableRowSelectionOnClick
        rows={rowData}
        columns={columnData}
        slots={{
          ...(showCustomPagination && {
            pagination: () => <CustomPagination />,
          }),
          noRowsOverlay: NoRowsComponent,
        }}
        getRowId={
          valueToBeUsedAsID ? (row) => row[valueToBeUsedAsID] : undefined
        }
        initialState={{
          sorting: {
            sortModel: [{ field: sortingField, sort: sortingType }],
          },
        }}
      />

      <CustomToolBar
        handlePDFExport={handlePDFExport}
        handleCSVExport={handleCSVExport}
        handleSysLogExport={handleSysLogExport}
        optionConfig={tableOptionConfig}
        toggleShowAllRows={setShowAllRows}
        showAllRows={showAllRows}
      />
    </Box>
  );
}
