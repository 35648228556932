import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { Labels } from "../../utils/constants";
import { store } from "../../lib/redux/store";

const getApplicationEnvironmentsDetailService = createAsyncThunk(
  "applicationManagement/getApplicationEnvironmentsDetail",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;
      let applicationID = serviceConfig.applicationID;

      let response = [];

      const environmentDetailReq = await Api.get(
        URL.ApplicationManagement.EnvironmentDetail + applicationID + "/",
        abortController && {
          signal: abortController.signal,
        }
      );

      let activeEnvironments = environmentDetailReq.data?.filter(
        (env) => env.Is_deleted === false
      );

      let environmentIDs = [];

      let certificateTeamDetails = [];

      activeEnvironments.forEach((data) => {
        // Iterate over activeEnvironments and add new objects to certificateTeamDetails for certificateUIDs not already present
        if (
          !certificateTeamDetails.some(
            (certificate) => certificate.certificateUID === data.certificate_uid
          )
        ) {
          certificateTeamDetails.push({
            certificateUID: data.certificate_uid,
            certificateName: data.certificate_name,
          });
        }

        response.push({
          environmentID: data?.id ?? null,
          environmentName: data?.environment_name ?? null,
          environmentStatus: data?.status === 0 ? true : false,
          certificateID: data?.certificate_id ?? null,
          certificateUID: data?.certificate_uid ?? null,
          certificateName: data?.certificate_name ?? null,
          certificateType: data?.certificate_type ?? null,
          certificateOrganizationName:
            data?.certificate_organizationname ?? null,
          certificateEnvironmentID: data?.environment_id ?? null,
          needMFA: Boolean(data?.need_mfa),
          signingUsers: [],
          approverDetails: [],
          status: data?.status === 0 ? Labels.ACTIVE : Labels.IN_ACTIVE,
        });

        environmentIDs.push(data?.id ?? null);
      });

      if (certificateTeamDetails.length > 0) {
        const certificateTeamDetailPromiseArr = certificateTeamDetails.map(
          (certificate) =>
            Api.get(
              URL.ApplicationManagement.CertificateTeamDetail +
                certificate.certificateUID +
                "/",
              abortController && {
                signal: abortController.signal,
              }
            )
        );

        const certificateTeamDetailReqArr = await Promise.all(
          certificateTeamDetailPromiseArr
        );

        // if (store.getState().applicationManagement.formActive) {
        //   certificateTeamDetailReqArr.forEach((req, index) => {
        //     let teamDetails = req.data;
        //     certificateTeamDetails[index].teams =
        //       teamDetails?.map((team) => ({
        //         teamID: team.id,
        //         teamName: team.team_name,
        //         members:
        //           team.members?.map((member) => ({
        //             id: member.id,
        //             name: member.username,
        //           })) ?? [],
        //       })) ?? [];
        //   });
        // } else {
        //   certificateTeamDetailReqArr.forEach((req, index) => {
        //     let teamDetails = req.data;
        //     response[index].teams =
        //       teamDetails?.map((team) => ({
        //         teamID: team.id,
        //         teamName: team.team_name,
        //         members:
        //           team.members?.map((member) => ({
        //             id: member.id,
        //             name: member.username,
        //           })) ?? [],
        //       })) ?? [];
        //   });
        // }

        certificateTeamDetailReqArr.forEach((req, index) => {
          let teamDetails = req.data;
          certificateTeamDetails[index].teams =
            teamDetails?.map((team) => ({
              teamID: team.id,
              teamName: team.team_name,
              members:
                team.members?.map((member) => ({
                  id: member.id,
                  name: member.username,
                })) ?? [],
            })) ?? [];
        });
      }

      if (!store.getState().applicationManagement.formActive) {
        response.forEach((data) => {
          let teams =
            certificateTeamDetails.find(
              (certificate) =>
                certificate.certificateUID === data.certificateUID
            )?.teams ?? [];

          data.teams = teams;
        });
      }

      // response = activeEnvironments.map((data) => ({
      //   environmentID: data?.id ?? null,
      //   environmentName: data?.environment_name ?? null,
      //   environmentStatus: data?.status === 0 ? true : false,
      //   certificateID: data?.certificate_id ?? null,
      //   certificateName: data?.certificate_name ?? null,
      //   certificateType: data?.certificate_type ?? null,
      //   certificateOrganizationName: data?.certificate_organizationname ?? null,
      //   certificateEnvironmentID: data?.environment_id ?? null,
      //   needMFA: Boolean(data?.need_mfa),
      //   signingUsers: [],
      //   approverDetails: [],
      //   status: data?.status === 0 ? Labels.ACTIVE : Labels.IN_ACTIVE,
      // }));

      // const environmentIDs = response.map((data) => data.environmentID);

      const signingUsersDetailPromiseArr = environmentIDs.map((id) =>
        Api.get(
          URL.ApplicationManagement.SigningUserDetail + id + "/",
          abortController && {
            signal: abortController.signal,
          }
        )
      );

      const signingUsersDetailReqArr = await Promise.all(
        signingUsersDetailPromiseArr
      );

      signingUsersDetailReqArr.forEach((req, index) => {
        let signingUsers = req.data;
        if (signingUsers?.length > 0) {
          signingUsers.forEach((signingUser) => {
            let userID = signingUser?.id ?? null;
            let userName = signingUser?.username ?? null;
            let environmentID = signingUser?.env_cert_mapping_id ?? null;
            response[index].signingUsers.push({
              userID,
              userName,
              environmentID,
            });
          });
        }
      });

      const approverDetailPromiseArr = environmentIDs.map((id) =>
        Api.get(
          URL.ApplicationManagement.ApproverDetail + id + "/",
          abortController && {
            signal: abortController.signal,
          }
        )
      );

      const approverDetailReqArr = await Promise.all(approverDetailPromiseArr);

      approverDetailReqArr.forEach((req, index) => {
        let approverDetails = req.data;

        if (approverDetails?.length > 0) {
          response[index].approverDetails = approverDetails.map(
            (approverDetail) => ({
              approverType: approverDetail?.approverType ?? null,
              approverTypeName: approverDetail?.approverTypeName ?? null,
              environmentID: approverDetail?.env_cert_mapping_id ?? null,
              approverID: approverDetail?.id ?? null,
              approverLevel: approverDetail?.level ?? null,
              approverMinCount: approverDetail?.min_count ?? null,
              approverQuorumUsers:
                approverDetail?.quorumUsers?.map((user) => ({
                  userID: user.userid,
                  userName: user.username,
                })) ?? [],
            })
          );
        }
      });

      return { certificateTeamDetails, environmentDetail: response };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getApplicationEnvironmentsDetailService;
