import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getP12CertificateService = createAsyncThunk(
  "user/getP12CertificateService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let response = await Api.get(
        URL.User.GetP12Certificates,
        abortController && {
          signal: abortController.signal,
        }
      );

      let result =
        response.data?.map((data) => ({
          userName: data?.username ?? null,
          certificateDetails: data?.certificate_details ?? [],
        })) ?? [];

      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getP12CertificateService;
