import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { useDispatch } from "react-redux";
import { setADToken } from "./features/auth/authSlice";
import { getUserDataService } from "./services/auth/getUserDataService";
import { useSelector } from "react-redux";
import { selectUserDataServiceStatus } from "./features/auth/authSlice";
import { Status } from "./utils/constants";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ErrorComponent from "./components/ui/ErrorComponent";
import { useIdleTimer } from "react-idle-timer";
import { getLicenseInfoService } from "./services/license/getLicenseInfoService";
import { store } from "./lib/redux/store";
import { SnackbarUtilsConfigurator } from "./utils/notificationConfiguration";

export default function App() {
  const dispatch = useDispatch();

  const { start } = useIdleTimer({
    onIdle,
    timeout: 3600000, //1 hour
  });

  const initiateLogout = useSelector((state) => state.auth.initiateLogout);

  const [error, setError] = useState(null);

  const { instance, inProgress } = useMsal();

  if (initiateLogout) {
    localStorage.removeItem("userID");

    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  }

  const request = {
    scopes: ["User.Read", "User.ReadBasic.All", "email"],
  };

  const { result, error: loginError } = useMsalAuthentication(
    InteractionType.Redirect,
    request
  );

  const isAuthenticated = useIsAuthenticated();

  async function acquiredLoginToken() {
    let account = instance.getActiveAccount();
    const tokenRequest = {
      scopes: ["User.Read", "User.ReadBasic.All", "email"],
      loginHint: account?.username,
      account,
    };

    try {
      await instance.acquireTokenSilent(tokenRequest);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // console.log("token expired");
        return instance.acquireTokenRedirect(tokenRequest);
      } else {
        setError(error);
      }
    }
  }

  useEffect(() => {
    let tokenTimeoutId;

    if (isAuthenticated && inProgress === "none") {
      dispatch(setADToken(result.accessToken));
      dispatch(getUserDataService({ IDToken: result.idToken }));

      const expirationTime = new Date(result.expiresOn).getTime() - Date.now();

      tokenTimeoutId = setTimeout(() => {
        acquiredLoginToken();
      }, expirationTime);
    }

    return () => {
      if (tokenTimeoutId) {
        clearTimeout(tokenTimeoutId);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const { status: userDataStatus, error: userDataError } = useSelector(
    selectUserDataServiceStatus
  );

  const { status: licenseStatus, error: licenseError } = useSelector(
    (state) => state.license
  );

  useEffect(() => {
    let acquireTokenTimeoutId;

    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        // console.log("logged in");
        instance.setActiveAccount(message.payload.account);
        start();
      }

      if (message.eventType === EventType.LOGIN_FAILURE) {
        // console.log(message.error);
        // console.log("login error");
        setError(message.error);
      }

      if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        // console.log("acquired token", message.payload);
        dispatch(setADToken(message.payload.accessToken));

        const expirationTime =
          new Date(message.payload.expiresOn).getTime() - Date.now();

        acquireTokenTimeoutId = setTimeout(() => {
          acquiredLoginToken();
        }, expirationTime);
      }

      if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        // console.log("acquire token failed");
        // Notification.info(
        //   "Authentication Error: Please login again. You will be automatically logged out in 5 second."
        // );

        setTimeout(() => {
          instance.logoutRedirect({ account: instance.getActiveAccount() });
        }, 5000);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }

      if (acquireTokenTimeoutId) {
        clearTimeout(acquireTokenTimeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      userDataStatus === Status.Succeeded &&
      licenseStatus === Status.Succeeded
    ) {
      removeLoader();
    }
  }, [isAuthenticated, userDataStatus, licenseStatus]);

  function removeLoader() {
    let loader = document.getElementById("loader-wrapper");
    loader.classList.add("remove-loader");
  }

  function onIdle() {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  }

  if (loginError || userDataError || error || licenseError) {
    removeLoader();

    return (
      <ErrorComponent
        error={loginError || userDataError || error || licenseError}
        resetErrorBoundary={() => window.location.reload()}
      />
    );
  }

  if (userDataStatus === Status.Succeeded && licenseStatus === Status.Idle) {
    dispatch(getLicenseInfoService());
  }

  if (
    !isAuthenticated ||
    userDataStatus !== Status.Succeeded ||
    licenseStatus !== Status.Succeeded
  ) {
    return null;
  }

  return (
    <>
      <SnackbarUtilsConfigurator />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </>
  );
}
