import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { closeForm } from "../../features/forms/formSlice";

function arraysAreEqual(arr1, arr2) {
  return (
    arr1.length === arr2.length &&
    arr1.every((value, index) => value === arr2[index])
  );
}

export default async function updateTriggerDataByKeyID(
  formData,
  initialData,
  serviceData
) {
  try {
    if (
      arraysAreEqual(initialData.days, formData.days) &&
      arraysAreEqual(initialData.emails, formData.emails)
    ) {
      store.dispatch(closeForm());
      return;
    }

    store.dispatch(showLoader());

    let { days, emails } = formData;
    let { keyID } = serviceData;

    let reqBody = {};

    if (days.length > 0) {
      reqBody.triggerDays = days.map((item) => ({
        trigger_days: item.value,
      }));
    } else {
      reqBody.triggerDays = [];
    }

    if (emails.length > 0) {
      reqBody.triggerEmails = emails.map((value) => ({
        email: value,
      }));
    } else {
      reqBody.triggerEmails = [];
    }

    reqBody.Id = keyID;

    await Api.put(
      URL.KeysAndCertificates.UpdateTriggerDataByKeyID + keyID + "/",
      reqBody
    );
    store.dispatch(closeForm());
    Notification.success(NotificationLabels.TRIGGER_UPDATED);
  } catch (error) {
    Notification.error(NotificationLabels.TRIGGER_UPDATE_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
