import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { closeForm } from "../../features/forms/formSlice";
import { getCurrentUTCTime } from "../../utils/dateFormatters";
import { IdentityStoreADType } from "../../utils/enums";
import getIdentityStoreTableDataService from "./getIdentityStoreTableData";

export default async function manageIdentityAD(formData, _, serviceData) {
  try {
    store.dispatch(showLoader());

    let userID = store.getState().auth.userID;

    let request = null;

    if (serviceData.new) {
      let requestBody = {
        ClientId: formData.clientID,
        SecretKey: formData.secretKey,
        Is_deleted: false,
        CallBackURL: formData.callbackURL,
        ConnectionURL: formData.connectionURL,
        created_user: userID,
        created_date: getCurrentUTCTime(),
        AD_Type:
          formData.ADType.value === IdentityStoreADType.AzureAD
            ? "azure"
            : "ldap",
      };

      request = Api.post(URL.IdentityStore.ManageAD, requestBody);
    } else {
      let requestBody = {
        ClientId: formData.clientID,
        SecretKey: formData.secretKey,
        CallBackURL: formData.callbackURL,
        ConnectionURL: formData.connectionURL,
        Is_deleted: formData.status === "Active" ? true : false,
        modified_by: userID,
        modified_date: getCurrentUTCTime(),
        AD_Type:
          formData.ADType.value === IdentityStoreADType.AzureAD
            ? "azure"
            : "ldap",
      };

      request = Api.put(
        URL.IdentityStore.ManageAD + serviceData.identityID + "/",
        requestBody
      );
    }

    await request;

    store.dispatch(getIdentityStoreTableDataService());
    store.dispatch(closeForm());

    if (serviceData.new) {
      Notification.success(NotificationLabels.AD_ADDED);
    } else {
      Notification.success(NotificationLabels.AD_UPDATED);
    }
  } catch (error) {
    if (serviceData.new) {
      Notification.error(NotificationLabels.AD_ADDITION_FAILED);
    } else {
      Notification.error(NotificationLabels.AD_UPDATE_FAILED);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
