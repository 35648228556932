import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { store } from "../../lib/redux/store";
import { closeForm, initialValueFetched } from "../../features/forms/formSlice";

export default async function getMFAStatusForRequestFormService(serviceData) {
  try {
    let { applicationName, environmentName, jobID } = serviceData;

    let checkMFARequiredRequestBody = {
      app_name: applicationName,
      env_name: environmentName,
    };

    const checkMFARequiredRequest = await Api.post(
      URL.SigningRequests.CheckMFA,
      checkMFARequiredRequestBody
    );

    const { detail: isMFAEnabled } = checkMFARequiredRequest.data;

    if (isMFAEnabled === "MFA Enabled") {
      await Api.get(URL.SigningRequests.SendOTP + jobID);
    }

    let initialFormData =
      isMFAEnabled === "MFA Enabled"
        ? { needMFA: true, otp: null, comment: "" }
        : { needMFA: false, otp: null, comment: "" };

    store.dispatch(initialValueFetched(initialFormData));
  } catch (error) {
    Notification.error(NotificationLabels.FORM_SETUP_UNSUCCESSFUL);
    store.dispatch(closeForm());
  }
}
