import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export default function InputLabel({
  label,
  error,
  touched,
  errorTextPosition = "top",
  size = "large",
  ...props
}) {
  return (
    <Stack
      gap={1}
      sx={{
        gap: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "baseline",
        rowGap: "0",
        mb: 1,
      }}
      {...props}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "1rem",
          fontWeight: 600,
          ...(size === "small" && { fontSize: "0.9rem" }),
        }}
      >
        {label}
      </Typography>

      {touched && error ? (
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "0.85rem",
            color: "error.dark",
            ...(errorTextPosition !== "top" && {
              position: "absolute",
              top: "105%",
              width: "100%",
            }),
          }}
        >
          {`(${error})`}
        </Typography>
      ) : null}
    </Stack>
  );
}
