import QuickInsight from "../features/dashboard/QuickInsight";
import HSMHealthCheckup from "../features/dashboard/HSMHealthCheckup";
import SignatureCount from "../features/dashboard/SignatureCounts";
import ExpiringCertificates from "../features/dashboard/ExpiringCertificates";
import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../components/ui/ErrorComponent";
import { useDispatch } from "react-redux";
import { resetState } from "../features/dashboard/dashboardSlice";
import { useEffect } from "react";
import SigningKSP from "../features/dashboard/SigningKSP";

export default function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <Box
        sx={{
          width: 1,
          height: 1,
          maxHeight: "1080px",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr 1fr",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1.5fr",
            gridTemplateRows: "auto",
            gap: 2,
            "@media (max-width: 1200px)": { gridTemplateColumns: "1fr 1fr" },
          }}
        >
          <Box
            gridRow="1"
            sx={{
              "@media (max-width: 900px)": {
                gridRow: "1",
                gridColumn: "span 2",
              },
              display: "flex",
              gap: 2,
            }}
          >
            <QuickInsight />
            <SigningKSP />
          </Box>

          <Box
            gridRow="2"
            sx={{
              "@media (max-width: 1200px)": { gridRow: "1" },
              "@media (max-width: 900px)": {
                gridRow: "2",
                gridColumn: "span 2",
              },
            }}
          >
            <HSMHealthCheckup />
          </Box>

          <Box
            gridRow="span 2"
            sx={{
              width: "100%",
              overflow: "hidden",
              "@media (max-width: 1200px)": {
                gridRow: "2",
                gridColumn: "span 2",
              },
              "@media (max-width: 900px)": { gridRow: "3" },
            }}
          >
            <SignatureCount />
          </Box>
        </Box>

        <ExpiringCertificates />
      </Box>
    </ErrorBoundary>
  );
}
