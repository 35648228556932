import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { FileType, NotificationLabels } from "../../utils/constants";
import downloadFile from "../../utils/downloadFile";

export default async function downloadBackup({ backupName }) {
  try {
    store.dispatch(showLoader());

    let response = await Api.post(
      URL.Backup.DownloadBackup,
      { file_name: backupName },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    let extractedName =
      response.headers["content-disposition"].match(/filename=([^;]+)/);
    const fileName = extractedName ? extractedName[1] : "codesign_backup";

    downloadFile(response.data, fileName, FileType.ZIP);

    Notification.success(NotificationLabels.BACKUP_DOWNLOAD_SUCCESSFUL);
  } catch (error) {
    Notification.error(NotificationLabels.BACKUP_DOWNLOAD_UNSUCCESSFUL);
  } finally {
    store.dispatch(closeLoader());
  }
}
