import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import getAllCertificateService from "../applicationManagement/getAllCertificateService";
import { closeForm } from "../../features/forms/formSlice";

export default async function createSigningRequestCertificateService(
  formData,
  _,
  __
) {
  try {
    store.dispatch(showLoader());

    let requestBody = {
      id: 0,
      certificate_name: formData.certificateName,
      certificate_description: formData.certificateDescription,
      common_name: formData.commonName,
      organization_name: formData.organization,
      organization_unit: formData.organizationUnit,
      country_code: formData.countryCode.toUpperCase(),
      state_province: formData.state,
      locality: formData.locality,
      email: formData.email,
      key_algorithm: formData.keyAlgorithm,
      isActivate: true,
      isArchive: false,
      scope: formData.scope,
      Is_deleted: false,
      created_user: store.getState().auth.userID,
      key_usage: formData.keyUsage === "None" ? null : formData.keyUsage,
    };

    await Api.post(
      URL.KeysAndCertificates.GenerateSigningRequestCertificate,
      requestBody
    );

    const request = await Api.get(
      URL.KeysAndCertificates.DownloadSigningRequestCertificate +
        "?csrName=" +
        formData.certificateName
    );

    const response = request.data;

    let fileName = formData.certificateName + ".csr";
    var file = new Blob([response], { type: "text/csr;charset=utf-8" });
    var csrFile = window.URL.createObjectURL(file);
    let tempLink = document.createElement("a");
    tempLink.href = csrFile;
    tempLink.setAttribute("download", fileName);
    tempLink.click();

    if (store.getState().applicationManagement.formActive) {
      store.dispatch(getAllCertificateService());
    }

    store.dispatch(closeLoader());

    store.dispatch(closeForm());

    Notification.success(NotificationLabels.CERTIFICATE_CREATED);
    Notification.success(NotificationLabels.FILE_DOWNLOADED);
  } catch (error) {
    store.dispatch(closeLoader());
    Notification.error(NotificationLabels.CERTIFICATE_ADDITION_FAILED);
  }
}
