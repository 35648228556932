export default function NavBarAPI({ width, fill }) {
  return (
    <svg fill={fill} width={width} viewBox="0 0 35 35">
      <path d="M24.2537 7.15961H29.8721V13.9671H9.2148C9.21188 13.9164 9.20576 13.8598 9.20576 13.8032C9.20518 9.72686 9.20518 5.65023 9.20576 1.5739C9.20576 1.51352 9.21509 1.45315 9.22005 1.39307C14.2105 1.39307 19.2009 1.39307 24.1913 1.39307C24.1936 3.2994 24.196 5.20544 24.1983 7.11177C24.2167 7.12782 24.2353 7.14357 24.2537 7.15961Z" />
      <path d="M26.0778 1.39307C26.1288 1.47532 26.1665 1.57069 26.2327 1.63836C27.3722 2.80094 28.5153 3.96003 29.6566 5.12086C29.7026 5.16782 29.7385 5.22469 29.824 5.33494C28.5351 5.33494 27.3069 5.33494 26.0787 5.33494C26.0603 5.3189 26.0422 5.30257 26.0238 5.28653C26.0215 3.98861 26.0192 2.69098 26.0168 1.39307C26.0373 1.39307 26.0574 1.39307 26.0778 1.39307Z" />
      <path d="M29.879 28.5943H29.5368C22.2545 28.5943 14.9722 28.5888 7.68955 28.6034C7.01172 28.6048 6.40797 28.4576 5.90338 28.0247C5.41513 27.6062 5.09372 27.0488 5.09109 26.4001C5.07563 22.9019 5.0873 19.4033 5.09022 15.9048C5.09022 15.8176 5.09838 15.7304 5.10451 15.6035C5.77709 15.9168 6.4663 15.81 7.14238 15.8106C14.5971 15.8161 22.0521 15.8141 29.5068 15.8141H29.8787V28.5943H29.879ZM19.0325 24.1517C19.0485 24.1342 19.0646 24.1167 19.0806 24.0995C19.5111 24.0776 19.9433 24.0747 20.3715 24.0298C20.9776 23.9665 21.4962 23.6704 21.8928 23.2361C22.4814 22.5918 22.7451 21.8335 22.6223 20.9238C22.4846 19.903 21.6461 18.8404 20.5742 18.6981C19.472 18.5517 18.3453 18.589 17.2203 18.5456V25.8293H19.0322C19.0322 25.2612 19.0322 24.7067 19.0322 24.152L19.0325 24.1517ZM13.5696 25.8317C14.0829 25.6972 14.591 25.5549 15.104 25.4347C15.2851 25.3924 15.3231 25.3542 15.265 25.1562C15.0582 24.4538 14.8864 23.7413 14.6986 23.0334C14.3078 21.5611 13.9152 20.0891 13.5226 18.6132H10.4102C10.2247 19.3068 10.0389 19.994 9.85722 20.6826C9.46055 22.1864 9.07147 23.6923 8.66226 25.1929C8.61705 25.3592 8.62697 25.3962 8.78651 25.4318C9.13009 25.5085 9.47018 25.6018 9.81026 25.6937C10.1778 25.7931 10.4081 25.6634 10.5012 25.3046C10.5158 25.2484 10.5493 25.1964 10.5598 25.1398C10.5975 24.9377 10.7083 24.8779 10.9163 24.8823C11.6151 24.8972 12.3148 24.894 13.0136 24.8843C13.2228 24.8814 13.34 24.9141 13.3689 25.1614C13.3954 25.3901 13.5001 25.6097 13.5698 25.8317H13.5696ZM26.3268 25.8273V18.5902H24.5403V25.8273H26.3268Z" />
      <path d="M9.28113 30.4288C9.37213 30.4256 9.46313 30.4197 9.55413 30.4197C16.2269 30.4194 22.8996 30.4194 29.5724 30.4194H29.8804V32.5267H9.22046V30.4786C9.24058 30.462 9.261 30.4457 9.28113 30.4291V30.4288Z" />
      <path d="M7.36624 11.798V14.0019C6.7692 13.9033 6.16253 14.1573 5.59815 13.8126C5.2219 13.5827 4.9632 13.0204 5.10057 12.6024C5.25865 12.1218 5.56782 11.7584 6.08786 11.7928C6.50786 11.8205 6.93078 11.7983 7.36624 11.7983V11.798Z" />
      <path d="M29.8386 5.31474C29.8363 5.31912 29.8325 5.32583 29.829 5.33283C29.8182 5.32583 29.8074 5.31912 29.7966 5.31212C29.8039 5.30541 29.8112 5.2987 29.8185 5.29199C29.8252 5.29958 29.8316 5.30687 29.8386 5.31503V5.31474Z" />
      <path d="M11.8074 20.4453H12.1221C12.3508 21.3072 12.5803 22.1711 12.8137 23.0502H11.1208C11.3504 22.1799 11.5779 21.3159 11.8074 20.4453Z" />
      <path d="M19.0806 20.4487C19.3843 20.4454 19.6879 20.4437 19.9915 20.4382C20.3701 20.4312 20.6183 20.6464 20.7595 20.9573C20.8998 21.2662 20.8846 21.5923 20.653 21.8761C20.4392 22.1383 20.1712 22.2762 19.825 22.2614C19.573 22.2509 19.3198 22.2593 19.0322 22.2593V20.5006C19.0483 20.4834 19.0643 20.4659 19.0804 20.4487H19.0806Z" />
    </svg>
  );
}
