import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import getUserManagementTableDataService from "./getUserManagementTableDataService";

export default async function manageUserLockStatusService({
  lockStatus,
  userID,
}) {
  try {
    store.dispatch(showLoader());

    let requestBody = {
      id: userID,
      is_staff: lockStatus,
    };

    await Api.patch(URL.User.UpdateLockStatus + userID + "/", requestBody);

    store.dispatch(getUserManagementTableDataService());

    if (lockStatus) {
      Notification.success(NotificationLabels.USER_LOCKED);
    } else {
      Notification.success(NotificationLabels.USER_UNLOCKED);
    }
  } catch (error) {
    if (lockStatus) {
      Notification.error(NotificationLabels.FAILED_TO_LOCK_USER);
    } else {
      Notification.success(NotificationLabels.FAILED_TO_UNLOCK_USER);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
