// React
import { useRef, useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Constants
import { Status } from "../../utils/constants";

import getEmailTemplateTableDataService from "../../services/email/getEmailTemplateTableData";
import { Button, Stack, Box } from "@mui/material";
import { Labels } from "../../utils/constants";
import { selectEmailTemplateTableData } from "./emailSlice";
import { EmailTemplateType } from "../../utils/enums";
import CustomTable from "../../components/ui/table/CustomTable";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import { store } from "../../lib/redux/store";
import { selectAvailableTemplateOptions } from "./emailSlice";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import { useErrorBoundary } from "react-error-boundary";
import Skeleton from "@mui/material/Skeleton";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        mt: 3,
        width: 1,
        height: "calc(100% - 70px)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function EmailTemplateTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(
    selectEmailTemplateTableData
  );

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getEmailTemplateTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} dispatch={dispatch} />;
}

function TableComponent({ tableData, dispatch }) {
  let isUserAllowedToManageEmailTemplate = checkUserPermission(
    Permissions.ManageEmailTemplate
  );

  const rowData = tableData;

  function getTemplateTypeName(typeID) {
    let templateName = "";

    switch (typeID) {
      case EmailTemplateType.SigningRequestCreate:
        templateName = "Signing Request Create";
        break;
      case EmailTemplateType.SigningRequestApprove:
        templateName = "Signing Request Approve";
        break;
      case EmailTemplateType.AssignSigningUserOrOffice:
        templateName = "Assign Signing User/Office";
        break;
      case EmailTemplateType.CertificateExpiry:
        templateName = "Certificate Expiry";
        break;
      case EmailTemplateType.SystemMaintanence:
        templateName = "System Maintenance";
        break;
      default:
        templateName = "Signing Request Create";
        break;
    }

    return templateName;
  }

  function handleEditClicked(data) {
    const { templateOptions } = selectAvailableTemplateOptions(
      store.getState(),
      data.templateType
    );

    let currentOption = templateOptions.filter(
      (option) => option.value === data.templateType
    );

    dispatch(
      initializeForm({
        formType: FormType.EMAIL_TEMPLATE,
        initialValue: {
          emailType: currentOption.length === 1 ? currentOption[0] : null,
          emailSubject: data.templateSubject,
          emailContent: data.templateContent,
        },
        serviceData: { ...data, newTemplate: false },
        dynamicOption: {
          emailType: templateOptions,
        },
      })
    );
  }

  const columnData = [
    {
      field: "templateType",
      headerName: Labels.NAME,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return getTemplateTypeName(params.value);
      },
    },
    {
      field: "templateSubject",
      headerName: Labels.SUBJECT,
      flex: 1,
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      width: 250,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderActionCell(rowData) {
    return (
      <>
        {isUserAllowedToManageEmailTemplate ? (
          <Stack
            direction="row"
            sx={{
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
                paddingInline: "30px",
              }}
              onClick={() => handleEditClicked(rowData)}
            >
              {Labels.EDIT}
            </Button>
            <Button
              size="small"
              sx={{
                fontSize: "0.9rem",
                borderRadius: "5px",
              }}
              variant="outlined"
              onClick={() => {
                if (rowData.status === "Active") {
                  dispatch(
                    initializeModal({
                      modalType: ModalType.ARCHIVE_EMAIL_TEMPLATE,
                      modalData: {
                        templateID: rowData.templateID,
                      },
                    })
                  );
                } else {
                  dispatch(
                    initializeModal({
                      modalType: ModalType.RESTORE_EMAIL_TEMPLATE,
                      modalData: {
                        templateID: rowData.templateID,
                      },
                    })
                  );
                }
              }}
            >
              {rowData.status === "Active" ? Labels.ARCHIVE : Labels.RESTORE}
            </Button>
          </Stack>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </>
    );
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return <CustomTable rowData={rowData} columnData={columnData} />;
}
