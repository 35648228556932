import { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  Table,
  Paper,
} from "@mui/material";
import { Labels, ModalType, FormType } from "../../utils/constants";
import { initializeModal } from "../modals/modalSlice";
import { initializeForm } from "../forms/formSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  isCurrentTimeGreaterThenProvidedTime,
  formatDateToUserTimeZone,
} from "../../utils/dateFormatters";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";

export default function KeyAndCertDetailTable({ tableData, dispatch }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function handleEditStatusClicked() {
    if (tableData.status === Labels.ACTIVE) {
      dispatch(
        initializeModal({
          modalType: ModalType.DEACTIVATE_KEY,
          modalData: {
            keyID: tableData.keyID,
            currentStatus: tableData.status,
            certificateName: tableData.certificateName,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.ACTIVATE_KEY,
          modalData: {
            keyID: tableData.keyID,
            certificateName: tableData.certificateName,
          },
        })
      );
    }

    setAnchorEl(null);
  }

  function handleTriggerSetupClicked(daysDiff) {
    function constructDaysOptions() {
      let days = [];

      if (daysDiff >= 7) {
        days.push({ id: 1, label: "7 Days", value: 7 });
      }

      if (daysDiff >= 30) {
        days.push({ id: 2, label: "30 Days", value: 30 });
      }

      if (daysDiff >= 60) {
        days.push({ id: 3, label: "60 Days", value: 60 });
      }

      if (daysDiff >= 90) {
        days.push({ id: 4, label: "90 Days", value: 90 });
      }

      return days;
    }

    dispatch(
      initializeForm({
        formType: FormType.TRIGGER_SETUP,

        serviceData: {
          keyID: tableData.keyID,
        },

        dynamicOption: {
          days: constructDaysOptions(),
        },

        customSetupData: {
          email: tableData?.email ?? null,
        },
      })
    );

    setAnchorEl(null);
  }

  function isCertificateValidForTriggerSetup(dateString) {
    const date = new Date(dateString);

    const now = new Date();

    const diffMilliseconds = date.getTime() - now.getTime();

    const numberOfDays = Math.round(diffMilliseconds / (1000 * 60 * 60 * 24));

    return numberOfDays;
  }

  function handleEditKeyUsageClicked() {
    dispatch(
      initializeForm({
        formType: FormType.UPDATE_KEY_USAGE,
        serviceData: {
          keyID: tableData?.keyID ?? null,
        },
        initialValue: {
          keyUsage: tableData?.keyUsage ?? "None",
        },
      })
    );
    setAnchorEl(null);
  }

  function renderActionCell() {
    let isAllowedToCreateSelfSigned = checkUserPermission(
      Permissions.CreateSelfSignedCertificate
    );

    let isAllowedToGenerateCSR = checkUserPermission(Permissions.GenerateCSR);

    let isAllowedToImportCertificate = checkUserPermission(
      Permissions.ImportCertificate
    );

    let daysDiff = isCertificateValidForTriggerSetup(tableData.validTill);

    if (
      !isCurrentTimeGreaterThenProvidedTime(tableData?.validTill) &&
      (isAllowedToCreateSelfSigned ||
        isAllowedToGenerateCSR ||
        isAllowedToImportCertificate)
    ) {
      return (
        <Box
          sx={{
            width: 1,
            height: 1,
          }}
        >
          <IconButton
            onClick={(event) => setAnchorEl(event.target)}
            size="small"
            color="primary"
            aria-label="more actions"
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            sx={{
              "& .MuiPaper-root .MuiList-root": {
                minWidth: "150px",
                py: 0,
                "& .MuiButtonBase-root": {
                  py: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  bgcolor: "tertiary.light",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  "&:hover": {
                    bgcolor: "tertiary.main",
                  },
                  "& .MuiListItemText-root .MuiTypography-root": {
                    fontSize: "0.9rem",
                    fontWeight: 500,
                  },
                },
              },
            }}
            id="action-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {daysDiff >= 7 && (
              <MenuItem onClick={() => handleTriggerSetupClicked(daysDiff)}>
                {Labels.SETUP_TRIGGER}
              </MenuItem>
            )}
            <MenuItem onClick={() => handleEditStatusClicked()}>
              {tableData?.status === Labels.ACTIVE ? "Deactivate" : "Activate"}
            </MenuItem>
            <MenuItem onClick={() => handleEditKeyUsageClicked()}>
              {Labels.UPDATE_KEY_USAGE}
            </MenuItem>
          </Menu>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          width: 1,
          height: 1,
        }}
      >
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
          -
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{Labels.KEY_NAME}</TableCell>
            <TableCell>{Labels.UID}</TableCell>
            <TableCell>{Labels.ALGORITHM}</TableCell>
            <TableCell>{Labels.SIZE}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.VALID_TILL}</TableCell>
            <TableCell>{Labels.KEY_USAGE}</TableCell>
            <TableCell>{Labels.ACTION}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{tableData?.certificateName ?? "-"}</TableCell>
            <TableCell
              sx={{
                maxWidth: 250,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {tableData?.keyUID ?? "-"}
            </TableCell>
            <TableCell>{tableData?.algorithm ?? "-"}</TableCell>
            <TableCell>{tableData?.size ?? "-"}</TableCell>
            <TableCell>{renderStatusCell(tableData?.status)}</TableCell>
            <TableCell>
              {tableData?.validTill
                ? formatDateToUserTimeZone(tableData.validTill)
                : "-"}
            </TableCell>
            <TableCell>
              {tableData?.keyUsage ?? (
                <Typography
                  sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}
                >
                  -
                </Typography>
              )}
            </TableCell>
            <TableCell>{renderActionCell()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
