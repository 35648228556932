import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Labels } from "../../utils/constants";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import { JsonView, defaultStyles } from "react-json-view-lite";

export default function CertificateDetailModal({ certificateDetail }) {
  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  const parseStringToObject = (str) => {
    const obj = {};
    const keyValuePairs = str.split(", ");
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      obj[key] = value;
    });
    return obj;
  };

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.UID,
        value: certificateDetail?.id ?? "-",
      },
      {
        label: Labels.DOMAIN,
        value: certificateDetail?.domain ?? "-",
      },
      {
        label: Labels.CREATED_AT,
        value: certificateDetail?.createdAt
          ? formatDateToUserTimeZone(certificateDetail.createdAt)
          : "-",
      },
      {
        label: Labels.EXPIRES_AT,
        value: certificateDetail?.expiresAt
          ? formatDateToUserTimeZone(certificateDetail.expiresAt)
          : "-",
      },
      {
        label: Labels.TYPE,
        value: certificateDetail?.type ?? "-",
      },
      {
        label: Labels.STATUS,
        component: renderStatusCell(certificateDetail.status),
      },
      {
        label: Labels.SAN,
        value: certificateDetail?.san ?? "-",
      },
      {
        label: Labels.SUBJECT,
        component: certificateDetail?.subject ? (
          <JsonView
            data={parseStringToObject(certificateDetail.subject)}
            style={{
              ...defaultStyles,
              padding: "10px",
              backgroundColor: "#f0f0f0",
            }}
          />
        ) : (
          "-"
        ),
        fullWidth: certificateDetail?.subject ? true : false,
      },
      {
        label: Labels.CERTIFICATE_DATA,
        component: certificateDetail?.certificateData ? (
          <JsonView
            data={certificateDetail.certificateData.replace(/\\r\\n/g, "\n")}
            shouldExpandNode={false}
            style={defaultStyles}
          />
        ) : (
          "-"
        ),
        fullWidth: true,
      },
    ];

    return arr;
  }

  return (
    <Box
      className="custom-scroll-bar"
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        // height: "calc(100% - 280px)",
        height: "calc(100% - 400px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            More Information
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid
              item
              xs={12}
              {...(!item?.fullWidth && { md: 6, lg: 4 })}
              key={index}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              {item.component ? (
                item.component
              ) : (
                <Typography variant="subtitle1">{item.value}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
