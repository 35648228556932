import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import getQuickInsightService from "../../services/dashboard/getQuickInsightService";
import getHSMHealthService from "../../services/dashboard/getHSMHealthService";
import { createSelector } from "reselect";
import getSignatureCountService from "../../services/dashboard/getSignatureCountService";
import getExpiringCertificateService from "../../services/dashboard/getExpiringCertificateService";
import getSigningKSPService from "../../services/dashboard/getSigningKSPService";

const initialState = {
  status: {
    quickInsight: Status.Idle,
    signatureCount: Status.Idle,
    HSMHealthInfo: Status.Idle,
    expiringCertificates: Status.Idle,
    signingKSP: Status.Idle,
  },

  error: null,

  SigningKSP: null,
  quickInsight: null,
  HSMHealthInfo: null,
  signatureCount: null,
  expiringCertificates: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetState: (state) => {
      state.error = null;

      state.status.quickInsight = Status.Idle;
      state.status.HSMHealthInfo = Status.Idle;
      state.status.signatureCount = Status.Idle;
      state.status.expiringCertificates = Status.Idle;
      state.status.signingKSP = Status.Idle;
    },
  },
  extraReducers: (builder) => {
    builder
      // For Quick Insight
      .addCase(getQuickInsightService.pending, (state) => {
        state.status.quickInsight = Status.Loading;
      })
      .addCase(getQuickInsightService.fulfilled, (state, action) => {
        state.quickInsight = action.payload;
        state.status.quickInsight = Status.Succeeded;
      })
      .addCase(getQuickInsightService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.quickInsight = null;
          state.status.quickInsight = Status.Failed;
          state.error = action.payload;
        }
      })
      // For HSM Health Info
      .addCase(getHSMHealthService.pending, (state) => {
        state.status.HSMHealthInfo = Status.Loading;
      })
      .addCase(getHSMHealthService.fulfilled, (state, action) => {
        state.HSMHealthInfo = action.payload;
        state.status.HSMHealthInfo = Status.Succeeded;
      })
      .addCase(getHSMHealthService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.HSMHealthInfo = null;
          state.status.HSMHealthInfo = Status.Failed;
          state.error = action.payload;
        }
      })
      // For SignatureCount
      .addCase(getSignatureCountService.pending, (state) => {
        state.status.signatureCount = Status.Loading;
      })
      .addCase(getSignatureCountService.fulfilled, (state, action) => {
        state.signatureCount = action.payload;
        state.status.signatureCount = Status.Succeeded;
      })
      .addCase(getSignatureCountService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.signatureCount = null;
          state.status.signatureCount = Status.Failed;
          state.error = action.payload;
        }
      })
      // For Expiring Certificates
      .addCase(getExpiringCertificateService.pending, (state) => {
        state.status.expiringCertificates = Status.Loading;
      })
      .addCase(getExpiringCertificateService.fulfilled, (state, action) => {
        state.expiringCertificates = action.payload;
        state.status.expiringCertificates = Status.Succeeded;
      })
      .addCase(getExpiringCertificateService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.expiringCertificates = null;
          state.status.expiringCertificates = Status.Failed;
          state.error = action.payload;
        }
      })
      // For Signing KSP
      .addCase(getSigningKSPService.pending, (state) => {
        state.status.signingKSP = Status.Loading;
      })
      .addCase(getSigningKSPService.fulfilled, (state, action) => {
        state.signingKSP = action.payload;
        state.status.signingKSP = Status.Succeeded;
      })
      .addCase(getSigningKSPService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.SigningKSP = null;
          state.status.signingKSP = Status.Failed;
          state.error = action.payload;
        }
      });
  },
});

export const selectQuickInsight = createSelector(
  [
    (state) => state.dashboard.status.quickInsight,
    (state) => state.dashboard.quickInsight,
    (state) => state.dashboard.error,
  ],
  (status, data, error) => ({
    status,
    data,
    error,
  })
);

export const selectKSPData = createSelector(
  [
    (state) => state.dashboard.status.signingKSP,
    (state) => state.dashboard.signingKSP,
    (state) => state.dashboard.error,
  ],
  (status, data, error) => ({
    status,
    data,
    error,
  })
);

// For HSMHealthInfo
export const selectHSMHealthInfo = createSelector(
  [
    (state) => state.dashboard.status.HSMHealthInfo,
    (state) => state.dashboard.HSMHealthInfo,
    (state) => state.dashboard.error,
  ],

  (status, data, error) => ({
    status,
    data,
    error,
  })
);

// For SigntaureCount
export const selectSignatureCount = createSelector(
  (state) => state.dashboard.status.signatureCount,
  (state) => state.dashboard.signatureCount,
  (state) => state.dashboard.error,
  (status, data, error) => ({
    status,
    data,
    error,
  })
);

// For Expiring Certificates
export const selectExpiringCertificates = createSelector(
  (state) => state.dashboard.status.expiringCertificates,
  (state) => state.dashboard.expiringCertificates,
  (state) => state.dashboard.error,
  (status, data, error) => ({
    status,
    data,
    error,
  })
);

export const {
  resetQuickInsight,
  resetHSMHealthInfo,
  resetSignatureCount,
  resetExpiringCertificates,
  resetState,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
