import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Labels } from "../../utils/constants";
import { formatDateForUI } from "./utils";

export default function ReportDetailTable({ reportDetail }) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow className="custom-scroll-bar">
            <TableCell>{Labels.EVENT_TIME}</TableCell>
            <TableCell>{Labels.CATEGORY}</TableCell>
            <TableCell>{Labels.APPLICATION_ID}</TableCell>
            <TableCell>{Labels.MESSAGE}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>
              {reportDetail.eventTime
                ? formatDateForUI(reportDetail.eventTime)
                : "-"}
            </TableCell>
            <TableCell>{reportDetail?.category || "-"}</TableCell>
            <TableCell>
              {reportDetail.applicationID >= 0
                ? reportDetail.applicationID
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                maxWidth: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {reportDetail?.message || "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
