import { useEffect, useRef } from "react";
import { Status } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import getSigningToolServiceData from "../../services/signingTools/getSigningToolServiceData";
import { selectSigningToolState } from "./signingToolsSlice";
import {
  Stack,
  Box,
  Typography,
  Paper,
  Divider,
  IconButton,
} from "@mui/material";
import WindowsLogo from "../../components/icons/WindowsLogo";
import LinuxLogo from "../../components/icons/LinuxLogo";
import MacLogo from "../../components/icons/MacLogo";
import DownloadIcon from "@mui/icons-material/Download";
import Skeleton from "@mui/material/Skeleton";
import noDataFoundPng from "../../assets/images/no-data-found-png.png";
import { useErrorBoundary } from "react-error-boundary";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {Array.from({ length: 4 }, (_, index) => index).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          sx={{ width: 1, height: "100px", borderRadius: "5px" }}
        />
      ))}
    </Box>
  );
}

export default function SigningToolsList() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { signingToolsData, status, error } = useSelector(
    selectSigningToolState
  );

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getSigningToolServiceData({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (signingToolsData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return (
    <Stack
      sx={{
        gap: 2,
        overflowY: "auto",
        width: 1,
        height: 1,
        px: 2,
        maxHeight: "1080px",
      }}
    >
      {signingToolsData.length > 0 ? (
        signingToolsData.map((toolData, index) => (
          <SigningToolComponent key={index} data={toolData} />
        ))
      ) : (
        <Paper
          sx={{
            width: 1,
            height: 1,
            boxShadow: "3px 3px 13px 2px rgba(0, 0, 0, 0.10)",
            borderRadius: "5px",
            display: "grid",
            placeContent: "center",
          }}
        >
          <Box sx={{ width: "fit-content" }}>
            <Box sx={{ width: "100px", aspectRatio: 1, m: "0 auto" }}>
              <img src={noDataFoundPng} alt="No Data Found" />
            </Box>
            <Typography
              variant="h6"
              sx={{ fontSize: "1.25rem", fontWeight: 700 }}
              color="primary.main"
            >
              No Signing Tool Found!
            </Typography>
          </Box>
        </Paper>
      )}
    </Stack>
  );
}

function SigningToolComponent({ data }) {
  return (
    <Paper
      sx={{
        width: 1,
        minWidth: "fit-content",
        borderRadius: "5px",
        boxShadow: "3px 3px 13px 2px rgba(0, 0, 0, 0.10)",
        borderLeftWidth: "5px",
        borderLeftColor: "secondary.main",
        borderLeftStyle: "solid",
        px: 3,
        py: 2,
      }}
    >
      <Stack
        direction={"row"}
        spacing={{
          xs: 2,
          sm: 4,
          md: 6,
          lg: 9,
          xl: 15,
        }}
        sx={{ alignItems: "center" }}
      >
        <Typography
          sx={{
            width: "250px",
            flexShrink: 0,
            color: "primary.main",
            fontSize: "1.05rem",
            fontWeight: 600,
          }}
          variant="h6"
        >
          {data.toolName}
        </Typography>

        <Typography
          sx={{ flex: 1, minWidth: "250px", flexShrink: 0, fontSize: "0.9rem" }}
          variant="subtitle1"
        >
          {data.toolDescription}
        </Typography>

        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            gap: "15px",
            width: "100px",
            minWidth: "fit-content",
          }}
        >
          {data.os === "Windows" ? (
            <WindowsLogo width={28} fill="#000000" />
          ) : data.os === "Linux" ? (
            <LinuxLogo width={32} fill="#000000" />
          ) : (
            <MacLogo width={28} fill="#000000" />
          )}

          <Divider
            orientation="vertical"
            sx={{
              width: "1.6px",
              height: "45px",
              backgroundColor: "#000000",
            }}
          />

          <Box>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "0.9rem", fontWeight: 700 }}
              color="#000000"
            >
              {data.os}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.85rem" }}
              color="#000000"
            >
              Version {data.toolVersion}
            </Typography>
          </Box>
        </Stack>

        <IconButton
          sx={{
            a: {
              color: "inherit",
            },
          }}
          aria-label="download"
          color="primary"
        >
          <a href={data.fileURL}>
            <DownloadIcon />
          </a>
        </IconButton>
      </Stack>
    </Paper>
  );
}
