import moment from "moment";

export function formatDateToUserTimeZone(UTCDateString, includeTime = true) {
  let formatedDate = null;
  let UTCDateObj = moment.utc(UTCDateString);
  let localDateObj = UTCDateObj.tz(moment.tz.guess());

  if (includeTime) {
    formatedDate = localDateObj.format("MMM DD, yyyy h:mm:ss a");
  } else {
    formatedDate = localDateObj.format("MMM DD, yyyy");
  }

  return formatedDate;
}

export function getCurrentUTCTime() {
  return moment.utc();
}

export function isCurrentTimeGreaterThenProvidedTime(providedUTCDateString) {
  let currentDateLocal = moment();
  const currentDateUTC = currentDateLocal.utc();

  const providedDateUTC = moment.utc(providedUTCDateString);

  if (currentDateUTC.isBefore(providedDateUTC)) {
    return false;
  } else if (currentDateUTC.isAfter(providedDateUTC)) {
    return true;
  } else {
    return false;
  }
}
