import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "../../utils/constants";
import { Box, Menu, MenuItem } from "@mui/material";
import { Labels } from "../../utils/constants";
import CustomTable from "../../components/ui/table/CustomTable";
import { initializeForm } from "../forms/formSlice";
import { FormType } from "../../utils/constants";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import Skeleton from "@mui/material/Skeleton";
import { selectUserManagementTableStates } from "./userSlice";
import getUserManagementTableDataService from "../../services/user/getUserManagementTableDataService";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import getP12CertificateService from "../../services/user/getP12CertificateService";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        mt: 3,
        width: 1,
        height: "calc(100% - 30px)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function UserManagementTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, userStatus, p12Status, userError, p12Error } = useSelector(
    selectUserManagementTableStates
  );

  useEffect(() => {
    if (userStatus === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getUserManagementTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }

    if (p12Status === Status.Idle) {
      dispatch(
        getP12CertificateService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus, p12Status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userStatus === Status.Failed || p12Status === Status.Failed) {
    showBoundary(userError || p12Error);
  }

  if (userStatus !== Status.Succeeded && tableData.length === 0) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} dispatch={dispatch} />;
}

function TableComponent({ tableData, dispatch }) {
  let isAllowedToModifyUser = checkUserPermission(Permissions.ManageUser);
  let isAllowedToDeleteUser = checkUserPermission(Permissions.RegisterUser);

  const [actionMenuState, setActionMenuState] = useState({
    anchorEl: null,
    selectedRowData: null,
  });

  const rowData = tableData;

  const columnData = [
    {
      field: "email",
      headerName: Labels.EMAIL,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => renderEmailCell(params.row),
    },
    {
      field: "userName",
      headerName: Labels.USERNAME,
      flex: 1,
      minWidth: 250,
      filterable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "createdDate",
      headerName: Labels.CREATED_DATE,
      width: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return formatDateToUserTimeZone(params.value);
      },
    },
    {
      field: "isLocked",
      headerName: Labels.STATUS,
      width: 200,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "action",
      headerName: Labels.ACTION,
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    },
  ];

  function renderEmailCell(data) {
    if (data?.email) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${data.userID}`}
          >
            {data.email}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "100px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.UnLocked ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderActionCell(rowData) {
    return (
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {isAllowedToDeleteUser || isAllowedToModifyUser ? (
          <IconButton
            onClick={(event) =>
              setActionMenuState({
                anchorEl: event.target,
                selectedRowData: rowData,
              })
            }
            size="small"
            color="primary"
          >
            <MoreVertIcon />
          </IconButton>
        ) : (
          <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 1.5 }}>
            -
          </Typography>
        )}
      </Box>
    );
  }

  function handleDeleteUser() {
    let { selectedRowData } = actionMenuState;

    dispatch(
      initializeModal({
        modalType: ModalType.DELETE_USER,
        modalData: {
          userID: selectedRowData.userID,
        },
      })
    );
  }

  function handleModifyRoles() {
    let { selectedRowData } = actionMenuState;

    dispatch(
      initializeForm({
        formType: FormType.MODIFY_USER_ROLES,
        initialValue: {
          userRoles:
            selectedRowData.roles?.map((role) => ({
              id: role.id,
              label: role.label,
              value: role,
            })) ?? [],
        },
        serviceData: {
          userID: selectedRowData.userID,
        },
      })
    );
  }

  function handleLockStatus() {
    let { selectedRowData } = actionMenuState;

    if (selectedRowData.isLocked === "Unlocked") {
      dispatch(
        initializeModal({
          modalType: ModalType.LOCK_USER,
          modalData: {
            userID: selectedRowData.userID,
            lockStatus: true,
          },
        })
      );
    } else {
      dispatch(
        initializeModal({
          modalType: ModalType.UNLOCK_USER,
          modalData: {
            userID: selectedRowData.userID,
            lockStatus: false,
          },
        })
      );
    }
  }

  function ActionMenu() {
    let { selectedRowData, anchorEl } = actionMenuState;

    return (
      <Menu
        sx={{
          "& .MuiPaper-root .MuiList-root": {
            minWidth: "150px",
            py: 0,
            "& .MuiButtonBase-root": {
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "tertiary.light",
              fontSize: "0.9rem",
              fontWeight: 500,
              "&:hover": {
                bgcolor: "tertiary.main",
              },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontSize: "0.9rem",
                fontWeight: 500,
              },
            },
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setActionMenuState({ anchorEl: null, selectedRowData: null });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {isAllowedToDeleteUser && (
          <MenuItem
            onClick={() => {
              setActionMenuState({ anchorEl: null, selectedRowData: null });
              handleDeleteUser();
            }}
          >
            {Labels.DELETE}
          </MenuItem>
        )}

        {isAllowedToModifyUser && (
          <>
            <MenuItem
              onClick={() => {
                setActionMenuState({ anchorEl: null, selectedRowData: null });
                handleLockStatus();
              }}
            >
              {selectedRowData?.isLocked === Labels.LOCKED
                ? Labels.UNLOCK
                : Labels.LOCK}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActionMenuState({ anchorEl: null, selectedRowData: null });
                handleModifyRoles();
              }}
            >
              Modify Roles
            </MenuItem>
          </>
        )}
      </Menu>
    );
  }

  return (
    <>
      <CustomTable
        rowData={rowData}
        columnData={columnData}
        tableHeight={"calc(100% - 160px)"}
      />
      <ActionMenu />
    </>
  );
}
