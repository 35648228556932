import moment from "moment";
import { store } from "../../lib/redux/store";

export function formatDateForUI(date) {
  let timezone = store.getState().reports.timezone;

  return (
    moment.utc(date).tz(timezone)?.format("MMM DD, yyyy h:mm:ss a") || date
  );
}
