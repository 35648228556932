import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectApplicationDataByAppID } from "../../features/applicationManagement/applicationManagementSlice";
import getApplicationTableDataService from "../../services/applicationManagement/getApplicationTableDataService";
import ApplicationDetailTable from "../../features/applicationManagement/ApplicationDetailTable";
import { Box } from "@mui/material";
import ApplicationDetailModal from "../../features/applicationManagement/ApplicationDetailModal";
import { Skeleton } from "@mui/material";
import { resetDetailState } from "../../features/applicationManagement/applicationManagementSlice";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { useErrorBoundary } from "react-error-boundary";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 280px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function ApplicationManagementDetail({ dispatch }) {
  const { applicationID } = useParams();
  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const { isTableDataFetched, error, selectedApplicationData } = useSelector(
    (state) => selectApplicationDataByAppID(state, applicationID)
  );

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(getApplicationTableDataService());
    }

    if (!selectedApplicationData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationID, isTableDataFetched, error, selectedApplicationData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedApplicationData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <ApplicationDetailTable
        applicationDetail={selectedApplicationData}
        dispatch={dispatch}
        navigate={navigate}
      />

      <ApplicationDetailModal
        applicationData={selectedApplicationData}
        applicationID={applicationID}
        dispatch={dispatch}
      />
    </Box>
  );
}

export default function ErrorComponentWrappedApplicationManagementDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetDetailState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetDetailState())}
    >
      <ApplicationManagementDetail dispatch={dispatch} />
    </ErrorBoundary>
  );
}
