export default function LoggingSideBar({ fill, width }) {
  return (
    <svg width={width} fill={fill} viewBox="55 40 400 460">
      <path d="m160 170.7h170.7c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3h-170.7c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3z" />
      <path d="m160 229.3h170.7c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3h-170.7c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3z" />
      <path d="m160 288h170.7c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3h-170.7c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3z" />
      <path d="m160 346.7h85.3c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3h-85.3c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3z" />
      <path d="m373.3 357.3c-2.9 0-5.3 2.4-5.3 5.3v42.7c0 2.9 2.4 5.3 5.3 5.3h32c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3h-26.7v-37.4c.1-2.9-2.3-5.3-5.3-5.3z" />
      <path d="m378.7 325.9v-208.6c-0.3-1.3-0.5-2.8-1.6-3.8l-64-64c-1-1.1-2.5-1.2-3.8-1.6h-192c-2.9 0-5.3 2.4-5.3 5.3v362.8c0 2.9 2.4 5.3 5.3 5.3h128c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3h-122.6v-352h181.3v58.7c0 2.9 2.4 5.3 5.3 5.3h58.7v203.2c-41.9 2.7-74.5 37.5-74.7 79.5v12l-12.2-12.2c-2.1-2.1-5.4-2.2-7.5-0.1s-2.2 5.4-0.1 7.5l21.4 21.4c2.1 2.1 5.5 2.1 7.5 0l21.3-21.3c2.1-2.1 2.2-5.4 0.1-7.5s-5.4-2.2-7.5-0.1l-12.3 12.3v-12c0-38.3 31-69.4 69.3-69.4s69.4 31 69.4 69.3c0.3 32.7-23.6 61.6-55.5 68.1-22.8 4.7-46.4-2.4-62.9-18.9-2.1-2.1-5.4-2.2-7.5-0.1s-2.2 5.4-0.1 7.5l0.1 0.1c15 15.1 35.3 23.5 56.6 23.4 20.9 0 41.9-8.6 56.6-23.5 31.1-31.1 31.2-81.5 0.1-112.7-13.8-13.8-32-22.1-51.4-23.3zm-64-213.9v-45.8l45.8 45.8z" />
    </svg>
  );
}
