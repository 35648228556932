import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { closeForm } from "../../features/forms/formSlice";
import { closeModal } from "../../features/modals/modalSlice";

export const getSigningProjectsTableDataService = createAsyncThunk(
  "signing/getSigningProjectsTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let response = await Api.get(
        URL.Signing.SigningProjects,
        abortController && {
          signal: abortController.signal,
        }
      );

      let signingProjectsTableData =
        response.data?.map((project, index) => ({
          id: project?.id ?? index,
          projectName: project?.project_name ?? null,
          sourceCodeManagementType:
            project?.source_code_management_type ?? null,
          gitRepositoryURL: project?.git_repository_url ?? null,
          gitUserName: project?.git_username ?? null,
          gitAllowedBranches: project?.git_allowed_branches?.join("\n") ?? null,
          environmentalVariables:
            project?.environmental_variables?.join("\n") ?? null,
          buildCommand: project?.build_command ?? null,
          buildFilePath: project?.build_file_path ?? null,
          buildSignerType: project?.build_signer_type ?? null,
          buildServerType: project?.build_server_type ?? null,
          jenkinsProjectURL: project?.jenkins_project_url ?? null,
          jenkinsUserName: project?.jenkins_username ?? null,
          emailRecipients: project?.email_recipients ?? null,
        })) ?? [];

      return signingProjectsTableData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export async function createSigningProjectService(formData, _, __) {
  try {
    store.dispatch(showLoader());

    let requestBody = {
      project_name: formData.projectName,
      source_code_management_type: formData.sourceCodeManagementType,
      git_repository_url: formData.gitRepositoryURL,
      git_username: formData.gitUserName,
      git_password: formData.gitPassword,
      git_allowed_branches: formData.gitAllowedBranches
        ? formData.gitAllowedBranches.split("\n")
        : null,
      environmental_variables: formData.environmentalVariables
        ? formData.environmentalVariables.split("\n")
        : null,
      build_command: formData.buildCommand,
      build_file_path: formData.buildFilePath,
      build_signer_type: formData.buildSignerType,
      build_server_type: formData.buildServerType,
      jenkins_project_url: formData.jenkinsProjectURL,
      jenkins_username: formData.jenkinsUserName,
      jenkins_password: formData.jenkinsPassword,
      email_recipients: formData.emailRecipients,
    };

    await Api.post(URL.Signing.SigningProjects, requestBody);

    store.dispatch(getSigningProjectsTableDataService());

    store.dispatch(closeForm());

    Notification.success(NotificationLabels.SIGNING_PROJECT_SAVED);
  } catch (error) {
    Notification.error(
      NotificationLabels.SIGNING_PROJECT_SAVING_FAILED +
        " : " +
        error.response?.data?.details,
      4000
    );
  } finally {
    store.dispatch(closeLoader());
  }
}

export async function updateSigningProjectService(formData, _, serviceData) {
  try {
    store.dispatch(showLoader());

    let requestBody = {
      id: serviceData.projectID,
      project_name: formData.projectName,
      source_code_management_type: formData.sourceCodeManagementType,
      git_repository_url: formData.gitRepositoryURL,
      git_username: formData.gitUserName,
      git_password: formData.gitPassword,
      git_allowed_branches: formData.gitAllowedBranches
        ? formData.gitAllowedBranches.split("\n")
        : null,
      environmental_variables: formData.environmentalVariables
        ? formData.environmentalVariables.split("\n")
        : null,
      build_command: formData.buildCommand,
      build_file_path: formData.buildFilePath,
      build_signer_type: formData.buildSignerType,
      build_server_type: formData.buildServerType,
      jenkins_project_url: formData.jenkinsProjectURL,
      jenkins_username: formData.jenkinsUserName,
      jenkins_password: formData.jenkinsPassword,
      email_recipients: formData.emailRecipients,
    };

    await Api.put(
      URL.Signing.SigningProjects + "/" + serviceData.projectID,
      requestBody
    );

    store.dispatch(getSigningProjectsTableDataService());

    Notification.success(NotificationLabels.SIGNING_PROJECT_SAVED);
    store.dispatch(closeForm());
  } catch (error) {
    Notification.error(
      NotificationLabels.SIGNING_PROJECT_SAVING_FAILED +
        " : " +
        error.response?.data?.details,
      4000
    );
  } finally {
    store.dispatch(closeLoader());
  }
}

export async function deleteSigningProjectService({ signingProjectId }) {
  try {
    store.dispatch(showLoader());

    await Api.delete(URL.Signing.SigningProjects + "/" + signingProjectId);

    store.dispatch(
      getSigningProjectsTableDataService({ abortController: null })
    );
    store.dispatch(closeLoader());
    Notification.success(NotificationLabels.SIGNING_PROJECT_DELETED);
    store.dispatch(closeModal());
  } catch (error) {
    Notification.error(
      NotificationLabels.SIGNING_PROJECT_DELETION_FAILED +
        " : " +
        error.response?.data?.details,
      4000
    );
  }
}
