import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { store } from "../../../lib/redux/store";

export default function FormHeader({
  title,
  dynamicTitleCreator,
  CustomHeader,
}) {
  return (
    <DialogTitle
      component={"div"}
      sx={{
        px: 3.5,
        py: 1.75,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h6" sx={{ color: "primary.main" }}>
        {dynamicTitleCreator ? dynamicTitleCreator(store) : title}
      </Typography>

      {CustomHeader && <CustomHeader />}
    </DialogTitle>
  );
}
