// MUI
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

// ----#CustomPagination----
export default function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  //  Mixins
  const customPaginationMixin = {
    width: "100%",
    ".MuiPagination-ul": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 1,
    },
    ".MuiPagination-ul li:first-of-type button, .MuiPagination-ul li:last-of-type button":
      {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        px: 2,
        border: "1px solid #D0D5DD",
        borderRadius: "5px",
        position: "relative",
      },
    ".MuiPagination-ul li:first-of-type": {
      marginRight: "auto",
    },
    ".MuiPagination-ul li:last-of-type": {
      marginLeft: "auto",
    },
    ".MuiPagination-ul li:first-of-type button::after": {
      content: '"Previous"',
      fontWeight: 600,
    },
    ".MuiPagination-ul li:last-of-type button::before": {
      content: '"Next"',
      fontWeight: 600,
    },
    ".MuiPaginationItem-page": {
      borderRadius: "5px",
    },
  };

  return (
    <Pagination
      sx={{
        ...customPaginationMixin,
      }}
      color="tertiary"
      variant="text"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
// ----#END----
