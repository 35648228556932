// React
import { useRef, useEffect } from "react";

// MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

// Redux
import { useSelector } from "react-redux";
import { selectAdditionalDetailFromKeyID } from "./keysAndCertsSlice";

// Utils
import { Labels, Status } from "../../utils/constants";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";

// Services
import getApplicationDetailFromKeyID from "../../services/keysAndCertificates/getAdditionalDetailFromKeyID";
import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import { useErrorBoundary } from "react-error-boundary";

const KeyAndCertDetailModal = function ({ keyID, tableData, dispatch }) {
  const { showBoundary } = useErrorBoundary();

  const abortControllerRef = useRef(null);

  const { additionalDetail, status, error } = useSelector(
    selectAdditionalDetailFromKeyID
  );

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getApplicationDetailFromKeyID({
          abortController: abortControllerRef.current,
          keyID: keyID,
          keyAlias: tableData.keyAlias,
          certificateName: tableData.certificateName,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.APPLICATION,
        value: additionalDetail?.applicationName ?? "-",
        fetchValue: true,
      },
      {
        label: Labels.UID,
        value: tableData?.keyUID ?? "-",
      },
      { label: Labels.ALGORITHM, value: tableData?.algorithm ?? "-" },
      { label: Labels.KEY_ALIAS, value: tableData?.keyAlias ?? "-" },
      {
        label: Labels.CERTIFICATE_NAME,
        value: tableData?.certificateName ?? "-",
      },
      { label: Labels.SIZE, value: tableData?.size ?? "-" },
      {
        label: Labels.VALID_FROM,
        value: tableData?.validFrom
          ? formatDateToUserTimeZone(tableData.validFrom)
          : "-",
      },
      {
        label: Labels.VALID_TILL,
        value: tableData?.validTill
          ? formatDateToUserTimeZone(tableData.validTill)
          : "-",
      },
      { label: Labels.COMMON_NAME, value: tableData?.commonName ?? "-" },
      {
        label: Labels.EMAIL,
        value: tableData?.email ?? "-",
      },
      {
        label: Labels.STATUS,
        component: renderStatusCell(tableData.status),
      },
      { label: Labels.ORGANIZATION, value: tableData?.organization ?? "-" },
      { label: Labels.STATE_PROVINCE, value: tableData?.state ?? "-" },
      { label: Labels.COUNTRY_CODE, value: tableData?.country ?? "-" },
      { label: Labels.KEY_USAGE, value: tableData?.keyUsage ?? "-" },
      {
        label: Labels.CERTIFICATE_DATA,
        component: additionalDetail?.certificateData ? (
          <JsonView
            data={additionalDetail.certificateData}
            shouldExpandNode={false}
            style={defaultStyles}
          />
        ) : (
          "-"
        ),
        fetchValue: true,
        fullWidth: true,
      },
    ];

    return arr;
  }

  return (
    <Box
      className="custom-scroll-bar"
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 280px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            More Information
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid item xs={12} {...(!item?.fullWidth && { md: 6, lg: 4 })}>
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              {status !== Status.Succeeded && item?.fetchValue === true ? (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "50%",
                    height: "20px",
                    opacity: 0.5,
                  }}
                />
              ) : (
                <>
                  {item.component ? (
                    item.component
                  ) : (
                    <Typography variant="subtitle1">{item.value}</Typography>
                  )}
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyAndCertDetailModal;
