// MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Utils
import { FormLabels } from "../../utils/constants";

const SigningProjectDetailModal = function ({ tableData }) {
  function formatDataForUI() {
    let arr = [
      {
        label: FormLabels.ID,
        value: tableData?.id ?? "-",
      },
      {
        label: FormLabels.PROJECT_NAME,
        value: tableData?.projectName ?? "-",
      },
      {
        label: FormLabels.SOURCE_CODE_MANAGEMENT_TYPE,
        value: tableData?.sourceCodeManagementType ?? "-",
      },
      {
        label: FormLabels.GIT_REPOSITORY_URL,
        value: tableData?.gitRepositoryURL ?? "-",
      },
      {
        label: FormLabels.GIT_USERNAME,
        value: tableData?.gitUserName ?? "-",
      },
      {
        label: FormLabels.GIT_ALLOWED_BRANCHES,
        value: tableData?.gitAllowedBranches ?? "-",
      },
      {
        label: FormLabels.ENVIRONMENTAL_VARIABLES,
        value: tableData?.environmentalVariables ?? "-",
      },
      {
        label: FormLabels.BUILD_COMMAND,
        value: tableData?.buildCommand ?? "-",
      },
      {
        label: FormLabels.BUILD_FILE_PATH,
        value: tableData?.buildFilePath ?? "-",
      },
      {
        label: FormLabels.BUILD_SIGNER_TYPE,
        value: tableData?.build_signer_type || '-',
      },

      {
        label: FormLabels.BUILD_SERVER_TYPE,
        value: tableData?.buildServerType ?? "-",
      },
      {
        label: FormLabels.JENKINS_PROJECT_URL,
        value: tableData?.jenkinsProjectURL ?? "-",
      },
      {
        label: FormLabels.JENKINS_USERNAME,
        value: tableData?.jenkinsUserName ?? "-",
      },
      {
        label: FormLabels.EMAIL_RECIPIENTS,
        value: tableData?.emailRecipients ?? "-",
      },
    ];

    return arr;
  }

  return (
    <Box
      className="custom-scroll-bar"
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 350px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            More Information
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              <Typography
                sx={{ width: "fit-content", wordBreak: "break-word" }}
                variant="subtitle1"
              >
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SigningProjectDetailModal;
