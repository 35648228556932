import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Labels, Status } from "../../utils/constants";
import {
  formatDateToUserTimeZone,
  isCurrentTimeGreaterThenProvidedTime,
} from "../../utils/dateFormatters";
import { useErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { selectP12CertificatesByUserName } from "./userSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import getP12CertificateService from "../../services/user/getP12CertificateService";
import SelectInputComponent from "../../components/ui/inputComponents/SelectInputComponent";
import { Stack, Divider, Skeleton } from "@mui/material";
import noDataFoundPng from "../../assets/images/no-data-found-png.png";

export default function UserManagementDetailModal({ userDetail }) {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const { areCertificatesFetched, error, certificates, status } = useSelector(
    (state) => selectP12CertificatesByUserName(state, userDetail.userName)
  );

  useEffect(() => {
    if (!areCertificatesFetched && !error) {
      dispatch(getP12CertificateService());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areCertificatesFetched, error]);

  if (error) {
    showBoundary(error);
  }

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "100px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.UnLocked ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.EMAIL,
        value: userDetail?.email ?? "-",
      },
      {
        label: Labels.USERNAME,
        value: userDetail?.userName ?? "-",
      },
      {
        label: Labels.CREATED_DATE,
        value: formatDateToUserTimeZone(userDetail.createdDate),
      },
      {
        label: Labels.ROLES,
        value: userDetail?.roles?.map((role) => role.label).join(", ") ?? "-",
      },
      {
        label: Labels.STATUS,
        component: renderStatusCell(userDetail?.isLocked),
      },
    ];

    return arr;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 400px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2} sx={{ mb: 6 }}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            More Information
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              {item.component ? (
                item.component
              ) : (
                <Typography variant="subtitle1">{item.value}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Divider />

      <RenderCertificates certificates={certificates} status={status} />
    </Box>
  );
}

function NoDataFound() {
  return (
    <Box sx={{ width: 1 }}>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          py: 4,
          px: 1,
          width: 1,
          height: 1,
        }}
      >
        <Box sx={{ width: "100px", aspectRatio: 1 }}>
          <img src={noDataFoundPng} alt="No Data Found" />
        </Box>
        <Typography
          variant="h6"
          sx={{ fontSize: "1.25rem", fontWeight: 700 }}
          color="primary.main"
        >
          No Data Found!
        </Typography>
      </Stack>
    </Box>
  );
}

function P12CardRenderer({ certificateDetail }) {
  return (
    <Grid item xs={12} md={6} lg={4} key={certificateDetail.expires_at}>
      <Box
        sx={{
          width: 1,
          p: 2,
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.2)",
          borderRadius: "5px",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
            columnGap: 2,
            rowGap: 2,
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 700,
                mb: "5px",
              }}
            >
              Created At
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
            >
              {formatDateToUserTimeZone(certificateDetail.created_at)}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 700,
                mb: "5px",
              }}
            >
              Expires At
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.9rem", fontWeight: 500 }}
            >
              {formatDateToUserTimeZone(certificateDetail.expires_at)}
            </Typography>
          </Box>
        </Stack>

        <Box>
          <Typography
            variant="h6"
            sx={{
              fontSize: "0.9rem",
              fontWeight: 700,
              mb: "5px",
            }}
          >
            Thumbprint
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "0.9rem",
              fontWeight: 500,
              wordBreak: "break-word",
            }}
          >
            {certificateDetail.thumbprint}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

function LaodingSkeleton() {
  return (
    <>
      {Array.from({ length: 3 }, (_, index) => index).map((_, index) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: "5px", width: 1, height: 200 }}
            />
          </Grid>
        );
      })}
    </>
  );
}

function RenderCertificates({ certificates, status }) {
  const [filterType, setFilterType] = useState("Valid");
  const [displayedCerts, setDisplayedCerts] = useState([]);

  useEffect(() => {
    if (certificates.length > 0) {
      let currentCertificates = [...certificates];

      if (filterType === "Valid") {
        if (
          isCurrentTimeGreaterThenProvidedTime(
            currentCertificates[currentCertificates.length - 1].expires_at
          )
        ) {
          setDisplayedCerts([]);
        } else {
          setDisplayedCerts([
            currentCertificates[currentCertificates.length - 1],
          ]);
        }
      } else if (filterType === "Expired") {
        let expiredCerts = [];

        currentCertificates.forEach((cert) => {
          if (isCurrentTimeGreaterThenProvidedTime(cert.expires_at)) {
            expiredCerts.push(cert);
          }
        });

        setDisplayedCerts(expiredCerts);
      } else if (currentCertificates.length > 1 && filterType === "Disabled") {
        let availableCerts = [...currentCertificates].slice(0, -1);

        let disabledCerts = [];

        availableCerts.forEach((cert) => {
          if (!isCurrentTimeGreaterThenProvidedTime(cert.expires_at)) {
            disabledCerts.push(cert);
          }
        });

        setDisplayedCerts(disabledCerts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, status]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "end",
          mb: 2,
        }}
      >
        <Typography color="primary.main" variant="h6">
          SSL Authentication Certificate
        </Typography>

        <Box sx={{ width: 150 }}>
          <SelectInputComponent
            id="filterType"
            label={null}
            value={filterType}
            setFieldValue={(_, value) => {
              setFilterType(value);
            }}
            optionIsString="true"
            options={["Valid", "Disabled", "Expired"]}
            size="small"
          />
        </Box>
      </Grid>
      {status === Status.Loading ? (
        <Grid container item spacing={6} sx={{ mb: 2 }}>
          <LaodingSkeleton />
        </Grid>
      ) : (
        <>
          {certificates.length === 0 ? (
            <NoDataFound />
          ) : (
            <Grid container item spacing={6} sx={{ mb: 2 }}>
              {displayedCerts.length === 0 ? (
                <NoDataFound />
              ) : (
                <>
                  {displayedCerts?.map((certificate) => (
                    <P12CardRenderer certificateDetail={certificate} />
                  ))}
                </>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
