import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { closeForm } from "../../features/forms/formSlice";
import getEmailConfigTableDataService from "./getEmailConfigTableData";

export default async function manageEmailConfig(formData, _, serviceData) {
  try {
    store.dispatch(showLoader());

    let userId = store.getState().auth.userID;

    let request = null;

    if (serviceData.newConfig) {
      let requestBody = {
        host: formData.host,
        port: formData.port,
        username: formData.userName,
        password: formData.password,
        email_type: formData.configType,
        created_user: userId,
      };

      request = Api.post(URL.Email.ManageConfig, requestBody);
    } else {
      let requestBody = {
        id: serviceData.configID,
        host: formData.host,
        port: formData.port,
        username: formData.userName,
        password: formData.password,
        email_type: formData.configType,
        modified_by: userId,
      };

      request = Api.put(
        URL.Email.ManageConfig + serviceData.configID + "/",
        requestBody
      );
    }

    await request;

    store.dispatch(getEmailConfigTableDataService());
    store.dispatch(closeForm());

    if (serviceData.newConfig) {
      Notification.success(NotificationLabels.CONFIG_ADDED);
    } else {
      Notification.success(NotificationLabels.CONFIG_UPDATED);
    }
  } catch (error) {
    if (serviceData.newConfig) {
      Notification.error(NotificationLabels.CONFIG_ADDITION_FAILED);
    } else {
      Notification.error(NotificationLabels.CONFIG_UPDATE_FAILED);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
