import Backdrop from "@mui/material/Backdrop";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

export default function Loader() {
  const showLoader = useSelector((state) => state.loader.showLoader);

  return (
    <Backdrop
      sx={{
        zIndex: 1301,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={showLoader}
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "background.paper",
          animationDuration: "550ms",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={60}
        thickness={4}
      />
    </Backdrop>
  );
}
