import { createSelector, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";

import getSigningRequestsTableDataService from "../../services/signingRequests/getSigningRequestsTableDataService";

const initialState = {
  status: Status.Idle,
  error: null,
  tableData: [],
  isRequestApprovalForm: null,
  navigateBack: false,
};

export const signingRequestsSlice = createSlice({
  name: "signingRequests",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = Status.Idle;
      state.error = null;
      state.navigateBack = false;
    },
    setRequestApprovalFormState: (state, action) => {
      state.isRequestApprovalForm = action.payload;
    },
    navigateBack: (state) => {
      state.navigateBack = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // For Signing Requests Table Data
      .addCase(getSigningRequestsTableDataService.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(
        getSigningRequestsTableDataService.fulfilled,
        (state, action) => {
          state.tableData = action.payload;
          state.status = Status.Succeeded;
        }
      )
      .addCase(getSigningRequestsTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.tableData = [];
          state.status = Status.Failed;
          state.error = action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectTableData = createSelector(
  [
    (state) => state.signingRequests.tableData,
    (state) => state.signingRequests.status,
    (state) => state.signingRequests.error,
  ],
  (tableData, status, error) => ({ tableData, status, error })
);

export const selectRequestDetailByReqID = createSelector(
  [
    (state) => state.signingRequests.tableData,
    (state) => state.signingRequests.status,
    (state) => state.signingRequests.error,
    (_, requestID) => requestID,
  ],
  (tableData, status, error, requestID) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedReqData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedReqData = tableData.filter(
        (data) => data.requestID === Number(requestID)
      );

      if (selectedReqData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedReqData: selectedReqData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedReqData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedReqData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedReqData: null,
      };
    }
  }
);

export const { resetState, setRequestApprovalFormState, navigateBack } =
  signingRequestsSlice.actions;

export default signingRequestsSlice.reducer;
