import React from "react";

export default function DocIcon({ width, fill }) {
  return (
    <svg width={width} fill={fill} viewBox="0 0 500 500">
      <path d="m385.42 93.75h41.67c4.17 0 8.33-2.08 9.38-6.25 2.08-4.17 1-8.33-2.08-11.46l-62.5-62.5c-3.12-3.13-7.29-4.17-11.46-2.08s-6.25 5.21-6.25 9.37v41.67c-.01 17.71 13.53 31.25 31.24 31.25z" />
      <path d="m437.5 112.5a37 37 0 0 1-10.42 2.08h-41.66a51.58 51.58 0 0 1-52.09-52.08v-41.67a37 37 0 0 1 2.08-10.42h-241.66c-17.75 0.01-31.25 13.59-31.25 31.26v114.58h375z" />
      <path d="m62.5 458.33c0 17.67 13.5 31.25 31.25 31.25h312.5c17.71 0 31.25-13.54 31.25-31.25v-72.91h-375z" />
      <circle cx="250" cy="270.83" r="20.83" />
      <path d="m145.83 250h-20.83v41.67h20.83a20.83 20.83 0 1 0 0-41.67z" />
      <path d="m437.5 177.08h-375a20.89 20.89 0 0 0-20.83 20.83v145.84a20.89 20.89 0 0 0 20.83 20.83h375a20.89 20.89 0 0 0 20.83-20.83v-145.83a20.89 20.89 0 0 0-20.83-20.84zm-291.67 135.42h-31.25c-6.25 0-10.42-4.17-10.42-10.42v-62.5c0-6.25 4.17-10.42 10.42-10.42h31.25a41.67 41.67 0 0 1 0 83.33zm104.17 0a41.67 41.67 0 1 1 41.67-41.67 41.79 41.79 0 0 1-41.67 41.67zm89.58-27.08c8.33 8.33 21.88 8.33 29.17 0a10.31 10.31 0 0 1 14.58 14.58 41.13 41.13 0 0 1-29.17 12.5c-10.42 0-21.87-4.17-29.17-12.5a41.13 41.13 0 0 1-12.5-29.17c0-10.42 4.17-21.88 12.5-29.17 15.63-15.62 42.71-15.62 59.38 0a10.31 10.31 0 0 1-14.58 14.58c-8.33-8.33-21.87-8.33-29.17 0a20.57 20.57 0 0 0-6.25 14.58c0.01 5.18 1.05 10.43 5.21 14.6z" />
    </svg>
  );
}
