import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { Status } from "../../utils/constants";
import getApplicationListService from "../../services/reports/getApplicationListService";
import { createSelector } from "reselect";
import getUserListService from "../../services/reports/getUserListService";
import getReportsTableDataService from "../../services/reports/getReportsTableDataService";

function getInitialDateRange() {
  const currentDate = moment().utc().format("YYYY-MM-DD");
  const sevenDaysAgo = moment().subtract(7, "days").utc().format("YYYY-MM-DD");

  return { fromDate: sevenDaysAgo, toDate: currentDate };
}

const initialState = {
  status: {
    userOptions: Status.Idle,
    applicationOptions: Status.Idle,
    tableData: Status.Idle,
  },
  error: {
    userOptions: null,
    applicationOptions: null,
    tableData: null,
  },
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/GMT",
  dateRange: getInitialDateRange(),
  reportType: "Audit Trial",
  category: null,
  applications: [],
  users: [],
  userOptions: [],
  applicationOptions: [],
  tableData: [],
  tableDataType: "Audit Trial",
  selectedReportData: null,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportFilters: (state, action) => {
      let { fromDate, toDate, category, reportType, applications, users } =
        action.payload;
      state.dateRange = { fromDate, toDate };
      state.category = category;
      state.applications = applications;
      state.reportType = reportType;
      state.users = users;
    },
    setTableDataType: (state, action) => {
      state.tableDataType = action.payload;
    },
    fetchReportsTableData: (state) => {
      state.status.tableData = Status.Idle;
    },
    setSelectedReportData: (state, action) => {
      state.selectedReportData = action.payload;
    },
    resetSelectedReportData: (state) => {
      state.selectedReportData = null;
    },
    resetReportsState: (state) => {
      state.status.userOptions = Status.Idle;
      state.status.applicationOptions = Status.Idle;
      state.status.tableData = Status.Idle;

      state.error.userOptions = null;
      state.error.applicationOptions = null;
      state.error.tableData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // For Application List
      .addCase(getApplicationListService.pending, (state) => {
        state.status.applicationOptions = Status.Loading;
      })
      .addCase(getApplicationListService.fulfilled, (state, action) => {
        state.applicationOptions = action.payload;
        state.status.applicationOptions = Status.Succeeded;
      })
      .addCase(getApplicationListService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.applicationOptions = [];
          state.status.applicationOptions = Status.Failed;
          state.error.applicationOptions = action.error;
        }
      })
      // For User List
      .addCase(getUserListService.pending, (state) => {
        state.status.userOptions = Status.Loading;
      })
      .addCase(getUserListService.fulfilled, (state, action) => {
        state.userOptions = action.payload;
        state.status.userOptions = Status.Succeeded;
      })
      .addCase(getUserListService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.userOptions = [];
          state.status.userOptions = Status.Failed;
          state.error.userOptions = action.error;
        }
      })
      // For Table Data
      .addCase(getReportsTableDataService.pending, (state) => {
        state.status.tableData = Status.Loading;
      })
      .addCase(getReportsTableDataService.fulfilled, (state, action) => {
        state.tableData = action.payload;
        state.status.tableData = Status.Succeeded;
      })
      .addCase(getReportsTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.tableData = [];
          state.status.tableData = Status.Failed;
          state.error.tableData = action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectReportsTableData = createSelector(
  (state) => state.reports.tableData,
  (state) => state.reports.tableDataType,
  (state) => state.reports.status.tableData,
  (state) => state.reports.error.tableData,
  (tableData, tableDataType, status, error) => {
    return {
      tableData,
      tableDataType,
      status,
      error,
    };
  }
);

export const selectApplicationList = createSelector(
  (state) => state.reports.applicationOptions,
  (state) => state.reports.status.applicationOptions,
  (state) => state.reports.error.applicationOptions,
  (applicationOptions, status) => {
    return {
      applicationOptions,
      status,
    };
  }
);

export const selectUserList = createSelector(
  (state) => state.reports.userOptions,
  (state) => state.reports.status.userOptions,
  (state) => state.reports.error.userOptions,
  (userOptions, status) => {
    return {
      userOptions,
      status,
    };
  }
);

export const {
  setReportFilters,
  setTableDataType,
  fetchReportsTableData,
  resetSelectedReportData,
  setSelectedReportData,
  resetReportsState,
} = reportsSlice.actions;

export default reportsSlice.reducer;
