import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Provider } from "react-redux";
import { store } from "./lib/redux/store";
import App from "./app";
import { SnackbarProvider } from "notistack";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useState, useEffect } from "react";
import axios from "axios";
import URL from "./utils/url";
import { BackendSettings } from "./utils/constants";
import { LogLevel } from "@azure/msal-browser";
import ErrorComponent from "./components/ui/ErrorComponent";

const Root = () => {
  const [msalConfig, setMsalConfig] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMsalConfig = async () => {
      try {
        const response = await axios.get(
          BackendSettings.BaseURL + URL.Auth.ADConfig
        );

        let configs = response.data?.filter(
          (data) => !data.Is_deleted && data.AD_Type === "azure"
        );

        if (configs?.length > 0) {
          let configDetail = configs[0];
          setMsalConfig({
            auth: {
              clientId: configDetail?.ClientId ?? null,
              authority: configDetail?.ConnectionURL ?? null,
              redirectUri: "/",
              postLogoutRedirectUri: "/",
            },
            cache: {
              cacheLocation: "sessionStorage",
              storeAuthStateInCookie: false,
            },
            // system: {
            //   loggerOptions: {
            //     loggerCallback: (level, message, containsPii) => {
            //       if (containsPii) {
            //         return;
            //       }
            //       switch (level) {
            //         case LogLevel.Error:
            //           console.error(message);
            //           return;
            //         case LogLevel.Info:
            //           console.info(message);
            //           return;
            //         case LogLevel.Verbose:
            //           console.debug(message);
            //           return;
            //         case LogLevel.Warning:
            //           console.warn(message);
            //           return;
            //       }
            //     },
            //     piiLoggingEnabled: false,
            //   },
            // },
          });
        } else {
          throw new Error("Failed to setup Authentication!");
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchMsalConfig();
  }, []);

  if (error) {
    let message = error.response?.data?.detail ?? null;
    let statusCode = error.response?.status ?? 500;
    let statusText = error.response?.statusText ?? error.message;

    // Removing Loader
    let loader = document.getElementById("loader-wrapper");
    loader.classList.add("remove-loader");

    return (
      <ErrorComponent
        error={{ message, statusCode, statusText }}
        resetErrorBoundary={() => window.location.reload()}
      />
    );
  }

  if (!msalConfig) {
    return null;
  }

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <App />
        </SnackbarProvider>
      </Provider>
    </MsalProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
