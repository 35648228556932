import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B2C58",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#78A1DE",
      dark: "#375CBD",
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: "#ECF0F2",
      light: "#F6F7FB",
    },
    background: {
      default: "#F3F3F3",
      paper: "#FFFFFF",
    },
    success: {
      main: "#E2FDD5",
      dark: "#67A749",
    },
    error: {
      main: "#FFCFCC",
      dark: "#FF857E",
    },
    divider: "#c4c4c4",
    text: {
      placeholder: "#989898",
    },
  },
  typography: {
    fontFamily: 'Nunito, "Open Sans", sans-serif',
    h6: {
      fontSize: "1.25rem",
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "0.3em 1.25em",
          width: "fit-content",
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            fontSize: "0.875rem",
          },
        },
        {
          props: { size: "medium" },
          style: {
            fontSize: "0.95rem",
          },
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "1rem",
          },
        },
      ],
    },
  },
});

export default theme;
