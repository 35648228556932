import { closeForm } from "../../features/forms/formSlice";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";

export default async function generateCSR(formData, _, __) {
  try {
    store.dispatch(showLoader());

    const domainName = window.location.hostname;

    let reqBody = {
      domain: domainName,
      country_code: formData.countryCode.toUpperCase(),
      state: formData.state,
      locality: formData.locality,
      organisation: formData.organization,
      organisation_unit: formData.organizationUnit,
      common_name: formData.commonName,
    };

    const response = await Api.post(URL.SSLManagement.GENERATE_CSR, reqBody);

    let fileName = `${formData.commonName}.csr`;

    let file = new Blob([response.data.csr], {
      type: "text/csr;charset=utf-8",
    });
    let csrFile = window.URL.createObjectURL(file);
    let tempLink = document.createElement("a");
    tempLink.href = csrFile;
    tempLink.setAttribute("download", fileName);
    tempLink.click();

    Notification.success("CSR Generated Successfully");
    store.dispatch(closeForm());
  } catch (error) {
    Notification.error("Failed to generate CSR");
  } finally {
    store.dispatch(closeLoader());
  }
}
