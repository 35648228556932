import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import getUserManagementTableDataService from "./getUserManagementTableDataService";
import { navigateBack } from "../../features/user/userSlice";
import { initiateLogout } from "../../features/auth/authSlice";

export default async function deleteUserService({ userID }) {
  try {
    store.dispatch(showLoader());

    const response = await Api.delete(URL.User.DeleteUser + userID + "/");

    let currentUserID = store.getState().auth.userID;

    if (response.status !== 204) {
      throw Error("Falied to delete user");
    }

    if (userID === currentUserID) {
      store.dispatch(initiateLogout());
    }

    store.dispatch(getUserManagementTableDataService());

    store.dispatch(navigateBack());

    Notification.success(NotificationLabels.USER_DELETED_SUCCESSFULLY);
  } catch (error) {
    Notification.error(NotificationLabels.FAILED_TO_DELETE_USER);
  } finally {
    store.dispatch(closeLoader());
  }
}
