import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { UserRoles } from "../../utils/enums";
import { store } from "../../lib/redux/store";
import { setUserPermissions } from "../../features/auth/authSlice";
import { Permissions } from "../../utils/enums";

export default async function getUserPermission(userRoles) {
  try {
    let userPermissionReqArr = userRoles.map((role) =>
      Api.get(URL.User.GetPermissions + role.id + "/")
    );

    let userPermissionRes = await Promise.all([...userPermissionReqArr]);

    let userPermissions = {};

    userPermissionRes.forEach((response, index) => {
      if (userRoles[index].id === UserRoles.SystemAdmin.id) {
        // Assigning Page View Permissions based on Role
        userPermissions[Permissions.ViewDashboard] = {
          status: true,
        };
        userPermissions[Permissions.ViewKeysAndCertificates] = {
          status: true,
        };
        userPermissions[Permissions.ViewApplicationManagement] = {
          status: true,
        };
        userPermissions[Permissions.ViewSigningRequest] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetup] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupEmail] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupUserManagement] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupUserPermissions] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupBackup] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupSigningProjects] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupSSLCertificate] = {
          status: true,
        };

        response.data?.forEach((permission) => {
          if (userPermissions[permission.pid] === undefined) {
            userPermissions[permission.pid] = {
              status: permission.status,
              label: permission.pname,
            };
          } else if (userPermissions[permission.pid].status === false) {
            userPermissions[permission.pid] = {
              status: permission.status,
              label: permission.pname,
            };
          }
        });
      }

      if (userRoles[index].id === UserRoles.ProjectManager.id) {
        userPermissions[Permissions.ViewDashboard] = {
          status: true,
        };
        userPermissions[Permissions.ViewApplicationManagement] = {
          status: true,
        };
        userPermissions[Permissions.ViewSigningRequest] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupEmail] = {
          status: true,
        };

        response.data?.forEach((permission) => {
          if (permission.pid >= 10 && permission.pid <= 17) {
            if (userPermissions[permission.pid] === undefined) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            } else if (userPermissions[permission.pid].status === false) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            }
          }
        });
      }

      if (userRoles[index].id === UserRoles.SecurityOfficer.id) {
        userPermissions[Permissions.ViewDashboard] = {
          status: true,
        };
        userPermissions[Permissions.ViewKeysAndCertificates] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupUserManagement] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupBackup] = {
          status: true,
        };
        userPermissions[Permissions.ViewSystemSetupSigningProjects] = {
          status: true,
        };

        response.data?.forEach((permission) => {
          if (permission.pid >= 20 && permission.pid <= 27) {
            if (userPermissions[permission.pid] === undefined) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            } else if (userPermissions[permission.pid].status === false) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            }
          }
        });
      }

      if (userRoles[index].id === UserRoles.Auditor.id) {
        userPermissions[Permissions.ViewDashboard] = {
          status: true,
        };

        response.data?.forEach((permission) => {
          if (permission.pid >= 30 && permission.pid <= 34) {
            if (userPermissions[permission.pid] === undefined) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            } else if (userPermissions[permission.pid].status === false) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            }
          }
        });
      }

      if (userRoles[index].id === UserRoles.Developer.id) {
        response.data?.forEach((permission) => {
          if (permission.pid >= 51 && permission.pid <= 52) {
            if (userPermissions[permission.pid] === undefined) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            } else if (userPermissions[permission.pid].status === false) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            }
          }
        });
      }
    });

    store.dispatch(setUserPermissions(userPermissions));
  } catch (error) {
    throw new Error(error);
  }
}
