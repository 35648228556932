import { Button, Stack, Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import {
  ApplicationManagementFormTypes,
  Permissions,
} from "../../../utils/enums";
import { Status } from "../../../utils/constants";
import { store } from "../../../lib/redux/store";
import { switchForm } from "../applicationManagementSlice";
import checkUserPermission from "../../../utils/checkUserPermission";

export default function FormHeader({ isNewApplication }) {
  const { activeForm, completedForms, allApplicationStatus } = useSelector(
    (state) => state.applicationManagement.formState
  );

  function getForms() {
    let forms = [
      {
        label: "Application Detail",
        id: ApplicationManagementFormTypes.Application,
      },
      {
        label: "Environment Detail",
        id: ApplicationManagementFormTypes.Environment,
      },
    ];

    if (checkUserPermission(Permissions.CreateTeam)) {
      forms.push({
        label: "Team Detail",
        id: ApplicationManagementFormTypes.Team,
      });
    }

    if (checkUserPermission(Permissions.ManageApplicationPolicy)) {
      forms.push({
        label: "Policy Detail",
        id: ApplicationManagementFormTypes.Policy,
      });
    }

    return forms;
  }

  function getBoxStyles(formType) {
    if (!isNewApplication || completedForms.includes(formType)) {
      return {
        backgroundColor: "success.dark",
      };
    } else if (activeForm === formType) {
      return {
        backgroundColor: "primary.main",
      };
    } else {
      return {
        backgroundColor: "background.paper",
        border: "1px solid #c0c3cc",
      };
    }
  }

  function getBoxLableText(formType) {
    if (!isNewApplication || completedForms.includes(formType)) {
      return (
        <CheckIcon
          sx={{
            fontSize: 15,
            color: "background.paper",
          }}
        />
      );
    } else {
      return (
        <Typography
          sx={{
            color: activeForm === formType && "white",
            fontSize: "0.85rem",
          }}
          variant="subtitle1"
        >
          {formType}
        </Typography>
      );
    }
  }

  function getLabelStyle(formType) {
    if (!isNewApplication || completedForms.includes(formType)) {
      return { color: "success.dark", fontWeight: 600 };
    } else if (activeForm === formType) {
      return { fontWeight: 600 };
    } else {
      return { fontWeight: 500, opacity: 0.7 };
    }
  }

  function formSwitchHandler(data) {
    let completedForms =
      store.getState().applicationManagement.formState.completedForms;
    if (completedForms.includes(data.id)) {
      store.dispatch(switchForm({ activeForm: data.id }));
    }
  }

  return (
    <Stack
      direction="row"
      sx={{
        width: 1,
        px: 2,
        py: 1,
        backgroundColor: "background.paper",
        borderRadius: "5px",
        boxShadow: "3px 0px 13px 2px rgba(0, 0, 0, 0.05)",
        alignItems: "center",
        gap: 1,
      }}
    >
      {getForms().map((data, index) => (
        <>
          <Button
            key={index}
            disabled={allApplicationStatus !== Status.Succeeded}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
              py: 1,
              px: 2,
            }}
            variant="text"
            onClick={() => formSwitchHandler(data)}
          >
            <Box
              sx={{
                width: "22px",
                height: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50px",
                ...getBoxStyles(data.id),
              }}
            >
              {getBoxLableText(data.id)}
            </Box>

            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: `${activeForm === data.id ? 500 : 300}`,
                ...getLabelStyle(data.id),
              }}
            >
              {data.label}
            </Typography>
          </Button>
          {index !== getForms().length - 1 && (
            <KeyboardArrowRightIcon
              key={index + 4}
              sx={{
                fontSize: 30,
                color: "#898EA0",
              }}
            />
          )}
        </>
      ))}
    </Stack>
  );
}
