import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { FileType, NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { closeForm } from "../../features/forms/formSlice";
import { initializeModal } from "../../features/modals/modalSlice";
import { ModalType } from "../../utils/constants";
import downloadFile from "../../utils/downloadFile";
import getP12CertificateService from "./getP12CertificateService";

function getNumberOfDays(targetDateString) {
  // const targetDate = new Date(targetDateString);
  // const currentDate = new Date();
  // const differenceMs = targetDate - currentDate;
  // const numberOfDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  // return numberOfDays;

  const targetDate = new Date(targetDateString);
  const currentDate = new Date();

  // Adjust for timezone offset
  const timezoneOffsetMs =
    targetDate.getTimezoneOffset() - currentDate.getTimezoneOffset();
  const differenceMs = targetDate - currentDate + timezoneOffsetMs * 60 * 1000;

  const numberOfDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return numberOfDays;
}

export default async function generateP12Certificate(formData) {
  try {
    store.dispatch(showLoader());

    let reqBody = {
      certificate_name: formData.certificateName,
      expiry_days: getNumberOfDays(formData.validTill),
      username: formData.userName,
      algo: "3DES",
    };

    const res = await Api.post(URL.User.GenerateP12Certificate, reqBody, {
      responseType: "arraybuffer",
      headers: {
        "Content-type": "application/json",
      },
    });

    downloadFile(res.data, formData.certificateName + ".pfx", FileType.PKCS);

    const passwordForCert = res.headers["x-certificate-password"];

    store.dispatch(
      initializeModal({
        modalType: ModalType.P12_GENERATION,
        modalData: {
          password: passwordForCert,
        },
      })
    );

    store.dispatch(closeForm());

    store.dispatch(getP12CertificateService());

    Notification.success(
      NotificationLabels.P12_CERTIFICATE_GENERATED_SUCCESSFULLY
    );
  } catch (error) {
    Notification.error(NotificationLabels.FAIL_TO_GENERATE_P12_CERTIFICATE);
  } finally {
    store.dispatch(closeLoader());
  }
}
