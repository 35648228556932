import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { Box, Paper } from "@mui/material";
import InputLabel from "./InputLabel";
import MenuItem from "@mui/material/MenuItem";

function CustomChip(props) {
  const { label, onDelete: handleDelete, ...other } = props;
  return (
    <Chip
      {...other}
      size="small"
      sx={{ fontSize: "0.75rem" }}
      key={label}
      onDelete={() => handleDelete()}
      label={label}
    />
  );
}

function getStyles(option, value, optionIsString) {
  if (!optionIsString) {
    return {
      backgroundColor:
        value.findIndex((item) => item.id === option.id) !== -1 &&
        "rgba(27, 44, 88, 0.08)",
    };
  }

  return {
    backgroundColor: value.indexOf(option) !== -1 && "rgba(27, 44, 88, 0.08)",
  };
}

export default function MultiSelectInputComponent({
  options,
  label,
  value: selectedValues,
  id,
  placeholder,
  error,
  handleBlur,
  touched,
  setFieldValue,
  optionIsString,
  size = "large",
  isDisabled = false,
}) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
    focused,
  } = useAutocomplete({
    disableCloseOnSelect: true,
    id: id,
    value: selectedValues,
    multiple: true,
    options: options,
    getOptionLabel: (option) => (optionIsString ? option : option.label),
    onChange: (_, value) => setFieldValue(id, value),
    ...(!optionIsString && {
      isOptionEqualToValue: (option, value) => option.id === value.id && true,
    }),
  });

  return (
    <Box sx={{ position: "relative" }}>
      <Box {...getRootProps()}>
        <InputLabel
          {...getInputLabelProps()}
          label={label}
          error={error}
          touched={touched}
          size={size}
        />
        <Paper
          elevation={0}
          sx={{
            px: 2,
            py: 1.5,
            ...(size === "small" && { px: 1.5, py: 0.75 }),
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
          }}
          ref={setAnchorEl}
          onBlur={handleBlur}
        >
          {value?.length > 0 && (
            <Box
              className="custom-scroll-bar horizontal"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                overflow: "auto",
                pb: "5px",
              }}
            >
              {value.map((option, index) => (
                <CustomChip
                  label={optionIsString ? option : option.label}
                  {...getTagProps({ index })}
                />
              ))}
            </Box>
          )}
          <TextField
            id={id}
            name={id}
            variant="standard"
            {...getInputProps()}
            placeholder={placeholder}
            disabled={isDisabled}
            sx={{
              input: {
                ...(size === "small" && { fontSize: "0.8rem" }),
              },
            }}
          />
        </Paper>
      </Box>
      {groupedOptions.length > 0 ? (
        <Box
          {...getListboxProps()}
          className="custom-scroll-bar"
          sx={{
            position: "absolute",
            top: "100%",
            width: "calc(100% - 32px)",
            maxHeight: "200px",
            backgroundColor: "background.paper",
            py: 0.5,
            borderRadius: "5px",
            boxShadow: "3px 3px 13px 2px rgba(0, 0, 0, 0.10)",
            mt: -1.25,
            mx: 2,
            zIndex: 2,
            overflowY: "auto",
          }}
        >
          {groupedOptions.map((option, index) => (
            <MenuItem
              style={getStyles(option, value, optionIsString)}
              {...getOptionProps({ option, index })}
              sx={{ ...(size === "small" && { fontSize: "0.8rem" }) }}
            >
              {optionIsString ? option : option.label}
            </MenuItem>
          ))}
        </Box>
      ) : focused ? (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            width: "calc(100% - 32px)",
            backgroundColor: "background.paper",
            py: 1,
            borderRadius: "5px",
            boxShadow: "3px 3px 13px 2px rgba(0, 0, 0, 0.10)",
            mt: -1.25,
            mx: 2,
            zIndex: 2,
          }}
        >
          <MenuItem sx={{ ...(size === "small" && { fontSize: "0.8rem" }) }}>
            No Data Found!
          </MenuItem>
        </Box>
      ) : null}
    </Box>
  );
}
