import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import BackupTable from "../../features/backup/BackupTable";
import takeBackup from "../../services/backup/takeBackup";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import { resetState } from "../../features/backup/backupSlice";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";

function Header() {
  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "flex-end", alignItems: "center" }}
    >
      <Button
        variant="contained"
        size="large"
        sx={{ borderRadius: "5px" }}
        onClick={() => takeBackup()}
      >
        Take Backup
      </Button>
    </Stack>
  );
}

export default function SystemSetupBackup() {
  const isUserAllowedToManageBackup = checkUserPermission(
    Permissions.ManageBackup
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <Stack sx={{ width: 1, height: 1 }}>
        {isUserAllowedToManageBackup && <Header />}

        <BackupTable />
      </Stack>
    </ErrorBoundary>
  );
}
