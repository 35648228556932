import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { store } from "../../lib/redux/store";

const getSigningRequestsTableDataService = createAsyncThunk(
  "signingRequests/getSigningRequestsTableData",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;
      let { isUserProjectManager } = serviceConfig;

      let request = null;

      if (isUserProjectManager) {
        let userID = store.getState().auth.userID;

        request = await Api.get(
          URL.SigningRequests.TableDataByUserID + userID + "/",
          abortController && {
            signal: abortController.signal,
          }
        );
      } else {
        request = await Api.get(
          URL.SigningRequests.TableData,
          abortController && {
            signal: abortController.signal,
          }
        );
      }

      let response =
        request.data?.map((data, index) => ({
          id: data?.id ?? index,
          requestID: data?.signingRequest_id ?? null,
          jobID: data?.id ?? null,
          environmentName: data?.environmentName ?? null,
          applicationName: data?.applicationName ?? null,
          submittedOn: data?.created_date ?? null,
          status: data.status,
          comment: data?.comment ?? data?.comments ?? null,
          approvalUserID: data?.approvalUser_id ?? null,
          approvalStatus:
            data.userApprovalStatus === undefined
              ? false
              : data.userApprovalStatus,
        })) || [];

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getSigningRequestsTableDataService;
