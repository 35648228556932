import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { resetTemplateState } from "../../features/email/emailSlice";

export default async function archiveEmailTemplate({ templateID }) {
  try {
    store.dispatch(showLoader());

    let requestBody = {
      id: templateID,
      Is_deleted: true,
    };

    await Api.patch(URL.Email.ManageTemplate + templateID + "/", requestBody);

    Notification.success(NotificationLabels.TEMPLATE_ARCHIVED);
    store.dispatch(resetTemplateState());
  } catch (error) {
    Notification.error(NotificationLabels.TEMPLATE_ARCHIVE_FAILED);
  } finally {
    store.dispatch(closeLoader());
  }
}
