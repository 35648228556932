import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { BackendSettings } from "../../utils/constants";

const getSigningToolServiceData = createAsyncThunk(
  "signingTools/getSigningToolServiceData",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      const userPermissionRequest = await Api.get(
        URL.SigningTools.GetUserPermissions,
        abortController && {
          signal: abortController.signal,
        }
      );

      let requiredEndpointInPermission =
        "/api/system_setup/downloadclienttool/";

      let isUserAllowedToDownloadSigningTools =
        userPermissionRequest.data?.some((permission) => {
          return (
            permission.endpoints === requiredEndpointInPermission &&
            permission.status
          );
        }) || false;

      if (!isUserAllowedToDownloadSigningTools) {
        throw new Error(
          `Your token is invalid or you don't have permission to access Signing Tools.`
        );
      }

      const signingToolsDataRequest = await Api.get(
        URL.SigningTools.GetToolData,
        abortController && {
          signal: abortController.signal,
        }
      );

      const response =
        signingToolsDataRequest.data?.map((toolData) => ({
          fileURL: toolData?.Filename
            ? BackendSettings.BaseURL +
              URL.SigningTools.DownloadTool +
              "/?filename=" +
              toolData.Filename
            : null,
          os: toolData.Platform ?? null,
          toolName: toolData.Title ?? null,
          toolVersion: toolData.Version ?? null,
          toolDescription: toolData.content ?? null,
        })) ?? [];

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getSigningToolServiceData;
