import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { createSelector } from "reselect";
import getUserManagementTableDataService from "../../services/user/getUserManagementTableDataService";
import getP12CertificateService from "../../services/user/getP12CertificateService";
import getRolePermissionService from "../../services/user/getRolePermissionService";

const initialState = {
  status: {
    managementTableData: Status.Idle,
    p12CertificateData: Status.Idle,
    rolePermission: Status.Idle,
  },
  error: {
    managementTableData: null,
    p12CertificateData: null,
    rolePermission: null,
  },
  managementTableData: [],
  p12CertificateData: [],
  searchedUserName: "",
  filterRoles: [],
  rolePermission: [],
  navigateBack: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserManagementState: (state) => {
      state.status.managementTableData = Status.Idle;
      state.error.managementTableData = null;
      state.navigateBack = false;
      state.status.p12CertificateData = Status.Idle;
      state.error.p12CertificateData = null;
    },
    resetPermissionState: (state) => {
      state.rolePermission = [];
      state.status.rolePermission = Status.Idle;
      state.error.rolePermission = null;
    },
    setSearchedUserName: (state, action) => {
      state.searchedUserName = action.payload;
    },
    setFilterRoles: (state, action) => {
      state.filterRoles = action.payload;
    },
    navigateBack: (state) => {
      state.navigateBack = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // For User Management Table Data
      .addCase(getUserManagementTableDataService.pending, (state) => {
        state.status.managementTableData = Status.Loading;
      })
      .addCase(getUserManagementTableDataService.fulfilled, (state, action) => {
        state.managementTableData = action.payload;
        state.status.managementTableData = Status.Succeeded;
      })
      .addCase(getUserManagementTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.managementTableData = [];
          state.status.managementTableData = Status.Failed;
          state.error.managementTableData =
            action.payload ?? "something went wrong";
        }
      })
      // For P12 Certificates
      .addCase(getP12CertificateService.pending, (state) => {
        state.status.p12CertificateData = Status.Loading;
      })
      .addCase(getP12CertificateService.fulfilled, (state, action) => {
        state.p12CertificateData = action.payload;
        state.status.p12CertificateData = Status.Succeeded;
      })
      .addCase(getP12CertificateService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.p12CertificateData = [];
          state.status.p12CertificateData = Status.Failed;
          state.error.p12CertificateData =
            action.payload ?? "something went wrong";
        }
      })
      // For User Permissions
      .addCase(getRolePermissionService.pending, (state) => {
        state.status.rolePermission = Status.Loading;
      })
      .addCase(getRolePermissionService.fulfilled, (state, action) => {
        state.rolePermission = action.payload;
        state.status.rolePermission = Status.Succeeded;
      })
      .addCase(getRolePermissionService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.rolePermission = [];
          state.status.rolePermission = Status.Failed;
          state.error.rolePermission = action.payload.error;
        }
      });
  },
});

export const selectUserManagementTableStates = createSelector(
  [
    (state) => state.user.managementTableData,
    (state) => state.user.searchedUserName,
    (state) => state.user.filterRoles,
    (state) => state.user.status.managementTableData,
    (state) => state.user.status.p12CertificateData,
    (state) => state.user.error.managementTableData,
    (state) => state.user.error.p12CertificateData,
  ],

  (
    tableData,
    searchedUserName,
    filterRoles,
    userStatus,
    p12Status,
    userError,
    p12Error
  ) => {
    tableData =
      tableData?.filter((user) =>
        user?.userName?.toLowerCase().includes(searchedUserName.toLowerCase())
      ) ?? [];

    if (filterRoles.length > 0) {
      tableData = tableData?.filter((user) =>
        filterRoles.every((role) => user.roles.includes(role.value))
      );
    }

    return { tableData, userStatus, p12Status, userError, p12Error };
  }
);

export const selectUserDetailsByUserID = createSelector(
  [
    (state) => state.user.managementTableData,
    (state) => state.user.status.managementTableData,
    (state) => state.user.error.managementTableData,
    (_, userID) => userID,
  ],
  (tableData, status, error, userID) => {
    if (status === Status.Failed) {
      return {
        isUserDataFetched: false,
        error: error,
        selecteduserData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedUserData = tableData.filter(
        (data) => data.userID === Number(userID)
      );

      if (selectedUserData.length > 0) {
        return {
          isUserDataFetched: true,
          error: null,
          selectedUserData: selectedUserData[0],
        };
      } else {
        return {
          isUserDataFetched: true,
          error: null,
          selectedUserData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isUserDataFetched: true,
        error: null,
        selectedUserData: null,
      };
    } else {
      return {
        isUserDataFetched: false,
        error: null,
        selectedUserData: null,
      };
    }
  }
);

export const selectP12CertificatesByUserName = createSelector(
  [
    (state) => state.user.p12CertificateData,
    (state) => state.user.status.p12CertificateData,
    (state) => state.user.error.p12CertificateData,
    (_, userName) => userName,
  ],
  (certificates, status, error, userName) => {
    if (status === Status.Failed) {
      return {
        areCertificatesFetched: false,
        error: error,
        certificates: [],
        status: status,
      };
    } else if (certificates?.length > 0) {
      let userSpecificCertificates =
        certificates?.filter((cert) => cert.userName === userName) ?? [];

      if (userSpecificCertificates.length > 0) {
        return {
          areCertificatesFetched: true,
          error: null,
          certificates: userSpecificCertificates[0].certificateDetails,
          status: status,
        };
      } else {
        return {
          areCertificatesFetched: true,
          error: null,
          certificates: [],
          status: status,
        };
      }
    } else if (status === Status.Succeeded && certificates?.length === 0) {
      return {
        areCertificatesFetched: true,
        error: null,
        certificates: [],
        status: status,
      };
    } else {
      return {
        areCertificatesFetched: false,
        error: null,
        certificates: [],
        status: status,
      };
    }
  }
);

export const selectRolePermissionStates = createSelector(
  [
    (state) => state.user.rolePermission,
    (state) => state.user.status.rolePermission,
    (state) => state.user.error.rolePermission,
  ],

  (permissions, status, error) => ({ permissions, status, error })
);

export const {
  resetUserManagementState,
  setSearchedUserName,
  setFilterRoles,
  resetPermissionState,
  navigateBack,
} = userSlice.actions;

export default userSlice.reducer;
