import { Paper, Stack, Box, Typography } from "@mui/material";
import noDataFoundPng from "../../../assets/images/no-data-found-png.png";

export default function NoRowsComponent() {
  return (
    <Paper
      elevation={0}
      sx={{
        width: 1,
        height: 1,
        borderRadius: "0px",
      }}
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          py: 4,
          px: 1,
          width: 1,
          height: 1,
        }}
      >
        <Box sx={{ width: "100px", aspectRatio: 1 }}>
          <img src={noDataFoundPng} alt="No Data Found" />
        </Box>
        <Typography
          variant="h6"
          sx={{ fontSize: "1.25rem", fontWeight: 700 }}
          color="primary.main"
        >
          No Data Found!
        </Typography>
      </Stack>
    </Paper>
  );
}
