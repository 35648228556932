import { Stack, Button, Box, Paper, Divider, Typography } from "@mui/material";
import { FormLabels, Labels, Status } from "../../../utils/constants";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCertificateFormState } from "../applicationManagementSlice";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Formik, Form as FormikForm, FieldArray, getIn } from "formik";
import { store } from "../../../lib/redux/store";
import SelectInputComponent from "../../../components/ui/inputComponents/SelectInputComponent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { initializeForm } from "../../forms/formSlice";
import { FormType } from "../../../utils/constants";
import Skeleton from "@mui/material/Skeleton";
import manageApplicationEnvironmentService from "../../../services/applicationManagement/manageApplicationEnvironmentService";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";

export default function EnvironmentForm({ isNewApplication }) {
  const navigate = useNavigate();

  // const navigateBack = useSelector(
  //   (state) => state.applicationManagement.formState.navigateBack
  // );

  // if (navigateBack) {
  //   navigate("/application-management");
  // }

  let environmentDetailStatus = useSelector(
    (state) => state.applicationManagement.formState.environmentDetailStatus
  );

  // For Loader
  if (isNewApplication) {
    if (environmentDetailStatus === Status.Loading) {
      return <FormSkeleton navigate={navigate} />;
    }
  } else {
    if (environmentDetailStatus !== Status.Succeeded) {
      return <FormSkeleton navigate={navigate} />;
    }
  }

  function getIntialValue() {
    let prevEnvironments =
      store.getState().applicationManagement.formState.environmentDetail;

    if (prevEnvironments.length > 0) {
      let initialValue = prevEnvironments.map((envData) => ({
        id: envData.environmentID,
        certificateDetail: {
          id: envData.certificateID,
          label: envData.certificateName,
        },
        certificateEnvironmentDetail: {
          id: envData.certificateEnvironmentID,
          label: envData.environmentName,
        },
        status: envData.status,
        isNewlyAdded: false,
      }));

      return initialValue;
    } else {
      let initialValue = [
        {
          id: null,
          certificateDetail: null,
          certificateEnvironmentDetail: null,
          status: Labels.ACTIVE,
          isNewlyAdded: true,
        },
      ];

      return initialValue;
    }
  }

  return <FormContainer initialValue={getIntialValue()} navigate={navigate} />;
}

function FormSkeleton({ navigate }) {
  return (
    <Stack direction="column" sx={{ gap: 2, height: 1 }}>
      <Box>
        <Stack
          sx={{
            width: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
            py: 2,
            px: 3,
          }}
        >
          <Stack
            sx={{
              width: "fit-content",
              gap: 2,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Skeleton
              variant="rounded"
              width={300}
              height={40}
              sx={{ borderRadius: "5px" }}
            />
          </Stack>

          <Skeleton
            variant="rounded"
            width={150}
            height={40}
            sx={{ borderRadius: "5px" }}
          />
        </Stack>

        <Divider />
      </Box>

      <Stack
        sx={{
          flexDirection: "column",
          gap: 3,
          width: 1,
          height: "calc(100% - 142.5px)",
          px: 3,
          overflowY: "auto",
        }}
        className="custom-scroll-bar"
      >
        {Array.from({ length: 5 }, (_, index) => index).map((_) => (
          <Skeleton
            variant="rounded"
            width={"100%"}
            sx={{ minHeight: "100px", borderRadius: "5px" }}
          />
        ))}
      </Stack>

      <Box>
        <Divider />
        <Stack
          sx={{
            width: 1,
            flexDirection: "row-reverse",
            gap: 2,
            alignItems: "center",
            px: 3,
            py: 2,
          }}
        >
          <Button
            sx={{ borderRadius: "5px" }}
            size="large"
            variant="contained"
            disabled={true}
          >
            {FormLabels.SAVE_AND_PROCEED}
          </Button>

          <Button
            size="large"
            sx={{ borderRadius: "5px" }}
            variant="outlined"
            onClick={() => navigate("/application-management")}
          >
            {FormLabels.CANCEL}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}

// Per Environment Validation Schema
const EnvironmentSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  certificateDetail: Yup.object()
    .shape({
      id: Yup.number(),
      label: Yup.string(),
    })
    .required("Select an Certificate"),
  certificateEnvironmentDetail: Yup.object()
    .shape({
      id: Yup.number(),
      label: Yup.string(),
    })
    .required("Select a Environment"),
  status: Yup.string(),
});

function Header({ addEnvironment, validateForm, submitForm, dispatch }) {
  async function handleAddNewEnv() {
    const errors = await validateForm();

    if (Object.keys(errors).length === 0) {
      addEnvironment({
        id: null,
        certificateDetail: null,
        certificateEnvironmentDetail: null,
        status: Labels.ACTIVE,
        isNewlyAdded: true,
      });
    } else {
      submitForm();
    }
  }

  let isAllowedToCreateSelfSigned = checkUserPermission(
    Permissions.CreateSelfSignedCertificate
  );

  let isAllowedToGenerateCSR = checkUserPermission(Permissions.GenerateCSR);

  let isAllowedToImportCertificate = checkUserPermission(
    Permissions.ImportCertificate
  );

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          py: 2,
          px: 3,
        }}
      >
        <ButtonGroup variant="outlined">
          {checkUserPermission(Permissions.CreateEnvironment) && (
            <Button
              sx={{ borderRadius: "5px" }}
              onClick={() =>
                dispatch(
                  initializeForm({
                    formType: FormType.CREATE_ENVIRONMENT,
                  })
                )
              }
            >
              {FormLabels.CREATE_ENVIRONMENT}
            </Button>
          )}

          {(isAllowedToCreateSelfSigned ||
            isAllowedToGenerateCSR ||
            isAllowedToImportCertificate) && (
            <Button
              sx={{ borderRadius: "5px" }}
              onClick={() =>
                dispatch(
                  initializeForm({
                    formType: isAllowedToCreateSelfSigned
                      ? FormType.SELF_SIGNED_CERTIFICATE
                      : isAllowedToGenerateCSR
                      ? FormType.SIGNING_REQUEST_CERTIFICATE
                      : FormType.IMPORT_CERTIFICATE,
                  })
                )
              }
            >
              {FormLabels.CREATE_CERTIFICATE}
            </Button>
          )}
        </ButtonGroup>

        <Button
          sx={{ borderRadius: "5px" }}
          size="large"
          variant="contained"
          onClick={() => handleAddNewEnv()}
        >
          {FormLabels.ADD_ENVIRONMENT}
        </Button>
      </Stack>

      <Divider />
    </Box>
  );
}

function Footer({ navigate, errors, submitForm }) {
  let isPolicyManagementAllowed = checkUserPermission(
    Permissions.ManageApplicationPolicy
  );

  let isCreateTeamAllowed = checkUserPermission(Permissions.CreateTeam);

  return (
    <Box>
      <Divider />

      <Stack
        sx={{
          width: 1,
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          px: 3,
          py: 2,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "1.1rem",
            color: "error.dark",
            fontWeight: 500,
            lineHeight: 1,
          }}
        >
          {typeof errors.environments === "string" && errors.environments}
        </Typography>

        <Stack
          sx={{ flexDirection: "row-reverse", gap: 2, alignItems: "center" }}
        >
          <Button
            sx={{ borderRadius: "5px" }}
            size="large"
            variant="contained"
            onClick={() => submitForm()}
          >
            {isPolicyManagementAllowed || isCreateTeamAllowed
              ? FormLabels.SAVE_AND_PROCEED
              : FormLabels.FINISH}
          </Button>
          <Button
            size="large"
            sx={{ borderRadius: "5px" }}
            variant="outlined"
            onClick={() => navigate("/application-management")}
          >
            {FormLabels.CANCEL}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

function FormContainer({ initialValue, navigate }) {
  const dispatch = useDispatch();
  const deletedEnvironments = useRef([]);

  return (
    <Formik
      initialValues={{
        environments: initialValue,
      }}
      validationSchema={Yup.object().shape({
        environments: Yup.array()
          .of(EnvironmentSchema)
          .test(
            "is-unique",
            "Duplicate Environments are not allowed",
            function (values) {
              const seen = new Set();
              return values.every((value) => {
                const { certificateDetail, certificateEnvironmentDetail } =
                  value;
                const key = `${certificateDetail?.id}:${certificateEnvironmentDetail?.id}`;
                if (seen.has(key)) {
                  return false;
                }
                seen.add(key);
                return true;
              });
            }
          )
          .min(1, "Please add at least 1 Environment"),
      })}
      onSubmit={(values) => {
        manageApplicationEnvironmentService(
          deletedEnvironments.current,
          values.environments
        );
      }}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        validateForm,
        submitForm,
      }) => (
        <FormikForm noValidate autoComplete="off">
          <FieldArray name="environments">
            {({ remove, unshift }) => (
              <>
                <Header
                  addEnvironment={unshift}
                  validateForm={validateForm}
                  submitForm={submitForm}
                  dispatch={dispatch}
                />

                <Stack
                  sx={{
                    flexDirection: "column",
                    gap: 3,
                    width: 1,
                    height: "calc(100% - 142.5px)",
                    px: 3,
                    overflowY: "auto",
                  }}
                >
                  <Divider sx={{ borderColor: "transparent" }} />

                  {values.environments.map((detail, index) => (
                    <ParticularEnvironmentForm
                      key={index}
                      id={index}
                      errors={errors}
                      detail={detail}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      removeEnvironment={remove}
                      dispatch={dispatch}
                      deletedEnvironments={deletedEnvironments}
                      numberOfEnvs={values.environments.length}
                    />
                  ))}

                  <Divider sx={{ borderColor: "transparent" }} />
                </Stack>

                <Footer
                  navigate={navigate}
                  errors={errors}
                  submitForm={submitForm}
                />
              </>
            )}
          </FieldArray>
        </FormikForm>
      )}
    </Formik>
  );
}

function ParticularEnvironmentForm({
  id,
  detail,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  removeEnvironment,
  deletedEnvironments,
  numberOfEnvs,
}) {
  const { environments, environmentStatus, certificates, certificateStatus } =
    useSelector(selectCertificateFormState);

  let certificateEnvironmentFormID = `environments.${id}.certificateEnvironmentDetail`;
  let certificateFormID = `environments.${id}.certificateDetail`;
  let statusFormID = `environments.${id}.status`;

  return (
    <Paper
      elevation={0}
      key={id}
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 5,
        px: 2.5,
        pt: 1.5,
        pb: 3,
        borderRadius: "5px",
        border: "1px solid #C4C7D0",
        backgroundColor: "#fafbfc",
        alignItems: "center",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <SelectInputComponent
          label={"Environment"}
          error={getIn(errors, certificateEnvironmentFormID)}
          touched={getIn(touched, certificateEnvironmentFormID)}
          placeholder={
            environmentStatus !== Status.Succeeded
              ? "Fetching Envrionments..."
              : "Select Environment"
          }
          id={certificateEnvironmentFormID}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          value={detail?.certificateEnvironmentDetail ?? ""}
          options={environments}
          optionIsString={false}
          isDisabled={environmentStatus !== Status.Succeeded}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <SelectInputComponent
          label={"Certificate"}
          error={getIn(errors, certificateFormID)}
          touched={getIn(touched, certificateFormID)}
          placeholder={
            certificateStatus !== Status.Succeeded
              ? "Fetching Certificates..."
              : "Select Certificate"
          }
          id={certificateFormID}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          value={detail?.certificateDetail ?? ""}
          options={certificates}
          optionIsString={false}
          isDisabled={certificateStatus !== Status.Succeeded}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <SelectInputComponent
          label={"Status"}
          error={getIn(errors, statusFormID)}
          touched={getIn(touched, statusFormID)}
          id={statusFormID}
          handleBlur={handleBlur}
          value={detail.status}
          options={[Labels.ACTIVE, Labels.IN_ACTIVE]}
          setFieldValue={setFieldValue}
          optionIsString={true}
        />
      </Box>

      <IconButton
        onClick={() => {
          deletedEnvironments.current = [
            ...deletedEnvironments.current,
            detail,
          ];
          removeEnvironment(id);
        }}
        disabled={numberOfEnvs === 1}
        size="large"
        sx={{ mt: 2 }}
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </Paper>
  );
}
