export default function ECLogo({ fill, width }) {
  return (
    <svg fill={fill} width={width} viewBox="0 0 43 29">
      <path d="m16.453 17.39h4.3296c1.356 3.4644 3.6372 5.9376 7.4508 6.5088 3.7728 0.564 6.7404-0.93 8.982-3.9792h4.764c-0.0528 0.1692-0.0804 0.3144-0.1404 0.444-2.0952 4.4952-5.6292 7.0968-10.502 7.8276-7.3752 1.1064-13.327-4.3092-14.755-10.172-0.0456-0.1848-0.0768-0.3732-0.1284-0.6288z" />
      <path d="m42.29 10.035c-0.2016 0.012-0.3516 0.03-0.5004 0.03-1.1904 0.0024-2.3808-0.0156-3.5712 0.0108-0.3804 0.0084-0.5724-0.12844-0.7704-0.44404-1.398-2.232-3.3648-3.708-5.9436-4.3032-1.4928-0.3444-2.9748-0.1524-4.4388 0.2256-0.2208 0.0576-0.4392 0.1236-0.5892 0.1668-1.1352-1.0764-2.2284-2.1144-3.3132-3.1428 5.982-3.8328 16.232-1.3884 19.127 7.4568z" />
      <path d="m18.132 23.664c0.4992 0.5364 0.9492 1.0608 1.4424 1.5408 0.48 0.4668 1.0056 0.8868 1.5696 1.3788-0.1836 0.108-0.3504 0.2172-0.5268 0.3096-1.9812 1.038-4.0824 1.5156-6.324 1.4232-5.7864-0.24-9.9816-2.9532-12.521-8.1396-1.1256-2.298-1.3656-4.788-1.032-7.3116 0.6828-5.16 3.5424-8.778 8.1888-10.9 4.5912-2.0988 10.711-1.4784 15.053 2.7432 1.1376 1.1064 2.1228 2.3232 2.8464 3.7404 0.0696 0.1368 0.1344 0.276 0.192 0.4176 0.0192 0.048 0.0084 0.1092 0.0144 0.21-1.4904-0.2028-2.8404 0.0888-4.0128 1.1424-1.632-2.8908-4.0404-4.6836-7.3092-5.1528-2.2476-0.3228-4.3896 0.102-6.36 1.2948-4.0392 2.4456-5.7456 7.4244-4.0392 11.836 0.6384 1.65 1.6524 3.0252 3.042 4.1184 1.3884 1.092 2.9616 1.7484 4.7136 1.9944 1.752 0.2472 3.4368-0.0276 5.0628-0.6456z" />
      <path d="m13.483 14.03c0.2028-0.4428 0.4044-0.8856 0.6492-1.4232 0.252 0.5448 0.4572 0.9888 0.7164 1.548 0.2592-0.5436 0.474-0.9936 0.732-1.5324 0.2364 0.5136 0.4416 0.9576 0.7068 1.5324 0.2676-0.5568 0.4824-1.0056 0.7356-1.5336 0.2448 0.5328 0.45 0.9792 0.7092 1.5432 0.2088-0.4536 0.3972-0.7968 0.528-1.1616 0.1296-0.3588 0.3288-0.5016 0.7248-0.486 0.8628 0.0324 1.728-0.0096 2.5908 0.0192 0.3768 0.012 0.5736-0.12 0.774-0.4392 1.1184-1.788 3.2532-2.5308 5.22-1.8552 1.9344 0.6648 3.1656 2.5668 2.9904 4.6212-0.174 2.046-1.806 3.7596-3.8376 4.0008-1.878 0.2232-3.3432-0.486-4.3728-2.0544-0.2124-0.324-0.4308-0.4116-0.7896-0.4104-2.7456 0.012-5.4912-0.0036-8.2368 0.0168-0.4308 0.0036-0.7152-0.1008-0.9456-0.48-0.2988-0.4896-0.6588-0.9408-1.0224-1.4508 0.4332-0.6048 0.8628-1.2072 1.3416-1.8756 0.2376 0.5184 0.4416 0.9648 0.6468 1.4124 0.0468 0.0024 0.0936 0.0048 0.1392 0.0084zm12.611-2.004c-1.3752 0.0072-2.4348 1.0788-2.4252 2.454 0.0096 1.356 1.1088 2.4348 2.4708 2.4252 1.368-0.0096 2.4132-1.0824 2.4084-2.4708-6e-3 -1.3884-1.0524-2.4156-2.454-2.4084z" />
    </svg>
  );
}
