import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { createSelector } from "reselect";
import getBackupTableDataService from "../../services/backup/getBackupTableData";

const initialState = {
  status: Status.Idle,
  error: null,
  tableData: [],
};

export const backupSlice = createSlice({
  name: "backup",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = Status.Idle;
      state.error = null;
    },
    fetchBackup: (state) => {
      state.status = Status.Idle;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBackupTableDataService.pending, (state) => {
        state.status = Status.Loading;
        state.error = null;
      })
      .addCase(getBackupTableDataService.fulfilled, (state, action) => {
        state.tableData = action.payload;
        state.status = Status.Succeeded;
        state.error = null;
      })
      .addCase(getBackupTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.tableData = [];
          state.status = Status.Failed;
          state.error = action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectBackupTableData = createSelector(
  [
    (state) => state.backup.status,
    (state) => state.backup.error,
    (state) => state.backup.tableData,
  ],

  (status, error, tableData) => ({
    tableData,
    status,
    error,
  })
);

export const { resetState, fetchBackup } = backupSlice.actions;

export default backupSlice.reducer;
