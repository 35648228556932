import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Status } from "../../../utils/constants";
import {
  Stack,
  Box,
  Button,
  Skeleton,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { FormLabels } from "../../../utils/constants";
import { store } from "../../../lib/redux/store";
import { useDispatch } from "react-redux";
import { Formik, Form as FormikForm, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import TextInputComponent from "../../../components/ui/inputComponents/TextInputComponent";
import noDataFoundPng from "../../../assets/images/no-data-found-png.png";
import { switchForm } from "../applicationManagementSlice";
import { ApplicationManagementFormTypes } from "../../../utils/enums";
import ButtonGroup from "@mui/material/ButtonGroup";
import { initializeForm } from "../../forms/formSlice";
import { FormType } from "../../../utils/constants";
import { initializeModal } from "../../modals/modalSlice";
import { ModalType } from "../../../utils/constants";
import checkUserPermission from "../../../utils/checkUserPermission";
import { Permissions } from "../../../utils/enums";
import MultiSelectInputComponent from "../../../components/ui/inputComponents/MultiSelectInputComponent";
import manageCertificateTeamService from "../../../services/applicationManagement/manageCertificateTeamService";

export default function TeamForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let environmentDetailStatus = useSelector(
    (state) => state.applicationManagement.formState.environmentDetailStatus
  );

  // For Loader
  if (environmentDetailStatus !== Status.Succeeded) {
    return <FormSkeleton navigate={navigate} />;
  }

  // If no environment present
  if (
    store.getState().applicationManagement.formState.environmentDetail
      .length === 0
  ) {
    return (
      <Stack
        sx={{
          width: 1,
          height: 1,
          alignItems: "center",
          justifyContent: "center",
          P: 2,
        }}
      >
        <Box sx={{ width: "100px", aspectRatio: 1 }}>
          <img src={noDataFoundPng} alt="No Data Found" />
        </Box>
        <Typography
          variant="h6"
          sx={{ fontSize: "1.25rem", fontWeight: 700 }}
          color="primary.main"
        >
          No Environment Found!
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2, borderRadius: "5px" }}
          onClick={() =>
            dispatch(
              switchForm({
                activeForm: ApplicationManagementFormTypes.Environment,
              })
            )
          }
        >
          Add Environment
        </Button>
      </Stack>
    );
  }

  function getInitialValue() {
    let certificateTeamDetail =
      store.getState().applicationManagement.formState.certificateTeamDetail;

    let initialValue =
      certificateTeamDetail?.map((data) => ({
        id: data.certificateUID,
        certificateName: data.certificateName,
        teams:
          data.teams?.map((team) => ({
            id: team.teamID,
            name: team.teamName,
            label: team.teamName,
            members:
              team?.members?.map((member) => ({
                id: member?.id,
                name: member?.name,
              })) ?? [],
          })) ?? [],
      })) ?? [];

    return initialValue;
  }

  return (
    <>
      <FormContainer initialValue={getInitialValue()} navigate={navigate} />
    </>
  );
}

function FormSkeleton({ navigate }) {
  return (
    <Stack direction="column" sx={{ gap: 2, height: 1 }}>
      <Box>
        <Stack
          sx={{
            width: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
            py: 2,
            px: 3,
          }}
        >
          <Stack
            sx={{
              width: "fit-content",
              gap: 2,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Skeleton
              variant="rounded"
              width={300}
              height={40}
              sx={{ borderRadius: "5px" }}
            />
          </Stack>
        </Stack>

        <Divider />
      </Box>

      <Stack
        sx={{
          flexDirection: "column",
          gap: 3,
          width: 1,
          height: "calc(100% - 142.5px)",
          px: 3,
          overflowY: "auto",
        }}
        className="custom-scroll-bar"
      >
        {Array.from({ length: 5 }, (_, index) => index).map((_) => (
          <Skeleton
            variant="rounded"
            width={"100%"}
            sx={{ minHeight: "100px", borderRadius: "5px" }}
          />
        ))}
      </Stack>

      <Box>
        <Divider />
        <Stack
          sx={{
            width: 1,
            flexDirection: "row-reverse",
            gap: 2,
            alignItems: "center",
            px: 3,
            py: 2,
          }}
        >
          <Button
            sx={{ borderRadius: "5px" }}
            size="large"
            variant="contained"
            disabled={true}
          >
            {FormLabels.SAVE_AND_PROCEED}
          </Button>

          <Button
            size="large"
            sx={{ borderRadius: "5px" }}
            variant="outlined"
            onClick={() => navigate("/application-management")}
          >
            {FormLabels.CANCEL}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}

const MemberSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string(),
});

const TeamSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string(),
  label: Yup.string(),
  members: Yup.array().of(MemberSchema),
});

// Per Certificate Validation Schema
const CertificateTeamSchema = Yup.object().shape({
  id: Yup.string(),
  certificateName: Yup.string(),
  teams: Yup.array().of(TeamSchema).min(1, "Please select atleast one team"),
});

function Header() {
  const dispatch = useDispatch();
  const memberStatus = useSelector(
    (state) => state.applicationManagement.formState.memberStatus
  );
  const teamStatus = useSelector(
    (state) => state.applicationManagement.formState.teamStatus
  );

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          py: 2,
          px: 3,
        }}
      >
        <ButtonGroup variant="outlined">
          <Button
            sx={{ borderRadius: "5px" }}
            disabled={memberStatus !== Status.Succeeded}
            onClick={() =>
              dispatch(
                initializeForm({
                  formType: FormType.CREATE_TEAM,
                  dynamicOption: {
                    members:
                      store.getState().applicationManagement.formState.members,
                  },
                })
              )
            }
          >
            {FormLabels.CREATE_TEAM}
          </Button>

          <Button
            sx={{ borderRadius: "5px" }}
            disabled={teamStatus !== Status.Succeeded}
            onClick={() =>
              dispatch(
                initializeModal({
                  modalType: ModalType.MANAGE_TEAM,
                  modalData: {
                    teams:
                      store.getState().applicationManagement.formState.teams,
                  },
                })
              )
            }
          >
            {FormLabels.MANAGE_TEAM}
          </Button>
        </ButtonGroup>
      </Stack>

      <Divider />
    </Box>
  );
}

function Footer({ navigate, submitForm }) {
  let isPolicyManagementAllowed = checkUserPermission(
    Permissions.ManageApplicationPolicy
  );

  return (
    <Box>
      <Divider />

      <Stack
        sx={{
          width: 1,
          gap: 2,
          alignItems: "center",
          flexDirection: "row-reverse",
          px: 3,
          py: 2,
        }}
      >
        <Button
          sx={{ borderRadius: "5px" }}
          size="large"
          variant="contained"
          onClick={() => submitForm()}
        >
          {isPolicyManagementAllowed
            ? FormLabels.SAVE_AND_PROCEED
            : FormLabels.FINISH}
        </Button>
        <Button
          size="large"
          sx={{ borderRadius: "5px" }}
          variant="outlined"
          onClick={() => navigate("/application-management")}
        >
          {FormLabels.CANCEL}
        </Button>
      </Stack>
    </Box>
  );
}

function FormContainer({ initialValue, navigate }) {
  return (
    <Formik
      initialValues={{
        certificates: initialValue,
      }}
      validationSchema={Yup.object().shape({
        certificates: Yup.array().of(CertificateTeamSchema),
      })}
      onSubmit={(values) => {
        manageCertificateTeamService(values.certificates);
      }}
    >
      {({ values, touched, errors, handleBlur, setFieldValue, submitForm }) => (
        <FormikForm noValidate autoComplete="off">
          <FieldArray name="certificates">
            {() => (
              <>
                <Header />

                <Stack
                  sx={{
                    flexDirection: "column",
                    gap: 3,
                    width: 1,
                    height: "calc(100% - 142.5px)",
                    px: 3,
                    overflowY: "auto",
                  }}
                >
                  <Divider sx={{ borderColor: "transparent" }} />

                  {values.certificates.map((data, index) => (
                    <ParticularCertificateForm
                      key={index}
                      id={index}
                      errors={errors}
                      data={data}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  <Divider sx={{ borderColor: "transparent" }} />
                </Stack>

                <Footer navigate={navigate} submitForm={submitForm} />
              </>
            )}
          </FieldArray>
        </FormikForm>
      )}
    </Formik>
  );
}

function ParticularCertificateForm({
  id,
  data,
  errors,
  touched,
  handleBlur,
  setFieldValue,
}) {
  let teamInputID = `certificates.${id}.teams`;

  let allTeams = useSelector(
    (state) => state.applicationManagement.formState.teams
  );
  let teamFetchingStatus = useSelector(
    (state) => state.applicationManagement.formState.teamStatus
  );

  return (
    <Paper
      elevation={0}
      key={id}
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 5,
        px: 2.5,
        pt: 1.5,
        pb: 3,
        borderRadius: "5px",
        border: "1px solid #C4C7D0",
        backgroundColor: "#fafbfc",
        alignItems: "center",
      }}
    >
      <Box sx={{ minWidth: "200px", flex: 0.5 }}>
        <TextInputComponent
          label="Certificate"
          isDisabled={true}
          value={data.certificateName}
        />
      </Box>
      <Box sx={{ flex: 1, minWidth: "300px" }}>
        <MultiSelectInputComponent
          label={"Teams"}
          options={allTeams}
          value={data.teams}
          id={teamInputID}
          placeholder={"Select Team"}
          error={getIn(errors, teamInputID)}
          handleBlur={handleBlur}
          touched={getIn(touched, teamInputID)}
          setFieldValue={setFieldValue}
          optionIsString={false}
          isDisabled={teamFetchingStatus !== Status.Succeeded}
        />
      </Box>
    </Paper>
  );
}
