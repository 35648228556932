import { Box, Paper, TextareaAutosize } from "@mui/material";
import InputLabel from "./InputLabel";

export default function TextAreaComponent({
  label,
  error,
  touched,
  isDisabled,
  placeholder,
  id,
  handleChange,
  handleBlur,
  value,
  inputType,
}) {

  return (
    <Box sx={{ position: "reltiave" }}>
      <InputLabel label={label} error={error} touched={touched} />
      <Paper
        elevation={0}
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
        }}
      >
        <TextareaAutosize
          aria-label="minimum height"
          minRows={6}
          disabled={isDisabled}
          placeholder={placeholder}
          id={id}
          name={id}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value || ''}
        />
      </Paper>
    </Box>
  );
}