import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import UserPermissionTable from "../../../features/user/UserPermissionTable";

export default function UserPermission() {
  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <UserPermissionTable />
    </ErrorBoundary>
  );
}
