import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { store } from "../../lib/redux/store";
import { Labels } from "../../utils/constants";
import { renderStatusCell, fromatDateForUI } from "./utils";

export default function LogsDetailTable({ logDetail }) {
  const timezone = store.getState().logs.timezone;

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
            },
          }}
        >
          <TableRow className="custom-scroll-bar">
            <TableCell>{Labels.DATE}</TableCell>
            <TableCell>{Labels.EVENT}</TableCell>
            <TableCell>{Labels.SOURCE}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {new Array(logDetail).map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
                td: {
                  whiteSpace: "nowrap",
                },
              }}
            >
              <TableCell>
                {fromatDateForUI(row.Date, timezone) || "-"}
              </TableCell>
              <TableCell>{row?.Event || "-"}</TableCell>
              <TableCell>{row?.Source || "-"}</TableCell>
              <TableCell>{renderStatusCell(row.Severity)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
