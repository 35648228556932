import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { Box, Paper } from "@mui/material";
import InputLabel from "./InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { Status } from "../../../utils/constants";

function CustomChip(props) {
  const { label, group, onDelete: handleDelete, ...other } = props;
  return (
    <Chip
      {...other}
      size="small"
      sx={{ fontSize: "0.75rem" }}
      key={label}
      onDelete={() => handleDelete()}
      label={label}
    />
  );
}

function getStyles(option, value, equalityMethod, isMultipleTrue) {
  if (isMultipleTrue) {
    return {
      backgroundColor:
        value.findIndex(
          (item) => item[equalityMethod] === option[equalityMethod]
        ) !== -1 && "rgba(27, 44, 88, 0.08)",
    };
  } else {
    if (value) {
      return {
        backgroundColor:
          value[equalityMethod] === option[equalityMethod] &&
          "rgba(27, 44, 88, 0.08)",
      };
    }
  }
}

export default function AsyncMultiSelectInputComponent({
  label,
  value: selectedValues,
  id,
  placeholder,
  error,
  handleBlur,
  touched,
  setFieldValue,
  optionFetchingFunction,
  equalityMethod = "id",
  multipleValues,
}) {
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [optionFetchingStatus, setOptionFetchingStatus] = useState(Status.Idle);

  async function handleOptionFetching(searchText) {
    try {
      setOptionFetchingStatus(Status.Loading);
      const res = await optionFetchingFunction(searchText);
      setOptions(res);
    } catch (error) {
      setOptions([]);
    } finally {
      setOptionFetchingStatus(Status.Idle);
    }
  }

  function handleChange(value) {
    if (
      id === "applicationOwners" ||
      id.includes("signingUsers") ||
      id === "approvers"
    ) {
      let users = [];
      value.forEach((item) => {
        if (item.group) {
          users.push(...item.members);
        } else {
          users.push(item);
        }
      });

      const uniqueUserIds = {};

      const uniqueUsers = users.filter((user) => {
        if (!uniqueUserIds[user[equalityMethod]]) {
          uniqueUserIds[user[equalityMethod]] = true;
          return true;
        }
        return false;
      });

      setFieldValue(id, uniqueUsers);
    } else {
      setFieldValue(id, value);
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText?.trim()?.length > 0) {
        handleOptionFetching(searchText.trim());
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
    focused,
  } = useAutocomplete({
    disableCloseOnSelect: true,
    id: id,
    multiple: multipleValues,
    value: selectedValues,
    options: options,
    getOptionLabel: (option) => option.label,
    onChange: (_, value) => handleChange(value),
    onInputChange: (_, value) => setSearchText(value),
    isOptionEqualToValue: (option, value) =>
      option[equalityMethod] === value[equalityMethod] && true,
  });

  return (
    <Box sx={{ position: "relative", width: 1, height: "fit-content" }}>
      <Box {...getRootProps()}>
        <InputLabel
          {...getInputLabelProps()}
          label={label}
          error={error}
          touched={touched}
        />
        <Paper
          elevation={0}
          sx={{
            px: 2,
            py: 1.5,
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
          }}
          ref={setAnchorEl}
          onBlur={handleBlur}
        >
          {value?.length > 0 && (
            <Box
              className="custom-scroll-bar horizontal"
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "10px",
                overflow: "auto",
                pb: "5px",
                maxHeight: "100px",
              }}
            >
              {value.map((option, index) => (
                <CustomChip label={option.label} {...getTagProps({ index })} />
              ))}
            </Box>
          )}
          <TextField
            id={id}
            name={id}
            variant="standard"
            {...getInputProps()}
            placeholder={placeholder}
          />
        </Paper>
      </Box>
      {optionFetchingStatus !== Status.Loading ? (
        groupedOptions.length > 0 ? (
          <Box
            {...getListboxProps()}
            className="custom-scroll-bar"
            sx={{
              position: "absolute",
              top: "100%",
              width: "calc(100% - 32px)",
              maxHeight: "200px",
              fontSize: "0.85rem",
              backgroundColor: "background.paper",
              py: 1,
              borderRadius: "5px",
              boxShadow: "3px 3px 13px 2px rgba(0, 0, 0, 0.10)",
              mt: -1.25,
              mx: 2,
              zIndex: 2,
              overflowY: "auto",
            }}
          >
            {groupedOptions.map((option, index) => (
              <MenuItem
                style={getStyles(option, value, equalityMethod, multipleValues)}
                {...getOptionProps({ option, index })}
              >
                {option.label}
              </MenuItem>
            ))}
          </Box>
        ) : (
          focused &&
          options.length === 0 && (
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                width: "calc(100% - 32px)",
                fontSize: "0.85rem",
                backgroundColor: "background.paper",
                py: 1,
                borderRadius: "5px",
                boxShadow: "3px 3px 13px 2px rgba(0, 0, 0, 0.10)",
                mt: -1.25,
                mx: 2,
                zIndex: 2,
              }}
            >
              <MenuItem>No Data Found!</MenuItem>
            </Box>
          )
        )
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            width: "calc(100% - 32px)",
            fontSize: "0.85rem",
            backgroundColor: "background.paper",
            py: 1,
            borderRadius: "5px",
            boxShadow: "3px 3px 13px 2px rgba(0, 0, 0, 0.10)",
            mt: -1.25,
            mx: 2,
            zIndex: 2,
          }}
        >
          <MenuItem>Fetching!</MenuItem>
        </Box>
      )}
    </Box>
  );
}
