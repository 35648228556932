import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import { resetTemplateState } from "../../features/email/emailSlice";
import { closeForm } from "../../features/forms/formSlice";

export default async function manageEmailTemplate(formData, _, serviceData) {
  try {
    store.dispatch(showLoader());

    let userId = store.getState().auth.userID;

    let request = null;
    if (serviceData.newTemplate) {
      let requestBody = {
        template_type: formData.emailType.value,
        subject: formData.emailSubject,
        content: formData.emailContent,
        created_user: userId,
      };

      request = Api.post(URL.Email.ManageTemplate, requestBody);
    } else {
      let requestBody = {
        id: serviceData.templateID,
        template_type: formData.emailType.value,
        subject: formData.emailSubject,
        content: formData.emailContent,
        modified_by: userId,
      };

      request = Api.put(
        URL.Email.ManageTemplate + serviceData.templateID + "/",
        requestBody
      );
    }

    await request;

    store.dispatch(resetTemplateState());
    store.dispatch(closeForm());

    if (serviceData.newTemplate) {
      Notification.success(NotificationLabels.TEMPLATE_ADDED);
    } else {
      Notification.success(NotificationLabels.TEMPLATE_UPDATED);
    }
  } catch (error) {
    if (serviceData.newTemplate) {
      Notification.error(NotificationLabels.TEMPLATE_ADDITION_FAILED);
    } else {
      Notification.error(NotificationLabels.TEMPLATE_UPDATE_FAILED);
    }
  } finally {
    store.dispatch(closeLoader());
  }
}
