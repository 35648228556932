import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { Labels } from "../../utils/constants";
import { isCurrentTimeGreaterThenProvidedTime } from "../../utils/dateFormatters";

const getKeysAndCertsTableData = createAsyncThunk(
  "keysAndCertificates/getKeysAndCertsTableData",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let response = await Api.get(
        URL.KeysAndCertificates.allKeysAndCertsTableData,
        abortController && {
          signal: abortController.signal,
        }
      );

      function getStatus(isDeleted, validTill) {
        if (isDeleted === false && validTill) {
          if (isCurrentTimeGreaterThenProvidedTime(validTill)) {
            return Labels.IN_ACTIVE;
          } else {
            return Labels.ACTIVE;
          }
        } else {
          return Labels.IN_ACTIVE;
        }
      }

      let keysAndCertsTableData =
        response.data?.map((item, index) => ({
          id: item?.id ?? index,
          keyID: item?.id ?? null,
          certificateName: item?.certificate_name ?? null,
          keyUID: item?.uid ?? null,
          algorithm: item?.algorithm ?? null,
          keyAlias: item?.key_alias ?? null,
          size: item?.size ?? null,
          status: getStatus(item?.Is_deleted, item?.valid_to),
          validFrom: item?.valid_from ?? null,
          validTill: item?.valid_to ?? null,
          commonName: item?.common_name ?? null,
          organization: item?.organization_name ?? null,
          email: item?.email ?? null,
          country: item?.country_code ?? null,
          state: item?.state_province ?? null,
          keyUsage: item.key_usage ?? null,
        })) ?? [];

      return keysAndCertsTableData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getKeysAndCertsTableData;
