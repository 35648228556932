import { Box, Paper } from "@mui/material";
import InputLabel from "./InputLabel";
import JoditEditor, { Jodit } from "jodit-react";
import { useRef, memo } from "react";

const dynamicFields = {
  "First Name": "{{FirstName}}",
  "Certificate Name": "{{CertificateName}}",
  "Request ID": "{{RequestID}}",
  "Expire Date": "{{ExpireDate}}",
  "Application Name": "{{ApplicationName}}",
};

const RichTextEditorComponent = memo(
  ({
    label,
    error,
    touched,
    id,
    setFieldValue,
    setFieldTouched,
    value,
    textEditorType,
  }) => {
    const textEditorRef = useRef(null);

    return (
      <Box sx={{ position: "reltiave" }}>
        <InputLabel label={label} error={error} touched={touched} />
        <Paper
          elevation={0}
          sx={{
            px: 2,
            py: 1.5,
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
            ".jodit-container": {
              ".jodit-wysiwyg": {
                pl: "10px !important",
              },
              ul: {
                listStyle: "inside !important",
              },
              ol: {
                listStyle: "inside !important",
                listStyleType: "number !important",
              },
              td: {
                border: "solid 1px !important",
              },
            },
          }}
        >
          <JoditEditor
            ref={textEditorRef}
            value={value}
            config={{
              width: "100%",
              height: "500px",
              buttons: [
                ...Jodit.defaultOptions.buttons,
                textEditorType === "email" && {
                  name: "Dynamic Field",
                  tooltip: "Insert dynamic field",
                  list: dynamicFields,
                  childTemplate: (editor, key, value) => {
                    return `<span>${key}</span>`;
                  },
                  exec: function (editor, t, { control }) {
                    const buttonPorps = control.args;

                    if (buttonPorps !== undefined) {
                      editor.selection.insertHTML(buttonPorps[1]);
                    }
                  },
                },
              ],
            }}
            tabIndex={0}
            onBlur={(newContent) => {
              // console.log(newContent);
              setFieldTouched(id, true);
              setFieldValue(id, newContent);
            }}
          />
        </Paper>
      </Box>
    );
  }
);

export default RichTextEditorComponent;
