// React
import { useRef, useEffect, useState } from "react";

// MUI
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// React Router
import { Link } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  selectTableData,
  setRequestApprovalFormState,
} from "./signingRequestsSlice";
import { initializeForm } from "../forms/formSlice";

// Constants
import { Status, Labels } from "../../utils/constants";

// Utils
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import { Permissions, SigningRequestStatus } from "../../utils/enums";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Custom Components
import CustomTable from "../../components/ui/table/CustomTable";

import { FormType } from "../../utils/constants";
import getSigningRequestsTableDataService from "../../services/signingRequests/getSigningRequestsTableDataService";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../utils/checkUserPermission";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function SigningRequestsTable() {
  let isUserProjectManager = checkUserPermission(
    Permissions.ManageSigningRequests
  );

  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(selectTableData);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getSigningRequestsTableDataService({
          abortController: abortControllerRef.current,
          isUserProjectManager,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return (
    <TableComponent
      tableData={tableData}
      dispatch={dispatch}
      isUserProjectManager={isUserProjectManager}
    />
  );
}

function TableComponent({ tableData, dispatch, isUserProjectManager }) {
  const [actionMenuState, setActionMenuState] = useState({
    anchorEl: null,
    selectedRowData: null,
  });

  const rowData = tableData;

  const columnData = [
    isUserProjectManager
      ? {
          field: "requestID",
          headerName: Labels.REQUEST_ID,
          width: 200,
          renderCell: (params) => renderRequestIDCell(params),
        }
      : {
          field: "jobID",
          headerName: Labels.JOB_ID,
          width: 200,
          valueFormatter: (params) => {
            if (params.value == null) {
              return "-";
            }
            return params.value;
          },
        },
    {
      field: "environmentName",
      headerName: Labels.ENVIRONMENT_NAME,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "applicationName",
      headerName: Labels.APPLICATION_NAME,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "submittedOn",
      headerName: Labels.SUBMITTED_ON,
      width: 250,
      filterable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return formatDateToUserTimeZone(params.value);
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      width: 180,
      renderCell: (params) => renderStatusCell(params.row),
    },
  ];

  if (isUserProjectManager) {
    columnData.push({
      field: "action",
      headerName: Labels.ACTION,
      width: 160,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderActionCell(params.row),
    });
  }

  function renderRequestIDCell(params) {
    if (params.value) {
      return (
        <Box
          sx={{
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${params.row.requestID}`}
          >
            {params.value}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderStatusCell(data) {
    let { status, approvalStatus } = data;

    let cellText = "";

    switch (status) {
      case SigningRequestStatus.ApprovePending:
        if (approvalStatus) {
          cellText = "In Progress";
        } else {
          cellText = "Pending";
        }
        break;
      case SigningRequestStatus.Approved:
        cellText = "Approved";
        break;
      case SigningRequestStatus.Signed:
        cellText = "Signed";
        break;
      case SigningRequestStatus.Rejected:
        cellText = "Rejected";
        break;
      default:
        cellText = "Pending";
        break;
    }

    function getBGColorForCell(cellText) {
      switch (cellText) {
        case "Pending": {
          return "#fffbb0";
        }
        case "Approved": {
          return "success.main";
        }
        case "Signed": {
          return "";
        }
        case "In Progress": {
          return "#fffbb0";
        }
        case "Rejected": {
          return "error.main";
        }
        default: {
          return "";
        }
      }
    }

    return (
      <Box
        sx={{
          width: "90px",
          textAlign: "center",
          paddingBlock: 0.75,
          color: "primary.main",
          borderRadius: "5px",
          backgroundColor: getBGColorForCell(cellText),
          fontWeight: cellText === "Signed" ? 700 : 500,
        }}
      >
        {cellText}
      </Box>
    );
  }

  function handleRequestApprove() {
    dispatch(setRequestApprovalFormState(true));

    let { selectedRowData } = actionMenuState;

    dispatch(
      initializeForm({
        formType: FormType.SIGNING_REQUEST,
        serviceData: {
          ...selectedRowData,
          type: "approve",
        },
      })
    );

    setActionMenuState({ anchorEl: null, selectedRowData: null });
  }

  function handleRequestReject() {
    let { selectedRowData } = actionMenuState;
    dispatch(setRequestApprovalFormState(false));
    dispatch(
      initializeForm({
        formType: FormType.SIGNING_REQUEST,
        serviceData: {
          ...selectedRowData,
          type: "reject",
        },
      })
    );

    setActionMenuState({ anchorEl: null, selectedRowData: null });
  }

  function ActionMenu() {
    return (
      <Menu
        sx={{
          "& .MuiPaper-root .MuiList-root": {
            minWidth: "150px",
            py: 0,
            "& .MuiButtonBase-root": {
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "tertiary.light",
              fontSize: "0.9rem",
              fontWeight: 500,
              "&:hover": {
                bgcolor: "tertiary.main",
              },
              "& .MuiListItemText-root .MuiTypography-root": {
                fontSize: "0.9rem",
                fontWeight: 500,
              },
            },
          },
        }}
        id="action-menu"
        anchorEl={actionMenuState.anchorEl}
        open={Boolean(actionMenuState.anchorEl)}
        onClose={() => {
          setActionMenuState({ anchorEl: null, selectedRowData: null });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleRequestApprove()}>
          {Labels.APPROVE}
        </MenuItem>
        {
          <MenuItem onClick={() => handleRequestReject()}>
            {Labels.REJECT}
          </MenuItem>
        }
      </Menu>
    );
  }

  function renderActionCell(data) {
    if (
      data.status === SigningRequestStatus.ApprovePending &&
      !data.approvalStatus
    ) {
      return (
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
            pl: 2,
          }}
        >
          <IconButton
            onClick={(event) =>
              setActionMenuState({
                anchorEl: event.target,
                selectedRowData: data,
              })
            }
            size="small"
            color="primary"
            aria-label="more actions"
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontSize: "1.5rem", fontWeight: 600, pl: 3 }}
          >
            -
          </Typography>
        </Box>
      );
    }
  }

  return (
    <>
      <CustomTable
        tableHeight={"100%"}
        rowData={rowData}
        columnData={columnData}
        sortingField="submittedOn"
        sortingType="desc"
      />
      <ActionMenu />
    </>
  );
}
