import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { ModalType } from "../../utils/constants";
import { closeModal } from "./modalSlice";
import modalConfig from "./modalConfig";
import Divider from "@mui/material/Divider";
import { IconButton } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import WarningIcon from "@mui/icons-material/Warning";

export default function Modal() {
  const dispatch = useDispatch();

  const { modalType, modalData } = useSelector((state) => state.modal);

  function handleModalClose() {
    dispatch(closeModal());
  }

  function handleModalSubmit() {
    modalSetupData.onModalSubmit(modalData);
  }

  const modalSetupData = modalConfig[modalType];

  return (
    <Dialog
      {...(modalSetupData?.width && { maxWidth: modalSetupData.width })}
      sx={{ ".MuiPaper-root": { borderRadius: "5px", minWidth: "500px" } }}
      open={modalType !== ModalType.NONE}
      onClose={() => handleModalClose()}
    >
      {modalType !== ModalType.NONE && (
        <>
          <DialogTitle
            sx={{
              color: "primary.main",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {modalSetupData?.warningIcon && (
              <WarningIcon
                sx={{
                  color: "#ef4f44",
                }}
              />
            )}
            {modalSetupData.title}
          </DialogTitle>

          <ModalCloseIcon handleModalClose={handleModalClose} />

          <Divider />

          {modalSetupData?.customComponent ? (
            <modalSetupData.body data={modalData} />
          ) : (
            <DialogContent> {modalSetupData.body}</DialogContent>
          )}

          {/* <DialogContent>
            {modalSetupData?.customComponent ? (
              <modalSetupData.body data={modalData} />
            ) : (
              modalSetupData.body
            )}
          </DialogContent> */}

          {!modalSetupData?.hideFooter && (
            <DialogActions sx={{ display: "flex", gap: 2, pb: 2, px: 3 }}>
              <Button
                sx={{ borderRadius: "5px" }}
                variant="outlined"
                onClick={() => handleModalClose()}
              >
                {modalSetupData.buttonLabelForCancel}
              </Button>

              <Button
                sx={{ borderRadius: "5px" }}
                variant="contained"
                onClick={() => {
                  handleModalClose();
                  handleModalSubmit();
                }}
                autoFocus
              >
                {modalSetupData.buttonLabelForSubmit}
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
}

function ModalCloseIcon({ handleModalClose }) {
  return (
    <IconButton
      onClick={() => handleModalClose()}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <HighlightOffOutlinedIcon
        fontSize="small"
        color="primary"
        sx={{
          opacity: 0.8,
          "&:hover": {
            opacity: 1,
          },
        }}
      />
    </IconButton>
  );
}
