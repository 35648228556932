import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import getSSLTableDataService from "./getSSLTableData";
import { navigateBack } from "../../features/sslCertificateManagement/sslManagementSlice";

export default async function deleteCertificate({ certificateID }) {
  try {
    store.dispatch(showLoader());
    await Api.delete(URL.SSLManagement.DeleteCertificate + certificateID + "/");

    store.dispatch(navigateBack());

    Notification.success(NotificationLabels.CERTIFICATE_DELETED);
    store.dispatch(getSSLTableDataService());
  } catch (error) {
    Notification.error(NotificationLabels.FAILED_TO_DELETE_CERTIFICATE);
  } finally {
    store.dispatch(closeLoader());
  }
}
