// React
import { useRef, useEffect } from "react";

// MUI
import Box from "@mui/material/Box";

// React Router
import { Link } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Constants
import { Status, Labels } from "../../utils/constants";

// Custom Components
import CustomTable from "../../components/ui/table/CustomTable";

import getLogsTableDataService from "../../services/logs/getLogsTableDataService";
import { setSelectedLogData } from "./LogsSlice";
import { renderStatusCell, fromatDateForUI } from "./utils";
import { useErrorBoundary } from "react-error-boundary";
import { Skeleton } from "@mui/material";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import getLoggingSysLogService from "../../services/logs/getLoggingSysLogService";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        mt: 3,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function LogsTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const {
    logsTableDataLoadingState: status,
    logsTableDataErrorState: error,
    logsTableData: tableData,
  } = useSelector((state) => state.logs);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getLogsTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return <TableComponent dispatch={dispatch} tableData={tableData} />;
}

function TableComponent({ dispatch, tableData }) {
  const timezone = useSelector((state) => state.logs.timezone);

  // Row data for the table
  const rowData = tableData;

  // Column data for table
  const columnData = [
    {
      field: "Date",
      headerName: Labels.DATE,
      width: 300,
      filterable: false,
      sortable: true,
      renderCell: (params) => renderDateCell(params),
    },
    {
      field: "Event",
      headerName: Labels.EVENT,
      flex: 1,
      minWidth: 250,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "Source",
      headerName: Labels.SOURCE,
      flex: 1,
      minWidth: 250,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "Severity",
      headerName: Labels.STATUS,
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => renderStatusCell(params.value),
    },
  ];

  function renderDateCell(params) {
    if (params.value) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            onClick={() => {
              dispatch(setSelectedLogData(params.row));
            }}
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail`}
          >
            {fromatDateForUI(params.value, timezone)}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function getTableOptionConfig() {
    let config = {
      includeShowMaxRows: true,
      includeCSVExport: true,
      fileName: "Logs",
    };

    if (checkUserPermission(Permissions.DownloadLoggingSysLog)) {
      config.includeSYSLogExport = true;
      config.sysLogExportService = getLoggingSysLogService;
    }

    return config;
  }

  return (
    <>
      <CustomTable
        sortingField="Date"
        sortingType="desc"
        valueToBeUsedAsID={"Date"}
        rowData={rowData}
        columnData={columnData}
        tableOptionConfig={getTableOptionConfig()}
      />
    </>
  );
}
