import URL from "../../utils/url";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import axios from "axios";
import { store } from "../../lib/redux/store";

export default async function getUsersFromAzure(userName) {
  try {
    let azureAccessToken = store.getState().auth.ADToken;

    let reqBody = {
      userName: userName,
    };

    const usersReq = await axios.get(
      URL.External.Azure.UserDetails +
        `?$search="userPrincipalName:${userName}"&$orderby=userPrincipalName`,
      {
        data: reqBody,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${azureAccessToken}`,
          ConsistencyLevel: "eventual",
        },
      }
    );

    let result = [];

    let users =
      usersReq.data.value?.map((user) => ({
        id: user.id,
        email: user.mail,
        label: user.userPrincipalName,
      })) ?? [];

    if (users.length > 0) {
      result.push(...users);
    }

    return result;
  } catch (error) {
    Notification.error(NotificationLabels.ERROR_IN_FETCHING_USERS);
    throw new Error(error);
  }
}
