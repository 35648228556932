import { Paper, Stack, Typography, Button, Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextAreaComponent from "../components/ui/inputComponents/TextAreaComponent";
import { useLocation } from "react-router-dom";
import Loader from "../features/loader/Loader";
import updateLicenseService from "../services/license/updateLicenseService";
import checkUserPermission from "../utils/checkUserPermission";
import { Permissions } from "../utils/enums";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { store } from "../lib/redux/store";

export default function LicensePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get("action");
  const navigate = useNavigate();

  let isUserAuthorized = checkUserPermission(Permissions.ActivateLicense);

  const navigateBack = useSelector((state) => state.license.navigateToRoot);

  if (navigateBack) {
    window.location.href = "/";
  }

  const formik = useFormik({
    initialValues: {
      licenseKey: "",
    },

    validationSchema: Yup.object().shape({
      licenseKey: Yup.string().required("License key is required").min(1),
    }),

    onSubmit: (values) => {
      const cleanedValues = {};

      Object.keys(values).forEach((key) => {
        if (typeof values[key] === "string") {
          cleanedValues[key] = values[key].trim();
        } else {
          cleanedValues[key] = values[key];
        }
      });

      updateLicenseService(values.licenseKey, action);
    },
  });

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
        }}
      >
        <Stack
          direction="column"
          sx={{ textAlign: "center", width: "fit-content" }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#CECCCC",
              fontSize: "1.75rem",
              fontWeight: 700,
              width: 1,
              maxWidth: "800px",
              mb: 2.5,
              wordBreak: "break-word",
            }}
          >
            {action === "expired"
              ? "Your license has expired. Please renew it to continue using our services"
              : action === "renew"
              ? "Renew your license now to extend your service period and ensure uninterrupted access."
              : "No license is currently associated with your account. Please provide the license key"}
          </Typography>

          {isUserAuthorized && (
            <Box>
              <TextAreaComponent
                error={formik.errors["licenseKey"]}
                touched={formik.touched["licenseKey"]}
                label=""
                placeholder={"Enter the key here"}
                id="licenseKey"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values["licenseKey"]}
              />

              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => formik.submitForm()}
                sx={{
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 700,
                  px: 3,
                  py: 1,
                  mt: 4,
                }}
              >
                {action === "renew" || action === "expired"
                  ? "Renew License"
                  : "Activate License"}
              </Button>
            </Box>
          )}
        </Stack>
      </Paper>
      <Loader />
    </>
  );
}
