import { closeForm } from "../../features/forms/formSlice";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";
import getSSLTableDataService from "./getSSLTableData";

export default async function uploadSSLCertificate(formData, _, __) {
  try {
    store.dispatch(showLoader());

    let reqBody = new FormData();

    const domainName = window.location.hostname;

    reqBody.append("domain", domainName);
    reqBody.append("certificate", formData.certificate);
    reqBody.append("fullchain", formData.certificateFullChain);

    await Api.post(URL.SSLManagement.UploadCertificate, reqBody, {
      headers: { "Content-type": "multipart/form-data" },
    });

    store.dispatch(getSSLTableDataService());

    Notification.success(
      "Certificates uploaded and environment variables set successfully"
    );
    store.dispatch(closeForm());
  } catch (error) {
    let errorMessage = error?.response?.data?.error ?? null;
    Notification.error(errorMessage ?? "Failed to upload certificate");
  } finally {
    store.dispatch(closeLoader());
  }
}
