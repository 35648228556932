const URL = {
  Auth: {
    ADConfig: "api/system_setup/identitystore/ad/",
    UserData: "api/auth/GetLoginToken/",
    ProductVersion: "api/dashboard/version/",
  },
  License: {
    GetLicenseInfo: "api/license/retrieve/",
    ActivateLicense: "api/license/Activation/",
  },
  Dashboard: {
    QuickInsight: "api/dashboard/tile_info/",
    HSMHealth: "api/dashboard/hsm_health/",
    SignatureCount: "api/dashboard/count/",
    ExpiringCertificates: "api/dashboard/piechart/",
    SigningKSP: "api/dashboard/ksp_limit/",
  },
  KeysAndCertificates: {
    allKeysAndCertsTableData: "api/certificate_manage/certificates",
    ApplicationDetailFromKeyID: "api/certificate_manage/mappedProjects/",
    CertificateDetailFromCertificateName:
      "api/certificate_manage/certificateDetailsByName/",
    updateKeyAndCertStatus: "api/certificate_manage/certificates/",
    UpdateCertificateStatusToActive:
      "api/certificate_manage/certificate_status/active/",
    UpdateCertificateStatusToInActive:
      "api/certificate_manage/certificate_status/inactive/",
    KeyAndCertificateTriggerDataByKeyID:
      "api/certificate_manage/certificatesTriggers/",
    UpdateTriggerDataByKeyID: "api/certificate_manage/certificatesTriggers/",
    GenerateSelfSignedCertificate:
      "api/certificate_manage/selfSignCertificate/",
    DownloadSelfSignedCertificate:
      "api/certificate_manage/downloadcertificate/",
    GenerateSigningRequestCertificate: "api/certificate_manage/signRequest/",
    DownloadSigningRequestCertificate: "api/certificate_manage/downloadCSR/",
    GetCertificateDetailByName:
      "api/certificate_manage/certificateDetailsByName/",
    GenerateImportCertificate: "api/certificate_manage/importCertificate/",
    UpdateCertReproducibleBuildKeyUsage:
      "api/certificate_manage/update_key_usage",
  },
  SigningRequests: {
    TableData: "api/signing_request/signing_request/",
    TableDataByUserID: "api/signing_request/signing_request_approval/",
    CheckMFA: "api/multifactor/check_mfa",
    SendOTP: "api/multifactor/SendApprovalOTP/",
    ValidateOTP: "api/multifactor/Validate_OTP/",
    Approval: "api/signing_request/singing_request_approval_api/",
    Rejection: "api/signing_request/signing_request/",
  },
  Logs: {
    GetLogs: "/api/reports/daily-log",
    GetSysLog: "/api/reports/daily-log-file",
  },
  SigningTools: {
    GetUserPermissions: "api/permissions/userpermissionsbyuserID/",
    GetToolData: "api/system_setup/GetSystemInfoTool",
    DownloadTool: "api/system_setup/downloadclienttool",
  },
  Reports: {
    GetSigningRequestReportData: "api/reports/signing_report",
    GetAuditTrialReportData: "api/reports/log_report",
    GetApprovalRequestReportData: "api/reports/approval_report",
    GetCertificateRequestReportData: "api/reports/certificate_report",
    GetClientData: "api/reports/client_report",
    GetApplicationList: "api/project_manage/basic/",
    GetUserList: "api/user_management/userList/",
    GetSysLog: "/api/reports/syslog_file/",
  },
  Backup: {
    GetBackupData: "api/system_setup/backup_list/",
    TakeBackup: "api/system_setup/backup/",
    DownloadBackup: "api/system_setup/backup/",
    RestoreBackup: "api/system_setup/restore/",
  },
  User: {
    GetAllUsers: "api/user_management/userList/",
    GetP12Certificates: "api/signing/p12_user/",
    RegisterUser: "api/auth/register",
    GenerateToken: "api/auth/APIToken/",
    GenerateP12Certificate: "api/system_setup/generate_p12/",
    GetPermissions: "api/permissions/userpermissionsbyuserGroupID/",
    UpdatePermissions: "api/permissions/Grantpermission/",
    DeleteUser: "api/user_management/delete-user/",
    UpdatedRoles: "api/user_management/userRoleMapping/",
    UpdateLockStatus: "api/user_management/userList/",
  },
  Email: {
    GetConfigTableData: "api/email/email_config/",
    GetTemplateTableData: "api/email/email_template/",
    ManageTemplate: "api/email/email_template/",
    ManageConfig: "api/email/email_config/",
  },
  IdentityStore: {
    GetADTableData: "api/system_setup/identitystore/ad/",
    ManageAD: "api/system_setup/identitystore/ad/",
    ManageSSO: "api/system_setup/identitystore/sso/",
    GetSSOTableData: "api/system_setup/identitystore/sso/",
  },
  ApplicationManagement: {
    ApplicationTableData: "api/project_manage/basic/",
    EnvironmentDetail: "api/project_manage/create_certificate/",
    SigningUserDetail: "api/project_manage/signingUsersByEnvMappingid/",
    ApproverDetail: "api/project_manage/ApprovalDetailsByEnvMappingId/",
    EditApplicationStatus: "api/project_manage/basic/",
    GetAllCertificate: "api/certificate_manage/certificates",
    GetAllEnvironment: "api/project_manage/environment/",
    GetAllApproverType: "api/project_manage/approver_master/",
    AddApplication: "api/project_manage/basic/",
    UpdateApplication: "api/project_manage/basic/",
    AddEnvironment: "api/project_manage/environment/",
    ManageApplicationEnvironment:
      "api/project_manage/project_certificate_mapping/",
    ManageSigningUserForPolicy: "api/project_manage/signing_user/",
    ManageMFA: "api/project_manage/project_certificate_mapping/",
    ManageApprover: "api/project_manage/approval/",
    GetAllTeams: "api/project_manage/teams/",
    GetAllMembers: "api//user_management/userList/",
    CreateTeam: "api/project_manage/teams/",
    CertificateTeamDetail: "api/project_manage/create_certificates/",
    ManageTeam: "api/project_manage/teams/",
    UpdateCertificateTeam: "api/project_manage/update_certificate_teams/",
  },
  External: {
    Azure: {
      UserDetails: "https://graph.microsoft.com/v1.0/users",
      GroupDetails: "https://graph.microsoft.com/v1.0/groups",
    },
  },
  Signing: {
    SigningProjects: "api/signing/signingProjects",
  },
  SSLManagement: {
    GetSSLData: "api/system_setup/tls_records/",
    GENERATE_CSR: "api/system_setup/generate_tls_csr/",
    UploadCertificate: "api/system_setup/import_tls/",
    RenewSSL: "api/system_setup/renew_tls/",
    DeleteCertificate: "api/system_setup/tls_records/delete_records/",
    CheckModalFlag: "api/system_setup/tls_records/status/",
  },
};

export default URL;
