import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  JsonView,
  collapseAllNested,
  allExpanded,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { useState } from "react";
import { Stack, Button } from "@mui/material";

const LogsDetailModal = function ({ detailObj }) {
  const [isExpanded, setIsExpanded] = useState(true);

  function isValidJSON(jsonStr) {
    try {
      JSON.parse(jsonStr);
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 280px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "hidden",
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary.main" variant="h6">
          Detail
        </Typography>
        {isValidJSON(detailObj) && (
          <Stack direction={"row"} sx={{ gap: 3 }}>
            <Button
              onClick={() => setIsExpanded(false)}
              sx={{ borderRadius: "5px" }}
              size="small"
              variant="outlined"
            >
              Collapse
            </Button>
            <Button
              onClick={() => setIsExpanded(true)}
              sx={{ borderRadius: "5px" }}
              size="small"
              variant="contained"
            >
              Expand
            </Button>
          </Stack>
        )}
      </Stack>

      <Box
        className="custom-scroll-bar"
        sx={{
          height: "90%",
          mt: 2,
          overflowY: "auto",
          "&>div": {
            background: "none",
          },
        }}
      >
        {isValidJSON(detailObj) ? (
          <JsonView
            data={JSON.parse(detailObj)}
            shouldExpandNode={isExpanded ? allExpanded : collapseAllNested}
            style={defaultStyles}
          />
        ) : (
          <>{detailObj}</>
        )}
      </Box>
    </Box>
  );
};

export default LogsDetailModal;
