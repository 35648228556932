import { useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetApplicationFormStates } from "../../features/applicationManagement/applicationManagementSlice";
import FormHeader from "../../features/applicationManagement/forms/FormHeader";
import getApplicationTableDataService from "../../services/applicationManagement/getApplicationTableDataService";
import { useParams } from "react-router-dom";
import { Status } from "../../utils/constants";
import FormWrapper from "../../features/applicationManagement/forms/FormWrapper";
import getAllCertificateService from "../../services/applicationManagement/getAllCertificateService";
import getAllEnvironmentService from "../../services/applicationManagement/getAllEnvironmentService";
import getApplicationEnvironmentsDetailService from "../../services/applicationManagement/getApplicationEnvironmentsDetailService";
import { ApplicationManagementFormTypes } from "../../utils/enums";
import getAllApproverTypeService from "../../services/applicationManagement/getAllApproverTypeService";
import { toggleForm } from "../../features/applicationManagement/applicationManagementSlice";
import ErrorComponent from "../../components/ui/ErrorComponent";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import getAllTeamService from "../../services/applicationManagement/getAllTeamService";
import getAllMemberService from "../../services/applicationManagement/getAllMemberService";

function ManageApplication({ dispatch }) {
  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const navigateBack = useSelector(
    (state) => state.applicationManagement.formState.navigateBack
  );

  if (navigateBack) {
    navigate("/application-management");
  }

  const { applicationID } = useParams();

  const abortControllerRef = useRef(null);

  useEffect(() => {
    dispatch(toggleForm({ isNewApplication: false }));

    return () => {
      dispatch(toggleForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      dispatch(resetApplicationFormStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationID]);

  const {
    allApplicationStatus,
    allApplicationError,

    environmentDetailStatus,
    environmentDetailError,

    certificateStatus,
    certificateError,

    teamStatus,
    teamError,

    memberStatus,
    memberError,

    environmentStatus,
    environmentError,

    approverTypeStatus,
    approverTypeError,

    activeForm,
  } = useSelector((state) => state.applicationManagement.formState);

  useEffect(() => {
    if (
      allApplicationStatus === Status.Idle &&
      activeForm === ApplicationManagementFormTypes.Application
    ) {
      dispatch(
        getApplicationTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }

    if (
      environmentDetailStatus === Status.Idle &&
      (activeForm === ApplicationManagementFormTypes.Environment ||
        activeForm === ApplicationManagementFormTypes.Policy ||
        activeForm === ApplicationManagementFormTypes.Team)
    ) {
      dispatch(
        getApplicationEnvironmentsDetailService({
          abortController: abortControllerRef.current,
          applicationID: applicationID,
        })
      );
    }

    if (
      certificateStatus === Status.Idle &&
      activeForm === ApplicationManagementFormTypes.Environment
    ) {
      dispatch(
        getAllCertificateService({
          abortController: abortControllerRef.current,
        })
      );
    }

    if (
      environmentStatus === Status.Idle &&
      activeForm === ApplicationManagementFormTypes.Environment
    ) {
      dispatch(
        getAllEnvironmentService({
          abortController: abortControllerRef.current,
        })
      );
    }

    if (
      teamStatus === Status.Idle &&
      activeForm === ApplicationManagementFormTypes.Team
    ) {
      dispatch(
        getAllTeamService({
          abortController: abortControllerRef.current,
        })
      );
    }

    if (
      memberStatus === Status.Idle &&
      activeForm === ApplicationManagementFormTypes.Team
    ) {
      dispatch(
        getAllMemberService({
          abortController: abortControllerRef.current,
        })
      );
    }

    if (
      approverTypeStatus === Status.Idle &&
      activeForm === ApplicationManagementFormTypes.Policy
    ) {
      dispatch(
        getAllApproverTypeService({
          abortController: abortControllerRef.current,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allApplicationStatus,
    environmentDetailStatus,
    certificateStatus,
    environmentStatus,
    approverTypeStatus,
    teamStatus,
    memberStatus,
    activeForm,
  ]);

  if (
    allApplicationError ||
    certificateError ||
    environmentError ||
    approverTypeError ||
    environmentDetailError ||
    teamError ||
    memberError
  ) {
    showBoundary(
      allApplicationError ||
        environmentDetailError ||
        certificateError ||
        environmentError ||
        approverTypeError ||
        teamError ||
        memberError
    );
  }

  return (
    <Stack direction={"column"} sx={{ width: 1, height: 1, gap: "20px" }}>
      <FormHeader isNewApplication={false} />

      <FormWrapper isNewApplication={false} applicationID={applicationID} />
    </Stack>
  );
}

export default function ErrorComponentWrappedManageApplication() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetApplicationFormStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetApplicationFormStates())}
    >
      <ManageApplication dispatch={dispatch} />
    </ErrorBoundary>
  );
}
