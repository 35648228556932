import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { Labels } from "../../utils/constants";

const getApplicationTableDataService = createAsyncThunk(
  "applicationManagement/getApplicationTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.ApplicationManagement.ApplicationTableData,
        abortController && {
          signal: abortController.signal,
        }
      );

      let businessUnits = new Set();

      let response = request.data.map((data, index) => {
        if (data?.business_unit.trim()) {
          businessUnits.add(data.business_unit.trim().toLowerCase());
        }
        return {
          id: data?.id ?? index,
          applicationID: data?.id ?? null,
          applicationName: data?.project_name ?? null,
          applicationDescription: data?.description ?? null,
          isDeleted: data?.Is_deleted ?? true,
          applicationOwners: data?.owner ?? [],
          businessUnit: data?.business_unit ?? null,
          repositoryURL: data?.repository_url ?? null,
          artifactTypes: data?.artifact_type ?? [],
          status: data?.Is_deleted === false ? Labels.ACTIVE : Labels.IN_ACTIVE,
          createdBy: data?.created_user ?? null,
          createdOn: data?.created_date ?? null,
          modifiedBy: data?.modified_by ?? null,
          modifiedDate: data?.modified_date ?? null,
        };
      });

      return {
        businessUnits: Array.from(businessUnits),
        applications: response,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getApplicationTableDataService;
