import React, { useEffect } from "react";
import MultiSelectInputComponent from "../../components/ui/inputComponents/MultiSelectInputComponent";
import { store } from "../../lib/redux/store";
import { Box } from "@mui/material";
import { useState } from "react";
import { setFilterRoles } from "./userSlice";
import { useDispatch } from "react-redux";
import { UserRoles } from "../../utils/enums";

let roleOptions = [
  {
    id: 1,
    label: UserRoles.SystemAdmin.label,
    value: UserRoles.SystemAdmin,
  },
  {
    id: 2,
    label: UserRoles.ProjectManager.label,
    value: UserRoles.ProjectManager,
  },
  {
    id: 3,
    label: UserRoles.SecurityOfficer.label,
    value: UserRoles.SecurityOfficer,
  },
  {
    id: 4,
    label: UserRoles.Auditor.label,
    value: UserRoles.Auditor,
  },
  {
    id: 5,
    label: UserRoles.Developer.label,
    value: UserRoles.Developer,
  },
];

export default function RoleFilter() {
  const dispatch = useDispatch();

  const [roles, setRoles] = useState(
    (() => store.getState().user.filterRoles)()
  );

  useEffect(() => {
    dispatch(setFilterRoles(roles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  return (
    <Box sx={{ width: 1, maxWidth: 220 }}>
      <MultiSelectInputComponent
        options={roleOptions}
        label="User Role Filter"
        value={roles}
        id="roleFilter"
        placeholder="Select User Roles"
        setFieldValue={(_, values) => {
          setRoles(values);
        }}
        optionIsString={false}
        size="small"
      />
    </Box>
  );
}
