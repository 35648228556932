import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetConfigState,
  selectConfigDataByConfigID,
} from "../../../features/email/emailSlice";
import getEmailConfigTableDataService from "../../../services/email/getEmailConfigTableData";
import ConfigDetailTable from "../../../features/email/EmailConfigDetailTable";

import { Box } from "@mui/material";
import ConfigDetailModal from "../../../features/email/EmailConfigDetailModal";
import Skeleton from "@mui/material/Skeleton";
import { useErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        pt: 3,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 380px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function EmailConfigDetail() {
  const { configID } = useParams();

  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isTableDataFetched, error, selectedConfigData } = useSelector(
    (state) => selectConfigDataByConfigID(state, configID)
  );

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(getEmailConfigTableDataService());
    }

    if (!selectedConfigData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configID, isTableDataFetched, error, selectedConfigData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedConfigData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1, pt: 3 }}>
      <ConfigDetailTable
        dispatch={dispatch}
        configDetail={selectedConfigData}
      />
      <ConfigDetailModal configDetail={selectedConfigData} />
    </Box>
  );
}

export default function ErrorComponentWrappedEmailConfigDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetConfigState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetConfigState())}
    >
      <EmailConfigDetail dispatch={dispatch} />
    </ErrorBoundary>
  );
}
