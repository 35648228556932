// React
import { useRef, useEffect } from "react";

// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

// Redux
import { useSelector } from "react-redux";

// Utils
import { Labels, Status } from "../../utils/constants";

// Services
import { selectApplicationEnvironmentsDetail } from "./applicationManagementSlice";
import getApplicationEnvironmentsDetail from "../../services/applicationManagement/getApplicationEnvironmentsDetailService";
import { Divider, Tooltip } from "@mui/material";
import { CertificateTypes } from "../../utils/enums";
import { useErrorBoundary } from "react-error-boundary";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "../../components/icons/InfoIcon";

export default function ApplicationDetailModal({
  applicationID,
  applicationData,
  dispatch,
}) {
  const { showBoundary } = useErrorBoundary();

  const abortControllerRef = useRef(null);

  const {
    applicationEnvrionmentsDetail: environmentDetail,
    status,
    error,
  } = useSelector(selectApplicationEnvironmentsDetail);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    dispatch(
      getApplicationEnvironmentsDetail({
        abortController: abortControllerRef.current,
        applicationID: applicationID,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationID]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) abortControllerRef.current.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationID]);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  return (
    <Stack
      className="custom-scroll-bar"
      sx={{
        flexDirection: "column",
        gap: 4,
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 280px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <RenderApplicationDetailSection data={applicationData} />

      <Divider />

      {environmentDetail.map((envDetail, index) => (
        <>
          <RenderApplicationEnvironmentDetailSection data={envDetail} />
          {index !== environmentDetail.length - 1 && <Divider />}
        </>
      ))}

      <ApplicationEnvLoader status={status} />
    </Stack>
  );
}

function RenderApplicationDetailSection({ data }) {
  let arr = [
    {
      label: Labels.APPLICATION_NAME,
      value: data?.applicationName ?? "-",
    },
    {
      label: Labels.BUSINESS_UNIT,
      value: data?.businessUnit ?? "-",
    },
    {
      label: Labels.APPLICATION_OWNERS,
      value:
        data?.applicationOwners?.map((owner) => owner.label).join(", ") ?? "-",
    },
    {
      label: Labels.REPOSITORY_URL,
      value: data?.repositoryURL ?? "-",
    },
    {
      label: Labels.ARTIFACT_TYPES,
      value:
        data?.artifactTypes?.map((artifact) => artifact.label).join(", ") ??
        "-",
    },
    {
      label: Labels.APPLICATION_DESCRIPTION,
      value: data?.applicationDescription ?? "-",
    },
    {
      label: Labels.STATUS,
      component: renderStatusCell(data?.status),
    },
  ];

  function renderStatusCell(value) {
    if (value) {
      return (
        <Box
          sx={{
            width: "90px",
            textAlign: "center",
            paddingBlock: 0.75,
            color: "primary.main",
            borderRadius: "5px",
            backgroundColor:
              value === Labels.ACTIVE ? "success.main" : "error.main",
          }}
        >
          {value}
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary.main" variant="h6">
          Application Detail
        </Typography>
      </Grid>

      <Grid container item spacing={6}>
        {arr.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Typography
              sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
              variant="subtitle2"
            >
              {item.label}
            </Typography>

            {item.component ? (
              item.component
            ) : (
              <Typography variant="subtitle1">{item.value}</Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

function RenderApplicationEnvironmentDetailSection({ data }) {
  function resolveCertificateType() {
    let certificateTypeText = "-";

    switch (Number(data.certificateType)) {
      case CertificateTypes.CertificateSigningRequest: {
        certificateTypeText = "Signing Request";
        break;
      }
      case CertificateTypes.ImportCertificate: {
        certificateTypeText = "Import Certificate";
        break;
      }
      case CertificateTypes.SelfSignedCertificate: {
        certificateTypeText = "Self Signed Certificate";
        break;
      }
      default: {
        break;
      }
    }

    return certificateTypeText;
  }

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.ENVIRONMENT_NAME,
        value: data?.environmentName ?? "-",
      },
      {
        label: Labels.CERTIFICATE_NAME,
        value: data?.certificateName ?? "-",
      },
      {
        label: Labels.CERTIFICATE_TYPE,
        value: resolveCertificateType(),
      },
      {
        label: Labels.CERTIFICATE_ORGANIZATION,
        value: data?.certificateOrganizationName ?? "-",
      },
      {
        label: Labels.SIGNING_USERS,
        value:
          data?.signingUsers?.map((user) => user.userName)?.join(", ") ?? "-",
      },
      {
        label: Labels.NEED_MFA,
        value: data?.needMFA ? "YES" : "NO",
      },
      {
        label: Labels.APPROVER_DETAILS,
        component: (
          <RenderApproverDetails approverDetails={data.approverDetails} />
        ),
        fullWidth: true,
      },
      {
        label: Labels.TEAM_DETAILS,
        component: <RenderTeamDetails teamDetails={data.teams} />,
        fullWidth: true,
      },
    ];

    return arr;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary.main" variant="h6">
          Environment Detail
        </Typography>
      </Grid>
      <Grid container item spacing={6}>
        {formatDataForUI().map((item, index) => (
          <Grid item xs={12} {...(!item?.fullWidth && { md: 6, lg: 4 })}>
            <Typography
              sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
              variant="subtitle2"
            >
              {item.label}
            </Typography>

            {item.component ? (
              item.component
            ) : (
              <Typography variant="subtitle1">{item.value}</Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

function RenderApproverDetails({ approverDetails }) {
  if (approverDetails?.length === 0) {
    return (
      <Typography sx={{ width: 1 }} variant="subtitle1">
        {"-"}
      </Typography>
    );
  }

  return (
    <Grid item container rowSpacing={3} columnSpacing={6}>
      {approverDetails.map((detail, index) => (
        <Grid item xs={12} md={6} lg={4} key={index}>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "0.95rem", fontWeight: "700" }}
          >
            Type :
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ fontSize: "0.95rem", ml: 1 }}
            >
              {detail.approverTypeName || "-"}
            </Typography>
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "0.95rem", fontWeight: "700" }}
          >
            Approvers :
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ fontSize: "0.95rem", ml: 1 }}
            >
              {detail.approverQuorumUsers.length > 0
                ? detail.approverQuorumUsers
                    .map((user) => user.userName)
                    .join(", ")
                : "none"}
            </Typography>
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "0.95rem", fontWeight: "700" }}
          >
            Level :
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ fontSize: "0.95rem", ml: 1 }}
            >
              {detail.approverLevel || "-"}
            </Typography>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

function RenderTeamDetails({ teamDetails }) {
  if (teamDetails?.length === 0) {
    return (
      <Typography sx={{ width: 1 }} variant="subtitle1">
        {"-"}
      </Typography>
    );
  }

  return (
    <Stack
      item
      sx={{
        flexWrap: "wrap",
        gap: 3,
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {teamDetails.map((team, index) => (
        <Tooltip
          title={
            <Stack sx={{ p: 1, flexDirection: "column", gap: 1 }}>
              <Typography
                color="inherit"
                sx={{
                  fontSize: "0.9rem",
                }}
              >
                Members :
                {
                  <Typography color="inherit" sx={{ fontSize: "0.75rem" }}>
                    {team.members?.map((member) => member.name).join(", ")}
                  </Typography>
                }
              </Typography>
            </Stack>
          }
          arrow
        >
          <Box
            sx={{
              border: "1px solid rgb(189, 189, 189)",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            {team.teamName}
            <IconButton sx={{ p: 0, ml: 2 }}>
              <InfoIcon width={13} fill={"#1b2c58"} />
            </IconButton>
          </Box>
        </Tooltip>
      ))}
    </Stack>
  );
}

function ApplicationEnvLoader({ status }) {
  if (status === Status.Succeeded) {
    return;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary.main" variant="h6">
          Environment Detail
        </Typography>
      </Grid>
      <Grid container item spacing={6}>
        {Array.from({ length: 8 }, (_, index) => index).map((_, index) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Skeleton variant="rounded" width={"100%"} height={60} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
