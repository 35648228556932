import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { IdentityStoreType, IdentityStoreADType } from "../../utils/enums";

const getIdentityStoreTableDataService = createAsyncThunk(
  "identityStore/getIdentityStoreTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let ADRequest = Api.get(
        URL.IdentityStore.GetADTableData,
        abortController && {
          signal: abortController.signal,
        }
      );

      let SSORequest = Api.get(
        URL.IdentityStore.GetSSOTableData,
        abortController && {
          signal: abortController.signal,
        }
      );

      const [ADResponse, SSOResponse] = await Promise.all([
        ADRequest,
        SSORequest,
      ]);

      let response = [];

      if (ADResponse.data?.length > 0) {
        let arr = ADResponse.data.map((data, index) => ({
          id: (data?.id ?? index) + "AD",
          identityID: data?.id ?? null,
          identityType: IdentityStoreType.AD,
          ADType: data?.AD_Type
            ? data.AD_Type === "azure"
              ? IdentityStoreADType.AzureAD
              : IdentityStoreADType.LocalAD
            : null,
          callbackURL: data?.CallBackURL ?? null,
          clientID: data?.ClientId ?? null,
          connectionURL: data?.ConnectionURL ?? null,
          status: data.Is_deleted ? "Inactive" : "Active",
          secretKey: data?.SecretKey ?? null,
          createdDate: data?.created_date ?? null,
          createdUser: data?.created_user ?? null,
          modifiedBy: data?.modified_by ?? null,
          modifiedDate: data?.modified_date ?? null,
        }));

        response.push(...arr);
      }

      if (SSOResponse.data?.length > 0) {
        let arr = SSOResponse.data.map((data, index) => ({
          id: (data?.id ?? index) + "SSO",
          identityID: data?.id ?? null,
          identityType: IdentityStoreType.SSO,
          callbackURL: data?.CallBackURL ?? null,
          clientID: data?.ClientId ?? null,
          connectionURL: data?.ConnectionURL ?? null,
          status: data.Is_deleted ? "Inactive" : "Active",
          logoutURL: data?.LogoutURL ?? null,
          secretKey: data?.SecretKey ?? null,
          createdDate: data?.created_date ?? null,
          createdUser: data?.created_user ?? null,
          modifiedBy: data?.modified_by ?? null,
          modifiedDate: data?.modified_date ?? null,
        }));

        response.push(...arr);
      }

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getIdentityStoreTableDataService;
