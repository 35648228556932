import { store } from "../../lib/redux/store";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Notification from "../../utils/notificationConfiguration";
import { NotificationLabels } from "../../utils/constants";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import { closeForm } from "../../features/forms/formSlice";
import getUserManagementTableDataService from "./getUserManagementTableDataService";

export default async function updateUserRoleService(formData, _, serviceData) {
  try {
    store.dispatch(showLoader());

    let { userID } = serviceData;
    let currentUserID = store.getState().auth.userID;

    let requestBody = {
      user_id: userID,
      group_ids: formData.userRoles?.map((role) => role.id) ?? [],
    };

    await Api.post(URL.User.UpdatedRoles, requestBody);

    if (currentUserID === userID) {
      Notification.info(
        "Your roles have been updated. The UI will be reloaded in 5 second."
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }

    Notification.success(NotificationLabels.USER_ROLE_UPDATED);

    store.dispatch(getUserManagementTableDataService());

    store.dispatch(closeForm());
  } catch (error) {
    Notification.error(NotificationLabels.FAILED_TO_UPDATE_USER_ROLE);
  } finally {
    store.dispatch(closeLoader());
  }
}
