import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { isCurrentTimeGreaterThenProvidedTime } from "../../utils/dateFormatters";

const getAllCertificateService = createAsyncThunk(
  "applicationManagement/getAllCertificateService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.ApplicationManagement.GetAllCertificate,
        abortController && {
          signal: abortController.signal,
        }
      );

      // let activeCerts = request.data?.filter(
      //   (data) => data?.Is_deleted === false
      // );

      let activeCerts = request.data?.filter(
        (data) => !isCurrentTimeGreaterThenProvidedTime(data.valid_to)
      );

      let response =
        activeCerts?.map((data, index) => ({
          id: data?.id ?? index,
          label: data?.certificate_name ?? null,
        })) || [];

      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getAllCertificateService;
