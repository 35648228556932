import { Box, Paper, Stack, Typography, Button } from "@mui/material";
import InputLabel from "./InputLabel";
import { useState, useEffect } from "react";
import { store } from "../../../lib/redux/store";
import Notification from "../../../utils/notificationConfiguration";
import { NotificationLabels } from "../../../utils/constants";
import { closeForm } from "../../../features/forms/formSlice";
import OtpInput from "react-otp-input";

export default function OTPInputComponent({
  id,
  value,
  label,
  touched,
  error,
  setFieldValue,
  timeLimit,
  resendOTP,
}) {
  async function handleResendOTP() {
    let { serviceData } = store.getState().form;

    try {
      await resendOTP(serviceData);
      Notification.success(NotificationLabels.OTP_SENT_SUCCESSFUL);
    } catch (error) {
      Notification.error(NotificationLabels.OTP_SENT_UNSUCCESSFUL);
      store.dispatch(closeForm());
    }
  }

  return (
    <Box sx={{ position: "reltiave" }}>
      <InputLabel label={label} error={error} touched={touched} />

      <OtpInput
        inputType="tel"
        id={id}
        containerStyle={{
          gap: 10,
          width: "100%",
          maxWidth: "500px",
          marginRight: "auto",
        }}
        inputStyle={{
          width: "100%",
          height: "100%",
          aspectRatio: 1,
          borderRadius: "5px",
          border: "none",
          backgroundColor: "rgb(236, 240, 242)",
          fontSize: "1rem",
        }}
        value={value}
        onChange={(otp) => {
          setFieldValue(id, otp);
        }}
        isInputNum
        numInputs={6}
        renderInput={(props) => (
          <Paper
            elevation={0}
            sx={{
              flex: 1,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
              "& > input": {
                background: "white !important",
              },
            }}
          >
            <input {...props} />
          </Paper>
        )}
      />
      <OTPTimerComponent
        timeLimit={timeLimit}
        handleResendOTP={handleResendOTP}
      />
    </Box>
  );
}

function OTPTimerComponent({ timeLimit, handleResendOTP }) {
  const [otpTimer, setOTPTimer] = useState(timeLimit);

  useEffect(() => {
    let interval = setInterval(() => {
      setOTPTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  const formattedTime = () => {
    const minutes = Math.floor(otpTimer / 60);
    const seconds = otpTimer % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
        mt: 2,
      }}
    >
      <Typography variant="subtitle1" sx={{ fontSize: "0.95rem" }}>
        {formattedTime()}
      </Typography>
      <Button
        disabled={otpTimer !== 0}
        size="small"
        variant="outlined"
        sx={{ px: 1.5, py: 0.75, borderRadius: "5px", fontSize: "0.75rem" }}
        onClick={() => {
          setOTPTimer(timeLimit);
          handleResendOTP();
        }}
      >
        Resend OTP
      </Button>
    </Stack>
  );
}
