export default function DownloadIcon({ fill, width }) {
  return (
    <svg width={width} viewBox="0 0 17 17" fill="none">
      <path
        d="M5.26187 8.50004L8.49997 11.9259L11.7381 8.53323M8.49997 2.83337V11.7381M3.64282 14.1667H13.3571"
        stroke="#1B2C58"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
