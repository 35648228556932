import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getSignatureCountService = createAsyncThunk(
  "dashboard/getSignatureCountService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      const response = await Api.get(
        URL.Dashboard.SignatureCount,
        abortController && {
          signal: abortController.signal,
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getSignatureCountService;
