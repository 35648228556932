// export default function keyCertSideBar({ fill, width }) {
//   return (
//     <svg viewBox="0 0 28 29" fill="none" width={width}>

//     </svg>
//   );
// }

export default function keyCertSideBar({ fill, width }) {
  return (
    <svg width={width} viewBox="0 0 30 29" fill={"none"}>
      <path
        d="M25.7871 5.39996C26.5567 6.64535 27 8.11174 27 9.68082C27 14.1996 23.3211 17.8629 18.7842 17.8629C17.9574 17.8629 16.0724 17.6731 15.156 16.9114L14.0094 18.0527C13.0539 19.0043 13.8183 19.0043 14.2005 19.7648C14.2005 19.7648 15.156 21.0973 14.2005 22.4298C13.6272 23.1903 12.0217 24.255 10.1888 22.4298L9.80658 22.8094C9.80658 22.8094 10.9519 24.1419 9.99768 25.4744C9.42439 26.2349 7.89563 26.9954 6.55796 25.6642L5.22159 26.9954C4.30381 27.9093 3.18323 27.3763 2.73864 26.9954L1.59077 25.854C0.52089 24.788 1.14488 23.6336 1.59077 23.189L11.5251 13.2961C11.5251 13.2961 10.5697 11.7751 10.5697 9.68212C10.5697 5.16337 14.2486 1.5 18.7855 1.5C19.8502 1.5 20.8667 1.7015 21.8001 2.06809"
        stroke={fill}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6506 9.68193C21.6489 10.4402 21.3461 11.1668 20.8089 11.7019C20.2716 12.237 19.5438 12.5368 18.7855 12.5354C18.4099 12.5363 18.0379 12.4631 17.6906 12.3202C17.3432 12.1773 17.0275 11.9673 16.7613 11.7023C16.4951 11.4374 16.2838 11.1226 16.1393 10.7759C15.9947 10.4292 15.9199 10.0575 15.9191 9.68193C15.9197 9.30624 15.9944 8.93437 16.1389 8.58756C16.2833 8.24075 16.4947 7.92579 16.7609 7.66068C17.027 7.39558 17.3429 7.18551 17.6903 7.04249C18.0377 6.89947 18.4098 6.8263 18.7855 6.82716C19.1611 6.82647 19.5331 6.89977 19.8804 7.04287C20.2276 7.18596 20.5433 7.39605 20.8093 7.66114C21.0754 7.92623 21.2866 8.24113 21.431 8.58786C21.5753 8.93459 21.65 9.30635 21.6506 9.68193Z"
        stroke={fill}
        strokeWidth="1"
      />
    </svg>
  );
}
