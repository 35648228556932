import EmailTemplateTable from "../../../features/email/EmailTemplateTable";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { resetTemplateState } from "../../../features/email/emailSlice";

export default function EmailTemplate() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetTemplateState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetTemplateState())}
    >
      <Box sx={{ width: 1, height: 1 }}>
        <EmailTemplateTable />
      </Box>
    </ErrorBoundary>
  );
}
